/*
1. Set preference "plpNoPagesContainer" (Merchant Tools > Site Preferences > Custom Site Preference Groups > PLP Section) to  'true'
2. Remove old redundant code for product_tile block and children elements from _search_product_result-cssgrid
*/

$plp-grid-config: (
  item-bg: $white,
  default-columns: 4,
  max-columns: 4,
  gap: 20px,
  gap-color: $white
) !default;

@mixin plp-gridcss($config) {
	$grid-gap-color: map-get($config, "gap-color");

	.l-search_result-content:not(.l-search_result-content_no_hits) {
		--bs-columns: #{map-get($config, "default-columns")};
		--bs-gap: #{map-get($config, "gap")};

		@include make-cssgrid($columns: map-get($config, "max-columns"));

		display: grid;
		margin: 0;
		grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
		grid-template-columns: repeat(var(--bs-columns), 1fr);
		grid-auto-flow: dense;
		gap: var(--bs-gap);
		background-color: $grid-gap-color;

		.b-product_contentasset,
		.b-product_tile_container {
			width: 100%;
			margin: 0;
			padding: 0;
		}

		.b-showcase {
			background-color: $grid-gap-color;
		}

		&.m-three-columns {
			--bs-columns: 3;
		}
	}

	.b-list_item_page,
	.js-infinite_scroll-placeholder,
	.l-search_result-paging-controls {
		grid-column: 1 / -1;
	}

	.l-search_result-paging-controls {
		&,
		&::before {
			background-color: $grid-gap-color;
		}
	}

	.b-product_tile {
		&-special {
			grid-row: span 2;
			grid-column: span 2;
	
			&.right {
				grid-column: -3 / -1;
			}
	
			&.left {
				grid-column: 1 / 3;
			}
	
			.b-product-hover_box {
				flex-grow: 1;
				flex-basis: 0;
			}
	
			.b-product_image {
				display: block;
				height: 100%;
				object-fit: cover;
			}
	
			.b-product_tile,
			.b-product_image-container,
			.b-product_image-wrapper {
				height: 100%;
			}
		}

		&_container {
			&.b-product_contentasset {
				width: 100%;
				grid-column: 1 / -1;

				&:has(.b-plp-editorial_push) {
					grid-column: unset;
				}

				&:has(.b-plp-editorial_push-double) {
					grid-column: span 2;
				}

				.m-three-columns & {
					&:has(.b-plp-editorial_push-double) {
						grid-column: unset;
					}
				}
			}
		}
	}

	.b-plp-editorial {
		display: flex;
		align-items: center;

		&-left,
		&-right {
			width: 50%;
		}

		&_image {
			max-width: 100%;
			aspect-ratio: .73;
			object-fit: cover;
		}

		&-right {
			padding: 40px;

			&:has(.b-product_tile) {
				display: flex;
				justify-content: center;
			}
		}

		&_diamond-card {
			width: 47.8%;
			min-width: 345px;
			margin: 0 auto;
			text-align: center;

			img {
				@extend .m-mask-diamond;

				display: block;
				max-width: 100%;
				aspect-ratio: .73;
			}

			.b-plp-editorial_push &,
			.b-plp-editorial_push-double & {
				width: 100%;
			}
		}

		&_image-link {
			padding-bottom: 0;
			border: 0;
		}

		&_cta {
			@include t-title-h6-h7;

			display: inline-block;
			margin-top: 32px;
			padding: 0 0 3px;
			border-bottom: 1px solid $color_main;
		}

		&_description {
			@include t-title-h7-secondary;

			display: block;
			margin-top: 16px;
			padding: 0 45px;
		}

		&_push {
			img {
				aspect-ratio: .73;
			}

			&-double {
				img {
					aspect-ratio: 1.46;
					object-fit: cover;
					object-position: bottom;
				}

				.m-three-columns & {
					img {
						aspect-ratio: .73;
						object-fit: fill;
					}
				}

				.b-plp-editorial_description {
					padding: 0 16px;
				}
			}

			.b-plp-editorial_description {
				padding: 0 16px;
			}
		}
	}
}

@include plp-gridcss($plp-grid-config);
