.b-accordion,
.b-order_confirmation-accordion {
    &-item {
		@include transition-animation(all, 0.5s, ease-in-out);

		max-height: 100px;
		border-bottom: 1px solid $grey2;

		&.js_accordion_description--open {
			max-height: 1000px;

			.b-accordion-description,
			.b-order_confirmation-accordion-description {
				max-height: 1000px;
			}
		}
    }

    &-title {
		@include t-title-h5;
		@include icon(after, plus);

		position: relative;
		display: block;
		padding: 20px 40px;

		&:hover {
			cursor: pointer;
		}

		&::after {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			right: 40px;
			top: 50%;
			width: 16px;
			height: 16px;
			transform: translateY(-50%);
		}

		.js_accordion_description--open & {
			@include icon(after, minus-long);
		}
    }

    &-description {
        @include transition-animation(all, 0.5s, ease-in-out);

        max-height: 0;
		padding: 0 40px;
        overflow: hidden;
    }
}
