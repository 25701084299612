// ============
// @@Checkboxes
// ============

// =====================
// Mixins for checkboxes
// =====================

@mixin init-checkbox($size: 16px, $padding: 4px, $line-height: 16px) {
	.f-field-checkbox {
        .f-label {
			position: relative;
			display: inline-block;
			min-height: $line-height;
			padding: 0 0 0 calc(#{$size} + #{$padding} * 2);
			line-height: $line-height;

            &-value {
                @include t-title-h11;
            }

			&::before,
			&::after {
				content: '';
				position: absolute;
			}

			&::before {
				top: 0;
				left: 0;
				width: $size;
				height: $size;
				border: 1px solid $color_main;
			}

			&::after {
				top: 5px;
				left: calc(#{$padding} + 1px);
				width: 6px;
				height: 6px;
				background-color: transparent;
				transition: transform 200ms ease-out;
			}
        }

        .f-checkbox {
            position: absolute;
            visibility: hidden;
        }

		.f-checkbox:checked + .f-label::after,
		.ie &.f-checked .f-label::after {
			background-color: $color_second;
			transform: rotate(45deg);
		}
    }
}

@include init-checkbox();
