.b-product_badge {
    img {
        height: 100%;
        width: 100%;
    }

	@include t-title-h9(uppercase);

	padding-right: 12px;
	margin-right: 12px;
	color: $color_second;
	border-right: $border_second;

	&:last-child {
		margin-right: 0;
		padding-right: 0;
		border: 0;
	}
}

.b-product_labels {
    @include t-title-h7;

    color: $color_second;
    text-align: center;
    font-weight: 700;

    .b-last_visited & {
        margin: 0;
    }

    .b-product_content & {
        text-align: left;

        .b-product_labels {
            margin: 0;
        }
    }

    .l-search_result-content & {
        &.b-product_preorder_msg {
            margin: 0;
            padding: 0;
        }
    }

    .b-bag_recap &,
    .l-wishlist_content & {
        text-align: left;
    }
}

.b-content_asset--product-preorder {
    @include t-title-h7;

    margin: 10px 0 0;
    padding: 0;
    background: none;
    color: $color_second;

    .pre-order-pdp-label-info {
        display: none;
    }

    p {
        margin: 0;
        font-size: 13px;
    }
}

.b-quickview-wrapper .b-product_content {
    .b-product_labels ~ .b-product_labels {
        display: none;
    }

    .b-product_labels {
        margin: 0;

        > div {
            margin: 10px 0 0;
        }
    }
}

.l-product_carousel {
    &-wrapper,
    &-static-wrapper {
        .b-product_labels {
			display: none;
			min-height: 17px;
			margin: 6px 0 -26px;
			padding: 0 1%;

            &.b-product_preorder_msg {
                margin: 0;
                padding: 0;
            }
        }
    }
}

.l-homepage-single_product .b-product_tile,
.b-hp_product_slider,
.b-product_last-visited,
.b-slot-grid_bottom .plp-last-view-wrapper {
    .b-product_labels {
        display: none;
    }
}

