@import './bookappointment_popup';

.l-main_bookappointment {
	max-width: 710px;
	margin: 0 auto;
	padding: 45px 0 20px;

	.b-breadcrumbs {
		display: flex;

		.b-breadcrumb-link {
			letter-spacing: 0.14px;
		}

		.b-breadcrumb-item:nth-child(n+2)::before {
			padding-top: 2px;
		}

		&_container {
			margin: 130px 0 0;
		}
	}
}

.b-bookappointment {
	&_input-placeholder {
		float: none;
		width: 50%;
		padding-right: 10px;
		margin-bottom: 35px;

		.f-label,
		.f-field-wrapper {
			float: none;
		}

		.f-textinput {
			padding: 20px 15px 0 0;
			background: right 28px no-repeat;
			background-image: $long-arrow;
			letter-spacing: 0.24px;
		}
	}

	&_input-placeholder .f-label,
	&_form-fields .f-label {
		position: absolute;
		width: auto;
		float: none;
		top: 0;
		left: 0;
		transform: translateY(8px);
		letter-spacing: 0.24px;
		transition: transform 0.3s ease-in-out;
		z-index: 2;
		pointer-events: none;

		&-value {
			@include t-title-h9;

			transition: font-size 0.3s ease-in-out;
		}
	}

	&_input-placeholder,
	&_form-fields .f-field {
		&:not(.f-type-comment) {
			&.f-state-valid .f-label,
			&.f-state-error .f-label,
			&:focus-within .f-label {
				transform: translateY(-16px);

				&-value {
					font-size: 10px;
				}
			}
		}
	}

	&_phone-fields,
	&_form-fields {
		display: flex;
		flex-wrap: wrap;

		.f-field {
			position: relative;
			width: 50%;
			margin-bottom: 35px;
			float: none;

			&:nth-child(odd):not(.f-type-comment) {
				padding-right: 10px;
			}

			&:nth-child(even):not(.f-type-comment) {
				padding-left: 10px;

				.f-label {
					left: 10px;
				}
			}

			.f-textinput {
				padding: 0;
				letter-spacing: 0.24px;
			}

			.f-textinput,
			.f-email {
				padding-top: 20px;
			}
		}

		.f-error_text {
			margin: 0;
			transform: translateY(5px);
		}

		.f-field-checkbox {
			.f-label {
				&::after {
					top: 9px;
				}
			}
		}

		.f-type-comment {
			width: 100%;
			padding: 0;
			margin-bottom: 10px;
			border-bottom: 1px solid currentColor;

			.f-textarea {
				padding: 0;
				height: 125px;
				border: none;

				&::placeholder {
					@include t-title-h9;
				}
			}

			.f-label {
				display: none;
			}

			.char-count {
				position: relative;
				padding-bottom: 2px;
				text-align: right;
				color: $white;
			}

			.char-remain-count,
			.char-allowed-count {
				@include t-title-h9;

				position: absolute;
				right: 0;
				color: $black;
			}

			.char-remain-count {
				display: inline-block;
				right: 29px;

				&::after {
					content: '/';
					display: inline;
				}
			}
		}

		.f-type-calendar,
		.f-type-time-slot {
			.f-field-wrapper {
				position: relative;

				&::after {
					position: absolute;
					right: 0;
					font-size: 16px;
				}
			}
		}

		.f-type-time-slot {
			.f-field-wrapper {
				@include icon(after, booktime);

				&::after {
					top: 24px;
					font-size: 20px;
					pointer-events: none;
				}
			}

			.f-select {
				padding: 20px 0 0;

				option:first-child {
					display: none; // hide disabled first disabled option
				}
			}

			.f-select-wrapper {
				&::after {
					content: none;
				}
			}
		}

		.f-type-calendar {
			.f-field-wrapper {
				@include icon(after, calendar);

				&::after {
					top: 22px;
					pointer-events: none;
				}
			}

			.f-error_message-block {
				opacity: 0;
				// delay error message for calendar

				transition: opacity 0.1ms ease 0.2s;
			}

			&.f-state-error {
				.f-error_message-block {
					opacity: 1;
				}
			}
		}
	}

	&_title,
	&_confirmation-title {
		@include t-title-h2(uppercase);
	}

	&_subtitle,
	&_confirmation-subtitle {
		@include t-title-h7-secondary;
	}

	&_subtitle,
	&_title,
	&_confirmation-title,
	&_confirmation-subtitle {
		margin-bottom: 24px;
		text-align: center;
		letter-spacing: 0.16px;
	}

	&_subtitle {
		max-width: 463px;
		margin: 0 auto 24px;
	}

	&_confirmation {
		$verticalPaddings: 90px;

		display: flex;
   		flex-direction: column;

		/* stylelint-disable unit-no-unknown */

		height: calc(var(--full-dvh) - var(--header-height) - #{$verticalPaddings});

		&-link {
			&:not(:hover):not(:focus):not(:active) {
				color: $white;
			}

			&.g-button {
				display: inline-block;
				width: auto;
				min-width: 230px;
				margin: 0 auto;
				padding-top: 12px;
				letter-spacing: 0.14px;
				text-transform: lowercase;

				&::first-letter {
					text-transform: capitalize;
				}
			}
		}

		.b-breadcrumbs_container {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: 100%;
		}

		&-title {
			margin: 30px 0 25px;
		}

		&-subtitle {
			margin-bottom: 40px;
		}
	}

	&_privacy {
		display: flex;
		justify-content: space-between;
		gap: 20px;

		.f-label {
			width: 100%;

			&::before {
				top: 50%;
				transform: translateY(-50%);
			}

			&::after {
				top: 9px;
			}

			&-value {
				@include t-title-h6;

				letter-spacing: 0.14px;

				&::after {
					content: none;
				}
			}
		}

		.f-field {
			width: 60%;
			margin-bottom: 32px;
		}

		.f-error_message {
			display: block;
			margin-top: 24px;
		}

		&-mandatory-label {
			@include t-title-h9;

			width: 40%;
			padding-top: 5px;
			text-align: right;
			color: $grey4;
			letter-spacing: 0.24px;
		}
	}

	&_submit {
		width: 134px;
	}

	&_phone-fields {
		width: 50%;
		padding-left: 10px;

		.f-type-phonecode {
			width: 35%;

			.f-label {
				display: none;
			}

			.f-select {
				padding-bottom: 0;
			}

			.f-select-wrapper {
				&::after {
					top: 7px;
				}
			}
		}

		.f-type-phone {
			width: 65%;
		}
	}

	&_form-warning-message {
		@include t-title-h11;

		margin-bottom: 40px;
		color: $red;
	}
}
