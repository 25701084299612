@mixin css-clear {
	padding: 0;
	margin: 0;
	text-transform: initial;
}

//Custom title and text snippets, combos and modifications
%title,
.title {
	&-sm-1 {
		@include t-title-h1(uppercase);
	}

	&-sm-2 {
		@include css-clear;
		@include t-title-h2;

		&:not(:last-child) {
			margin-bottom: 50px;
		}
	}

	&-sm-3 {
		@include t-title-h3(uppercase);

		&:not(:last-child) {
			margin-bottom: 24px;
		}
	}

	&-sm-4 {
		@include t-title-h4;
	}

	&-sm-5 {
		@include t-title-h5;
	}

	&-sm-6 {
		@include t-title-h6;
	}

	&-sm-7 {
		@include t-title-h7(uppercase);
	}

	&-sm-8 {
		@include t-title-h8;
	}

	&-sm-9 {
		@include t-title-h9;
	}

	&-sm-10 {
		@include t-title-h10;
	}

	&-sm-11 {
		@include t-title-h11;
	}

	&-sm-h1_secondary {
		@include t-title-h1-secondary;

		&:not(:last-child) {
			margin-bottom: 60px;
		}
	}

	&-sm-h2_secondary {
		@include t-title-h2-secondary;
	}

	&-sm-h3_secondary {
		@include t-title-h3-secondary;
	}

	&-sm-h4_secondary {
		@include t-title-h4-secondary;
	}

	&-sm-h5_secondary {
		@include t-title-h5-secondary;
	}

	&-sm-h6_secondary {
		@include t-title-h6-secondary;
	}

	&-sm-h7_secondary {
		@include t-title-h7-secondary;
	}
}

//Text variations
%text,
.text {
	&-sm-1 {
		@include t-text-1;
	}

	&-sm-2 {
		@include t-text-2;
	}

	&-sm-3 {
		@include t-text-3;
	}

	&-sm-4 {
		@include t-text-4;
	}

	&-sm-5 {
		@include t-text-5;
	}

	&-sm-6 {
		@include t-text-6;
	}

	&-sm-7 {
		@include t-text-7;
	}

	&-sm-8 {
		@include t-text-8;
	}

	//Secondary font
	&-60s {
		@include t-title-h1-secondary;

		&:not(:first-child) {
			margin-top: 24px;
		}

		&:not(:last-child) {
			margin-bottom: 24px;
		}
	}

	&-32s {
		@include t-title-h2-secondary;
	}
}

