.l-main_productsearchresult_nohits {
    .l-breadcrumb {
        width: 100%;
        float: none;
        padding: 0;
    }

    .b-breadcrumb {
        margin: 0;
        line-height: 1;
        text-align: center;

        &-item,
        &-item > a {
            line-height: 1;
        }
    }

    .l-primary_content {
        float: none;
        width: 100%;

        @include style_class_list((b-no_hits-title, b-no_hits-section_header, b-no_hits-search_tips, b-no_hits-search, b-no_hits-help)) {
            padding: 0 9%;
        }
    }

    .l-secondary_content {
        display: none;
    }

	.b-no_hits {
		&-button {
			@extend %g-button-primary;

			margin: 30px 0 0;
			width: 150px;
		}

		&-title {
			@include t-text-6;

			margin-bottom: 19px;
			text-transform: uppercase;
		}

		&-search_title {
			@include t-text-6;

			text-transform: none;
		}

		&-search_info,
		&-search_key,
		&-search_tips {
			@include t-text-6;
		}

		&-help {
			margin-top: 25px;
		}

		&-banner {
			.b-capture_product_id {
				display: none;
			}

			.b-product_tile {
				width: 100%;
			}
		}
	}

	.f-textinput-no_hits {
		@include t-text-6;

		margin: 20px 0 0;
		width: 260px;
	}

    .b-last_visited {
        &-list {
            margin: 0 -1%;
            letter-spacing: 0;
            font-size: 0;
        }

        &-item {
            display: inline;

            .b-content_asset--product-preorder {
                display: none;
            }

            .b-product_tile {
                width: 35%;
                margin: 0 0 40px 10%;
            }
        }
    }

    .b-last_visited-title {
        margin: 65px 0 40px;
    }
}

.l-search_result-content_no_hits {
    margin-left: 0;

    .b-recommendation_title {
        padding: 35px 0 27px;
        border-top: 1px solid $color_second;
    }

    .l-recommendations {
        .l-product_carousel-item {
            display: inline-block;
            width: 25%;
            margin: 10px 0.9%;
            padding: 0;
            vertical-align: top;
            text-align: center;

            .b-product_tile {
                width: 100%;
            }
        }
    }
}
