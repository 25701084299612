.b-slide-flyout {
	&-wrapper--minicart,
	&-wrapper--wishlist {
		max-height: 0;
		overflow: hidden;
		visibility: hidden;

		&.m-show.active {
			max-height: 999px;
			animation: slideDownMaxHeight $flyout_time_delay linear; /* stylelint-disable-line */
			visibility: visible;
		}

		&.m-animation-out.active {
			max-height: 0;
			animation: slideUpMaxHeight $flyout_time_delay linear; /* stylelint-disable-line */
			visibility: visible;
		}
	}

	&-wrapper {
		&:not(&--minicart):not(&--wishlist) {
			position: fixed;
			display: flex;
			justify-content: flex-end;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			cursor: initial;
			animation-duration: 0.3s;
			animation-fill-mode: both;
			transition: all .3s ease;
			opacity: 1;
			z-index: 199;

			&.m-show {
				visibility: visible;
				animation-name: fadeInGrow; /* stylelint-disable-line */
			}

			&.m-animation-out {
				visibility: hidden;
				animation-name: fadeOutGrow; /* stylelint-disable-line */
			}

			&.m-right {
				&.m-show .b-slide-flyout {
					animation-name: slideInRight; /* stylelint-disable-line */
					visibility: visible;
				}

				&.m-animation-out .b-slide-flyout {
					animation-name: slideOutRight; /* stylelint-disable-line */
					visibility: visible;
				}
			}

			&.m-center {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				animation: none;
				visibility: visible;
			}

			.b-slide-flyout {
				position: relative;
				display: flex;
				height: 100%;
				width: calc(100% / 3);
				max-height: 100vh;
				background: $white;
				z-index: 1;
				animation-duration: 0.3s;
				animation-timing-function: linear;
				animation-fill-mode: both;
				will-change: transform;

				@include respond-to(portrait_tablet) {
					width: calc(100% / 2);
				}

				&_container {
					display: flex;
					flex-direction: column;
					height: 100%;
					width: 100%;
					padding-top: 30px;
				}

				&-overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 0, 0, .6);
					z-index: 0;
				}

				&_close-btn {
					top: 32px;
					right: 40px;
				}

				.b-flyout-static-title,
				.b-slide-flyout_title {
					@include t-title-h3(uppercase);

					display: inline-block;
					padding: 0 40px;
					margin-bottom: 40px;
				}

				.b-flyout-static-content {
					@include t-title-h5-secondary;

					position: absolute;
					top: 50%;
					left: 40px;
					right: 40px;
					transform: translateY(-50%);
				}

				.b-slide-flyout_description {
					@include t-title-h9;

					display: flex;
					align-items: end;
					flex-grow: 1;
					padding: 40px;
				}

				&:has(.m-flyout-centered) {
					position: absolute;
					width: 100%;
					height: fit-content;
					max-width: 680px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					.b-flyout-static-content {
						position: static;
						transform: none;

						.social-wechat-wrapper {
							margin: 20px 0;

							img {
								display: block;
								margin: 0 auto;
								width: 80%;
							}
						}
					}
				}
			}
		}

		&--countrySelector {
			.b-accordion {
				overflow: auto;

				&-item {
					max-height: initial;

					&.js_accordion_description--open {
						.b-accordion-description {
							max-height: calc(100vh - 388px);
							overflow-y: auto;
							background: transparent;
							transform: translateZ(0);

							&::-webkit-scrollbar {
								width: 0;
							}
						}
					}
				}

				&-option {
					@include option;

					margin-bottom: 16px;
				}
			}
		}

		&--languageSelector {
			.b-slide-flyout {
				&_subtitle {
					text-transform: uppercase;

					&-container {
						@include t-title-h6;

						padding: 0 40px;
					}
				}

				&_list {
					margin-top: 40px;
					padding: 0 40px;
				}

				&_option {
					@include option;

					margin-bottom: 16px;
				}
			}
		}

		&--countrySelector,
		&--languageSelector {
			.b-slide-flyout {
				&_form {
					display: flex;
					flex-grow: 1;
					flex-direction: column;
					justify-content: space-between;
					overflow: hidden;
				}

				&_submit {
					width: 100%;
					padding: 20px 40px;
					border-top: 1px solid $grey2;
					background: $white;

					&-btn {
						margin: 0;
						max-width: 100%;
					}
				}
			}
		}

		&--color,
		&--width {
			.b-slide-flyout_colors,
			.b-swatches_width {
				display: grid;
				width: 100%;
				padding: 40px;
				grid-template-columns: repeat(3, 1fr);
				grid-gap: 16px 8px;
				overflow-y: auto;

				.b-swatch-color-item,
				.b-swatches_width-item {
					display: flex;
					flex-direction: column;
					gap: 8px;

					&__action {
						all: initial;
						display: flex;

						.b-swatch-color-item__image,
						.b-swatches_width-item__image {
							width: 100%;
							height: 174px;
							pointer-events: none;
							object-fit: contain;
							background-color: $grey1;
						}

						&--selected {
							border: $border_second;
						}

						&:hover {
							cursor: pointer;
						}
					}

					&__title {
						@include t-title-h9;
					}
				}
			}
		}

		&--size {
			.b-slide-flyout_size {
				display: flex;
				flex-direction: column;
			}

			.b-variation-dropdown {
				overflow: auto;
			}

			.b-variation-dropdown,
			.b-variation-value {
				width: 100%;
			}

			.b-swatches_size {
				display: flex;
				flex-direction: column;
				padding: 0 40px;
				gap: 40px;

				&-item {
					display: flex;
					align-items: center;
					justify-content: space-between;

					&-selected {
						.b-swatches_size-link {
							color: $color_second;
						}
					}
				}

				.b-swatches_size-link {
					@include t-title-h7;

					border: none;
				}

				.b-swatches-link_notifyme {
					color: $grey4;

					&:hover {
						cursor: pointer;
					}
				}

				.b-swatches_low-in-stock {
					color: $grey4;
				}

				.b-swatches_notifyme-text {
					@include icon(after, mail);

					&::after {
						margin-left: 8px;
						font-size: 12px;
					}
				}
			}
		}
	}

	&_close-btn {
		@include icon(before, close);

		position: absolute;
		top: 16px;
		right: 13px;
		width: 16px;
		min-width: 0;
		height: 16px;
		padding: 0;
		color: currentColor;
		background: none;
		outline: 0;
		border: none;
		line-height: 1;
		cursor: pointer;

		&:hover,
		&:focus {
			background: none;
			border: none;
			outline: 0;
		}

		&::before {
			font-size: 16px;
}

		&_text {
			@include visually-hidden();
		}
	}
}
