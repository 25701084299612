.f-state-error {
    .textinput,
    .f-textinput,
    .f-email,
    .f-password,
    .f-textarea,
    &.textinput,
    &.f-textinput,
    &.f-email,
    &.f-password {
		border: 0 none;
		border-bottom: 1px solid $validation-color;

		&::placeholder {
			color: $validation-color;
		}
    }
}
