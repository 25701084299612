/*
#BLOCK country select page
*/
.l-countryselect {
	width: 100%;
	max-width: 650px;
	margin: 50px auto;
	padding: 0 35px;
}

.b-countryselect {
	&-title {
		@include t-title-h8(uppercase);

		margin: 0 0 $padding-sm;
	}

	&-asset {
		@include t-text-6;

		margin: 0 0 $padding-md;
	}

	&-input {
		margin-bottom: $padding-md;

		.f-field-wrapper {
			width: 68%;
		}
	}

	.f-state-required label > span::after {
		content: ":";
	}

	&-currency,
	&-dispatched {
		margin-bottom: $padding-md;

		&_name {
			@include t-title-h9;

			width: 30%;
			margin-right: 2%;
		}

		&_value {
			@include t-title-h8;
		}

		&_name,
		&_value {
			display: inline-block;
		}
	}

	&-button {
		@extend %g-button-primary;

		margin: 25px 0 $padding-md;

		&_close {
			display: none;
		}
	}
}

.b-modal_country_redirect {
	text-align: center;

	&-title {
		@include t-title-h6(uppercase);

		margin-bottom: 32px;
	}

	&-text {
		@include t-title-h9;

		max-width: 640px;
		margin-bottom: 40px;
	}

	&-confirm {
		&_action {
			display: flex;
			justify-content: center;
			gap: 20px;
		}

		&_cancel {
			@extend %g-button-secondary;

			margin-bottom: 0;
		}

		&_continue {
			width: auto;
			margin-bottom: 0;
		}
	}
}
