/*
#Icon font
##SCSS file (_fonts.scss) is automaticaly generated with a Gulp using lodash template(_fonts.tpl). Don't touch it.
Font generates from SVG icons which are in 'fonts/icons' folder
All SVG icons must be of the same size: 500x500.
If they're not we have two options:
1) normalize SVG icons to make them all equal
2) ask a client to provide new SVG icons with the right size
To generate fonts from SVG icons run Gulp task
```
gulp iconfont
```
*/

@font-face {
	font-family: rc-icons;
	src:  url('../fonts/rc-icons.eot?mcyoim');
	src:  url('../fonts/rc-icons.eot?mcyoim#iefix') format('embedded-opentype'),
	  url('../fonts/rc-icons.ttf?mcyoim') format('truetype'),
	  url('../fonts/rc-icons.woff?mcyoim') format('woff'),
	  url('../fonts/rc-icons.svg?mcyoim#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }

@font-face {
	font-family: Garamond;
	src: url('../fonts/Garamond.woff2') format('woff2'),
		 url('../fonts/Garamond.woff') format('woff');
	font-weight: 325;
	font-style: normal;
	font-stretch: normal;
    font-display: swap;
}

@font-face {
	font-family: Gotham;
	src: url('../fonts/GothamBook.woff2') format('woff2'),
		 url('../fonts/GothamBook.woff') format('woff');
	font-weight: 325;
	font-style: normal;
	font-stretch: normal;
    font-display: swap;
}

@font-face {
	font-family: Gotham;
	src: url('../fonts/GothamMedium.woff2') format('woff2'),
		 url('../fonts/GothamMedium.woff') format('woff');
	font-weight: 350;
	font-style: normal;
	font-stretch: normal;
    font-display: swap;
}

@font-face {
	font-family: Gotham;
	src: url('../fonts/Gotham-Light.woff2') format('woff2'),
		 url('../fonts/Gotham-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
    font-display: swap;
}
