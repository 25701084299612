/*
#BLOCK - tabs
*/

body .ui-tabs {
	padding: 0;
	border: none;
	border-radius: 0;
	background: none;

	.ui-tabs-nav {
		margin: 36px 60px;
		padding: 24px 0 0;
		border: none;
		border-radius: 0;
		background: none;

		li,
		.ui-tabs-anchor {
			margin: 0;
			padding: 0 0 10px;
			border-radius: 0;
			background: none;
		}

		li {
			@include t-title-h3;

			display: inline-block;
			padding: 0;
			float: none;
			text-transform: uppercase;
			line-height: 14px;

			&.ui-tabs-active {
				margin: 0;
				padding: 0;

				.ui-tabs-anchor {
					color: $color_main;
					cursor: default;
				}
			}

			&:first-child {
				padding-right: 16px;
				border-right: $border_main;
			}

			&:last-child {
				padding-left: 12px;
			}
		}

		.ui-tabs-anchor {
			padding-bottom: 0;
			color: $color_third;
			border-bottom: none;
			cursor: pointer;
		}
	}

	.ui-tabs-panel {
		padding: 0;
		border: none;
		border-radius: 0;
		color: $color_main;

		&,
		p {
			@include t-text-6;
		}
	}
}
