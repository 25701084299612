.b-ran_creation {
    padding-top: 2%;
    text-align: center;

    &-info {
        margin: 22px 0;
    }

    &-content {
        display: flex;
        justify-content: center;
    }

    &-block {
        width: 240px;
        min-height: 300px;
        padding-bottom: 25px;
    }

    &-main {
        position: relative;
        background: $black;
        color: $white;
    }

    &-arrow {
        position: absolute;
        bottom: -9px;
        left: 50%;
        margin-left: -12px;
        width: 24px;
        height: 24px;
        background-color: $black;
        transform: rotate(-45deg);
    }

    &-img {
        display: block;
        width: 97px;
        height: 97px;
        margin: 25px auto 0;
    }

    &-title {
        margin-top: 15px;
        padding: 0 10px;
        text-transform: uppercase;
    }

    &-description {
        margin: 4px auto 0;
        padding: 0 20px;
    }
}

.b-ran_confirmation {
    margin-bottom: 25px;
    text-align: center;

    &-content {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
    }

	&-info {
		@include t-title-h4;

		margin: 20px 0;
		font-weight: normal;
	}

    &-title {
        margin: 8px 8px 0;
        text-transform: uppercase;
        font-size: 15px;
    }

    &-description {
        margin: 12px auto 0;
        padding: 0 10px;
        line-height: 16px;
    }

    &-img {
        display: block;
        margin: 0 auto;
        width: 60px;
        height: 60px;
    }
}
