.b-popup {
	&-wrapper--bookAppointment {
		.b-popup {
			top: 22%;
			width: 100%;
			max-width: 710px;
			min-height: 512px;
			padding: 40px;
			background-color: $white;
		}

		.f-select {
			padding-left: 0;
			letter-spacing: 0.24px;

			option:first-child {
				display: none !important; // hide first disabled option
			}
		}
	}

	&-bookappointment {
		&_title {
			@include t-title-h3(uppercase);

			margin-bottom: 30px;
			letter-spacing: 0.16px;
		}

		&_subtitle {
			@include t-title-h7-secondary;

			margin-bottom: 10px;
			letter-spacing: 0.16px;
		}
	}

	&_close-btn {
		top: 40px;
		right: 32px;
		transition: none;

		&:hover {
			background-color: transparent;
			opacity: 1;
			color: inherit;
		}

		&::before {
			font-size: 16px;
			color: $black;
		}
	}

	.b-storelocator-map {
		height: 296px;
		margin-bottom: 20px;
	}
}
