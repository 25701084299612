/*
#LAYOUT - Header
*/

$configHeader: (
	"logo": (
		"width": (
			"desktop": 432px,
			"tablet": 250px
		),
		"ar": 10.8
	)
);

$logo-width: 432px;
$logo-ar: 10.8;

.l-header_main { // .l-header_main
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
	background-color: $white;

	.b-header_main-top .b-header_main-wrapper {
		border-bottom: $border_grey;

		.transparent-header & {
			border-bottom: 0;
		}
	}

	//Header with colored logo
	&.b-minimized_header {
		.b-header_main-top .b-header_main-wrapper {
			@include colored-header;
		}
	}

    .b-product-hover_box {
        margin: 0 0 20px;
    }

    .b-product_image-box {
        height: 150px;
        text-align: center;
        background: #f4f4f4;

        &::before {
            content: ' ';
            display: inline-block;
            height: 100%;
            vertical-align: bottom;
        }

        .b-product_image {
            display: inline-block;
            max-width: 177px;
            vertical-align: bottom;
        }
    }

	.b-header_logo_animated {
		display: none;
	}

	.b-language_selector {
		margin-left: auto;
		margin-right: 20px;
	}

    .b-product_name {
        margin-bottom: 11px;
    }

    .product-availability-list {
        @include t-title-h5;

        padding: 0 0 1px;
        color: $color_second;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
    }
}

//Transparent Header
.s-homepage:not(.s-landingpage),
.transparent-header {
	.l-header_main {
		&:hover .b-header_main-wrapper,
		.b-header_main-wrapper:hover {
			@include colored-header;
		}
	}

	.b-header_main {
		&-wrapper {
			background: $gradient_bg;
			border: none;
		}

		&-top {
			position: fixed;
			top: var(--promo_banner-height);
			left: 0;
			right: 0;
			color: $white;
		}
	}

	.b-primary_logo-image {
		background-color: $white;
	}

	.b-menu_category-level_2 {
		height: calc(var(--full-dvh) - (var(--promo_banner-height) + var(--service_menu-height)));
		inset: calc(var(--promo_banner-height) + var(--service_menu-height)) 0 0;
	}
}

.b-header_main {
	$main-header: &;

	&-top {
		position: relative;
		top: -1px;
		z-index: 3;

		.m-search_opened & {
			position: fixed;
			inset: var(--header-height) 0 0;
			overflow: auto;
		}

		#{$main-header}-wrapper {
			@include clearfix;

			position: relative;
			max-width: $max-width;
			margin: 0 auto;

			&:hover,
			.m-menu_opened & {
				background-color: rgba($white, 1);
			}

			@include respond-to(nav) {
				width: auto;
			}

			.m-search_opened & {
				@include colored-header;

				min-height: calc(var(--full-dvh) - (var(--promo_banner-height)));
			}
		}
	}

	&-content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		padding: 20px 40px 16px;

		@include respond-to(nav) {
			padding: 20px 20px 16px;
		}
	}
}

.b-header {
	&_service_menu {
		&-item {
			position: absolute;
			left: 40px;
			top: 21px;

			@include respond-to(nav) {
				display: none;
			}
		}

		&-link {
			position: absolute;
			left: 100px;
			top: 16px;
			padding: 0;
			color: currentColor;
			border: none;

			@include respond-to(nav) {
				display: none;
			}
		}
	}

	&_storelocator_icon {
		@include icon(after, storelocator);

		color: currentColor;
		border: none;

		&::after {
			position: absolute;
			top: -7px;
			left: -17px;
			font-size: 20px;

			@include respond-to(nav) {
				top: -7px;
				left: -2px;
			}

			.m-active-icon & {
				color: $gold;
			}
		}
	}

	&_img-banner {
		flex: 1 0 calc(100% / 3);
		height: calc(var(--full-dvh) - (var(--service_menu-height) + var(--promo_banner-height)));
		width: auto;
		margin-left: auto;

		a {
			border: none;
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}

		@include respond-to(nav) {
			display: none;
		}
	}
}

/*
##LAYOUT - header service menu
*/

.l-header_service_menu {
	position: absolute;
	display: flex;
	align-items: center;
	gap: 32px;
	right: 36px;
	top: 16px;

	@include respond-to(nav) {
		gap: 32px;
	}

	@include respond-to(nav) {
		gap: 20px;
	}

	&-item {
		position: relative;
		display: grid;
		place-items: center;
		z-index: 1;

		.b-wishlist_flyout-title,
		.b-header_search_icon,
		.b-login_dropdown-title,
		.b-mini_cart-title {
			@include t-title-h4;

			transition: initial;
			padding: 0;
			color: currentColor;
			line-height: initial;

			&:hover,
			&:focus {
				color: currentColor;
			}
		}

		&--search {
			margin-top: 4px;
		}
	}
}

/*
##LAYOUT - Left bar in header
*/
.l-header-container {
    position: absolute;
    top: 100%;
    left: 30px;
}

/*
#BLOCK - header banner
*/
.b-header_banner {
    display: none;
    padding: 16px 50px;

    &-text {
        color: $color_main;
        font-size: 14px;
        text-transform: uppercase;
    }

    &-more_information {
        &_link {
            @include underline_on_hover_remove;

            color: $color_main;
        }
    }
}

/* Logo */
.b-primary_logo {
	display: inline-block;
	margin: 0 auto;

	.b-minimized_header & {
		display: none;

		@include respond-to(nav) {
			display: inline-block;
		}
	}

	@include respond-to(nav) {
		left: calc(#{$padding-lg} + 40px);
	}

    &-link {
        @include underline_remove;

        display: inline;
    }

	&-container_desktop {
		width: auto;
		margin: 0 auto;

		&_text {
			@include t-title-h2-secondary(uppercase);

			font-size: 40px;
			color: $white;

			@include respond-to(nav) {
				font-size: 32px;
			}
		}
	}

    &-image {
		$logo-width: map-get(map-get(map-get($configHeader, "logo"), "width"), "desktop");

		width: $logo-width;
		height: calc(#{$logo-width} / #{$logo-ar});
		mask: url('../images/logo.svg') no-repeat top center;

		&,
		.m-search_opened &,
		.l-header_main:hover {
			background-color: $gold;
		}

		@include respond-to(nav) {
			$logo-width: map-get(map-get(map-get($configHeader, "logo"), "width"), "tablet");

			width: $logo-width;
			height: calc(#{$logo-width} / #{$logo-ar});
		}
    }
}

/*
#BLOCK - Help flyout
*/
.b-header_help {
    position: relative;

    &-title {
        @include header_navigation_title;
    }

    &-flyout {
        @include header_navigation_flyout;

        min-width: 244px;
    }

    &:hover &-flyout {
        display: block;
    }

    &:hover &-title {
        @include header_navigation_title_hover;
    }
}

.b-login_account-fields_for_iframe {
    @include clearfix;

    .f-field-password .f-label,
    .f-field-email .f-label {
        display: none;
    }

    .f-field-radio .f-field-wrapper {
        padding: 0 0 0 26px;

        .f-label-value {
            margin-right: 10px;
        }

        .f-label {
            display: inline-block;
            min-width: 0;
            padding-left: 30px;
        }
    }
}

.b-login_account {
    &-form {
        &-sign_up_news {
            text-transform: uppercase;
        }

        .f-field_checkbox {
            padding-top: 14px;
        }
    }

    &-form_fildset {
        margin: 0;
        padding: 0;
        border: 0;
    }

    &-privacy_box {
		@include font_main(11px, 1.3);

		display: none;

        &_link {
            @include underline_on_hover_remove;

            color: $color_second;
        }
    }

    &-login_button {
        display: inline-block;
        margin: 0 16px 0 0;
        width: 212px;
        transition: none;
    }

    &-social_login {
        display: none;
    }
}

.b-registration_user {
    position: relative;
    padding-left: 45px;

    &-createaccount_button {
        min-width: 0;
        text-transform: uppercase;
    }

    &-createaccount_box {
        float: left;
    }
}

/*
#BLOCK - Modal Country and Language
*/

.b-modal_country_lang { // .b-modal_country_lang
    &-form {
        .f-label {
            width: 180px;
            margin-right: 0;

            &-value {
                padding-top: 14px;
            }
        }

        .f-field {
            margin-bottom: 30px;

            &-wrapper {
                float: left;
                width: 310px;

                .f-error_text {
                    margin-bottom: -14px;
                }
            }
        }
    }

    &-title {
        @include t-title-h9;

        margin-bottom: 20px;
        margin-top: -3px;
    }

    &-button {
        margin-bottom: 0;
        margin-left: 180px;
    }
} // /.b-modal_country_lang

/*
#BLOCK - Language Informer
*/
.b-language_informer {
    @include clearfix;
    @include t-title-h9;

    position: relative;

    &-current {
        float: left;
        padding-right: 40px;
        margin-right: 40px;
        border-right: $border_main;
    }

    &-change {
        float: left;
    }

    &-choise {
        position: absolute;
        right: 0;
        top: 0;
    }

    &-link {
        cursor: pointer;
    }
}

//Welcome
.b-login_dropdown-welcome_box { // .b-login_dropdown-welcome_box
    .b-login_dropdown-welcome_name {
        display: inline-block;
        padding: 14px 0 0;
        text-transform: uppercase;
        color: $color_main;
        vertical-align: top;
    }

    > a {
        display: inline-block;
        margin-left: 25px;
        text-transform: uppercase;
        vertical-align: top;
    }
} // /.b-login_dropdown-welcome_box

//Logged in - navigation
.b-logged_in-menu { //.b-logged_in-menu
    margin: 40px 0 15px;
    font-size: 0;

    .b-logged_in-item {
        display: inline-block;
        width: 25%;
        text-align: center;

        > a {
            position: relative;
            display: inline-block;
            padding: 45px 0 0;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                width: 60px;
                height: 45px;
                margin: 0 0 0 -30px;
                background: url("../images/account/account-items-sprite.png") no-repeat top center;
            }
        }

        &--account {
            > a:hover::before,
            > a::before {
                background-position: 0 0;
            }
        }

        &--orders {
            > a::before {
                background-position: -60px 0;
            }

            > a:hover::before {
                background-position: -60px -45px;
            }
        }

        &--wishlist {
            > a::before {
                background-position: -123px 0;
            }

            > a:hover::before {
                background-position: -123px -45px;
            }
        }

        &--details {
            > a::before {
                background-position: -180px 0;
            }

            > a:hover::before {
                background-position: -180px -45px;
            }
        }
    }

    .b-wishlist_empty.b-wishlist-text_link {
        .b-wishlist-quantity_value {
            display: none;
        }
    }

    .b-wishlist-text_link,
    .b-logged_in-link {
        display: inline-block;
        padding-bottom: 2px;
        color: $color_main;
        text-transform: uppercase;
        border-bottom: 1px solid $white;

        &:hover {
            border-bottom: $border_main;
        }

        .b-wishlist-quantity_value {
            position: absolute;
            top: 10px;
            left: 68%;
            max-width: 33px;
            color: $color_main;
        }
    }
}

//Custom title for logged user
.b-login_dropdown-title_logged {
    margin: 0 -5px 0 0;

    .b-login_dropdown-title {
        &::before {
            width: 40px;
            background: #f0f no-repeat 0 0;
        }

        &:hover::before {
            background: #f0f no-repeat -40px 0;
        }
    }
}

.b-header_close_button {
    @include icon(before, close);

    position: absolute;
    top: 16px;
    right: 13px;
    width: 16px;
    min-width: 0;
    height: 16px;
    padding: 0;
    color: currentColor;
	background: none;
    outline: 0;
    border: none;
    line-height: 1;
    cursor: pointer;

	&:hover,
	&:focus {
		background: none;
		border: none;
		outline: 0;
	}

	&::before {
		font-size: 16px;
	}
}

//Custom styles for first visit banner
.b-first_visit_banner.h-minimized {
    display: none;
}

.l-header-search,
.b-login_dropdown,
.l-header-minicart,
.l-header-wishlist {
	position: relative;
	max-height: 999px;
	margin: -1px 0 1px;
	overflow: hidden;
	z-index: 4;
	background-color: $white;
}

.l-header-wishlist {
    max-width: $max-width;

	@include respond-to(laptop) {
        width: auto;
    }
}

.b-wishlist_flyout {
    $_flyout: &;

    &-title {
	    @include font_main(10px, 14px);
        @include icon(before);
	    @include button-as-icon-reset();

        position: relative;
        display: flex;
        margin: 0;
        text-align: center;
		color: $color_main;

        &::before {
            content: icon-char(wishlist-not-selected);
            font-size: 18px;
            vertical-align: bottom;
        }

        &_link {
			position: absolute;
			top: 0;
			right: 0;
			width: 10px;
			height: 10px;
			background: $gold;
			border-radius: 50%;
			font-size: 7px;

			.b-wishlist-quantity_value {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
        }

        & + .l-header_service_menu-arrow {
            right: auto;
            left: 23px;
        }

        ~ .b-wishlist-tooltip {
            display: none;
        }

        &-empty {
            padding: 38px 40px;
        }
    }

    &-list {
        &.b-owl_carousel {
            margin: 10px 0 -13px 9px;

            .owl-stage {
                display: flex;
                align-items: stretch;
            }

            .b-owl_carousel {
                &-nav {
					&_next {
						margin-top: -60px;
					}

					&_prev {
						left: -30px;
						margin-top: -60px;
					}
                }
            }
        }

        .owl-stage-outer {
            position: relative;
            overflow: hidden;
        }
    }

    &-item {
        position: relative;
        display: block;
        height: 100%;

		.b-product-tile_link {
			@include t-title-h3(uppercase);
			@include line-clamp;
		}
    }

    &-block {
        padding: 36px 40px 25px 28px;
    }

    .b-product_tile:hover .b-product_share {
        display: none;
    }

    .b-content_asset {
        &--empty-wishlist-login-flyout {
            padding: 36px 40px;
        }
    }

    .b-wishlist_empty-title {
		@include t-title-h7(uppercase);

		margin: 0;
    }

    .b-product_promo,
    &-total {
        display: none;
    }

    &-name {
        @include t-title-h6;

        display: inline-block;
        margin-bottom: 0;
        padding-left: 20px;
    }

    &-count {
        display: inline-block;
    }

    &-name,
    &-count {
        position: relative;
        z-index: 2;
    }

    &-availability,
    .b-cart_table-body_col_product-attribute {
        display: none;
    }

    .b-product_list-price {
		@include font_main(18px, 1);

        display: block;

        &::before {
            display: none;
        }
    }

    .b-product_name {
        @include product-name(uppercase);

        margin: 3px auto 4px;

        &:hover {
            border-bottom-color: transparent;
        }
    }

    .b-product_price,
    .b-manufacturer_name,
    .b-delivery_message {
        @include font_main(18px, 1);

        display: block;
        margin: 0 0 6px;
    }

    .b-product_price {
        margin-bottom: 20px;
    }

    .b-product_list-name {
        @include font_main(18px, 1);

        .b-product_list-name_link {
            @include underline_remove;
        }
    }

    &-dashboard_label,
    &-dashboard_input {
        display: none;
    }
}

//Header country/language selector
.b-locale-flyout {
    &-wrapper {
        min-width: 700px;
        margin: 0 auto;
        text-align: center;
    }

    max-height: 999px;
    padding: 0 2%;
    transition: max-height 0.3s ease-in;
    overflow: hidden;

    &.h-minimized {
        transition: max-height 0.3s ease-in;
    }

    .b-language_selector {
        &-country {
            display: inline-block;
            width: 385px;
            padding: 3.7em 0 3.5em;
            text-align: left;

            &_title {
                display: block;
            }

            &_list {
                border-right: 1px solid $color_second;
            }

            &_item {
                width: 160px;
                padding-bottom: 17px;
                text-transform: uppercase;

                &:nth-child(odd) {
                    margin: 0 12% 0 0;
                }

                &:nth-child(13) {
                    width: 100%;
                    margin: 0;
                }

                &:nth-child(14) {
                    margin: 0 12% 0 0;
                }

                &:last-child {
                    margin: 0;
                    padding: 0;
                }
            }

            &_link {
                font-size: 12px;
            }
        }

        &-language {
            display: inline-block;
            width: 25%;
            min-width: 210px;
            max-width: 300px;
            padding: 3.7em 0 3.5em 6%;
            text-align: left;

            &_item {
                padding-bottom: 17px;
                text-transform: uppercase;
            }

            &_link {
                font-size: 12px;
            }
        }
    }
}

/*
#BLOCK - Language_selector
=======
*/
.b-language_selector {
	&-container {
		width: 182px;
	}

	&-country {
		display: flex;

		&::after {
			content: '';
			position: relative;
			top: 2px;
			height: 17px;
			width: 1px;
			margin-left: 5px;
			background-color: currentColor;
		}
	}
}
