/*
## LAYOUT - Header
*/
.l-header_checkout {
    position: relative;
    max-width: 1060px;
    margin: 0 auto;
    padding: 40px 40px 50px;
    z-index: 2;

    .b-login_header {
        &-link {
            @include t-title-h8(uppercase);

            padding-bottom: 1px;
            cursor: pointer;

            .b-login {
                &_dropdown {
                    position: absolute;
                    display: block;
                    right: 40px;
                    width: 80%;
                    background: $white;
					border: $border_main;
                    text-align: left;
                    text-transform: none;
                    visibility: hidden;
                    z-index: 50;

                    @include respond-to(portrait_tablet) {
                        width: 287px;
                        padding: 0 20px;
                    }

                    @include respond-to(landscape_tablet) {
                        .b-login_dropdown-flyout {
                            width: 100%;
                        }
                    }

                    &-active {
                        max-height: 999px;
						border: $border_second;
                        cursor: auto;
                        visibility: visible;
                    }
                }

                &_account-forgot_password {
                    right: 0;
                }

                &_account-form {
                    .f-field-email,
                    .f-field-password {
                        width: 100%;
                        margin: 0 0 30px;

                        .f-error_text {
                            margin-bottom: -22px;
                        }
                    }

                    &_row {
                        position: relative;
                        white-space: nowrap;
                    }
                }

                &_wide-customers {
                    width: 55%;
                    min-width: auto;
                }

                @include respond-to(portrait_tablet) {
                    &_wide-create {
                        width: 100%;
                    }
                }

                &_wide-create_new_account_button {
                    transition: none;
                }

                @include respond-to(portrait_tablet) {
                    &_wide {
                        &-customers {
                            display: block;
                            width: 100%;
                            padding: 0 0 25px;
                        }

                        &-create_new_account_button {
                            margin: 0;
                        }
                    }

                    &_account {
                        &-forgot_password {
                            position: static;
                            margin: 0;
                        }

                        &-login_button {
                            position: relative;
                            left: 0;
                            margin: 0 0 12px;
                        }

                        &-form_row {
                            white-space: normal;
                        }
                    }
                }
            }
        }
    }

    .b-header_close_button {
        display: none;
    }

	.b-primary_logo-container_desktop_text {
		color: $gold;
	}
}

/*
## LAYOUT - Header service menu
*/
.l-header_service_menu {
    &-checkout {
        float: right;
        width: 35%;
        margin-bottom: 0;
        list-style-type: none;
    }

    &-list {
        float: right;
        clear: both;
        margin-bottom: 15px;
        text-align: right;

        .b-back_to_shopping {
            text-transform: uppercase;
        }
    }
}

/*
## LAYOUT - Checkout promo banner
*/
.s-checkout {
    .b-slot--checkout-promo-message {
        max-width: 1060px;
        margin: 0 auto;
        padding: 0 40px;
    }
}
