/* #LAYOUT- MINICART LAYOUTS

*/
.l-mini_cart {
    &-totals_subtotals {
        @include clearfix;

        .label {
            @include t-title-h3;

            float: left;
        }

        .value {
            @include t-title-h6;

            float: right;
        }
    }

    &-totals_slot {
        display: none;
    }

    .b-product_image-wrapper {
        display: inline-block;
    }

	&-right {
        width: 20%;
        padding-bottom: 18px;

        @include respond-to(all) {
			right: 0;
            width: 25%;
        }
	}

	&-left {
		$col-margin-right: 20px;

		position: relative;
		width: calc(80% - #{$col-margin-right});
		margin-top: 10px;

		@include respond-to(all) {
			width: calc(75% - #{$col-margin-right});
		}

        .owl-stage-outer {
            position: relative;
            overflow: hidden;
        }
	}

	&_product-tiles {
		padding-right: 30px;

		.b-product-tile_link {
			@include t-title-h3(uppercase);
			@include line-clamp;
		}
	}
}

.b-minicart-quantity,
.b-wishlist-quantity_value {
    @include font_main(7px, inherit);

    &_value {
        &:hover {
            border-color: transparent;
        }
    }

    &_label {
        display: none;
    }
}


.l-flyout-opened-minicart {
	.b-minicart {
		.l-header_service_menu-arrow {
			@include tooltip-arrow;
		}
	}
}

.b-mini_cart-flyout {
    &.b-mini_cart-flyout-empty {
        padding: 39px 40px 32px;
    }
}

.b-mini_cart,
.b-wishlist_flyout-list {
    position: relative;
    max-width: $max-width;
    margin: 0 auto;

    .b-header_close_button {
        right: 13px;
        top: 16px;

        @include respond-to(all) {
            right: 12px;
        }
    }

    &-title {
        @include icon(before, carrello);
		@include button_reset();

        position: relative;
        display: flex;
		height: auto;
		margin: 0;
		border: none;
        font-size: 18px;
		color: $color_main;

        & + .l-header_service_menu-arrow {
            display: none;
        }

		&:hover,
		&:focus {
			background-color: transparent;
			border: none;
		}
    }

    &-flyout {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 40px;

        &_empty_cart {
            @include t-title-h3;

            position: relative;
            display: block;
            margin: 0;
            padding-bottom: 5px;
            text-transform: none;
        }

		&_empty-title {
			padding-left: 0;
		}

		&_title {
			@include t-title-h4(uppercase);
		}

		&_header {
			position: relative;
			width: 100%;
		}

		&_qty {
			@include t-title-h6;

			margin: 0 0 10px 6px;
		}

		&_products {
			position: relative;
			width: 100%;

			&.b-owl_carousel .b-owl_carousel-nav_prev {
				left: -30px;
			}

			&.b-owl_carousel .b-owl_carousel-nav_next {
				right: 0;
			}
		}
    }

    &-summary_title,
    &-flyout_empty_cart {
        @include t-title-h7(uppercase);

        margin-bottom: 10px;

        @include respond-to(all) {
            margin-top: -5px;
            margin-bottom: -12px;
        }
    }

    &-qty_container {
		display: flex;
        margin: 42px 0 27px;
        padding-bottom: 26px;
        border-bottom: 1px solid;
        font-size: 0;

        @include respond-to(all) {
            margin-top: 55px;
        }
    }

    &-subtotal_title {
        @include t-text-6;

        display: inline-block;
        width: 80%;
    }

    &-subtotal_qty {
        @include t-text-6;

        display: inline-block;
        width: 20%;
        text-align: right;
    }

    &-product_attributes {
        display: none;
    }

	.l-product-tile_labels {
		margin-top: 15px;
	}

    .product-availability-list {
        margin: 0;

        .notavailable {
            @include t-title-h10;

            color: $validation-color;
            text-align: center;
        }
    }

    &-checkout_btn {
        @extend %g-button-primary;
    }

    &-product_name {
        @include product-name(uppercase);

        min-height: 14px;
        margin: 3px auto 5px;
        text-align: center;
        overflow: hidden;

        &-link {
            @include t-title-h5;
            @include underline_remove;

            display: inline-block;
            max-width: 200px;
            padding: 1px 0 0;
        }
    }

    &-product_pricing {
        @include font_main(14px, 20px, inherit, 1px);

        margin: 0 0 5px;
        text-align: center;

        &-qty {
            display: none;
        }

        &-price {
            @include t-title-h6;
        }
    }

    &-subtotal {
        margin-bottom: 43px;
        white-space: nowrap;

        @include respond-to(all) {
            margin-bottom: 43px;
        }

        /// ---- that section for PayPal Express once it's switch ON ----
        &_wrap-info {
            display: flex;
            flex-flow: column wrap;

            $subtotal: ".b-mini_cart-subtotal"; /// #{$subtotal}

            .b-paypal_express {
                &-subtotal,
                &-shipping {
                    position: relative;

                    #{$subtotal} {
                        &_label,
                        &_value {
                            @include t-text-6;

                            text-transform: capitalize;
                        }
                    }
                }
            }

            #{$subtotal} {
                margin-bottom: 20px;
            }
        }
    }

    &-totals_slot {
        font-family: $font_main;
        line-height: 20px;
        text-transform: uppercase;
    }

    @include respond-to(all) {
        width: auto;
    }


    &-text_link {
        @include underline_remove;

        position: absolute;
        left: 50%;
        bottom: -36px;
        transform: translateX(-50%);
    }
}

.b-mini_cart {
	&-title {
		.b-minicart-quantity {
			position: absolute;
			top: 0;
			right: 0;
			width: 10px;
			height: 10px;
			background: $gold;
			border-radius: 50%;
			font-size: 7px;

			&_value {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

    .l-mini_cart-right & {
        &-checkout_btn {
			max-width: none;

			&_wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.b-amazon-pay-button {
					order: 3;
				}
			}
        }
    }
}

.b-shipping-info {
    &-icon {
		@include underline_remove;
		@include icon(before);

		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		left: 0;
		top: 0;
		margin: 0;
		width: 18px;
		height: 18px;
		border: 1px solid $black;
		border-radius: 50%;

		&::before {
			content: icon-char(tooltip);
			font-size: 14px;
		}
    }

    &-text {
        position: relative;
    }

    &-content {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-100%, -50%);
        z-index: 99;
        white-space: normal;

        @include respond-to(nav) {
            min-width: 320px;
            max-width: 100vw;
        }

		.b-content_asset {
			margin: 20px 20px 0;
			padding: 20px;
			border: 1px solid;
		}
    }
}
