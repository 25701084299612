.c-search {
	border-top: $border_grey;

	&-container {
		display: flex;
		flex-direction: column;
		padding: 40px 0;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 40px;
	}

	&-close {
		@include button-as-icon-reset();
		@include icon(before, close);

		width: fit-content;
		margin: 0;

		&::after {
			font-size: 16px;
		}

		&:hover {
			cursor: pointer;
		}
	}

	&-field {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-width: 760px;
		padding-bottom: 6px;
		border-bottom: 1px solid $black;

		@include respond-to(nav) {
			flex-grow: 1;
			min-width: initial;
		}

		&__icon {
			@include button-as-icon-reset();
			@include icon(before, arrow-link);

			width: fit-content;
			margin: 0;

			@include respond-to(nav) {
				display: none;
			}

			&::after {
				font-size: 16px;
			}

			&:hover {
				cursor: pointer;
			}
		}

		&__input {
			flex-grow: 1;
			color: $black;
			outline: none;
			border: none;

			&::placeholder {
				color: $grey4;
			}
		}
	}

	&-product-suggestions {
		margin-top: 56px;
	}

	&-popular {
		display: flex;
		flex-direction: column;
		margin-top: 56px;
		padding: 0 40px;
		gap: 16px;

		&__title {
			font-weight: 400;
		}

		&__list {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		&-item {
			text-transform: capitalize;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.b-product_recommendations-list {
		display: flex;
		gap: 20px;
	}

	.b-product_recommendations-title,
	.b-recommendation_title {
		padding: 20px 40px;
	}

	&-phrase-suggestions {
		display: flex;
		flex-direction: column;
		margin-top: 56px;
		padding: 0 40px;
		gap: 16px;
	}

	&-phrase-suggestion-item {
		@include t-title-h5;

		color: $black;

		&:hover {
			cursor: pointer;
		}

		&__match {
			font-weight: 400;
		}
	}

	&-hits {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 50px;
		gap: 12px;

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 12px 40px;
			width: 100%;

			&-view-all {
				width: fit-content;
				height: fit-content;
				padding: 0;
				margin: 0;
				padding-bottom: 6px;
				background: transparent;
				color: $black;
				border: none;
				border-bottom: 1px solid $black;

				&:hover {
					border: none;
					border-bottom: 1px solid $black;
				}
			}
		}

		&__list {
			display: grid;
			width: 100%;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 40px 20px;
		}

		&__view-all {
			width: fit-content;
			margin: 60px 0 0;
			background: transparent;
			border: 1px solid $black;
			color: $black;

			&:hover {
				background-color: $color_second;
				color: $white;
				border-color: $color_second;
			}
		}
	}

	&-hit-container {
		display: flex;
		width: 100%;
		overflow: hidden;
	}

	&-hit {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 16px;
		width: 100%;

		.b-owl_carousel-nav {
			position: initial;

			.b-owl_carousel-nav_prev {
				left: 10px;
			}

			.b-owl_carousel-nav_next {
				right: 10px;
			}

			.arrow-btn {
				all: initial;
			}
		}

		.b-product-tile_img {
			width: 100%;
			height: 100%;
			padding: 0;
		}

		.b-product-tile_img-link {
			border-bottom: none;
		}

		&__details,
		.c-search-hit-hover {
			display: flex;
			flex-direction: column;
			padding: 0 16px;
			gap: 16px;

			.b-product-tile_link {
				@include t-title-h3(uppercase);

				padding: 0;
			}

			&-variation {
				display: flex;
				flex-direction: column;

				.b-variation-attribute {
					display: flex;
					flex-wrap: wrap;
					gap: 20px 16px;

					.b-variation-value {
						all: initial;

						@include t-title-h4;

						&--notifyme {
							color: $grey4;
						}

						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}


		.b-product-tile_add-to-wishlist {
			@include icon(before, wishlist-not-selected);

			background: transparent;
			border: none;
			width: fit-content;
			height: fit-content;
			padding: 0;
			position: absolute;
			top: 32px;
			right: 50%;
			opacity: 0;
			z-index: 2;
			transform: translate(50%, 0);
			transition: opacity .3s ease-in;

			&::before {
				color: $color_second;
				font-size: 20px;
			}

			&--added {
				@include icon(before, wishlist);
			}
		}

		.c-search-hit-hover,
		.b-owl_carousel-nav_controls {
			display: none;
			opacity: 0;
		}

		&:hover {
			.c-search-hit-hover,
			.b-owl_carousel-nav_controls {
				display: block;
				opacity: 1;
			}

			.b-product-tile_add-to-wishlist {
				opacity: 1;
			}

			.c-search-hit__badges {
				opacity: 0;
			}

			.c-search-hit__details,
			.c-search-hit__badges {
				display: none;
			}
		}
	}

	&-no-results {
		display: flex;
		flex-direction: column;
		margin-top: 56px;
		gap: 50px;

		&__title {
			padding: 0 40px;
			font-weight: 400;
			text-transform: uppercase;
		}
	}

	&-editorial {
		display: flex;
		gap: 20px;

		&-item {
			display: flex;
			flex-direction: column;

			&__img {
				@include diamond-box;

				width: 100%;
				height: 100%;
			}

			&__link {
				align-self: center;
				margin-top: 32px;
			}

			&__text {
				@include t-title-h7-secondary;

				padding: 0 50px;
				margin-top: 16px;
				text-align: center;
			}
		}
	}
}
