/*
## ApplePay button
```
<div class="l-applepay-button l-applepay-button--pdp">
  <button class="dw-apple-pay-button dw-apple-pay-cart"></button>
</div>
```
*/
@mixin applepay-button {
	.dw-apple-pay-button {
		&,
		&:hover,
		&:active {
			@content;
		}
	}
}

.l-applepay-button {
	@include applepay-button {
		display: inline-block;
		width: 100%;
		max-width: inherit;
		height: 40px;
		margin: 0 auto 10px;
		-webkit-appearance: -apple-pay-button; /* stylelint-disable-line property-no-vendor-prefix */
		-apple-pay-button-type: check-out;
	}

	.b-applepay-policy-wrapper {
		margin-top: 5px;
		text-align: left;

		a {
			&:hover {
				border-bottom: 1px solid;
			}

			border-bottom: none;
		}

		.f-field-checkbox {
			.f-checkbox:checked + .f-label::after {
				transform: scale(1) translateY(-50%);
			}

			.f-label {
				&::before {
					top: 50%;
					transform: translateY(-50%);
				}

				&::after {
					top: 50%;
				}
			}
		}

		.b-applepay_login_policy {
			display: none;
		}
	}

	&--pdp {
		margin-bottom: 15px;

		.f-label {
			width: 100%;
		}
	}

	&--checkout,
	&--minicart {
		&::after {
			content: '';
			display: block;
			clear: both;
		}

		.dw-apple-pay-button {
			&[disabled='disabled'] {
				display: none;
			}
		}

		.f-field-wrapper {
			position: relative;
			margin-bottom: 16px;

			.f-error_message {
				position: absolute;
				left: 0;
				bottom: -16px;
				text-transform: uppercase;
			}
		}
	}

	&--checkout {
		padding-top: 5px;

		.l-checkout_cart-right & {
			min-width: inherit;
			padding: 5px 16px 0;

			.b-checkout_placeorder-condition {
				margin-bottom: 14px;
			}
		}

		.l-checkout_cart-left & {
			.b-checkout_placeorder-condition {
				margin: 0;
			}
		}

		.dw-apple-pay-button {
			&,
			&:hover,
			&:active {
				margin-bottom: 0;
			}
		}

		.b-applepay-policy-wrapper {
			margin-top: 20px;
		}

		.f-field-checkbox .f-label {
			width: 100%;
		}
	}

	&--minicart {
		.b-applepay-policy-wrapper {
			width: 100%;
			margin-left: auto;
			margin-right: auto;

			.b-checkout_placeorder-condition {
				margin-bottom: 14px;
			}
		}

		.f-checkbox {
			&:checked + .f-label,
			& + .f-label {
				background-size: 12px 12px;
			}

			& + .f-label {
				width: 100%;
				float: none;
				text-align: left;
				font-size: 9px;
				line-height: 12px;
				float: none;

				&-value {
					font-size: 9px;
					line-height: 12px;
					letter-spacing: 0;
				}
			}
		}

		.f-error_message {
			font-size: 10px;
			line-height: 12px;
			text-transform: none;
		}
	}
}
