/*
#BLOCKS - recommendation
*/

.b-products_recommender {
    display: inline-block;
    width: 100%;
    padding: 0 0 30px;

    $bp: ".b-product";

    .l-product_carousel {
        &-list {
            margin: 0;
            font-size: 0;
            letter-spacing: 0;
            text-align: left;
        }

        &-item {
            list-style: none;
        }
    }

    .b-productile_hover-middle_box_inner {
        bottom: 0;
    }

    #{$bp}_tile {
        display: block;
        float: none;
        width: 100%;
        margin: 0 auto;
    }

    .b-quickview {
        display: none;
    }
}

/*My account & Wishlist*/
.l-account,
 .l-wishlist {
    $bp: ".b-product";

    #{$bp}s_recommender {
        .b-recommendation_title {
            @include t-title-h3;

            text: {
                align: center;
                transform: initial;
            };

            margin: 0 0 34px;
        }

        #{$bp}_tile {
            padding: 0 10px 40px;
        }

        .b-owl_carousel-nav_prev,
        .b-owl_carousel-nav_next {
            top: 24%;

            @include respond-to(after-laptop) {
                top: 27%;
            }
        }
    }
}

/* No hits page */
.l-search_result-content_no_hits {
    .b-products_recommender {
        .l-product_carousel {
            .l-product_carousel-item {
                margin: 0;
                width: 100%;
            }

            .b-variation {
                display: block;
            }
        }
    }
}

/* category */
.l-main_search_wrapper {
    $bp: ".b-product";

    #{$bp}s_recommender {
        display: block;
        max-width: 100%;
        margin: 0 auto 94px;

        .l-product_carousel {
            max-width: calc(100% + 40px);
            margin: 0 auto;
        }

        .b-recommendation_title {
            margin: 0 0 34px;
            text-align: center;

            @include t-title-h6;
        }

        #{$bp}_tile {
            padding: 0;
            max-width: 100%;

            #{$bp}_price {
                margin: 27px 0 0;
            }
        }

        .b-owl_carousel {
            &-item {
                padding: 0 10px;
                margin: 0 !important;
            }

			&-nav {
				bottom: 50%;

				&_next {
					right: 10px;
				}

				&_prev {
					left: 10px;
				}
			}
        }

        .owl-stage {
            margin: 0 auto;
        }
    }
}
