.l-journal-page {
    text-align: center;

    .full-screen-slider {
        margin-bottom: 59px;

        @include respond-to(until-laptop) {
            margin-bottom: 99px;
        }
    }

    .asset-half-text-img {
        padding: 0 40px 2px;

        @include respond-to(until-laptop) {
            padding-bottom: 10px;

            .b-asset_body-section_img {
                max-width: 92%;
            }
        }


        @include respond-to(laptop) {
            .b-asset_body-section {
                text-align: left;

                &.text-block {
                    text-align: center;
                }
            }
        }
    }

    .asset-text-under-img {
        padding: 0 40px;

        .b-asset_body-section {
            padding-top: 94px;
            padding-bottom: 24px;

            @include respond-to(until-laptop) {
                padding-top: 90px;
            }
        }

        &.three-in-row {
            padding-left: 20px;
            padding-right: 20px;

            .b-asset_body-section {
                padding-bottom: 72px;
                padding-top: 69px;

                @include respond-to(until-laptop) {
                    padding-bottom: 75px;
                }
            }
        }
    }

    .asset-video-cell {
        padding: 66px 0 0;
    }

    .loader {
        position: static;
        padding: 20px 0 30px;

        @include respond-to(nav) {
            padding: 10px 0 25px;
        }


        height: 50px;

        &-indicator {
            position: relative;
            top: 36%;
            width: 26px;
            height: 26px;
            background: none;

            @include loading-icon-indicator;
        }
    }
}
