/*
#Block - Return Products
*/

.b-return {
	&_information {
		@include t-title-h9;

		margin-bottom: 30px;
		border-bottom: $border_main;

		.b-orders_item-label {
			@include t-title-h9;
		}

		&-data_row {
			padding: 0 0 8px;
		}
	}

    &_product_list-wrapper {
        @include t-title-h8;

        margin: 25px 0 30px;
        border-bottom: $border_main;
        border-top: $border_main;
    }

    &_product_list {
        padding: 20px 0;
        font-size: 0;

        &-product_image,
        &-product_details,
        &-product_price {
            @include t-title-h9;

            display: inline-block;
            vertical-align: top;
        }

        &-product_image {
            width: 15%;
            margin-right: 4%;
        }

		&-product_details {
			width: 59%;
			margin-bottom: 10px;
			word-break: break-all;
		}

        &-product_price {
            width: 19%;
            line-height: 25px;
            text-align: right;
        }

        &-product_return {
            height: 15px;
            margin-top: 10px;
            font-size: 12px;

            &_left .f-field {
                margin-bottom: 10px;

                .f-label {
                    width: 100%;
                    padding-left: 15px;

                    &-value {
                        display: none;
                    }
                }
            }

            .b-return_product_list-title {
                @include t-title-h10;

                position: absolute;
                left: 25px;
            }
        }
    }

    &-summary_list {
        margin-bottom: 30px;
        border-bottom: $border_main;

        .b-summary_list {
            padding: 0;

            &-total_summary,
            &-total_due {
                margin-top: 10px;
                padding: 10px 0;
                border-top: $border_main;
            }
        }

        h2 {
            display: none;
        }
    }

	&_addresses {
		font-size: 0;

		&-shipping,
		&-billing {
			@include t-title-h9;

			display: inline-block;
			width: 45%;
			margin-right: 4%;
			vertical-align: top;

			.b-return_addresses-title_shipping,
			.b-return_addresses-title_billing {
				@include t-title-h9(uppercase);

				margin-bottom: 19px;
				text-transform: uppercase;
			}

			.b-shipping_address {
				padding: 15px 0;
			}
		}
	}

	&_methods-payment,
	&_methods-shipping {
		@include t-title-h7;

		display: inline-block;
		width: 45%;
		margin-right: 4%;
		padding-top: 15px;
		vertical-align: top;
		font-size: 12px;

		h3 {
			@include t-title-h9(uppercase);

			margin-bottom: 19px;
			text-transform: uppercase;
		}

		.b-mini_credit_card {
			@include t-title-h9;
		}
	}

	&_methods-shipping,
	&_methods-data_amount,
	&_methods-data_type {
		@include t-title-h9;
	}

    &_summary_list {
        @include t-title-h6;

        margin-bottom: 30px;
    }

    &_methods-method,
    &_summary_list {
        & > div {
            display: inline;
        }
    }

    &_comments-link {
        @include t-title-h9;
        @include underline_on_hover_remove;
    }

    &_product_list-product_return_right {
        .f-field-select {
            @include clearfix;

            padding-top: 30px;
            font-size: 12px;

            .f-field-wrapper {
                @include myaccount_field_wrapper;
            }
        }
    }

    &_comments {
        .f-field-textarea {
            @include clearfix;

            padding-top: 30px;

            .f-field-wrapper {
                @include myaccount_field_wrapper;

                textarea {
                    width: 100%;
                    min-height: 70px;
                }
            }
        }

        .f-form-error_message {
            @include clearfix;

            clear: both;
            padding: 30px;
            color: $validation-color;
        }
    }

    &_comments-submit {
        @extend %g-button-primary;

        width: auto;
        float: right;
    }

    &_orderreturnauth-form {
        .f-field-wrapper {
            width: 68%;
        }
    }
}

.l-return_guest {
    padding: 50px 20% 0;
    width: 100%;

    &_content {
        @include clearfix;

        max-width: 1170px;
        margin: 0 auto;
        padding: 0 5% 40px;

        .b-return_details h2 {
            @include myaccount_title;
        }

        .b-return_guest {
            margin: 0 auto;
            width: 500px;

            &-title {
                @include t-title-h6;

                margin: 20px 0 10px;
            }

            &-description {
                text-align: left;
            }

            &-login {
                @include clearfix;
            }

            &-login_button,
            &-form_submit {
                @extend %g-button-primary;

                float: right;
            }

            &-form {
                .f-field-wrapper {
                    float: left;
                    width: 68%;
                }
            }
        }
    }

    .f-field-checkbox .f-label {
        width: auto;

        &-value {
            line-height: 15px;
        }
    }
}

/*
Store Credit List
*/

.b-account_store_credit-info {
    @include font_main(12px, 28px, $font_main);
}
