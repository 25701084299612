/* Product Details Page */
@include loading-icon-animation;

html.ipad {
	height: 100%;
	overflow: hidden;

	body {
		height: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: auto;
	}

	&.html_fancybox_opened {
		height: 100vh !important;

		body {
			height: 100vh !important;
			overflow: hidden;
		}
	}
}

.l-pdp,
.b-quickview-wrapper {
	.b-notifyme_onsale {
		margin: 5px 0 0;
	}
}

.l-pdp {
	&_primary_content {
		position: relative;

		&_header {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding: 0 30px 60px;
			gap: $contentGap;

			@include respond-to(all) {
				gap: 0;
			}
		}

		.b-product-additional_info {
			@include font_main(10px, 24px);

			padding-top: 10px;
		}
	}

	.b-productile_hover-middle_box_inner {
		bottom: 0;
	}

	.pre-order-pdp-label {
		display: inline-block;
		width: 100%;
		margin: 25px 0 10px;
		padding: 15px;
		background: $grey1;

		&-info {
			a {
				border-color: $color_main;

				&:hover {
					border-color: transparent;
				}
			}

			.info-pre-order,
			.info-add-text {
				color: $color_main;
			}
		}
	}
}

.l-product {
	&_images_container {
		position: relative;
		float: left;
		width: 50%;
		padding-left: 80px;
		background-color: $grey1;

		&-wrap {
			display: flex;
			max-height: 100%;
		}

		.b-primary_logo {
			@extend .h-hidden;
		}

		.b-owl_carousel-item {
			vertical-align: top;
		}
	}

	&-details {
		display: inline-block;
		padding-top: 35px;
		width: 50%;
		z-index: 2;

		&-wrapper {
			max-width: 400px;
			margin: 0 auto;

			@include respond-to(portrait_tablet) {
				max-width: 300px;
			}
		}

		span.b-swatches-link_notifyme {
			display: none;
		}
	}
}

/*right column pdp and quick view*/
.b-product_preorder-message {
	position: relative;
	display: inline-block;
	width: 100%;
	margin: 0 0 21px;
	padding: 15px 15px 15px 45px;
	background: $color_second;

	a {
		text-decoration: underline;
	}

	&::before {
		@extend %icon;

		content: $icon-info;
		position: absolute;
		top: 50%;
		left: 16px;
		width: 22px;
		font-size: 22px;
		transform: translateY(-50%);
	}

	.b-content_asset {
		display: inline-block;
		padding-top: 0;
		color: $color_second;
		font-size: 12px;
		line-height: 1.5em;
		text-align: left;
		text-transform: none;
	}

	&.b-product_container-additional_info .b-product-additional_info {
		padding-top: 0;
	}
}

.b-product_content {
	position: relative;
	padding-top: 5px;

	.b-product {
		&_badges {
			position: initial;
			justify-content: start;
		}

		&_name {
			@include t-title-h2(uppercase);

			padding: 0;
		}

		&_container {
			&-title {
				display: flex;
				justify-content: space-between;
				margin: 12px 0;
				gap: 28px;
			}
		}

		&_category {
			@include t-text-5(uppercase);

			margin-bottom: 10px;
			color: $color_second;
		}

		&_price {
			display: flex;
			flex-wrap: wrap;
			gap: 10px 4px;

			&-standard,
			&-standard--line_through,
			&-sales {
				@include t-title-h4;

				display: inline-block;
			}

			&-info {
				@include t-title-h11;

				flex-grow: 1;
			}
		}

		&_badge {
			@include t-title-h5(uppercase);

			position: initial;
			color: $gold;

			&-info {
				position: relative;
				margin-bottom: 16px;
				padding: 10px 120px 10px 13px;
				background-color: $grey4;
				border-radius: 4px;

				&-content {
					margin: 0;
					padding: 0;
				}

				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 20px;
					width: 32px;
					height: 32px;
					background: url('../images/made_to_order.png') no-repeat;
					transform: translateY(-50%);
				}
			}
		}

		&_variations {
			&--pdp,
			&--quickview {
				.b-swatches_size-link {
					border: none;
				}
			}
		}

		&_container {
			&-price {
				margin-bottom: 24px;
			}
		}
	}

	.b-variation {
		&-dropdown {
			width: 100%;

			.b-variation-value {
				@include t-title-h9;
			}
		}

		&-item {
			position: relative;
			display: flex;
			flex-flow: column wrap;
			justify-content: space-between;
			padding: 24px 0;
			border-bottom: 1px solid $grey2;

			&:last-of-type {
				border-bottom: 0;
			}

			&--size,
			&--width {
				position: relative;
				display: flex;
				flex-flow: column wrap;
				justify-content: space-between;
				gap: 24px;

				.b-variation-title {
					@include icon(before);

					position: relative;

					&::before {
						content: $icon-plus;
						position: absolute;
						top: 0;
						right: 0;
						font-size: 16px;
					}

					&:hover {
						cursor: pointer;
					}
				}
			}

			.b-variation-title,
			.b-variation-color-title {
				@include t-title-h9;
			}

			.b-variation-title::after {
				display: none;
			}

			.b-variation-title-name,
			.b-variation-color-title {
				&::after {
					content: ':';
				}
			}
		}

		&-size-info {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.b-product_sizeinfo-contentasset {
				@include t-title-h9;

				color: $grey4;
			}
		}

		&-size_chart_link {
			@include underline;
			@include t-title-h9;

			display: flex;
			width: fit-content;
		}

		&-error_message {
			@include t-title-h11;

			display: none;
			clear: both;
			margin: -4px 0 4px;
			color: $validation-color;
		}

		&-few_left_message {
			position: absolute;
			top: 100%;
			left: 50%;
			width: 0;
			height: 0;
			margin: 0 0 0 -1px;
			border: 1px solid transparent;
			background-color: $white;
			text-align: center;
			color: $color_main;
			font-size: 0;
			line-height: 0;
			text-transform: none;
			visibility: hidden;
			opacity: 0;
			z-index: 1;
		}

		&-soldout-message {
			@include font_main(13px, 19px);

			padding-bottom: 8px;
			color: $color_second;
			font-weight: 350;
		}

		&-colors_wrap {
			display: flex;
		}
	}

	.b-swatches {
		&_color,
		&_size,
		&_width {
			display: block;
			height: auto;
			margin: 0 -12px 0 0;
			padding: 20px 0 0;

			&-item {
				margin: 0 20px 12px 0;
			}

			&-link-one {
				pointer-events: none;
				cursor: default;
			}
		}

		&_color {
			position: relative;
			display: flex;
			margin: 0;
			padding: 8px 0 0;
			overflow: hidden;

			&-item {
				display: flex;
				flex-direction: column;
				gap: 4px;
				width: 64px;
				margin: 0 4px 0 0;

				&-selected .b-swatches_color-link_text {
					font-weight: 325;
				}
			}

			&-view-more {
				display: grid;
				place-items: center;
				height: 88px;
				color: $black;
				background-color: $grey1;
				text-decoration: underline;
				text-align: center;

				&:hover {
					cursor: pointer;
				}
			}

			&-link {
				@include underline_remove;

				position: relative;
				display: block;
				width: 64px;
				height: 88px;
				background-size: 100% 100% !important;
				border: none;
				border-radius: 1px 1px 0 0;

				&_text {
					@include t-title-h11;

					font-weight: 300;
				}

				&:hover {
					.b-variation-few_left_message-not_empty {
						visibility: visible;
						opacity: 1;
						z-index: 2;
					}
				}

				&-selected {
					border: $border_second;
				}

				&-one {
					pointer-events: none;
					cursor: default;
				}
			}
		}

		&_size,
		&_width {
			&-item {
				position: relative;

				&-selected .b-swatches {
					&_size-link,
					&_width-link {
						border-bottom: $border_second;
					}
				}
			}

			&-link {
				@include underline_remove;

				display: inline-block;
				text-align: center;
				border: 1px solid currentColor;
			}
		}

		&_size {
			display: flex;
			flex-wrap: wrap;

			&-link {
				@include t-text-5;

				width: 44px;
				height: auto;
				padding: 0;

				&:hover {
					.b-variation-few_left_message-not_empty {
						width: 132px;
						height: auto;
						margin: 0 0 0 -66px;
						padding: 4px;
						border-color: $color_main;
						font-size: 12px;
						line-height: 18px;
						z-index: 2;
					}
				}

				.b-variation-few_left_message-not_empty {
					@include icon(before);

					visibility: visible;
					opacity: 1;
					border: none;

					&::before {
						content: icon-char(lows-in-stock);
						position: absolute;
						top: -28px;
						left: 50%;
						width: 20px;
						height: 20px;
						margin: 0 0 0 -10px;
						color: $color_second;
						font-size: 15px;
						line-height: 20px;
					}
				}
			}
		}

		&_width-link {
			display: inline-block;
			min-width: 44px;
			padding: 0 15px;
		}

		&-link_notifyme {
			@include icon(after);

			&::after {
				content: icon-char(hare-email);
				position: absolute;
				display: block;
				top: 24px;
				left: 0;
				width: 100%;
				font-size: 10px;
				line-height: 12px;
				text-align: center;
			}
		}
	}

	.b-swatches_size-item {
		.b-swatches-link_notifyme {
			&,
			&:hover {
				cursor: pointer;
			}
		}

		.b-swatches_size-link.m-productnull {
			text-decoration: none !important;
			pointer-events: none;
		}

		&.b-swatches-productnull {
			display: none;
		}
	}

	.b-add_to_wishlist {
		@include icon(before);
		@include button-as-icon-reset;

		$icon-size: 20px;

		font-size: 0;
		line-height: initial;

		&:hover {
			cursor: pointer;
		}

		&::before {
			content: icon-char(wishlist-not-selected);
			font-size: $icon-size;
			color: $color_second;
			line-height: initial;
		}

		&--added {
			&::before {
				content: icon-char(wishlist);
			}
		}

		& span {
			display: none;
		}

		&-added_message {
			display: none !important;
		}
	}
}

.b-product {
	&_add_to_cart-submit {
		@extend %g-button-primary;

		max-width: 100%;
		width: 100%;
		margin: 27px 0 20px;

		&[disabled],
		&[disabled]:hover,
		&[disabled]:focus {
			background-color: $white;
			color: $color_main;
			border-color: $color_main;
		}
	}

	&_short-description {
		margin: 37px 0 20px;
	}

	&_link-row {
		@include t-text-5;
	}

	&_badge {
		position: initial;
		justify-content: start;
	}
}

.b-breadcrumbs_container {
	display: grid;
	margin: 20px 0;
	place-items: center;

	.b-product_breadcrumbs {
		display: flex;
	}
}

/*pdp main slider*/
.b-product_thumbnails {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	top: 20%;
	left: 0;
	padding-left: 20px;
	height: 100%;
	z-index: 2;

	&-list {
		margin: 0;
		width: 40px;
		text-align: center;
		font-size: 0;
	}

	&-arrow_up,
	&-arrow_down {
		z-index: 1;
		cursor: pointer;

		&::before {
			display: block;
			width: 24px;
			margin: 0 auto;
			font-size: 12px;
			line-height: 20px;
			text-align: center;
		}
	}

	&-arrow_up {
		@include icon(before, arrow-up);
	}

	&-arrow_down {
		@include icon(before, arrow-down);
	}

	.b-scroll-progress-bar {
		display: none;
		position: absolute;
		width: 1px;
		height: calc(100% - 5px);
		top: 0;
		right: 0;
		background: $grey2;

		&-indicator {
			position: absolute;
			max-height: 100%;
			height: 62px;
			width: 1px;
			background: $beige;
		}
	}
}

.b-product_thumbnail {
	display: inline-block;
	width: 40px;
	height: 56px;
	padding: 0;
	font-size: 0;
	line-height: 0;
	overflow: hidden;
	cursor: pointer;
	border: 1px solid transparent;

	&-image {
		display: block;
		max-width: 100%;
		height: 100%;
		object-fit: fill;
		line-height: 60px;
	}

	&-selected {
		position: relative;
		border: 1px solid $beige;
	}
}

.b-product_primary_image {
	position: relative;
	width: 100%;
	max-width: 640px;
	max-height: 100%;
	margin: 0 auto;

	.b-product_image {
		position: relative;
		display: inline-block;
		width: 100%;
		max-width: 100%;
		max-height: calc(100vh - 90px);
		height: 100%;
		margin: 0 auto;
		object-fit: contain;
		cursor: $icon-zoom-plus, crosshair;
		aspect-ratio: 1;

		.b-product_image_zoomed & {
			aspect-ratio: auto;
		}
	}

	.b-owl_carousel {
		&-nav {
			position: initial;
			&_next {
				right: -30px;

				@include respond-to(nav) {
					right: -10px;
					text-align: center;
				}
			}

			&_prev {
				left: -30px;

				@include respond-to(nav) {
					left: -10px;
					text-align: center;
				}
			}

			&_next,
			&_prev {
				margin-top: -10px;

				&::after {
					font-size: 20px;
				}
			}
		}

		&.owl-loaded {
			overflow: inherit;
		}

		&-item {
			display: inline-flex;
			justify-content: center;
			float: none;

			.fancybox-wrap .fancybox-inner & {
				display: block;
			}
		}

		.owl-stage {
			white-space: nowrap;
			overflow: hidden;
		}
	}

	.b-product_carousel {
		white-space: nowrap;
		overflow: hidden;
	}

	.b-product-image_picture {
		display: block;
		height: 686px;

		.b-product_image_zoomed & {
			height: auto;
		}
	}
}

.b-product_share {
	display: none;
	padding: 4px 0;
	font-size: 0;
	line-height: 0;
	white-space: nowrap;

	&-text {
		@include t-title-h8;
		@include icon(before, share);
		@include underline_on_hover_only;

		position: relative;
		top: -3px;
		margin: 0 0 0 40px;
		cursor: pointer;

		&::before {
			position: absolute;
			left: -40px;
			padding: 0 20px 0 0;
			font-size: 21px;
		}
	}

	&-send_to_friend {
		@include icon(before, share-email);
		@include font_main(14px, 1);

		display: inline-block;
		padding: 0 0 0 11px;
		border: none;

		span {
			display: none;
		}
	}

	&-content {
		display: none;
		z-index: 1;
	}

	.social-share-bar {
		display: inline-block;
	}

	&.b-product_share-tablet,
	&:hover,
	&:focus {
		.b-product_share {
			&-text {
				border-bottom-color: $color_main;
			}

			&-content {
				display: inline-block;
			}
		}
	}
}

.b-product_nav {
	position: absolute;
	top: 41px;
	right: 40px;
	width: 380px;
	z-index: 3;

	@include respond-to(all) {
		width: 280px;
	}


	&-link {
		&_prev,
		&_next {
			position: absolute;
			border: none;
		}

		&_prev {
			left: 0;
		}

		&_next {
			right: 0;
		}
	}

	&-image {
		&_prev,
		&_next {
			display: none;
		}
	}

	&-text {
		&_prev,
		&_next {
			@include font_main(10px, 20px, inherit, 0.1em, uppercase);
		}
	}
}

.b-product-banning-text {
	margin: 14px 0 0;
	padding: 15px;
	background: $validation-color;

	@include respond-to(tablet) {
		width: 100%;
	}


	.b-content_asset {
		&--product-banning-text {
			@include icon(before);

			position: relative;
			padding-left: 35px;

			&::before {
				content: icon-char(lows-in-stock);
				position: absolute;
				top: 50%;
				left: 0;
				width: 10%;
				font-size: 22px;
				transform: translateY(-50%);
			}
		}
	}
}

.b-product_slides {
	clear: both;
	margin: 20px 0;
	font-family: $font_main;
}

.b-product_slide {
	&-link {
		@include icon(after);
		@include underline_remove;

		display: block;
		padding: 0 17px 10px 0;
		text-transform: uppercase;
		font-family: $font_main;

		&::after {
			content: $icon-arrow-down;
			padding: 0 0 0 7px;
		}

		&--active::after {
			content: $icon-arrow-up;
			font-size: 10px;
		}
	}

	&-content:hover {
		cursor: default;
	}

	&-item {
		margin: 5px 0;

		div {
			line-height: 26px;
		}
	}

	&-content {
		display: none;
	}
}

.b-product_material {
	display: inline;
}

.b-product_bottom_details {
	p {
		margin: 0;
	}

	&,
	p {
		@include font_main(10px, 24px, inherit, 0.3em, uppercase);
	}
}

/*special pdp slider*/
.b-product_pdp-contentasset {
	margin: 0 0 20px;

	&-item {
		text-align: center;

		img {
			margin: 0 0 15px;
		}
	}

	&-title {
		@include t-title-h5;
	}

	.b-owl_carousel {
		max-width: 438px;
		padding: 0 45px;
		margin: 0 auto;

		&-nav {
			&_dots {
				position: absolute;
				right: 15px;
				top: calc(50% - 41px);
				width: 0;
				transform: translateY(-50%);
			}

			&_dot {
				@include t-title-h5;

				margin: 0 0 10px;
				padding: 0;
				width: auto;
				min-width: 13px;
				height: auto;
				text-align: right;

				&.active {
					border-bottom-color: $color_main;
				}
			}
		}
	}
}

/*last visited*/
.b-product_last-visited {
	@include clearfix-before;

	> .b-owl_carousel-nav_controls .b-owl_carousel-nav {
		display: none;
	}

	.owl-stage {
		margin: 0 auto;
	}
}

.b-product_promotion {
	@include t-title-h5;

	margin: 0 0 18px;

	&-title,
	&-tooltip_title {
		display: none;
	}
}

.b-last_visited {
	&-title {
		@include t-text-4;

		margin-bottom: 24px;
	}

	&-list {
		display: flex;
		justify-content: center;
		font-size: 0;
		line-height: 0;
	}

	&-item {
		width: 25%;
		margin-right: 20px;
		vertical-align: top;
	}

	.b-product {
		&_tile {
			position: relative;
			float: none;
			width: 100%;
			margin: 0 auto;

			&-add_to_wishlist {
				display: none;
			}
		}

		&_tile_wrapper {
			margin: 0 0 18px;
		}

		&_name {
			padding: 2px 0;
			margin: 0;
		}

		&_price {
			margin: 5px 0 0;
		}
	}

	.b-product_variations {
		&--pdp {
			@include clearfix;
		}
	}
}

.b-delivery_details {
	&-title {
		margin: 0 0 20px;
		text-transform: uppercase;
	}
}

/*special pdp platforms*/
.b-pdp-tiles {
	&-title {
		@include font_main(14px, 24px, inherit, 0.3em, uppercase);

		margin: 0 0 6px;
		text-align: center;
	}

	&-cols {
		@include clearfix;

		margin: 0 -20px;

		.b-pdp-tiles-title {
			margin: 0;
			font-size: 10px;
		}
	}

	&-col {
		width: 33.333%;
		padding: 0 20px;
		float: left;
	}

	&-row {
		display: inline-block;
		width: 100%;
		margin: 0 0 51px;
		text-align: center;

		&-text-item {
			display: inline-block;
			width: 100%;
			margin: 0 0 16px;
			text-align: center;

			.table {
				min-height: 335px;
			}

			.table-cell {
				padding: 30px 0 10px;
				vertical-align: middle;
			}
		}
	}

	&-img {
		margin: 0 0 9px;
		max-width: 100%;
	}

	p {
		@include font_main(24px, 34px);

		margin: 0;
		text-align: center;
	}
}

.b-product_image_zoomed {
	padding: 0;
	bottom: 0 !important;
	top: 0 !important;
	left: 0 !important;
	right: 0 !important;
	height: 100% !important;
	width: 100% !important;

	&.fancybox-wrap .fancybox {
		&-skin,
		&-outer {
			height: 100% !important;
		}

		&-inner {
			padding: 0 17px 0 0;
			height: 100% !important;
			width: calc(100% + 17px) !important;
			overflow-y: scroll !important;
		}
	}

	.fancybox-skin {
		padding: 0 !important;
	}

	.fancybox-wrap.fancybox-inner {
		height: 100% !important;
		width: 100% !important;
	}

	.b-product {
		&_image {
			max-height: none;
			width: 100%;
		}

		&_header {
			display: none;
		}

		&_thumbnails {
			position: fixed;
			top: 70px;
			left: 0;
			padding-left: 30px;
			z-index: 1;

			&-list {
				margin: 0;
				width: 72px;
				font-size: 0;
			}
		}

		&_thumbnail {
			width: 72px;
    		height: 108px;
			margin: 0;

			&-image {
				display: block;
				width: 100%;
				cursor: pointer;
			}
		}

		&_primary_image {
			max-width: none;
			padding: 0;

			&,
			img {
				cursor: $icon-zoom-minus, pointer;
			}
		}
	}

	.b-primary_logo {
		position: fixed;
		display: none;
		margin: 0;
	}

	.l-product_images_container {
		width: calc(100% + 17px);
		max-height: none;
		padding: 0;
		margin: 0;
		float: none;

		&-wrap {
			position: static;
			float: none;
		}

		.b-owl_carousel-item {
			height: auto;
			max-height: none;
			float: none;
		}
	}

	.b-scroll-progress-bar {
		display: none;
	}
}

/*
#BLOCK - need help pop-up content
*/
.b-pdp_need_help {
	&-wrapper {
		text-align: center;

		p {
			@include t-title-h5;

			margin: 0;
			text-align: center;
		}
	}

	&-container {
		display: none;
	}

	&-block {
		margin: 0 0 41px;

		p {
			@include t-text-6;

			display: block;
			margin: 0 0 9px;
		}
	}

	&-buttons {
		margin: 0 -20px;
		padding: 0 20px;

		&_btn {
			@include g-button($margin: 0 auto);

			color: $color_second;
		}
	}
}

/*
#BLOCK - notify my pop-up
*/
.b-notifyme_form {
	width: 390px;
	padding: 0 0 2px;
	text-align: center;

	//Notify me in popup flag styles
	.f-type-marketingnotify {
		.f-label {
			display: block;
			width: 100%;
			text-align: left;

			&::before {
				top: 3px;
			}
		}
	}

	&-message {
		&_not_available,
		&_notify {
			@include t-text-4;

			display: block;
			text-transform: none;
			text-align: center;
		}
	}

	.f-field {
		padding-top: 13px;
		margin: 0 0 30px;
	}

	.f-label {
		display: none;
	}

	.f-email {
		@include t-text-6;

		width: auto;
		min-width: 300px;
		height: 40px;
		padding: 12px 10px 10px;
		border: 0;
		border-bottom: 1px solid $black;
		background: none;
		color: $black;
		text-align: center;

		&::placeholder {
			color: $black;
			text-transform: uppercase;
		}
	}

	& .f-label-value {
		@include t-title-h7;
	}

	&-submit {
		@extend %g-button-primary;

		margin: 0 auto 26px;
	}

	&-privacy {
		@include t-title-h7;

		margin: 0 -4px;
		padding-bottom: 1px;

		&_link {
			@include t-title-h7;

			border-bottom: 1px solid currentColor;
			overflow: visible;
		}
	}

	&-title {
		@include t-text-4;

		margin: 0 0 18px;
		text-transform: none;
	}

	&-result {
		@include t-title-h7;

		text-align: center;
	}
}

/*
#BLOCK - size chart pop-up
*/
.b-size_chart {
	&-container {
		width: 100%;
		max-width: 100%;

		.table-wrapper {
			max-width: 100%;
			overflow-x: auto;
		}

		table {
			width: 1116px;
			margin: 0;
			border-collapse: collapse;
			overflow: hidden;

			tr {
				position: relative;

				&:last-child {
					td,
					th {
						padding-bottom: 7px;
					}
				}

				&:not(:last-child) {
					border-bottom: 1px solid $grey4;
				}

				&:not(:first-child) {
					th:first-child {
						font-weight: 350;
					}
				}

				&:first-child {
					background-color: $gold;
					color: $white;

					td,
					th {
						padding: 10px 0;
						border: 1px solid $gold;
						letter-spacing: 0.24px;
					}
				}

				th {
					@include t-title-h10;
				}
			}
		}

		td,
		th {
			@include t-title-h10;

			position: relative;
			padding: 10px 0;
			width: auto;
			width: 60px;
			text-align: center;
			letter-spacing: 0.12px;
			border: 0;

			&:first-child {
				@include t-title-h8(uppercase);

				width: 110px;
				padding: 10px 20px;
				letter-spacing: 0.24px;
			}

			&:hover {
				&::before {
					content: '';
					position: absolute;
					width: 100%;
					height: 10000px;
					left: 0;
					top: -5000px;
					background-color: $grey1;
					z-index: -2;
				}
			}
		}

		td {
			&:first-child {
				text-align: left;
			}
		}

		.b-size_chart_content .b-size_chart-text {
			@include t-title-h9;
		}
	}

	&-title {
		@include t-title-h3(uppercase);

		margin-bottom: 28px;
		text-align: left;
	}

	&-text {
		width: 50%;
		margin-bottom: 28px;
		text-align: left;

		&:last-of-type {
			margin-bottom: 40px;
		}
	}

	&-tabs {
		.b-size_chart-container.ui-tabs & {
			margin: 0 0 28px;
			padding: 0;

			.b-size_chart-title {
				margin: 0;
				line-height: 1;
			}
		}
	}
}

.fancybox-size_chart {
	width: calc(100% - 40px) !important;
	max-width: 1196px !important;
	left: 50% !important;
	top: 50% !important;
	height: auto;
	padding: 25px;
	box-sizing: border-box;
	transform: translate(-50%, -50%) !important;

	.fancybox-skin {
		padding: 0;
	}

	.fancybox-inner {
		padding-right: 0 !important;
	}
}

/*
#BLOCK - send to friend pop-up
*/
.b-send_to_friend_form {
	width: 460px;

	&-title {
		@include t-title-h2(uppercase);

		margin: 0 0 34px;
		text-align: center;
	}

	&-submit {
		float: right;
		margin: 0;
	}

	&-label_required {
		display: none;
	}

	&-privacy {
		position: absolute;
		bottom: 2px;
		left: 0;
		width: 160px;

		&_link {
			@include t-text-8;
		}
	}

	.f-field {
		float: none;

		&-wrapper {
			width: auto;
			float: none;
			margin: 0 0 0 152px;
		}
	}

	.f-textinput,
	.f-email,
	.f-password {
		height: 40px;
	}

	.f-label {
		width: 152px;
		margin: 0;
		padding: 0 5px 0 0;
		float: left;
	}

	.char-count {
		@include font_main(10px, 16px, inherit, 0.2em);

		margin: 4px 0 -16px;
	}

	&-button_box {
		width: 100%;
		padding-left: 152px;
		overflow: hidden;
	}

	.b-subscribe_to_newsletter {
		.f-label {
			width: 100%;
			padding-left: 24px;
			margin-bottom: $padding-lg;
		}
	}
}

.b-send_to_friend_response-title {
	@include t-text-4;

	text-transform: none;
}

/*
#BLOCK - Care & Details pop-up content
*/
.b-care_details {
	&-content {
		h2 {
			margin-bottom: 10px;
		}
	}

	&-code {
		margin-top: 48px;
	}
}

/*
Status message
*/
.b-status_message {
	margin-bottom: 20px;

	&-not_available {
		@include t-title-h11;

		color: $validation-color;
	}
}

.b-content_asset {
	&--special-content-pdp {
		margin: 0 0 78px;
	}

	&--special-content-pdp-platforms {
		margin: 0 -40px;
		padding: 53px 40px 0;
		border-top: 1px solid $color_second;
		border-bottom: 1px solid $color_second;
	}
}

.b-product-readmore {
	display: none;
}

.b-product_bottom {
	&-container {
		margin: 40px 0;
	}

	&-text {
		@include t-title-h3-secondary;

		max-width: 716px;
		margin: 0 auto;

		&_container {
			padding: 65px 0;
			text-align: center;
		}
	}

	&-image {
		display: block;
		width: 100%;
		max-width: 100%;
		height: 100%;

		&_container {
			height: 710px;
		}
	}
}

.b-product_long_description {
	margin-bottom: 24px;

	&-code {
		margin-top: 30px;
		color: $grey4;
	}

	&-text,
	&-code {
		@include t-title-h7-secondary;

		div,
		ul,
		span,
		p {
			font: inherit;
		}
	}

	&-list {
		margin-top: 30px;
		padding-left: 25px;
		list-style: initial;
	}
}

.b-product_middle-content {
	display: flex;
	margin: 200px auto 0;
	padding: 0 40px;
	clear: both;
	gap: 80px;

	@include respond-to(laptop) {
		margin-top: 100px;
	}

	.b-product_description-block,
	.b-customer_service {
		flex: 1 1 640px;
	}

	.b-product_tabs-menu {
		.b-accordion {
			&-item {
				border-bottom: none;
				border-top: 1px solid $grey2;
			}

			&-title {
				padding: 24px 0;

				&::after {
					right: 0;
				}

				&-text.m-product-tab-artisan-tip {
					@include icon(before, diamond);

					$icon-size: 12px;

					position: relative;
					padding-left: 0;
					transition: all .2s linear;

					&:hover {
						padding-left: 20px;

						&::before {
							opacity: 1;
						}
					}

					&::before {
						position: absolute;
						display: flex;
						align-items: center;
						left: 0;
						top: 50%;
						width: $icon-size;
						height: $icon-size;
						font-size: $icon-size;
						transform: translateY(-50%);
						opacity: 0;
						transition: all .2s linear;
					}
				}
			}


			&-title,
			&-title-text {
				@include t-title-h6(uppercase);
			}

			&-description {
				padding: 0 80px 0 0;

				&_content {
					@include t-title-h7-secondary;

					max-width: 560px;
					padding-bottom: 24px;

					p,
					ul,
					span,
					div { // needed if manager decides to add another asset without classes and with different structure
						font: inherit;
					}

					ul {
						padding-left: 25px;
						list-style: initial;
					}

					img {
						margin-top: 16px;
					}
				}
			}
		}
	}
}
