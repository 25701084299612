/* Styles for Special PLP */

.l-special_plp {
    padding: 0 2%;
    font-size: 0;

    &.m-four-columns {
        .video-teaser-bottom {
            position: relative;
            margin: 0 0 40px;

            > img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }

            .play-video {
                position: absolute;
                display: block;
                top: 50%;
                left: 50%;
                width: 50px;
                height: 50px;
                margin: -25px 0 0 -25px;
                cursor: pointer;
                background: url("../images/video-bg-t.png") no-repeat center center;
            }
        }

        iframe {
            display: block;
            max-width: 100%;
            max-height: 100vh;
            margin: 0 auto 40px;
        }
    }

    .grid2x1 {
        width: 48%;
    }

    img.grid2x1 {
        margin: 0 1% 2%;
    }

    .grid2x2 {
        width: 48%;
    }

    .left {
        float: left;
    }

    .right {
        float: right;
    }

    &-banner {
        clear: both;
        text-align: center;

        img {
            display: block;
            width: 100%;
        }

        > iframe {
            max-width: 100%;
        }
    }

    &-intro_text {
        @include font_main(16px, 26px);

        width: 50%;
        margin: 20px auto;

        @include respond-to(tablet) {
            width: 80%;
        }


        h1 {
            @include font_main(30px, 1);

            @include respond-to(tablet-portrait) {
                @include font_main(28px, 32px);
            }


            @include respond-to(tablet-landscape) {
                @include font_main(26px, 30px);
            }
        }

        p {
            @include respond-to(tablet-portrait) {
                @include font_main(16px, 24px, 0);
            }


            @include respond-to(tablet-landscape) {
                @include font_main(14px, 21px);
            }
        }
    }

    &-resp_container {
        @include clearfix;

        position: relative;
    }

    &-bottom {
        width: 50%;

        .grid2x1 {
            width: 100%;
        }

        &.left {
            left: 0;
        }

        .b-product_tile {
            width: 46%;
            margin: 0 2% 40px;

            &.grid2x1 {
                width: 100%;
            }
        }
    }

    .video,
    .video2x2 {
        margin: 0 0 40px;

        .grid2x2 {
            iframe {
                position: absolute;
                width: 50%;
                height: 100%;
                max-height: none;
                margin: 0;
            }
        }

        &.b-product_tile {
            display: inline-block;

            iframe {
                position: absolute;
                width: 25%;
                height: calc(100% - 117px);
            }

            &.grid2x1 {
                iframe {
                    width: 50%;
                }
            }
        }

        &.l-special_plp-resp_container {
            .b-product_tile {
                &.grid2x1,
                &.grid1x1 {
                    iframe {
                        height: 50%;
                    }
                }
            }
        }
    }

    &-row {
        position: relative;
        clear: both;
    }
}
