.ui-datepicker {
	&.ui-widget {
		width: 345px;
		margin: 25px auto;
		padding: 0;
		border: 0.5px solid $black;
		background-color: $white;
		z-index: 100 !important; // overwrite inline styles

		.ui-datepicker-header {
			padding: 12px 0;
			background-color: $grey2;
		}

		td {
			@include t-title-h9;

			padding: 3px;
		}

		.ui-datepicker-title {
			margin: 0 50px;
		}

		th,
		.ui-datepicker-title {
			@include t-title-h5;
		}

		a {
			padding: 8.5px 10.5px;
			border: none;
			text-align: center;
		}

		.ui-datepicker-other-month .ui-state-default,
		.ui-datepicker-week-end .ui-state-default {
			text-align: center;
		}

		.ui-datepicker-other-month {
			pointer-events: none;

			.ui-state-default {
				color: $grey3;
			}
		}

		.ui-datepicker-prev {
			@include icon(before, arrow-left);

			left: 32px;

			&::before {
				font-size: 16px;
			}
		}

		.ui-datepicker-next {
			@include icon(before, arrow-right);

			right: 45px;

			&::before {
				font-size: 16px;
			}
		}
	}
}
