/*
#Typography
*/

html {
	background-color: $white;
    color: $color_main;
    font: 325 14px / 24px $font_main;
	letter-spacing: $ls-main;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		letter-spacing: $ls-main;
		font-weight: 400;
	}
}

/*
##Typography Mixins
*/

@mixin font_main($font-size, $line-height, $font-family: inherit, $letter-spacing: $ls-main, $text-transform: inherit, $font-weight: normal) {
	font-size: $font-size;
	line-height: $line-height;

    @if $font-family != inherit {
		font-family: $font-family;
    }

    @if $letter-spacing != inherit {
        letter-spacing: $letter-spacing;
    }

    @if $text-transform != inherit {
        text-transform: $text-transform;
    }

	@if $font-weight != normal {
		font-weight: $font-weight;
	}
}

/*
font styles
###Titles mixins
*/

@mixin t-title-h1($text-transform: none) {
	@include font_main(24px, 40px, $font_main, $letter-spacing: $ls-3, $text-transform: $text-transform, $font-weight: 350);
}

@mixin t-title-h2($text-transform: none) {
	@include font_main(16px, 24px, $font_main, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 350);
}

@mixin t-title-h3($text-transform: none) {
	@include font_main(16px, 24px, $font_main, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 325);
}

@mixin t-title-h4($text-transform: none) {
    @include font_main(16px, 24px, $font_main, $letter-spacing: $ls-2, $text-transform: $text-transform, $font-weight: 300);
}

@mixin t-title-h5($text-transform: none) {
    @include font_main(14px, 24px, $font_main, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 350);
}

@mixin t-title-h6($text-transform: none) {
    @include font_main(14px, 24px, $font_main, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 325);
}

@mixin t-title-h6-h7($text-transform: none) {
    @include font_main(14px, 16px, $font_main, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 325);
}

@mixin t-title-h7($text-transform: none) {
    @include font_main(14px, 24px, $font_main, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 300);
}

@mixin t-title-h8($text-transform: none) {
	@include font_main(12px, 24px, $font_main, $letter-spacing: $ls-2, $text-transform: $text-transform, $font-weight: 350);
}

@mixin t-title-h9($text-transform: none) {
	@include font_main(12px, 18px, $font_main, $letter-spacing: $ls-2, $text-transform: $text-transform, $font-weight: 325);
}

@mixin t-title-h10($text-transform: none) {
	@include font_main(12px, 16px, $font_main, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 300);
}

@mixin t-title-h11($text-transform: none) {
	@include font_main(10px, 16px, $font_main, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 325);
}

@mixin t-title-h1-secondary($text-transform: none) {
	@include font_main(60px, 70px, $font_second, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 400);
}

@mixin t-title-h2-secondary($text-transform: none) {
	@include font_main(32px, 40px, $font_second, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 400);
}

@mixin t-title-h3-secondary($text-transform: none) {
    @include font_main(28px, 40px, $font_second, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 400);
}

@mixin t-title-h4-secondary($text-transform: none) {
    @include font_main(24px, 32px, $font_second, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 400);
}

@mixin t-title-h5-secondary($text-transform: none) {
    @include font_main(20px, 32px, $font_second, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 400);
}

@mixin t-title-h6-secondary($text-transform: none) {
    @include font_main(20px, 24px, $font_second, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 400);
}

@mixin t-title-h7-secondary($text-transform: none) {
    @include font_main(16px, 24px, $font_second, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 400);
}

@mixin product-name($text-transform: none) {
	@include font_main(16px, 28px, $font_main, $letter-spacing: $ls-1, $text-transform: $text-transform, $font-weight: 325);
}

@mixin t-text-1($text-transform: none) {
	@include font_main(24px, 40px, $font_main, $text-transform: $text-transform, $font-weight: 350);
}

@mixin t-text-2($text-transform: none) {
	@include font_main(16px, 24px, $font_main, $text-transform: $text-transform, $font-weight: 350);
}

@mixin t-text-3($text-transform: none) {
	@include font_main(16px, 40px, $font_main, $text-transform: $text-transform, $font-weight: 325);
}

@mixin t-text-4($text-transform: none) {
    @include font_main(16px, 24px, $font_main, $text-transform: $text-transform, $font-weight: 300);
}

@mixin t-text-5($text-transform: none) {
    @include font_main(14px, 24px, $font_main, $text-transform: $text-transform, $font-weight: 350);
}

@mixin t-text-6($text-transform: none) {
    @include font_main(14px, 24px, $font_main, $text-transform: $text-transform, $font-weight: 325);
}

@mixin t-text-7($text-transform: none) {
    @include font_main(14px, 24px, $font_main, $text-transform: $text-transform, $font-weight: 300);
}

@mixin t-text-8($text-transform: none) {
	@include font_main(12px, 24px, $font_main, $text-transform: $text-transform, $font-weight: 350);
}

@mixin t-navigation-link($font-family: inherit, $text-transform: none) {
    @include font_main(12px, 14px, $font-family: $font-family, $letter-spacing: $ls-navigation, $text-transform: $text-transform);
}

/*
#TYPOGRAPHY Styles For Static Pages
*/

h1 {
    @include t-title-h1;
}

h2 {
    @include t-title-h2;
}

h3 {
    @include t-title-h3;
}

h4 {
    @include t-title-h4;
}

h5 {
    @include t-title-h5;
}

h6 {
    @include t-title-h6;
}

/*
###Text Mixins
*/

p,
ul,
ol,
table,
iframe {
    @include font_main(14px, 20px);

    margin: 0 0 20px;
}

hr {
    border: 0;
    border-bottom: $border_main;
}

/*
###Hyperlinks mixin
*/
a {
	padding-bottom: 4px;
	color: $color_main;
	text-decoration: none;
	border-bottom: 1px solid;

    &:hover {
        text-decoration: none;
	}
}

.g-tooltip {
    &-link {
        @include t-title-h6;
    }
}

/*
###Typography helpers

```t-title-h9
<a class="g-link  t-uppercase">Link copy</a>
```
*/

.t-uppercase {
    text-transform: uppercase;
}

/*
###Table general styles

```
<table class="t-table">
  <tbody>
    <tr>
      <th>Clothing</th>
      <td>XXS</td>
      <td>S</td>
      ...
    </tr>
  </tbody>
</table>
```
*/
table,
.t-table {
    width: 100%;
    margin: 0 0 25px;
    padding: 0;
    border: none;
    border-collapse: collapse;

    td,
    th {
        padding: 16px 5px 15px;
        border: none;
        border-top: 1px solid currentColor;

        @include t-title-h5;
    }

    tr:first-child {
        td,
        th {
            padding-bottom: 4px;
            border-top: none;
        }
    }
}

