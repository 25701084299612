/*
#Settings

/*
##Colors variables
*/

$black: #121212;
$white: #FFFFFF;
$gold: #C0A550;
$bordeaux: #A70F18;
$grey1: #F7F8F9;
$grey2: #F0F0F1;
$grey3: #C7C7C7;
$grey4: #808082;
$orange: #F9B300;
$green: #329B00;
$red: #F90000;
$beige: $gold;
$sooty-gray: $black;

$progress_bar: rgba(251, 247, 238, 0.4);
$gradient_bg: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
$black-overlay: rgba(0, 0, 0, 0.6);

$color_main: $black;
$color_second: $gold;
$color_third: $grey4;

$validation-color: $bordeaux;
$validation-ok: $green;
$accessibility-color: var(--accessibility-color);

$border_main: 1px solid $color_main;
$border_second: 1px solid $color_second;
$border_third: 1px solid $progress_bar;
$border_grey: 1px solid $grey2;
$border_grey4: 1px solid $grey4;

$ls-main: 0.03em;
$ls-second: 0.05em;
$ls-navigation: 0.1em;

$notification-z-index: 1000;

$overlay_bg: rgba($color_third, .8);
/*
## Margin/padding vars
*/

$padding-xs: 3px;
$padding-sm: 10px;
$padding-md: 20px;
$padding-lg: 30px;
$padding-xl: 40px;

/*
##Time variables
*/
$time: 300ms;
$flyout_time_delay: .3s; //value stands for all dropups (search, login, wishlist, cart) transition effect duration
$lazyload-time-transition: .3s;

/*
##Checkout payment icons
*/
$payment-icon-size: 48px;
$grid-columns: 12;


/*
PDP gap
*/
$contentGap: 100px;
$contentPadding: 18px;

/*
letter spacing
*/
$ls-1: 1px;
$ls-2: 2px;
$ls-3: -1px;

:root {
	--full-vh: 100vh;
	--full-dvh: 100dvh;
	--full-svh: 100svh;

	@supports not (height: 100dvh) {
		--full-dvh: var(--full-vh);
		--full-svh: var(--full-vh);
	}
}
