// ===============
// @@Radio buttons
// ===============

// =======================
// Mixins for radiobuttons
// =======================

@mixin init-radiobutton {
	$size: 16px;
	$padding: 4px;

    .f-field-radio {
		text-align: initial;

        .f-label {
            @include t-title-h10;

            position: relative;
            display: block;
            padding: 0 0 0 calc(#{$size} + #{$padding} * 2);
            cursor: pointer;

            &-value {
                @include t-title-h9;
            }

			&::before,
			&::after {
				content: '';
				position: absolute;
			}

            &::before {
				top: 0;
				left: 0;
				width: $size;
				height: $size;
				border: 1px solid $color_main;
				border-radius: 50%;
			}

			&::after {
				top: 5px;
				left: 5px;
				width: 6px;
				height: 6px;
				background-color: transparent;
				transition: transform 200ms ease-out;
			}
        }

        .f-radio {
            position: absolute;
            visibility: visible;
            opacity: 0;
        }

		.f-radio:checked + .f-label::after,
		.ie &.f-checked .f-label::after {
			background-color: $color_second;
			transform: rotate(45deg);
		}
    }
}

%f-radio-wrapper,
.f-radio-wrapper {
	@include init-radiobutton;
}
