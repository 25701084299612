$gray_amazon: #e8eaed;
$gray_amazon_dark: #333e48;

/*
#Amazon buttons styles
*/
.b-amazon_button {
	vertical-align: middle;

	&.m-login {
		position: relative;
		display: inline-block;
		margin-top: 0;
	}

	&.m-pay {
		text-align: center;
		font-size: 0;

		.b-amazon_button-cntr {
			display: inline-block;
			width: 100%;
		}
	}

	&-pre {
		margin-right: 5px;
		margin-left: 2px;
	}

	&-error {
		display: block;
		width: 100%;
		margin-top: 7px;
		white-space: normal;
	}

	img {
		display: block;
		max-width: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.b-checkout_cta-container {
	.amazonpay-button-inner-image {
		max-height: none !important;
		object-fit: contain;
	}
}

/*
#Amazon widgets styles
*/
.b-amazon_widget {
	min-width: 300px;
	height: 270px;
	padding-left: 2px;

	&.m-address {
		margin-bottom: 15px;
	}
}

/*
#Amazon payment button styles
*/
.b-amazon-pay-button {
	margin-bottom: 10px;

	&:hover {
		cursor: pointer;
	}

	.s-checkout & {
		margin: 0 0 10px;
		width: 100% !important;
	}

	.s-checkout .b-cart_payment_method & {
		display: none;
	}

	&_inner {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 45px;
		padding: 2px 30px 2px 10px;
		width: 100%;
		margin: 0 auto;
		background-color: $gray_amazon;
		border-radius: 3px;
		transition: all .2s ease;
		font-size: 11px;

		&:hover,
		&:focus {
			background-color: $gray_amazon;
		}

		.s-checkout & {
			height: 35px;
		}
	}

	&_arrows {
		@include vertical-align;

		position: absolute;
		display: flex;
		justify-content: center;
		align-items: baseline;
		right: 10px;
		font-size: 34px;
		line-height: 15px;
		color: $gray_amazon_dark;
	}

	.b-amazon-paywith-text {
		margin-right: 10px;
		color: $gray_amazon_dark;
	}

	.amazonpay-img {
		position: relative;
		top: 3px;
		width: 100px;
	}
}
