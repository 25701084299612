/*
#Block - Account Newsletter
*/

.b-account_newsletter {
	&-title {
		@include t-title-h8(uppercase);

		margin-bottom: 25px;
	}

	&-subscription {
		&_status {
			@include t-title-h5;

			margin-bottom: 25px;
		}

		&_success {
			@include t-text-6;

			display: block;
			margin-bottom: 25px;
		}

		&_error {
			display: block;
			margin-bottom: 25px;
			color: $validation-color;
		}
	}
}

/*
#Block - Account Newsletter - Unsubscribed Customer
*/
.b-newsletter_unsubscribed {
	&-apply {
		@extend %g-button-primary;
	}

	&-description {
		@include t-text-6;

		margin-bottom: $padding-md;
	}

	&-signup .f-error_message {
		display: block;
		margin-top: 25px;
	}

	.f-state-required::after {
		@include required_fields;
	}

	form .f-state-required::after {
		display: none;
	}

    .b-login_account-privacy_box_link {
        margin: 0 0 30px;
        padding: 0;
    }

	&-form {
		.b-detailed_newsletter-form {
			&_description,
			&_types {
				float: left;
			}
		}
	}
}

/*
#Block - Account Newsletter - Subscribed Customer
*/
.b-newsletter_subscribed {
    &-unsubscribe,
    &-edit_profile {
		@extend %g-button-primary;

		margin-top: $padding-md;
    }

	&-unsubscribe_instruction {
		margin-bottom: $padding-md;
	}

    &-description {
        @include description_text;
    }
}

/*
#Block - Account Newsletter - Unsubscribe Form
*/
.b-newsletter_unsubscribe {
	.f-field-wrapper {
		@include myaccount_field_wrapper;
	}

	&-title {
		@include t-title-h8(uppercase);

		margin-bottom: 25px;
	}

    &-why_message {
        @include t-text-6;

        display: block;
        margin: 25px 0;
    }

    .f-field {
        @include clearfix;

        margin-bottom: 30px;
    }

	.f-label-value {
		@include t-title-h7;

		text-transform: uppercase;
	}

	.f-select {
		@include t-text-6;
	}

	&-button {
		@extend %g-button-primary;

		float: right;
	}

    &-error {
        @include t-title-h10;

        margin-bottom: 26px;
        color: $validation-color;
    }
}

/*
#Block - Account Newsletter - Change your newsletter profile
*/
.l-account_content,
.l-customer_service {
    .b-newsletter_profile {
        width: 100%;

        &-button_submit {
            @extend %g-button-primary;

            float: right;
            margin: 20px 0;
            width: 30%;
        }

        &-title {
            @include t-title-h8(uppercase);
        }

        &-form {
            float: none;
            margin: 0;
            padding: 0;
            border-left: none;
            text-align: left;
        }

        &-terms_and_conditions {
            float: left;
            width: 65%;
            margin: 25px 0;
            padding-left: 32%;

            a {
                @include underline_on_hover_remove;

                font-size: inherit;
            }
        }

        &-sections_label {
            @include t-title-h7;

            clear: both;
            margin-left: 32%;
        }

        &-sections_wrapper {
            .f-field {
                margin-bottom: 0;

                .f-error_message {
                    position: static;
                }

                .f-error_text {
                    clear: both;
                }
            }
        }

        &-sections {
            .f-label {
                padding-left: 35px;

                &-value {
                    @include t-title-h7;

                    text-transform: none;
                }
            }
        }

        .f-field-wrapper {
            @include myaccount_field_wrapper;
        }

        .f-type {
            &-day,
            &-phonecode,
            &-gender {
                width: 55.56%;

                .f-field-wrapper {
                    width: calc(40.79% - 13.33px);
                    margin-right: 20px;
                }
            }

            &-month,
            &-year,
            &-phone {
                width: calc(22.66% - 13.33px);

                .f-field-wrapper {
                    width: 100%;
                }
            }

            &-year {
                float: right;
            }

            &-phone {
                width: 44.44%;

                .f-label {
                    display: none;
                }
            }
        }
    }
}

.b-detailed_newsletter {
	&-form {
		&_description {
			margin: 10px 0;
			float: right;
			width: 68%;
		}

		&_types {
			display: flex;
			flex-wrap: wrap;
			float: right;
    		width: 68%;
			margin: 10px 0;

			.f-field-checkbox {
				width: auto;
				margin-right: 16px;
				margin-bottom: 10px;

				.f-field-wrapper {
					float: none;
				}
			}

			&-multichoice,
			&-error {
				width: 100%;
			}
		}
	}
}

.b-detailed_newsletter-button {
	@extend %g-button-primary;

	height: 25px;
	margin: 10px 0 20px;
	float: left;
}

.l-newsletter_popup_desktop .fancybox-inner {
    height: auto !important;
}
