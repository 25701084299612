.l-breadcrumb {
    margin: 20px 0;
}

.b-breadcrumb {
	display: flex;
	justify-content: center;
	padding: 0 20px;

	&-item {
		display: flex;

		&:nth-child(n + 2)::before {
			content: '/';
			padding: 0 8px;
		}

		a {
			border-color: transparent;

			&:hover {
				border-color: currentColor;
			}
		}
	}

	&-link {
		@include t-title-h6;

		display: inline-block;
		border: none;
	}
}

.l-main_search {
    .b-breadcrumb {
		display: flex;
		justify-content: center;
		max-width: 1900px;
		padding: 0 30px;
		overflow: auto;

		@include respond-to(after-laptop) {
			margin: 0 auto;
		}
    }
}
