/*
 *	Order SUMMARY step
 */

///------ .b-checkout_summary is used as parent wrapper in popup as well --------

.b-checkout_summary {
    &.l-checkout_cart-left {
        margin-top: 50px;
    }

    &-description_content {
        @include description_text;

        padding-bottom: 30px;
    }

    &-unit {
        display: flex;
        flex-wrap: wrap;
    }

    &-subunit {
        width: 50%;
        padding-right: 10px;

        &:nth-child(even) {
            padding-left: 10px;
        }
    }

    &-shipping_info,
    &-shipping,
    &-billing,
    &-payment {
        @include t-title-h9;

        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: $border_main;
    }

    &-shipping {
        &_info {
            padding-top: 0;
        }
    }

    &-payment {
        width: 100%;

        .b-creditcard_image {
            width: 33px;
            margin: 0 0 4px 17px;
            vertical-align: bottom;
		}

		.b-hosted_payment_method-klarna {
			float: left;
			margin-right: 10px;
		}

		&.m-amazon_pay_v2 {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: baseline;

			.b-cart_payment_method {
				&_name {
					display: none;
				}

				&-label_text {
					display: inline-flex;
					align-items: center;
					width: 100%;

					img {
						margin-right: 10px;
					}
				}
			}
		}
	}

    h3 {
        @include t-title-h3(uppercase);

        margin-bottom: 25px;
    }

    .b-mini_address {
        padding-top: 0;

        &-postalcode:not(:empty)::before {
            content: ',';
            margin-left: -0.3em;
        }
    }

    .b-checkout_submit-button {
        flex-shrink: 0;
        width: 240px;
        margin-left: 20px;

        &_container {
            width: 100%;
            border-top: 0;
        }

        &_box {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            clear: both;
        }
    }

    ///---------- Popup -------------

    &.fancybox-wrap {
        width: 50% !important;
        padding-right: 10px;
    }

    .b-address_edit {
        &-cta {
            @extend %g-button-primary;

            float: left;
			margin: 10px 0 5px;
        }

        &-fields {
            &_title {
                @include myaccount_title;
            }

            .f-field-wrapper {
                @include myaccount_field_wrapper;
            }

            @include phone_fields;
        }

		&-submit {
			float: right;
			margin-bottom: 20px;
		}
    }

    .f-type-useasbillingaddress .f-label {
        width: 100%;
    }
}
