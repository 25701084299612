body {
	&.m-filters-opened {
		height: 100%;
		overflow: hidden;
	}
}

.b-refinement {
	$refinement: &;
	position: relative;

	&:not(.m-empty) {
		padding: 0;
	}

	&-sub_title {
		@include t-title-h5;

		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px 76px 20px 40px;
		cursor: pointer;
		white-space: nowrap;

		.js_accordion_description--open & {
			margin-bottom: 0;

			.b-refinement-selected-amount,
			.b-refinement-selected_count {
				display: none;
			}
		}

		.b-refinement-selected_count {
			@include t-title-h9;

			color: $grey4;
		}

		&::after {
			top: 50%;
			right: 40px;
			transform: translate(0, -50%);
		}
	}

	&-selected-amount {
		@include t-title-h9;

		color: $grey4;

		.amount:empty + .amount-label {
			visibility: hidden;
		}
	}

	&_containter {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		padding: 0 0 84px;
		text-align: left;

		.b-refinement {
			&_flyout {
				&-title {
					@include t-title-h3(uppercase);

					padding: 30px 36px 40px 40px;
				}

				&-close {
					@include icon(before, close);

					position: absolute;
					right: 40px;
					top: 31px;
					cursor: pointer;

					&::before {
						font-size: 16px;
						line-height: 1;
					}
				}

				&-body {
					height: 100%;
					overflow-y: auto;
				}

				&-controls {
					position: absolute;
					display: flex;
					justify-content: space-between;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 84px;
					padding: 20px 40px;
					gap: 20px;
					border-top: 1px solid $grey2;

					.b-refinement_button {
						width: 190px;
						gap: 5px;

						&--clear {
							@extend %g-button-white;

							color: $color_main;
							background-color: $white;
							border: 1px solid $color_main;

							&:hover {
								background-color: $gold;
								color: $white;
								border-color: $gold;
							}
						}

						&--apply {
							@extend %g-button-primary;
						}
					}
				}
			}

			&-list {
				padding: 0 40px;
			}
		}
	}

	&_dropdown-flyout {
		position: fixed;
		top: 0;
		right: 0;
		width: 480px;
		height: 100%;
		max-height: 100%;
		background-color: $white;
		z-index: 999;
		visibility: hidden;
		transform: translate(100%, 0);

		.m-filters-opened &,
		&.m-show {
			visibility: visible;
			transform: translate(0, 0);
		}

		&.m-opened,
		&.m-show {
			& + .b-refinement_overlay {
				display: block;
			}
		}

		&.loading {
			@include icon(before, heelheight);

			&::before {
				position: absolute;
				display: block;
				left: 50%;
				top: 50%;
				font-size: 72px;
				line-height: 1;
				color: $gold;
				transform: translate(-50%, -50%);
				z-index: 2;
			}

			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: $color_main;
				opacity: .4;
				z-index: 1;
			}
		}
	}

	&_overlay {
		position: fixed;
		display: none;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: $color_main;
		opacity: .6;
		z-index: 998;
	}

	&-list {
		margin: 0;
	}

	&.selected-refinements {
		width: 100%;
		padding: 0;

		.b-refinement {
			&-list {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 0;
				padding: 0;
				gap: 64px;
			}

			&-name {
				@include t-text-5;

				&_icon {
					@include icon(before);

					padding-left: 8px;

					&::before {
						content: $icon-plus;
						line-height: 24px;
					}
				}
			}

			&-selected {
				position: relative;
				display: inline-block;
			}
		}

		.b-breadcrumb-refinement_clear {
			.b-breadcrumb-relax_image {
				@include t-title-h6-h7(none);

				margin: 0;
				padding: 0;

				&::after {
					display: none;
				}
			}
		}
	}

	&_checkbox {
		&-wrapper &-label {
			display: block;
			width: 100%;
			float: none;
			margin: 0;
			padding: 0 40px 0 0;

			&::before {
				right: 0;
				left: unset;
			}

			&::after {
				right: 5px;
				left: unset;
			}

			.f-label-value {
				@include t-title-h9;

				color: $color_main;
			}
		}
	}

	&-selected_value,
	.b-breadcrumb-refinement_value {
		position: relative;
		display: block;
		padding: 0 $padding-md 0 0;

		.b-breadcrumb-relax_image {
			@include icon(after);

			cursor: pointer;

			&::after {
				content: icon-char(close);
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				font-size: 7px;
			}
		}
	}

	&.m-empty {
		display: none;
	}

	&_dropdown {
		&-title {
			@include t-text-6;
			@include icon(after, plus);

			position: relative;
			display: none;
			z-index: 24;
			cursor: pointer;

			&::after {
				margin-left: 5px;
				vertical-align: bottom;
			}

			&.h-toggled {
				&::after {
					content: $icon-minus;
				}
			}
		}
	}

	.b-breadcrumb-refinement_clear {
		padding: 0 $padding-md 0 0;
	}

	&-title {
		@include t-title-h9;

		display: none;
	}

	&.selected-refinements {
		#{$refinement} {
			&-sub_title {
				&::before,
				&::after {
					display: none;
				}
			}

			&-selected {
				position: relative;
				cursor: pointer;

				&_value > a {
					@include t-title-h7(uppercase);
				}

				&_count {
					@include t-title-h9;

					padding-left: 8px;
					color: $grey4;
				}
			}
		}

		.b-breadcrumb-refinement_value {
			position: relative;
			display: inline-block;
			width: 100%;

			&.b-breadcrumb-refinement_clear {
				width: auto;
				margin-left: 118px;
			}
		}
	}

	.b-filter {
		&-buttons {
			position: absolute;
			right: 2%;
			bottom: 2%;
		}

		&-close_button {
			@include icon(after);
			@include font_main(14px, 14px, $font_main, $text-transform: uppercase);

			cursor: pointer;

			&::after {
				content: icon-char(close);
				margin-left: 5px;
				font-size: 11px;
			}
		}
	}

	&-item {
		margin-bottom: 16px;
	}

	&-link {
		@include t-title-h9;

		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 0;
		border: 0;

		&--active,
		&.selected {
			.fake-checkbox,
			.fake-radio {
				@include icon(before, diamond);

				&::before {
					width: 12px;
					height: 12px;
					font-size: 12px;
					line-height: 12px;
					color: $gold;
				}
			}
		}

		.fake-checkbox,
		.fake-radio {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 16px;
			height: 16px;
			border: 1px solid $black;
		}

		.fake-radio {
			border-radius: 50%;
		}
	}
}

.b-sort_select {
	&-title {
		@include t-text-6;
		@include icon(after, plus);

		position: relative;
		text-align: left;
		cursor: pointer;
		z-index: 22;

		&::after {
			margin: 0 0 0 6px;
			vertical-align: bottom;
			letter-spacing: 0;
		}

		&.h-toggled {
			&::after {
				content: $icon-minus;
			}
		}
	}

	&-value {
		padding: 0 40px;
	}

	&-item {
		position: relative;
		margin: 0 0 16px;
	}

	&-input {
		position: absolute;
		visibility: hidden;

		&-label {
			padding-right: 20px;
			cursor: pointer;

			:checked + & {
				.fake-radio {
					@include icon(before, $icon-diamond);

					color: $color_second;

					&::before {
						content: '\e925';
					}
				}
			}

			.fake-radio {
				position: absolute;
				display: block;
				top: 2px;
				right: 0;
				width: 16px;
				height: 16px;
				font-size: 12px;
				line-height: 14px;
				text-align: center;
				color: transparent;
				border: 1px solid $black;
				border-radius: 50%;
			}
		}
	}
}
