/*
#LAYOUT - Customer Service Main
*/
.l-main_customer_service {
    @include container;

    max-width: 1170px;
    padding: 0 5%;
    margin: 0 auto;
}

/*
#Customer Service - HEADER
*/

.l-header_customer_service {
    @include container;
    @include clearfix;

    position: fixed;
    top: 0;
    width: 100%;
    pointer-events: none;
    z-index: 100;

    .b-primary_logo {
        float: left;
        padding: 25px 0 0 30px;
    }
}

.l-customer_service {
    @include container;

    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;

    /*
    ##LAYOUT - Customer Service Top Block
    */
    &-top {
        order: 3;
        flex-basis: 100%;
    }

    /*
    ##LAYOUT - Customer Service Left Column
    */
    &-left {
        flex-basis: 25%;
        padding-right: 50px;
    }

    /*
    ##LAYOUT - Customer Service Right Column
    */
    &-right {
        flex-basis: 75%;

        h1,
        h2 {
            @include t-title-h6;
        }

        h3,
        h4,
        h5,
        h3 > a {
            @include font_main(14px, 20px, inherit, 1px, uppercase);

            margin: 0 0 9px;
        }

        article {
            margin: 0 0 30px;
        }

        ul li {
            margin-bottom: 30px;

            p {
                margin-left: -15px;
            }
        }

        u {
            text-decoration: none;
        }

        .b-customer_service_navigation {
            &-description {
                display: block;
            }

            &-list-li {
                margin-bottom: 35px;
            }
        }

        .b-content_asset {
            margin-bottom: 60px;

            & > ol {
                @include font_main(14px, 20px, inherit, 1px);

                margin-bottom: 30px;
                padding-left: 60px;
                list-style: decimal-leading-zero;

                & > li {
                    position: relative;

                    ol {
                        margin-left: -20px;
                        padding-left: 88px;
                        list-style: decimal;

                        & + p {
                            padding-left: 85px;
                        }
                    }

                    .b-accordion_icons {
                        margin-left: -20px;
                        padding-left: 75px;

                        .b-accordion_icon {
                            position: relative;
                            display: block;
                            left: -50px;
                            float: left;
                            width: 33px;
                            height: 35px;

                            &-sheet {
                                background-position-x: 70px;
                            }

                            &-letter {
                                background-position-x: 136px;
                            }
                        }

                        .b-accordion_text {
                            display: block;
                            padding-left: 35px;
                        }
                    }

                    & > ul {
                        margin: 0 0 50px -30px;
                        padding-left: 40px;
                        list-style: disc;
                        border-bottom: $border_main;

                        .ui-accordion-content {
                            margin-left: -20px;
                        }
                    }

                    &:last-child > ul {
                        border-bottom: none;
                    }
                }
            }

            .ui-accordion {
                h1,
                h5 {
                    @include icon(after);

                    position: relative;
                    cursor: pointer;

                    &::after {
                        content: $icon-arrow-down;
                        position: absolute;
                        margin-left: 15px;
                        transform: rotate(0deg);
                        font-size: 8px;
                        transition: transform 0.5s;
                    }
                }

                h1[aria-selected="true"],
                h5[aria-selected="true"] {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        h1 {
            padding: 0 0 45px;
        }

        h5 {
            margin: 0 0 35px;
        }
    }
}

.b-customer_service {
    &-widget {
        display: flex;
        margin: 0 0 50px;
        padding-top: 60px;
        text-align: center;
        border-top: $border_main;

        &_column {
            @include span(4 / 3);
        }

        p {
            text-align: inherit;
        }
    }

    &-sub_header {
        @include clearfix;

        margin-bottom: 48px;
        padding: 48px 0;
        border-bottom: 1px solid $color_second;

        &-left {
            position: relative;
            float: left;
            width: 250px;
            margin-left: 25%;

            @include icon(after);

            &::after {
                content: $icon-headphones;
                position: absolute;
                top: 35px;
                left: -48px;
                font-size: 24px;
            }
        }

        &-right {
            position: relative;
            float: left;
            margin-left: 120px;

            @include icon(after);

            &::after {
                content: $icon-mail;
                position: absolute;
                top: 35px;
                left: -48px;
                font-size: 19px;
            }
        }

        &-title {
            @include t-title-h6;

            margin: 5px 0 8px;
        }

        &-sub_title {
            &,
            a {
                @include t-title-h4;
            }
        }

        &-description {
            @include font_main(14px, 20px);

            text-align: center;
        }

        &-link {
            text-align: center;
        }
    }

    	/*
    #BLOCK - Customer Service Left Navigation
    */
    &_navigation {
        &-title {
            font-size: 14px;
        }

        &-list {
            @include font_main(12px, 18px);

            margin: 0;
            padding: 0;
            list-style-type: none;

            &-li {
                @include font_main(12px, 20px);

                margin-bottom: 20px;

                &--active a {
                    border-bottom: $border_main;
                }
            }
        }

        &-description {
            @include t-title-h5;

            display: none;
            padding: 1px 0 0;
        }

		&-link {
			@include t-text-6;

			text-transform: uppercase;
			border: 0;
        }
    }

    &_content {
		margin: 40px auto 0;
		max-width: 1130px;

		@include respond-to(desktop) {
			margin: 80px auto 0;
		}

		&-title {
			@include font_main(18px, 24px, inherit, 1px);

			padding-left: 20px;
		}

		&-form {
			padding: 0 20px;

			.f-field {
				margin: 20px 0 0;
			}

			.f-textarea {
				resize: none;
				border: none;
				border-bottom: $border_second;
			}

			.f-attachment {
				display: flex;
				justify-content: space-between;
				padding-left: 32%;
				gap: 20px;

				&_input {
					display: none;
				}
			}

			.b-attachment {
				&-info {
					&-files {
						display: flex;
						flex-direction: column;
						gap: 10px;
					}
				}

				&-file {
					position: relative;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 10px;
					gap: 10px;
					background-color: $color_second;
					color: $color_main;
					border-radius: 22px / 44px;

					&_name {
						text-decoration: underline;
					}

					&_remove-button {
						@include icon(before, close);

						width: auto;
						margin: 0;
						padding: 0;
						color: $color_main;
					}
				}
			}

			.f-textinput,
			.f-email,
			.f-select,
			.f-textarea {
				padding: 0 20px;
				text-transform: uppercase;
			}
		}

        &-submit_button {
			float: left;
			margin: 20px 0 80px;
        }

        .f-field-wrapper {
            float: right;
            width: 68%;
        }

        .char-count {
            @include t-title-h6;
        }

        .f-field-checkbox .f-label {
            width: auto;
        }
    }

	&_popup-wrapper {
		padding: 30px 40px;
	}
}

/*
404 page
*/
.l-error_page {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;

	@include respond-to(custom) {
        padding-left: 40px;
        padding-right: 40px;
    }

	.b-error_page {
		padding: 35px;

		.b-content_asset {
			h2 {
				font-size: 21px;
			}
		}

		&-primary_content {
			padding: 50px 20% 50px 0;
			text-align: center;
		}

		&-title {
			@include t-text-4;

			margin-bottom: 20px;
		}

		&-search {
			#error-search,
			.f-textinput {
				display: block;
				width: 250px;
				margin: 23px 20px 30px 0;
			}

			.f-label {
				display: none;
			}
		}

		&-button {
			@extend %g-button-primary;
		}

		&-nav {
			padding: 50px 0 50px 20%;
			}

		&-message {
			.b-content_asset {
				h2 {
					@include font_main(24px, 34px);

					margin-bottom: 20px;
					text-align: center;
				}

				p:last-child {
					margin-bottom: 0;
				}
			}
		}

		.b-error_page-form_row {
			margin-left: 40px;
		}
	}
}

.b-error_page {
	&-wrapper {
		width: 100%;
		padding: 20px;

		p {
			margin-bottom: 15px;
		}

		h1,
		h2 {
			a:hover {
				border-bottom: none;
			}
		}

		h2 {
			@include t-title-h9;

			margin-bottom: 20px;
		}
	}

	&-logo {
		max-width: 300px;
		padding-bottom: 25px;
	}

	.content-page {
		max-width: 1500px;
		width: 100%;

		@include respond-to(desktop) {
			display: flex;
			justify-content: space-between;
			margin: 0 auto;
		}

		&__main-content {
			@include respond-to(desktop) {
				margin-left: 30px;
				width: calc(50vw - 15px);
			}
		}

		&__header {
			margin-bottom: 12px;

			&-lock-up {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 8px;
			}
		}

		&__heading {
			@include t-title-h2;

			text-transform: none;

			&--sub {
				@include t-title-h4;

				display: block;
			}

			&--level-2 {
				@include t-text-5;

				margin: 12px auto;
			}
		}

		&__404 {
			font-size: 90px;
		}

		&__text {
			@include t-text-4;
		}

		&__collection {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			margin: 0 8px;

			&__card {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				width: calc(50% - 8px);
				margin-top: 24px;

				&--double {
					width: 100%;
				}
			}
		}

		&__ctas-list {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;

			&-item {
				&:not(:last-child) {
					margin-right: 30px;
				}
			}
		}

		&__cta {
			@extend .g-button_link;

			@include t-text-6;
		}

		&_img {
		display: inline-block;
		width: 100%;
		height: auto;
		max-width: 450px;

			&__main {
				margin-bottom: 30px;
				max-width: 812px;

				@include respond-to(all) {
				max-width: 950px;
				}
			}
		}
	}

	.f-textinput {
		display: flex;
		width: 30%;
	}

	.f-label {
		float: none;
	}
}

/*
#SHIPPING INFORMATION PAGE
*/
.b-content_asset--shippinginfo .shippingpromotable {
    &.countriestable {
        width: auto !important;

        th {
            white-space: normal;
        }
    }

    td:nth-child(3) {
        white-space: nowrap;
    }
}

/*
#BLOCK - Customer Service Contact Us
*/
.f-type-addfile {
    width: 68%;
    margin: 0 0 40px 32%;

    .f-inputfile {
        &-addfile {
            float: right;
            width: 38%;
            margin-top: 21px;
            background-color: $white;
            color: $color_main;
        }

        &-nofiles {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &-remove {
            @extend .g-button_link;

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            height: 49px;
            padding: 0 10px;
            border: 0;
            background-color: $white;
            color: $color_main;
            text-transform: none;

            &:hover {
                background-color: $white;
            }
        }
    }

    .f-attach-wrapper {
        @include clearfix;

        position: relative;
    }

    .f-field {
        margin: 0;

        &-wrapper {
            width: 100%;
        }
    }

    .f-textinput {
        padding: 0;
        color: $color_main;
        border-width: 0 0 1px;
        border-color: initial;
    }

    .b-attachment {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 80%;
        opacity: 0;
    }

    .fileField {
        float: left;
        width: 58%;

        &-state-error .f-textinput {
            border-color: $validation-color;
        }

        .f-warning_text {
            display: inline-block;
            margin: 4px 0;
        }
    }
}
