.l-header-search {
    &::placeholder {
        @include t-title-h2;

        color: $color_main;
    }
}

.b-header_search_icon {
    @include icon(before, search);

    cursor: pointer;

	//Prevents flickering when search is opened. @TODO: make toggler behavior for the search icon
	.m-search_opened & {
		pointer-events: none;
	}

    font-size: 18px;

    & + .l-header_service_menu-arrow {
        right: auto;
        left: 17px;
    }

    &::after {
        top: -39px;

        @include respond-to(nav) {
            top: -26px !important;
        }
    }

    .b-minimized_header & {
        &::after {
            top: -19px;
        }
    }
}

.b-header_search {
    position: relative;

    &-title {
        display: none;
    }

    &-form_wrapper {
        @include clearfix;

        position: relative;
        display: block;
        max-width: $max-width;
        margin: 0 auto;

        @include respond-to(all) {
            width: auto;
        }

        .b-search_result {
            position: relative;
            margin: 0 0 1px;
            padding: 0 0 31px;
            z-index: 4;
        }
    }
}

.b-search-foundresult_title {
    display: none;
}

.b-simple_search {
    position: relative;
    max-height: 200px;
    padding: 0 50px 0 40px;
    transition: all $flyout_time_delay ease-in;
    z-index: 7;
    border-bottom: 1px solid currentColor;

    @include respond-to(nav) {
        padding-left: 60px;
    }

    &.h-minimized {
        background: none;
        border: 0;
        transition: all $flyout_time_delay ease-in;
    }

	&-fieldset {
		position: relative;
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 0;
	}

    &-legend,
    &-label {
        display: none;
    }

    &-gender_buttons {
        position: relative;
        float: right;
        top: 48px;

        &--submit {
            @include icon(before);

            float: left;
            min-width: 74px;
            margin-right: 35px;
            padding: 0;
            cursor: pointer;
            border: 0;
            font-size: 11px;
            line-height: 18px;
            color: $color_main;
            background-color: $white;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 15px;
                height: 15px;
                margin-top: -8px;
                border: $border_main;
                background: $white;
            }

            &.active,
            &:hover {
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 4px;
                    width: 7px;
                    height: 7px;
                    margin-top: -4px;
                    background: $color_main;
                }
            }
        }
    }

    .b-simple_search-submit-button {
        position: relative;
        top: 44px;
        float: right;
        margin: 0 10px;
        font-size: 11px;
        line-height: 28px;
    }

    &-field,
    &-input {
		@include t-title-h5(uppercase);

        display: inline-block;
        width: 90%;
        height: 80px;
        margin: 0 0 1px;
        padding: 0 0 0 1px;
        border-left: 1px solid $color_second;
        border-radius: 0;
        border: 0;
        outline: none;
		color: $color_main;

        &.blured-placeholder {
            color: $color_main;
        }

        &[contentEditable="true"]:empty:not(:focus)::after {
            content: attr(data-text);
            display: inline-block;
            color: $color_main;
            cursor: text;
            text-transform: uppercase;
        }
    }

    &-field {
        width: calc(100% - 140px);
    }

    &-suggested {
        @include font_main(18px, 45px);

        display: inline-block;
        padding-right: 100px;
        color: $color_second;
    }

    &.h-minimized + .b-search_result {
        display: none;
    }

	&-close_button {
		@include icon(before, close);

		position: absolute;
		top: 16px;
		right: -20px;
		width: 16px;
		min-width: 0;
		height: 16px;
		padding: 0;
		color: currentColor;
		background: none;
		outline: 0;
		border: none;
		line-height: 1;
		cursor: pointer;

		&:hover,
		&:focus {
			background: none;
			border: none;
			outline: 0;
		}

		&::before {
			font-size: 16px;
		}
    }

    &-submit {
        margin: 0 20px 0 0;
    }
}

.b-search_result {
	padding: 0 9px;
	overflow: hidden;
	white-space: nowrap;

    &-item {
        position: relative;
        display: inline-block;
        width: 18.5%;
        margin: 15px 1.5% 15px 0;
        white-space: normal;
        vertical-align: top;

        @include respond-to(vertical) {
            width: 33%;
            margin: 0 0.5% 0 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &-product {
        @include underline_remove;

        position: relative;
        display: block;
    }

    &-image {
        display: block;
        width: 100%;
        height: auto;
        min-height: 152px;
        margin-bottom: 18px;
    }

    &-title {
        display: block;
        padding: 0 0 6px;
    }

    &-price {
        display: block;
    }

    &.h-minimized {
        display: none;
    }
}
