/*
#LAYOUT - Main Navigation in header
*/

$menu_customer-height: 82px;

.l-main_navigation {
	width: 100%;
	margin-top: 32px;

	@include respond-to(nav) {
		transform: translate3d(-100%, 0, 0);
		transition: max-width, transform 0.6s;
	}

	.b-minimized_header & {
		margin-top: 0;
	}

    .not-active {
        pointer-events: none;
    }

	.l-header_main.b-minimized_header &,
	& {
		@include respond-to(nav) {
			position: fixed;
			align-self: flex-start;
			top: calc(var(--promo_banner-height) + var(--service_menu-height));
			left: 0;
			width: 100%;
			height: calc(100% - var(--promo_banner-height) - var(--service_menu-height));
			margin: 0;
			padding: 0;
			background-color: $white;
			z-index: 40;
			overflow: auto;
			will-change: max-width, transform;

			&.m-active {
				max-width: 100%;
				padding: 0;
				transform: translate3d(0, 0, 0);
			}
		}
	}
}

/*
#BLOCK - Main navigation
*/
.b-main_navigation-title {
    display: none;
}

.b-menu_category,
.b-menu_account {
    margin: 0 0 20px;

	&-link {
		border-bottom: 0;
	}
}

.b-menu_account-level_2-link {
    text-transform: uppercase;
}

.b-menu_category {
	--menu-cols: 6;
	$gap: 30px;

    display: table;
    margin: 0 auto;
    font-size: 0;
    text-align: center;
	line-height: 1;

    @include respond-to(nav) {
		position: relative;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		width: 50%;
		margin: 0;
		padding-left: 30px;
		text-align: initial;
    }

    &-item {
		display: inline-block;
		margin: 0 24px;
		vertical-align: bottom;
		cursor: default;

        @include respond-to(desktop) {
            .b-cloned-config-subitem {
                display: none;
            }
        }

		@include respond-to(nav) {
            display: block;
            padding: 0;
            margin: 0 0 26px;

			&.js-ctg-chosen {
				.b-menu_category-level_2 {
					height: auto;
					transform: translateX(0);
				}
			}
        }

        .b-menu_category {
            &-link,
            &-add_link,
            &-item_title {
                @include t-title-h5(uppercase);

                display: inline-block;
				color: $white;
            }
        }

        &:hover {
            .b-menu_category {
                &-link,
                &-add_link {
                    cursor: pointer;
                    text-decoration: none;
                }
            }
        }

        &--current .b-menu_category-link {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
			}
        }

        &.m-two-columns {
            .b-menu_category {
                &-level_2 {
                    padding-top: 2vw;

                    &-item {
                        width: 48%;
                        float: left;
                        text-align: left;

                        @include respond-to(nav) {
                            width: 100%;
                            float: none;
                        }

                        &--first {
                            margin-right: 2%;

                            @include respond-to(nav) {
                                width: 100%;
                                margin: 0;
                            }
                        }

                        &--last {
                            margin-left: 2%;

                            @include respond-to(nav) {
                                width: 100%;
                                margin: 0;
                                clear: both;
                            }
                        }

                        .b-menu_subcategory_slot-wrapper {
                            float: left;
                            width: 49%;
                        }

                        .b-menu_category-nav-slot {
                            float: right;
                            width: 49%;
                            margin-top: -2em;
                            text-align: center;

                            .b-menu_category-image {
                                width: 100%;
                                height: auto;

                                a:hover {
                                    text-decoration: none;
                                }

                                &-description h3 {
                                    display: inline-block;
                                    padding-top: 18px;
                                    text-transform: uppercase;
                                    font-size: 10px;
                                    color: $color_second;
                                    line-height: 15px;
                                    border-bottom: 1px solid transparent;

                                    &:hover {
                                        border-bottom: 1px solid $color_second;
                                    }
                                }
                            }
                        }
                    }

                    &-link {
                        float: left;
                        max-width: 49%;
                        margin: 0 0 8px;
                        line-height: 1;

                        @include respond-to(nav) {
                            float: inherit;
                            max-width: 100%;
                            line-height: 26px;
                        }
                    }
                }

                &-level_3 {
                    width: 49%;
                    clear: left;

                    &-list {
                        float: left;
                    }
                }
            }

            @include respond-to(nav) {
                .b-menu_category-nav-slot {
                    display: none;
                }
            }
        }

		.b-menu_category-link {
			@include t-title-h6-h7(uppercase);

			position: relative;
			color: currentColor;

			&:hover {
				&::after {
					content: '';
					position: absolute;
					display: block;
					bottom: -5px;
					left: 0;
					width: 100%;
					height: 3px;
					background-color: $gold;
				}
			}
		}

        &.m-with_subcategories {
			@include icon(after, arrow-wide-right);

			@include respond-to(nav) {
				position: relative;
			}

			&::after {
				position: absolute;
				display: none;
				top: 50%;
				right: 16px;
				font-size: 16px;
				line-height: 1;

				@include respond-to(nav) {
					display: block;
					transform: translate(-10px, -50%);
					transition: transform .5s ease-in-out;
				}
			}

			&:hover::after {
				transform: translate(0, -50%);
			}
        }
    }

	&-link {
		padding-bottom: 0;
	}

	&-level {
		&_groups {
			display: flex;
            flex-flow: row nowrap;
		}

		&_group {
			width: calc((#{$nav-max-width} - (var(--menu-cols) + 1) * #{$gap}) / var(--menu-cols));
			padding: 0 $gap / 2;
			box-sizing: content-box;

			&:not(:first-child) {
				margin-top: 32px;
			}

			@include respond-to(nav_fullwidth) {
				width: calc((100vw - (var(--menu-cols) + 1) * #{$gap}) / var(--menu-cols));
            }

			@include respond-to(nav) {
				width: auto;
				box-sizing: initial;
			}

			&.m-dublicates-only {
				display: none;

				@include respond-to(nav) {
					display: block;
				}
			}
		}
	}

    &-level_2 {
		position: absolute;
		display: none;
		top: 100%;
		left: 0;
		width: 100%;
		height: calc(var(--full-dvh) - var(--header-height));
		margin: 0 auto;
		background: $white;
		border-bottom: 3px solid $color_second;
		z-index: 8;

		@include respond-to(nav) {
			left: initial;
			right: 0;
			height: 100%;
			width: 50%;
		}

        &-item {
			flex-shrink: 1;
			height: 24px;
            cursor: default;
			text-align: initial;

			&:not(:last-child) {
				margin-bottom: 30px;
			}

            &:hover {
                > a {
                    cursor: pointer;
					color: $gold;

                    &.not-active {
                        cursor: default;
                        border-bottom: none;
                    }
                }
			}

            .m-menu-central & {
                padding: 0 30px;
            }

            &_title {
                @include t-title-h9;
            }

            img {
                width: 100%;
                margin: 17px 0 26px;

                @include respond-to(all) {
                    display: none;
                }
            }

			.b-menu_category-level_2-link {
				@include t-title-h6;

				transition: color .3s ease-in;
			}
        }

        &-wrapper {
			display: flex;
			justify-content: center;
			max-width: 100%;
			margin: 0 auto;
			overflow: hidden;

            @include respond-to(nav) {
                height: 100%;
            }

			.b-category_img-banner {
				&_list {
					@include webkit-scrollbar;

					display: flex;
					flex-wrap: wrap;
					align-items: flex-end;
					flex: 0 0 calc(100% / 3);
					max-height: calc(var(--full-dvh) - var(--service_menu-height) - var(--promo_banner-height));
					margin-right: auto;
					margin-bottom: auto;
					padding: 40px min(22.5%, 100px) 0 40px;
					gap: 24px 20px;
					overflow-y: auto;
					overflow-x: inherit;

					@include respond-to(nav) {
						display: none;
						margin-right: 0;
						margin-bottom: 0;
						max-width: initial;
						padding: 0;
					}
				}

				&_text {
					@include t-title-h2(uppercase);

					margin: 0 0 10px;
					max-width: 176px;
					color: $black;
				}

				&_link {
					display: flex;
					flex-direction: column;
					padding: 0;
					border-bottom: none;
				}

				&-crop {
					position: relative;
					aspect-ratio: 0.7;
					overflow: hidden;
				}

				&_img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: top center;

					&_description {
						@include t-title-h2;

						margin-top: 10px;
					}
				}

				&_item {
					display: flex;
					align-items: flex-end;
					width: min-content;
					margin-top: auto;
					text-align: initial;
				}

				&__big {
					height: auto;

					@include respond-to(nav) {
						display: none;
					}
				}

				&__wide_text {
					display: none;
				}

				@include respond-to(nav) {
					display: none;
				}
			}
        }

        &-name {
            @include font_main(14px, 38px);

            margin-bottom: 15px;
            color: $color_main;

            @include respond-to(nav) {
                display: none;
            }
        }

        &-list {
			display: flex;
			justify-content: flex-start;
			flex-flow: column wrap;
			max-height: 100%;
			padding-bottom: 35px;
			font-size: 0;
			column-count: 2;
			column-gap: 40px;

			@include respond-to(nav) {
                display: block;
                float: none;
                width: auto;
				padding-bottom: 0;
				column-count: initial;
				column-gap: initial;
				overflow-y: auto;
				overflow-x: hidden;
            }

			&-wrapper {
				flex: 1 0 calc(100% / 3);
				padding: 40px 40px 10px 20px;
				margin: 0 auto;
				height: calc(var(--full-dvh) - var(--service_menu-height) - var(--promo_banner-height));

				@include respond-to(nav) {
					display: flex;
					flex-direction: column;
					padding: 23px 40px 40px 20px;
					height: inherit;
				}
			}

			&_title {
				margin-bottom: 32px;
				color: $black;
				text-align: left;
			}

            .m-menu-central & {
                display: inline-block;
                width: auto;
                text-align: left;
            }
        }

        &-link,
        &-link h2,
        &-link h1,
        &-link a {
            @include t-navigation-link($font_second, uppercase);

            display: inline;
			color: $color_main;

            &--current {
                border-bottom: $border_main;
            }

            @include respond-to(nav) {
                cursor: pointer;
            }
        }

		&-link {
			@include t-title-h5;

			border-bottom: none;
		}

        @include respond-to(nav) {
            top: calc(var(--promo_banner-height) + var(--service_menu-height));
            left: 0;
            height: 0;
            margin: 0;
            padding: 0;
            border: 0;
            overflow-y: auto;
			transform: translateX(100%);
			transition: transform 0.3s ease-in;

            &-item {
                display: block;
            }
        }
    }

    &-level_3 {
        display: none;

        &-item {
            @include respond-to(nav) {
                margin: 5px 0 10px;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: -1px;
                }
            }
        }

        h1,
        h2,
        h3 {
            @include font_main(13px, 30px);

            display: inline;
            padding: 0;
            text-transform: none;
			color: $color_main;

            @include respond-to(nav) {
                margin: 5px 0 10px;
            }

            &:hover {
                text-decoration: none;
                border-bottom: 1px solid currentColor;
            }
        }

		&-link {
			@extend %g-button_link-dark;

			@include t-navigation-link($font_main);
		}

        .m-current,
        &-link--viewall {
            border-bottom: $border_main;

            &:hover::after {
                display: none;
            }
        }
    }

	&-image {
        display: block;
		flex-grow: 1;
        width: 100%;
        object-fit: cover;

		&-container {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			flex-shrink: 1;
			width: calc((#{$nav-max-width} - (var(--menu-cols) + 1) * #{$gap}) / var(--menu-cols) * 2 + #{$gap});
			margin-right: $gap;
			text-align: left;

			@include respond-to(nav_fullwidth) {
				width: calc((100vw - (var(--menu-cols) + 1) * #{$gap}) / var(--menu-cols) * 2 + #{$gap});
			}
		}

		&-description {
			@include t-title-h7;

			margin-top: 5px;
			color: $black;
		}
	}

    &-nav-slot {
        display: flex;
		margin-left: auto;

		@include respond-to(nav) {
			display: none;
		}
    }
}

/*
## Forced decision (selectors by Content Asset name). @TODO: Check why field Custom css/js class (customCssClass)
## in the asset settings doesn't work correctly
*/

[class*="b-content_asset--header-category-img-banner--"] {
	&,
	& + .b-category_img-banner_text {
		width: 156px;
	}

	& + .b-category_img-banner_text {
		order: -1;
	}
}

[class*='b-content_asset--header-category-img-banner-big--'],
[class*='b-content_asset--header-category-img-banner-wide--'] {
	width: 331px;

	.b-category_img-banner-crop {
		aspect-ratio: 1.5;
	}
}

[class*='b-content_asset--header-category-img-banner-big--'] {
	+ .b-category_img-banner_text {
		display: none;
	}
}

[class*='b-content_asset--header-category-img-banner-wide--'] {
	+ .b-category_img-banner_text {
		order: -1;
	}
}

//responsive
.h-tablet_menu {
    position: absolute;
    width: 460px;
    height: 100%;
    margin-left: -460px;
    background: $white;
    transition: margin-left 0.5s ease-in, height 0.1s ease-in;
    z-index: 101;

    .b-menu_category {
        display: block;
        height: 100%;
        padding: 52px 0 0 28px;
        overflow-y: auto;

        &-item {
            display: block;
            margin-bottom: 24px;

            &:hover {
                .b-menu_category-link {
                    border-bottom-width: 1px;
                }
            }

            .b-menu_category-link {
                padding-bottom: 1px;
                border-bottom-width: 1px;
            }
        }
    }

    .b-header_close_button {
        top: 19px;
        right: 18px;
        z-index: 101;
    }

    &--open {
        position: fixed;
        top: 0;
        max-height: 100%;
        margin-left: 0;
        border-right: 1px solid $color_second;
        overflow-y: auto;
    }
}

.h-page_wrapper {
    transition: margin-left 0.5s ease-in;

    &--shift {
        margin-left: 460px;
        padding-left: 20px;
        overflow: hidden;
    }
}

.b-vertical_menu-button {
    @include icon(before, hamburger-menu);

	position: absolute;
	display: none;
	top: 50%;
	left: 20px;
	transform: translateY(-50%);
	cursor: pointer;
	font-size: 0;
	z-index: 51;
	color: currentColor;

	@include respond-to(nav) {
        display: block;
    }

	.m-search_opened & {
		display: none;
	}

    &::before {
        font-size: 14px;
    }

    &.m-active {
        &::before {
            content: icon-char(close);
        }
    }
}

/*
#BLOCK - Category custom navigation
*/

/*
  M-brand
*/
.b-menu_category-item.m-new,
.b-menu_category-item.m-office,
.b-menu_category-item.m-brand,
.b-menu_category-item.m-gift {
    .b-menu_category-level_2-list {
        @include respond-to(nav) {
            float: none;
            width: auto;
        }
    }

    .b-menu_category-level_2-content {
        @include clearfix;

        width: 100%;
    }
}

.b-menu_category-item.m-brand .b-menu_category-level_2-list-content {
    width: 100%;
}

/*
  M-designer
*/
.b-menu_category-item.m-travel,
.b-menu_category-item.m-tools,
.b-menu_category-item.m-workspace,
.b-menu_category-item.m-new,
.b-menu_category-item.m-home,
.b-menu_category-item.m-designer {
    .b-menu_category-level_2-content {
        float: left;

        @include respond-to(nav) {
            width: auto;
        }
    }

    .b-menu_category-level_2-custom_content {
        float: left;

        @include respond-to(nav) {
            width: auto;
        }
    }

    .b-navigation_custom_content {
        @include clearfix;

        font-size: 0;

        @include respond-to(nav) {
            display: none;
        }

        &-item {
            @include font_main(18px, 25px, $font_main);

            display: inline-block;
            margin-left: 18px;
        }

        &-text_link {
            text-decoration: none;
        }

        &-item:hover .b-navigation_custom_content-text_link {
            text-decoration: none !important;
            color: $color_second;
        }

        &-item:first-child {
            margin: 0;
        }

        &-image_box {
            margin-bottom: 20px;
            vertical-align: bottom;
        }

        &-title {
            @include font_main(18px, 25px, $font_main);

            color: $color_second;
            transition: color .2s;

            &:hover {
                color: $color_second;
            }
        }

        &-link {
            color: $color_second;
            text-decoration: none;
        }
    }
}

.b-menu_category-item.m-designer {
    .b-menu_category-level_2 {
        &-content,
        &-list-content {
            width: 100%;

            @include respond-to(nav) {
                width: auto;
            }
        }

        &-custom_content {
            display: none;
        }
    }
}

.b-main_navigation {
    .b-menu_category {
        .b-menu_category-level_3 {
            margin: 10px 0 42px;
        }
    }
}

@include respond-to(nav) {
    .b-main_navigation {
        display: flex;
		flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 28px 0 0;

        .b-menu_category {
            &-item {
                .b-menu_category-level_2 {
					width: 50%;
                    padding: 0;
					top: 0 !important;
					right: 0 !important;
					left: auto !important;

                    &-item {
                        padding: 0;
                        width: 100%;
                        margin: 0 0 20px;

                        p {
                            display: none;
                        }
                    }
                }

                .b-menu_category-level_3 {
                    margin: 10px 0 30px;

                    &-list {
                        margin: 0 0 11px;
                    }
                }
            }
        }

        .tablet-header-menu-nav {
            position: fixed;
            display: none;
            bottom: 0;

            .b-menu_category {
                &-link {
                    font-size: 10px;
                    vertical-align: middle;

                    @include t-title-h9;
                }
            }
        }
    }
}


.tablet-header-menu-nav {
    display: none;

    @include respond-to(nav) {
        display: block;
    }


    .b-menu_category {
        &-item {
            @include icon(before);

            &::before {
                padding: 0 20px 0 0;
                font-size: 17px;
                vertical-align: middle;
				color: $black;
            }

            &:nth-child(1) {
                margin: 0 0 24px;

                &::before {
                    content: icon-char(track-order);
                }
            }

            &:nth-child(2) {
                margin: 0 0 24px;

                &::before {
                    content: icon-char(phone);
                }
            }
        }
    }
}

.m-menu_opened {
	overflow: hidden;

	.b-menu_category-level_2 {
		position: fixed;
		inset: calc(var(--promo_banner-height) + var(--service_menu-height)) 0 0;

		@include respond-to(nav) {
			inset-block-end: $menu_customer-height;
		}
	}

	&::before {
		content: "";
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		opacity: .8;
	}
}

.b-menu_customer-info {
	display: none;
	flex-shrink: 0;
	margin-top: auto;
	height: $menu_customer-height;

	@include respond-to(nav) {
		display: block;
	}

	&-list {
		display: flex;
		justify-content: space-between;
		gap: 4px;
	}

	&-item {
		flex-basis: 100%;
		padding: 17px 20px;
		background-color: $bordeaux;
		border-radius: 2px;
		text-align: center;

		&:last-child {
			background-color: $gold;
		}
	}


	&-link {
		position: relative;
		display: inline-block;
		padding-bottom: 3px;
		color: $grey2;
		text-transform: none;
		border-bottom: 1px solid $grey2;
		text-align: initial;

		&.boutique {
			@include icon(before, storemarker);
		}

		&.whatsapp {
			@include icon(before, whatsapp);
		}

		&.experience {
			@include icon(before, chat);
		}

		&.boutique,
		&.whatsapp,
		&.experience {
			font-size: 16px;

			&::before {
				display: block;
				margin-bottom: 6px;
			}
		}
	}

	&-text {
		@include t-title-h9;
	}
}
