/*
#BLOCKS - flyout minicart, wishlist product tiles
*/

$swatch-block-width: 60px;

.l-product-tile {
	position: relative;

	&:hover {
		.b-product-tile_remove-block {
			display: block;
		}
	}

	&_img {
		position: relative;
	}
}

.b-product-tile {
	&_remove-block {
		position: absolute;
		display: none;
		top: 10px;
		right: 14px;
		z-index: 1;
		font-size: 0;
		cursor: pointer;

		@include respond-to(all) {
			display: block;
		}
	}

	&_remove-btn {
		@include icon(after, close);

		padding: $padding-xs;
		border: 0;
		line-height: normal;

		&::after {
			font-size: 10px;
		}
	}
}

/*
#BLOCKS - common producttile styles
*/

.b-product_tile {
	position: relative;
	display: flex;
	flex-direction: column;
	text-align: initial;

	&:hover {
        .b-productile_hover,
        .b-add_to_wishlist {
            opacity: 1;
        }

		.b-productimageslider {
			.b-owl_carousel-nav {
				display: flex;
			}
		}

		.b-variation {
			display: block;
		}

		&:has(.b-variation) {
			.b-product_tile-body-main {
				visibility: hidden;
			}
		}

		.b-product_badges {
			visibility: hidden;
		}
    }

	.b-product_name,
	.b-product_name-title {
		@include t-title-h3(uppercase);
		@include line-clamp;

		padding: 0;
	}

	.b-variation {
		position: absolute;
		display: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 16px;

		&--plp {
			&_colors {
				display: flex;
				justify-content: flex-end;
				width: $swatch-block-width;
				margin-left: 20px;
				padding-top: 24px;
			}
		}
	}

	.b-variation-size_chart_link,
	.pre-order-pdp-label-info,
	.b-stars,
	.pre-order-plp-label {
		display: none;
	}

	&-top_hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }

	&_container {
		overflow: hidden;
	}

	&_container & {
		margin: 0;
		height: 100%;
	}

	.b-product {
		&_category {
			@include t-text-8(uppercase);

			display: inline-block;
			margin-bottom: 4px;
		}

		&_min_price {
			@include t-title-h6;
		}

		&_image-wrapper {
			border: 0;

			.b-owl_carousel-image {
				height: auto;
				width: 100%;
				aspect-ratio: 0.73;
				object-fit: contain;
				object-position: bottom;
				background-color: $grey1;
			}
		}
	}

    .b-add_to_wishlist {
		@include icon(before, wishlist-not-selected);

		position: absolute;
		top: 32px;
		right: 50%;
		font-size: 0;
		line-height: normal;
		opacity: 0;
		z-index: 2;
		transform: translate(50%, 0);
		transition: opacity .3s ease-in;

        &::before {
			display: block;
			padding: 3px;
			color: $color_second;
			font-size: 19px;
			cursor: pointer;
        }

        &--added {
            @include icon(before, wishlist);
        }
    }

    .b-product-hover_box {
        position: relative;
        line-height: 0;
    }

    .b-swatches_color {
		position: relative;
		display: flex;
		justify-content: flex-end;
		width: 100%;
		margin: 0;
		padding: 0;
		line-height: 1;

        &-item {
			display: inline-block;
			margin-left: 10px;
			padding-bottom: 8px;

			&:first-child {
				margin-left: 0;
			}

			&-selected {
				border-bottom: 1px solid;
			}
        }

        &-link {
            display: block;
            width: 13px;
            height: 13px;
            background-size: 100% 100% !important;
            border: $border_second;
            border-radius: 100%;
        }

		.b-owl_carousel {
			&-nav {
				position: absolute;

				&_prev,
				&_next {
					margin-top: -16px;
				}
			}

			&-item {
				margin: 0;
			}
		}

        .owl-stage {
            display: flex;
            max-width: 100%;
            margin: auto;
        }
    }

	.b-variation {
		width: 100%;

		.b-owl_carousel {
			.b-owl_carousel-nav_next,
			.b-owl_carousel-nav_prev {
				top: 23px;
			}

			.b-owl_carousel-nav_next {
				right: -25px;
			}

			.b-owl_carousel-nav_prev {
				left: -25px;
			}
		}

		&-colors_wrap {
			position: relative;
			left: 15px;

			.b-owl_carousel {
				.b-owl_carousel-nav_prev,
				.b-owl_carousel-nav_next {
					top: 30px;
				}
			}
		}
	}

	.b-plp-editorial & {
		width: 47%;
		min-width: 345px;

		&:hover {
			.b-variation {
				display: none;
			}

			.b-product_tile-body-main {
				display: flex;
			}
		}
	}
}

.b-variation {
	.b-product_tile-body &-value {
		display: flex;
		flex-wrap: wrap;

		.b-swatches_size {
			display: flex;
			justify-content: start;
			flex-wrap: wrap;
			width: 100%;
			gap: 16px 20px;

			&-item {
				@include t-text-4;

				display: inline-block;
			}

			&-link {
				color: $black;
				border: none;
			}
		}

		.b-swatches_low-in-stock {
			display: none;
		}
	}

	.m-unselectable {
		.b-swatches_size-link {
			padding: 0;
			color: $grey4;

			&:hover {
				cursor: pointer;
			}

			+ span.b-swatches-link_notifyme {
				display: none;
			}
		}
	}
}


.b-product_tile-body,
.l-product-tile_info {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	padding: 16px 16px 72px;

	&-main {
		$swatch-left-indent: 30px;

		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 24px 0 0;
		gap: 16px;
		z-index: 1;

		@include respond-to(after-tablet) {
			width: 100%;
		}
	}
}

.l-product-tile_info {
	position: initial;
	overflow-wrap: break-word;
}

.b-product-tile_name,
.l-product-tile_pricing {
	width: 100%;
}

.b-search_result-product,
.b-product-tile_link,
.b-product_name {
	display: block;
	border: 0;
}

.b-search_result,
.b-product-tile_link {
	&,
	&-title {
		@include t-title-h8;
	}
}

.b-product_badges {
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	top: 32px;
	left: 0;
	gap: 8px 0;
	z-index: 1;

	&.l-product-tile_badges,
	&.c-search-hit__badges {
		padding: 0;
		z-index: 2;
		opacity: 1;
		transition: opacity .3s ease-in;

		.b-product-tile_badge {
			width: auto;
			height: auto;
		}
	}
}

.b-product_price {
	display: flex;
	flex-wrap: wrap;

	&-standard,
	&-standard--line_through,
	&-sales,
	&-percent {
		@include t-title-h4;
	}

	&-sales {
		order: -1;
		margin-right: 8px;
	}

	&-percent {
		display: flex;
		color: $bordeaux;
	}

	&-standard--line_through {
		display: inline;
		margin-right: 8px;
		text-decoration: line-through;
		color: $grey4;
	}
}

.l-wishlist-flyout_product-tiles {
    .l-product-tile {
		margin: 0;
	}

    .b-add_to_cart {
        @extend %g-button_link-white;

		width: auto;
        margin: 10px 0 0;
		color: $color_second;
    }
}

.b-productile_hover {
	position: absolute;
	display: flex;
	bottom: 0;
	right: 0;
	left: 0;
	opacity: 0;
	transition: opacity .3s ease-in;

	&:hover {
        .b-productile_hover,
        .b-add_to_wishlist {
            opacity: 1;
        }
    }

    .b-quickview {
        @include icon(before, carrello);
		@include t-title-h9;

		width: 100%;
        margin: 0;
		padding: 8px;
        color: $black;
		background-color: $color_second;
		cursor: pointer;
		text-align: center;

        &::before {
			display: none;
            color: currentColor;
            font-size: 18px;
            cursor: pointer;
        }
    }

    .b-product_name {
        @extend .h-hidden;
    }
}

.b-product_image {
	@include lazyload-with-transition;

	display: block;
	width: 100%;
	height: auto;

	&-wrapper {
        display: block;
        padding: 0;
        line-height: 1;
    }
}
