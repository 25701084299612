/*
#BLOCK - login_user
*/

.b-login_wide,
.b-login_dropdown {
    position: relative;
    display: none;
    max-width: $max-width;
    max-height: 500px;
    margin: 0 auto;
    padding: 0 40px;
    overflow: hidden;
    transition: max-height $flyout_time_delay ease-in;
	border-bottom: 1px solid $white;

    @include respond-to(all) {
        width: auto;
        max-height: 1000px;
    }

    &-create_new_account_link {
        @include underline_remove;
    }

    &.h-minimized {
        transition: max-height $flyout_time_delay ease-in;
    }

    &-title,
    &-title_logout {
        position: relative;
        display: block;
        padding: 0 0 5px;
        cursor: pointer;

        @include icon(before, login);

        &::before {
            font-size: 18px;
        }

		&:hover,
		&:focus,
		&:active {
			background-color: transparent;
			color: $color_main
		}

        @include respond-to(all) {
            padding-bottom: 0;
        }

        & + .l-header_service_menu-arrow {
            right: auto;
            left: 17px;
        }

        &.authenticated {
           &::before {
			color: $gold;
		   }
        }
    }

    &-title_logout {
        display: inline-block;
        margin: 0 0 0 9px;
        font-size: 12px;

        &::before {
            display: none;
        }

        .b-login_dropdown-link {
            @include underline_on_hover_remove;

            display: inline;
            min-width: 70px;
            margin: 0 0 0 10px;
            border: none;
        }
    }

    &-flyout {
        @include clearfix;

        position: relative;
        margin: 0 auto 1px;
        padding: 36px 0;
        z-index: 4;

        @include respond-to(portrait_tablet) {
            width: 100%;
        }

        .b-login_account {
            &-info {
				@include t-title-h6;

                margin: 10px 0 27px;
            }

			.f-form_error_message {
				@include font_main(12px, 14px, $font_main);

				top: 0;
				margin-bottom: 9px;
				color: $validation-color;
				text-transform: none;

				@include respond-to(tablet-retina) {
					margin: 6px 0 9px;
				}
			}
        }

        ul {
            margin: 0;
        }
    }

    &-link {
        @include t-title-h8(uppercase);
        @include underline_remove;

        display: inline-block;
        margin-bottom: 26px;

        @include respond-to(all) {
            margin-bottom: 20px;
        }
    }

    &:hover &-title {
        position: relative;
        border-bottom: 2px solid $color_main;
        z-index: 1;
    }

    &-customer_care {
        display: none;
        float: right;
        vertical-align: top;
    }

    &-create {
        float: left;
        width: 45%;
        text-align: left;

		@include respond-to(all) {
			float: left;
			margin: 0;
			width: 30%;
		}


        &_new_account_button {
            position: absolute;
            bottom: 36px;
            width: 212px;
            margin: 0;
        }

        &_title {
            @include t-title-h8(uppercase);

            margin-bottom: 18px;
        }

        &_new {
            p {
                @include t-text-6;
            }

            @include respond-to(portrait_tablet) {
                margin-bottom: 80px;
            }
        }

        .b-content_asset--registration-details {
            margin: 70px 0  125px;
        }

        ul {
            padding: 0;

            li {
                list-style: none;
            }
        }
    }

    &-customers {
        float: right;
        width: 50%;
        padding: 0 0 0 30px;
        vertical-align: top;
    }

    &-item {
        float: left;
        width: 35%;
        margin-bottom: 28px;

        &:nth-child(3),
        &:nth-child(6) {
            width: 30%;
        }
    }

    &-list_title {
        @include t-title-h5;
		@include animated-underline;

		display: inline-block;
        transition: color .2s;
		color: $color_second;
    }

    &-list_coment {
        padding: 1px 0 0;
    }

    &-benefits_list {
        margin-bottom: 30px;
        padding-left: 15px;
        list-style: disc;
        white-space: normal;
    }

    &-benefits_item {
        margin-bottom: 15px;
    }
}

.b-login_account {
    &-title {
        @include t-title-h8(uppercase);

        margin-bottom: 18px;
    }

    &-required {
        display: none;
    }

    &-capture {
        @include font_main(18px, 27px);

        margin-bottom: 18px;
    }

    &-form_fildset {
        @include clearfix;

        margin: 0;
        padding: 0;
        border: 0;
    }

    &-form {
        .f-field-email,
        .f-field-password {
            width: 250px;
            margin: 0 21px 14px 0;

			.f-email,
			.f-password {
				height: 45px;

				@include respond-to(custom) {
					width: 300px;
				}
			}
        }

        .f-field-password {
            margin: 0 0 10px;
        }

        .f-state-valid::before {
            right: -18px;
        }

        .f-field-checkbox {
            margin: 0 0 24px;
            width: 212px;
            height: 18px;
            clear: both;

            .f-label {
                width: auto;
            }

            & + .f-field-checkbox {
                display: none;
            }
        }

		&_row {
			position: relative;
		}
    }

    .f-form_error_message {
        position: relative;
        top: -20px;
        margin-bottom: -20px;
    }

    &-login_button {
        @extend %g-button;
    }

    &-forgot_password {
		@include animated-underline;
		@include t-title-h6;

		position: absolute;
		top: -50px;
		right: 65px;
		vertical-align: top;
		text-transform: none;

		@include respond-to(custom) {
			right: 40px;
		}

		&-wrap {
			@include clearfix;
		}
    }

    &-social_login {
        display: none;
    }
}

.b-login_dropdown {
    &-list {
        margin: -9px 0 -34px;
        overflow: hidden;
    }
}
