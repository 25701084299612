.b-general-notification {
	&-item {
		@include t-title-h6-h7;

		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		min-height: 48px;
		padding: 0 50px 0 20px;
		text-align: center;
		color: $grey2;
		background-color: $black;
		font-weight: 400;
		z-index: $notification-z-index;

		&-text * {
			width: 100% !important;
		}

		.js-close_fancybox {
			display: none !important;
		}
	}

	&-close {
		@include button_reset;
		@include icon(before, close);

		position: fixed;
		top: 16px;
		right: 20px;
		width: auto;
		height: auto;
		color: $black;

		&:hover {
			background-color: transparent;
			border: none;
		}

		&::before {
			color: $grey2;
			font-size: 20px;
		}
	}
}
