.s-boutiques {
	.b-breadcrumbs {
		display: flex;

		.b-breadcrumb-link {
			letter-spacing: 0.14px;
		}

		.b-breadcrumb-item:nth-child(n+2)::before {
			padding-top: 2px;
		}

		&_container {
			margin: 0;
			padding: 20px 0;
		}
	}
}

.b-stores {
	&_wrapper {
		max-width: 1440px;
		margin: 0 auto;
		padding: 0 40px;
	}

	&_header {
		position: relative;

		&-backlink {
			@include t-text-6;
			@include icon(before, arrow-wide-left);

			$icon-size: 16px;

			position: absolute;
			display: flex;
			align-items: center;
			top: 20px;
			left: 0;
			padding-bottom: 0;
			gap: $icon-size;
			border: none;

			&:hover {
				text-decoration: none;
			}

			&::before {
				display: flex;
				justify-content: center;
				align-items: center;
				width: $icon-size;
				height: $icon-size;
				font-size: $icon-size;
			}
		}

		&-title {
			@include t-title-h1(uppercase);

			padding: 40px 0 64px;
			text-align: center;
		}
	}
}

.b-store {
	$content-margin: 24px;

	padding: 40px;
	margin-bottom: 80px;
	gap: 20px;

	@include respond-to(all) {
		align-items: center;
		padding-inline: 20px;
		gap: 40px;
	}

	&:nth-child(even) {
		.b-store_img-container {
			order: 1;
		}
	}

	&_img {
		@extend %m-mask-diamond;

		max-width: 100%;
		width: 100%;
		height: auto;

		&-container {
			.b-owl_carousel-nav_dots {
				bottom: -55px;
				width: 20px;
				gap: $content-margin;
			}

			.b-owl_carousel-nav_dot {
				&::after {
					color: $grey2;
					font-size: 16px;
				}

				&.active {
					&::after {
						color: $grey4;
					}
				}
			}
		}
	}

	&_content {
		max-width: 440px;
		width: 100%;
		margin: 0 auto;
		padding-top: 45px;
	}

	&_description {
		@include t-title-h3(uppercase);

		margin-bottom: $content-margin;
		color: $gold;
	}

	&_name {
		@include t-title-h1-secondary;

		margin-bottom: $content-margin;

		@include respond-to(all) {
			word-break: break-word;
		}
	}

	&_hours {
		margin-bottom: $content-margin;

		&-description {
			padding-inline: 0;
		}

		&-title {
			padding-inline: 0;

			&::after {
				right: 0;
			}
		}

		.b-accordion {
			&-item {
				border: none;
			}
		}
	}

	.b-storelocator-store {
		&_info {
			margin-bottom: $content-margin;
		}

		&_contacts {
			padding: 0;
			margin-top: $content-margin;
		}

		&_city {
			padding: 0;
		}

		&_appointment-link {
			margin-bottom: $content-margin;
		}
	}

	.b-customer_service_box {
		margin-bottom: 4px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
