/*
#Styles for visual styleguide in asset typography
*/
$styleguide_colors: ("black": $black, "white": $white, "gold": $gold, "bordeaux": $bordeaux, "grey1": $grey1, "grey2": $grey2, "grey3": $grey3, "grey4": $grey4);

@each $color_name, $hex_value in $styleguide_colors {
	.b-styleguide_color {
		&.m-#{$color_name} &_icon::before {
			background-color: $hex_value;
		}
	}
}

.b-styleguide {
	--columnsNumber: 3;
	--gap: 20px;

	background-color: $white;

	&_paddings {
		text-align: initial;
		color: $color_main;
	}

	&_colors {
		display: flex;
		flex-wrap: wrap;
		color: $black;
	}

	&_color {
		width: 33.3%;

		&_title {
			width: 100%;
		}

		&_icon {
			display: flex;
    		flex-wrap: wrap;
			justify-content: center;
			margin: 0;
			padding: 10px;

			&::before {
				content: '';
				display: inline-block;
				width: 50px;
				height: 50px;
				border-radius: 50%;
			}
		}
	}

	.b-grid {
		padding: 20px;

		&-item {
			&.m-text-mixins {
				color: $black;
				text-align: initial;

				.t-title {
					margin-bottom: 20px;

					&.uppercase {
						text-transform: uppercase;
					}
				}

				.t-title-medium {
					@include t-text-4;
				}

				.t-title-h1 {
					@include t-title-h1;
				}

				.t-title-h2 {
					@include t-title-h2;
				}

				.t-title-h3 {
					@include t-title-h3;
				}

				.t-title-h4 {
					@include t-title-h4;
				}

				.t-title-h5 {
					@include t-title-h5;
				}

				.t-title-h6 {
					@include t-title-h6;
				}

				.t-title-h7 {
					@include t-title-h7;
				}

				.t-title-h8 {
					@include t-title-h8;
				}

				.t-title-h9 {
					@include t-title-h9;
				}

				.t-text-1 {
					@include t-text-1;
				}

				.t-text-2 {
					@include t-text-2;
				}

				.t-text-3 {
					@include t-text-3;
				}

				.t-text-4 {
					@include t-text-4;
				}

				.t-text-5 {
					@include t-text-5;
				}

				.t-text-6 {
					@include t-text-6;
				}

				.t-text-7 {
					@include t-title-h7;
				}

				.t-text-8 {
					@include t-text-8;
				}
			}

			&-title {
				margin-bottom: 20px;
				color: $color_main;
			}
		}
	}

	.f-radio-wrapper {
		margin: 20px 0;
	}

	button {
		margin-left: auto;
		margin-right: auto;
	}

	.f-field-email,
	.f-field-textarea {
		margin-bottom: 0;
	}
}
