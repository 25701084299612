.b-module_constructor {
	$module: &;

	//News Widget
	&--news {
		#{$module} {
			&-items {
				display: grid;
				gap: 20px;
			}

			&-item {
				&-content {
					align-self: center;
					max-width: 66%;
					margin: 0 auto;
					padding-left: 0;
					box-sizing: content-box;
				}

				&-label {
					@extend %title-sm-3;
				}

				&-title {
					@extend %text-60s;
				}

				&-text {
					@extend %text-sm-7;
				}
			}
		}
	}

	&--three_tiles {
		$imgWidth: 345;
		$imgHeight: 470;

		#{$module}-item {
			&.g-col-sm-3 {
				.b-showcase-media {
					padding-bottom: calc((#{$imgHeight} / #{$imgWidth}) * 100%);
				}
			}

			&.g-col-sm-6 {
				.b-showcase-media {
					padding-bottom: calc((#{$imgHeight} / (#{$imgWidth} * 2 + 10)) * 100%);
				}
			}

			.b-showcase {
				justify-content: initial;
				width: 100%;

				&-content {
					padding: 16px;
				}

				&-media {
					position: relative;
					flex-grow: initial;

					img {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}

				&-text {
					@include t-title-h7-secondary;
				}

				&-link {
					margin-top: 32px;
				}
			}
		}
	}

	//Single Text Widget
	&--single_text {
		#{$module} {
			&-content {
				padding: 65px 0;
			}

			&-item {
				&-content {
					@include t-title-h3-secondary;

					max-width: 58%;
					margin: 0 auto;
					padding: 0;
					text-align: center;
					box-sizing: content-box;
				}
			}
		}
	}

	&--single_text-image {
		#{$module} {
			&-content {
				padding: 80px 40px 40px;

				#{$module}-item {
					&-content {
						margin-bottom: 40px;
					}
				}
			}
		}
	}

	//Tiles Widget
	&--three-per-line,
	&--two-per-line {
		.b-showcase {
			&-content {
				padding: 24px 0 20px;
				color: $black;
			}

			&-title {
				@include t-title-h2(uppercase);

				margin-bottom: 16px;
			}

			&-text {
				@include t-title-h7-secondary;

				margin-bottom: 32px;
			}

			&-link {
				@include t-title-h6-h7;

				padding-bottom: 1px;
				color: $black;
			}
		}
	}

	&--three-per-line {
		$aspectRatio: map-get(map-get($configVariation, "aspectRatio"), "square");

		.b-showcase {
			&-media {
				aspect-ratio: $aspectRatio;
			}
		}
	}

	&--stretch-on-scroll {
		#{$module}-item,
		.b-showcase {
			width: 100%;
		}
	}

	&--two-per-line {
		$aspectRatio: map-get(map-get($configVariation, "aspectRatio"), "rect-3");

		#{$module} {
			&-content {
				padding: 0 25px 40px;
			}
		}

		.b-showcase {
			&-content {
				padding: 32px 40px 40px;
				color: $black;
			}

			&-media {
				aspect-ratio: $aspectRatio;
			}
		}
	}

	&--video_module {
		#{$module} {
			&-content {
				padding: 40px;
			}
		}

		.b-showcase {
			&-content {
				display: flex;
				flex-wrap: nowrap;
				align-items: flex-start;
				padding: 40px 0 0;
				color: $white;
				text-align: left;
				gap: 0 80px;
			}

			&-title {
				@include t-title-h2-secondary;

				flex: 1;
				letter-spacing: 0.32px;
			}

			&-text {
				@include t-title-h5-secondary;

				flex: 2;
				letter-spacing: 0.2px;
			}
		}

		.play-icon {
			@include icon(before, play);

			position: absolute;
			top: 50%;
			left: 50%;
			width: 40px;
			height: 40px;
			transform: translate(-50%, -50%);
			color: $white;
			cursor: pointer;
			z-index: 2;

			&::before {
				font-size: 40px;
			}
		}

		.playing .play-icon,
		.loader-indicator {
			display: none;
		}
	}

	&.m-square-images {
		.b-showcase-media img {
			height: auto;
			aspect-ratio: map-get(map-get($configVariation, "aspectRatio"), "square");
			object-position: top;
		}
	}

	&.m-grid {
		.b-showcase {
			&-content {
				padding: 24px 40px 0;
			}

			&-text {
				@include t-title-h4-secondary;
			}
		}
	}

	//Module items variations and modifications
	&-item {
		&.m-centered-image {
			#{$module}-item {
				&-stickers {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				&-image {
					position: relative;
					flex-shrink: 1;
					max-width: 57%;
					margin: 20px auto;
				}

				&-link {
					align-self: center;
					border-bottom: 1px solid currentColor;
				}
			}
		}

		&.m-product-centered {
			#{$module}-item {
				&-content {
					padding: 99px 0;
					text-align: center;
				}

				&-text {
					@include t-title-h7-secondary;

					margin-bottom: 40px;
					text-align: center;
				}

				&-link {
					@include t-title-h6-h7;

					margin: 0 0 40px;
					border-bottom-color: $black;
				}
			}

			.b-product {
				&_image-container {
					max-width: 55%;
					margin: 0 auto 40px;
				}

				&_tile {
					&:hover {
						.b-add_to_wishlist,
						.b-variation {
							display: none;
						}

						&:has(.b-variation) {
							.b-product_tile-body-main {
								visibility: visible;
							}
						}
					}
				}

				&_price,
				&_more-colors,
				&_variations--plp {
					display: none;
				}

				&_name {
					padding: 0;
				}

				&_tile-body {
					margin-bottom: 40px;
					padding: 0;
					text-align: center;

					&-main {
						padding-right: 0;
					}
				}

				&_name-title {
					@include t-title-h6-h7(uppercase);

					color: $black;
				}
			}

			.b-variation-list,
			.m-plp_colors {
				display: none;
			}
		}

		&.m-left-image {
			#{$module}-item {
				&-content {
					padding: 0;
				}

				&-title {
					margin-bottom: 32px;
				}

				&-text {
					@include t-title-h4;

					letter-spacing: 1px;
				}
			}
		}

		&.m-right-image {
			#{$module}-item {
				&-content {
					max-width: 54%;
					padding: 0;
				}

				&-text {
					@include t-title-h4-secondary;

					margin-bottom: 40px;
					text-align: center;
				}
			}
		}

		&.m-product-image {
			#{$module}-item {
				&-content {
					max-width: 49%;
					padding: 0;
					text-align: center;

					.b-showcase {
						margin-bottom: 16px;
					}
				}

				&-text {
					@include t-title-h7-secondary;

					margin-bottom: 32px;
					text-align: center;
				}

				&-link {
					@include t-title-h6-h7;

					margin-top: 0;
				}
			}
		}

		&.m-store-card {
			#{$module}-item {
				&-content {
					align-self: end;
					padding: 45px 0 0;

					.b-accordion {
						&-title {
							padding: 20px 0;

							&::after {
								right: 0;
							}
						}

						&-item {
							border-bottom: none;
						}
					}

					@include respond-to(all) {
						.g-button-card {
							@include t-title-h9;

							padding: 14px 20px;
							letter-spacing: 1px;
						}
					}
				}

				@include respond-to(all) {
					&-title {
						@include t-title-h2-secondary;
					}
				}

				&-text {
					@include t-title-h9;

					margin-bottom: 24px;

					&:last-child {
						margin-bottom: 44px;
					}
				}

				&-icon {
					@include icon(before);

					margin-right: 12px;
					color: $bordeaux;
					vertical-align: middle;

					&.whatsapp::before {
						content: icon-char(whatsapp);
						font-size: 16px;
					}

					&.curlyarrow::before {
						content: icon-char(curlyarrow);
						font-size: 16px;
					}
				}

				&-link {
					@include t-title-h9(uppercase);

					padding-bottom: 1px;
					letter-spacing: 0;

					&-wrap {
						margin-bottom: 4px;
						padding: 20px;
						background-color: $grey2;
						color: $black;

						&:last-child {
							margin-bottom: 0;
						}

						.g-button-link {
							margin-top: 0;
						}
					}
				}
			}
		}

		&.m-fullwidth_image {
			$aspectRatio: map-get(map-get($configVariation, "aspectRatio"), "rect-5");

			.b-showcase {
				&-media {
					aspect-ratio: $aspectRatio; /* stylelint-disable-line */
				}
			}
		}

		&.m-categories-line {
			.b-showcase {
				flex-basis: 100%;

				&-title {
					@include t-title-h4-secondary;
				}
			}
		}

		&.m-left-right-text {
			#{$module}-item {
				&-title {
					@include t-title-h3-secondary;

					margin-bottom: 32px;
				}

				&-text {
					@include t-text-7;
				}
			}
		}

		&.m-news-carousel {
			#{$module}-item-content {
				padding-top: 0;
			}

			.b-showcase {
				&-media {
					margin-bottom: 16px;
				}

				&-title {
					margin-bottom: 40px;
				}
			}
		}
	}

	&,
	&-item {
		&.m-links-scrolled-desktop,
		&.m-links-scrolled {
			overflow: hidden;

			#{$module}-item-links {
				overflow: auto;
			}
		}
	}

	.b-owl_carousel.m-top-nav {
		.b-owl_carousel-nav {
			padding: 0;

			&_controls {
				position: absolute;
				top: 30px;
				right: 30px;
				width: 104px;
			}

			&_next,
			&_prev {
				margin-top: 0;

				&::after {
					font-size: 20px;
					color: $gold;
					opacity: 0.4;
					transition: opacity 0.5 ease-in;
				}

				&:hover::after {
					opacity: 1;
				}
			}

			&_next {
				@include icon(after, arrow-wide-right);

				right: 0;
			}

			&_prev {
				@include icon(after, arrow-wide-left);

				left: 0;
			}
		}
	}

	&--anchor {
		@include carouselDotsBordered;

		.b-showcase {
			width: 100%;

			&-content {
				position: absolute;
				top: 50%;
				left: 50%;
				padding-top: 20px;
				transform: translate(-50%, -50%);
			}

			&-year,
			&-title {
				margin-bottom: 24px;
			}

			&-year {
				@include t-text-1;

				letter-spacing: -0.24px;
			}

			&-title {
				@include t-title-h1-secondary;

				$letter-spacing: 0.6px;
 			}

			&-text {
				@include t-title-h5-secondary;

				letter-spacing: 0.2px;
			}

			&-media {
				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(0deg, $black-overlay 0%, $black-overlay 100%);
				}

				img {
					height: 640px;
				}
			}
		}

		.b-owl_carousel-nav_dots {
			bottom: 40px;
		}
	}

	&.vh-100,
	.vh-100 & {
		#{$module}-items,
		#{$module}-item {
			height: 100%;
		}
	}
}

.g-row-2 {
	grid-row: span 2;
}

//Showcase / showcase content block variations
.b-showcase {
	$showcase: &;

	&-content.m-oneline {
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 20px 40px;

		#{$showcase}-title {
			@extend %title-sm-3;

			width: auto;
			margin: 0;

			&:not(:last-child) {
				margin-bottom: 0;
			}
		}
	}
}

//Fullscreen Carousel
.l-assets_slider_with_thumbs-container {
	.b-owl_carousel .owl-stage,
	.b-owl_carousel .owl-stage-outer,
	.b-owl_carousel-item,
	.b-assets_slider_with_thumbs-slides_item {
		height: 100%;
	}

	.b-module_constructor {
		&,
		&-item,
		&-desktop_bg-image,
		.b-showcase-image,
		.b-videomgr div[data-vimeo-initialized="true"] {
			height: 100%;
		}

		&-items {
			max-height: 100%;
		}
	}

	.b-videomgr iframe,
	.b-videomgr div[data-vimeo-initialized="true"] {
		background-color: $black;
	}

	.b-owl_carousel {
		&-nav {
			&_dots {
				left: auto;
				right: 80px;
				bottom: 30%;
			}

			&_next {
				right: 25px;
			}

			&_prev {
				left: 25px;
			}

			&_next,
			&_prev {
				&::after {
					font-size: 50px;
				}
			}
		}
	}
}

