/*
#Page - Account Login
*/

/*
#Block - Account Login Form
*/
.b-account_login {
    &-title {
        @include myaccount_title;
    }

    &-form {
        margin-bottom: 20px;

		.b-login_account {
			.b-login_account-title,
			.b-login_account-info {
				display: none;
			}

			&-form > .f-form-error_message {
				@include font_main(12px, 14px, $font_main);

				margin-bottom: 28px;
				text-transform: none;
			}

			.f-field {
				width: 100%;
				margin: 0 0 20px;
			}

			&-login_button {
				float: right;
				margin: 52px 0 0;
				min-width: 250px;

				@include respond-to(vertical) {
					position: static;
				}
			}

			&-forgot_password {
				position: relative;
				display: inline-block;
				top: 0;
				left: 0;
				margin: 0 0 0 32%;
				padding: 0;
			}

			.f-label {
				display: block;
			}
		}

        .f-field {
            @include clearfix;

            &-email,
            &-password {
                .f-label {
                    display: block;
                }
            }

            &-wrapper {
                @include myaccount_field_wrapper;
            }
        }
    }
}

/*
#Block - Account Login Create
*/
.b-account_login-create {
    &_title {
        @include myaccount_title;
    }

    &_description {
        @include description_text;
    }

    &_button {
        @extend %g-button-primary;

        float: right;
    }

    &_content {
        display: none;
    }
}
