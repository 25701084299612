/*
#Page - Order History
*/

.b-orders_title {
	@include t-title-h8(uppercase);

	margin-bottom: 25px;
}

.l-orders_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.b-orders_empty-title {
    @include t-text-6;
}

/*
#Block - Order Item
*/
.b-orders_item {
	position: relative;
	padding-top: 40px;

    &--first {
        padding: 0;
    }

    &-header {
        @include clearfix;

        position: relative;
        padding-bottom: 10px;
        text-align: left;
    }

    &-date,
    &-status,
    &-tracking_number,
    &-tracking_link,
    &-return_tracking_number,
    &-return_tracking_link {
        margin-bottom: 8px;
    }

	&-number {
		margin-bottom: 25px;
	}

    &-date,
    &-status,
    &-tracking_number,
    &-tracking_link,
    &-number {
        max-width: 75%;
    }

    &-label,
    &-value {
        @include t-title-h9;
    }

	&-buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	&-button_return,
	&-button_details {
		@extend %g-button-primary;

		display: inline-flex;
		width: auto;
	}

    &-table {
        width: 100%;
        border: none;
        border-bottom: $border_main;
        border-top: $border_main;

        th,
        td {
            text-align: left;
            vertical-align: top;
            border: none;
        }

		th,
		tr:first-child th {
			@include t-title-h7(uppercase);

			padding: 20px 0 10px;
		}

        td,
		tbody tr:first-child td {
			@include t-title-h7;

			padding: 5px 0 20px;
		}
    }

    &-col_shipped {
        width: 30%;
    }

    &-col_price {
        width: 130px;
        text-align: right !important;

        .b-orders_item-value {
            @include t-title-h9;
        }
    }

    &-items_list {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

/*
#Block - Paging Bar
*/
.b-pagination {
    @include t-title-h9;
    @include clearfix;

    &_top {
        padding: 4px 0 20px;
    }

    &_bottom {
        padding: 20px 0;
    }

    &_results {
        float: right;
        padding-top: 3px;
    }

	&_list {
		display: flex;
		justify-content: center;
		margin: 0;
	}

    &_item {
        float: left;
        margin-right: 7px;
    }

	&_link {
		@include t-text-6;

		@extend %g-button-secondary;

		display: block;
		width: 27px;
		height: 27px;
		padding: 0;
		margin: 0;
		text-align: center;

		&--current {
			background-color: $color_second;
			color: $white;
			border: $border-second;
		}
	}
}

.order-tracking-width {
	@include img-fluid();
}
