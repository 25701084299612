//--------------------------------
// Sprite
//--------------------------------

@mixin sprite($x, $y) {
    background: url("../images/sprite.png") $x $y no-repeat;
}

//--------------------------------
// Vertical align
//--------------------------------
@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

//--------------------------------
// Photoshop Drop Shadow
//--------------------------------
@mixin photoshop-drop-shadow($angle: 0, $distance: 0, $spread: 0, $size: 0, $color: $color_main, $inner: false) {
    $angle: (180 - $angle) * pi() / 180; // convert to radians
    $h-shadow: round(cos($angle) * $distance);
    $v-shadow: round(sin($angle) * $distance);
    $css-spread: $size * $spread / 100;
    $blur: $size - $css-spread;
    $inset: if($inner != false, "inset", "");

    @include box-shadow($h-shadow $v-shadow $blur $css-spread $color unquote($inset));
}

//--------------------------------
// Photoshop Inner Shadow
//--------------------------------

//--------------------------------
// Photoshop Text Shadow
//--------------------------------
@mixin photoshop-text-shadow($angle: 0, $distance: 0, $spread: 0, $size: 0, $color: $color_main) {
    // NOTE: $spread has no effect for text shadows
    $angle: (180 - $angle) * pi() / 180;
    $h-shadow: round(cos($angle) * $distance);
    $v-shadow: round(sin($angle) * $distance);
    $css-spread: $size * $spread / 100;
    $blur: $size - $css-spread;

    @include text-shadow($h-shadow $v-shadow $blur $color);
}

//--------------------------------
// Prevent Content Selection
//--------------------------------
@mixin unselect {
    user-select: none;
}

//--------------------------------
// Moving Layer To GPU
//--------------------------------
@mixin to-gpu {
    transform: translateZ(0);
}

//--------------------------------
// Animation
// Example usage:
// @include animation(10s, 5s, linear, changecolour)
//--------------------------------

@mixin animation($delay, $duration, $easing, $animation) {
    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    animation-timing-function: $easing;
}

//--------------------------------
// CSS Animation for toggle slide
// Example usage:
// @include toggle_slide(0.5s, ease-in)
//--------------------------------
@mixin toggle_slide($time: 0.5s, $effect: ease-in) {
    transition: height $time $effect;
    overflow: hidden;
}

//--------------------------------
// Arrow/Triangle
// Example usage:
// @include arrow($position: before,  $direction: left, $color: $color_main, $size: 5px, $margin: 0 0 0 0)
//or  absolute and with border for arrow
//@include arrow($position: both,  $direction: up, $color: $white, $size: 6px, $absolute: true, $second_border: $color_main);
//--------------------------------

@mixin arrow($position: before, $direction: left, $color: $color_main, $size: 5px, $absolute: false, $second_border: $beige) {
    @if $position == both {
        $position: "before, &:after";
    }

    // arrow is straight in element
    @if $position == self {
        display: inline-block;
        height: 0;
        width: 0;
        vertical-align: middle;

        @if $direction == left {
            border-top: $size solid transparent;
            border-bottom: $size solid transparent;
            border-right: $size solid $color;
            border-left: none;
        }

        @if $direction == right {
            border-top: $size solid transparent;
            border-bottom: $size solid transparent;
            border-left: $size solid $color;
            border-right: none;
        }

        @if $direction == up {
            border-left: $size solid transparent;
            border-right: $size solid transparent;
            border-bottom: $size solid $color;
            border-top: none;
        }

        @if $direction == down {
            border-left: $size solid transparent;
            border-right: $size solid transparent;
            border-top: $size solid $color;
            border-bottom: none;
        }
    }

    @else {
        &:#{$position} {
            content: '';
            display: inline-block;
            height: 0;
            width: 0;
            vertical-align: middle;

            @if $direction == left {
                border-top: $size solid transparent;
                border-bottom: $size solid transparent;
                border-right: $size solid $color;
                border-left: none;

                @if $absolute == true {
                    position: absolute;
                    right: 100%;
                    top: 50%;
                    margin: #{-$size} 0 0 0;
                }
            }

            @if $direction == right {
                border-top: $size solid transparent;
                border-bottom: $size solid transparent;
                border-left: $size solid $color;
                border-right: none;

                @if $absolute == true {
                    position: absolute;
                    left: 100%;
                    top: 50%;
                    margin: #{-$size} 0 0 0;
                }
            }

            @if $direction == up {
                border-left: $size solid transparent;
                border-right: $size solid transparent;
                border-bottom: $size solid $color;
                border-top: none;

                @if $absolute == true {
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    margin: 0 0 0 #{-$size};
                }
            }

            @if $direction == down {
                border-left: $size solid transparent;
                border-right: $size solid transparent;
                border-top: $size solid $color;
                border-bottom: none;

                @if $absolute == true {
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin: 0 0 0 #{-$size};
                }
            }
        }

        @if $position == "before, &:after" and $absolute == true {
            &::before {
                @if $direction == left {
                    margin-right: 1px;
                    border-right-color: $second_border;
                }

                @if $direction == right {
                    margin-left: 1px;
                    border-left-color: $second_border;
                }

                @if $direction == up {
                    margin-bottom: 1px;
                    border-bottom-color: $second_border;
                }

                @if $direction == down {
                    margin-top: 1px;
                    border-top-color: $second_border;
                }
            }
        }
    }
}

//#Mixin for box-sizing
@mixin box-sizing($p: border-box) {
    box-sizing: $p;
}

//#Mixins for clearfix
@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

.clearfix,
 %clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin clearfix-before {
    &::before {
        content: "";
        display: table;
        clear: both;
    }
}

//#Mixin for link underline
@mixin underline {
    position: relative;
    padding-bottom: 2px;
    text-decoration: none;
    cursor: pointer;

    &::after {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: 1px;
        background: currentColor;
    }
}

/*
#Mixin for removing underline
Example usage:
@include underline_on_hover_only;
*/
@mixin underline_on_hover_only {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

@mixin underline_on_hover_remove {
    &:hover {
        text-decoration: none;
    }
}

@mixin underline_remove {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

/*
#mixin for header navigation title
Example usage:
@include header_navigation_title;
*/
@mixin header_navigation_title {
    position: relative;
    display: block;
    padding: 8px 10px;
    border: 1px solid transparent;
    color: $color_main;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
}

/*
#mixin for header navigation title hover
Example usage:
@include header_navigation_title_hover;
*/

@mixin header_navigation_title_hover {
    background: $white;
    border: $border_main;
    border-bottom: none;
    text-decoration: none;
    z-index: 1;
}

/*
#mixin for header navigation title
Example usage:
@include header_navigation_flyout;
*/
@mixin header_navigation_flyout {
    position: absolute;
    display: none;
    top: 100%;
    right: 0;
    margin-top: -1px;
    padding: 20px 10px 20px 20px;
    background: $white;
    border: $border_main;
    z-index: 0;
}

/*
#mixin for box-shadow
Example usage:
@include box-shadow(0, 1px, 4px, rgba(0, 0, 0, 0.1), true);
*/
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        box-shadow: inset $top $left $blur $color;
    }

    @else {
        box-shadow: $top $left $blur $color;
    }
}

/*
#mixin for My Account
Example usage:
@include myaccount_title;
*/
@mixin myaccount_title {
    @include t-title-h2(uppercase);

    margin-bottom: 25px;
}

/*
#mixin for My Account field wrapper
Example usage:
@include myaccount_field_wrapper;
*/
@mixin myaccount_field_wrapper {
    float: right;
    width: 68%;
}

/*
#mixin for My Account description texts
*/
@mixin description_text {
    @include t-title-h5;
}

@mixin phone_fields {
    .f-type-phone {
        width: 44.44%;

        &code {
            width: 55.56%;

            .f-field-wrapper {
                width: calc(40.79% - 14px);
                margin-right: 20px;
            }
        }

        .f-field-wrapper {
            width: 100%;
        }
    }
}

/*
#mixin '*' icon for required fields
Example usage:
@include required_fields;
*/
@mixin required_fields {
    content: '*';
    padding-left: 1px;
}

@mixin loading-icon-animation {
    @keyframes uil-ring-anim {
        0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
    }
}

@mixin loading-icon-indicator {
    border-radius: 100%;
    box-shadow: 0 2px 0 0 $white;
    animation: uil-ring-anim 1s linear infinite;
}

/*
#TOOLTIPS
##Move tooltip mixins to mixin file
```
<div class="g-tooltip">
  <span class="g-tooltip-link">hover me</span>
  <span class="g-tooltip-content">Tooltip content</span>
</div>
```
*/

@mixin tooltip_base {
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: left;

    &-content {
        position: absolute;
        display: none;
        width: 220px;
        padding: 15px 20px;
        cursor: default;
        border: $border_main;
		background: $white;
        color: $color_main;
        line-height: normal;
        z-index: 999;
    }

	&:hover::before,
	&-opened.g-tooltip::before,
	&:hover::after,
	&-opened.g-tooltip::after {
        visibility: visible !important;
        z-index: 999;
    }

	&:hover &-content,
	&-opened &-content {
        display: block;
    }

    &-close {
        position: absolute;
        display: none;
        top: 10px;
        right: 10px;
        width: 11px;
        height: 11px;
        cursor: pointer;
    }

    &-opened &-close {
        display: block;
    }
}

// configuration of tooltips: top, right, bottom, left
@mixin tooltip_options($direction: bottom) {
    @if $direction == up {
        @include arrow(before, down, $color_main, 10px, 0 0 0 -10px);
        @include arrow(after, down, $white, 10px, 0 0 1px -10px);

        &::before,
        &::after {
            position: absolute;
            top: auto;
            right: auto;
            bottom: 100%;
            left: 50%;
            visibility: hidden;
        }

        .g-tooltip-content {
            top: auto;
            right: auto;
            bottom: 100%;
            left: 50%;
            margin: 0 0 10px -110px;
        }
    }

    @if $direction == right {
        @include arrow(before, left, $color_main, 10px, -10px 0 0 0);
        @include arrow(after, left, $white, 10px, -10px 0 0 1px);

        &::before,
        &::after {
            position: absolute;
            top: 50%;
            right: auto;
            bottom: auto;
            left: 100%;
            visibility: hidden;
        }

        .g-tooltip-content {
            bottom: auto;
            left: 100%;
            margin: 0 0 0 10px;
            right: auto;
            top: -20px;
        }
    }

    @if $direction == down {
        @include arrow(before, up, $color_main, 10px, 0 0 0 -10px);
        @include arrow(after, up, $white, 10px, 1px 0 0 -10px);

        &::before,
        &::after {
            position: absolute;
            top: 100%;
            right: auto;
            bottom: auto;
            left: 50%;
            visibility: hidden;
        }

        .g-tooltip-content {
            bottom: auto;
            left: 50%;
            margin: 10px 0 0 -110px;
            right: auto;
            top: 100%;
        }
    }

    @if $direction == left {
        @include arrow(before, right, $color_main, 10px, -10px 0 0 0);
        @include arrow(after, right, $white, 10px, -10px 1px 0 0);

        &::before,
        &::after {
            position: absolute;
            top: 50%;
            right: 100%;
            bottom: auto;
            left: auto;
            visibility: hidden;
        }

        .g-tooltip-content {
            top: -20px;
            right: 100%;
            bottom: auto;
            left: auto;
            margin: 0 10px 0 0;
        }
    }
}

.g-tooltip {
    @include tooltip_base;
}

.g-tooltip--up {
    @include tooltip_options(up);

    &::after {
        margin-bottom: 1px;
    }
}

.g-tooltip--right {
    @include tooltip_options(right);
}

.g-tooltip--down {
    @include tooltip_options(down);
}

.g-tooltip--left {
    @include tooltip_options(left);
}

@mixin justify_blocks {
    text-align: justify;

    &::after {
        content: '';
        display: inline-block;
        width: 100%;
    }
}

@mixin tooltip-arrow {
    position: absolute;
    top: -23px;
    right: 50%;
    margin-left: -7px;
    transition: top 0.3s;

    @include respond-to(nav) {
        top: -24px;
    }

	&::after,
	&::before {
        content: '';
        position: absolute;
        top: -10px;
        right: 0;
        width: 0;
        height: 0;
        margin-left: -12px;
        border: solid transparent;
        pointer-events: none;
        z-index: 8;
        border-width: 7px;
    }

    &::after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: $white;
    }

    &::before {
        top: 1px;
        border-color: rgba(0, 0, 0, 0);
        border-top-color: $color_main;
    }
}


@mixin style_class_list($class_list) {
    @if type_of($class_list) == "list" {
        @each $class_name in $class_list {
            .#{$class_name} {
                @content;
            }
        }
    }
}

@mixin custom_promo_adjustment {
    white-space: nowrap;

    &::before {
        content: '-';
        display: inline-block;
    }
}

@mixin icon_false {
    @include t-title-h3;

    margin: -4px 0 13px;

    &::before {
        display: none;
    }
}

@mixin input-with-focus-border($pseudoel: after) {
	opacity: 0;
	visibility: visible;

	&:focus {
		~ .f-label::#{$pseudoel} {
			border-color: $black;

			@if $accessibility-color {
				border-color: $accessibility-color;
			}
		}
	}

	~ label {
		position: relative;

		&::#{$pseudoel} {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 16px;
			height: 16px;
			transform: translateY(-50%);
			border: 2px solid transparent;
		}
	}
}

@mixin button_reset() {
	display: inline-block;
	min-width: unset;
	padding: 0;
	border: 1px solid transparent;
	font: inherit;
	background: transparent;
	text-transform: inherit;
}

@mixin animation_underline_reset() {
	&::after {
		display: none;
	}
}

@mixin visually-hidden() {
	position: absolute;
	height: 1px;
	width: 1px;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	overflow: hidden;
	white-space: nowrap;
}

@mixin lazyload-with-transition() {
    &.lazy {
        &:not([src]) {
            opacity: 0;
        }

        &.loaded + .lazyloadxt-indicator {
            display: none;
        }
    }

    &:not(.initial) {
        transition: opacity $lazyload-time-transition;
    }

    &.initial,
    &.loaded,
    &.error {
        opacity: 1;
    }

    &:not([src]) {
        visibility: hidden;
    }
}

@mixin img-fluid {
	max-width: 100%;
	height: auto;
}

@mixin button-as-icon-reset {
	@include button_reset();

	height: auto;
	min-height: auto;
	border: none;
	color: $black;
	font-size: 18px;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		border: none;
		color: $color_main;
	}
}

@mixin field-autofill($bg-color: $white, $text-color: $color_main) {
	&:-webkit-autofill {
		box-shadow: 0 0 0 50px $bg-color inset;
		-webkit-text-fill-color: $text-color; /* stylelint-disable-line */
		border-bottom: 1px solid $text-color;
	}
}

@mixin animated-underline {
	position: relative;
	display: inline-block;
	padding: 3px 0;
	overflow: hidden;
	border-bottom: 0;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		opacity: 0;
		background-color: currentColor;
		transition: opacity 300ms, transform 300ms;
		transform: translate3d(-100%, 0, 0);
	}

	&:hover::after,
	&:focus::after {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

/*
#Keyframes mixin
##This mixin is for cross-browser keyframes declaration
Example usage:
@include keyframe(name) { 0% { opacity: 0 } 100% { opacity: 1 } }
*/

@mixin keyframe($animation_name) {
    @keyframes #{$animation_name} {
        @content;
    }
}

@mixin webkit-scrollbar($vertical-width: 5px, $border-radius: 0, $bg-color: $grey4) {
    &::-webkit-scrollbar {
        appearance: none;

        /* stylelint-disable */
        &:vertical {
            width: $vertical-width;
        }

        /* stylelint-enable */

        &-thumb {
            border-radius: $border-radius;
            border: 1px solid $bg-color;
            background-color: $color_second;
        }

        &-track {
            border-radius: $border-radius;
            background-color: $bg-color;
        }
    }
}

@mixin diamond-box {
	clip-path: polygon(0 30.00px,30.00px 0,calc(100% - 30.00px) 0,100% 30.00px,100% calc(100% - 30.00px),calc(100% - 30.00px) 100%,30.00px 100%,0 calc(100% - 30.00px));
}

@mixin transition-animation($property: background-color, $time: 0.3s, $method: ease-in-out) {
    transition: $property $time $method;
    overflow: hidden;
}

@mixin option {
	&-radio {
		position: absolute;
		visibility: visible;
		opacity: 0;
	}

	&-radio:checked + label {
		color: $gold;
	}

	&-label {
		@include t-title-h9;

		&:hover {
			cursor: pointer;
		}
	}
}

@mixin colored-header {
	background: rgba($white, 1);
	color: $black;
	border-bottom: $border_grey;
	transition: background .3s linear;

	.b-primary_logo-image {
		background-color: $gold;
	}
}

@mixin line-clamp($lines: 2) {
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@mixin imgWithoutSrc() {
	background: $grey1;

	@include icon(before, heelheight);

	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 40px;
		height: 40px;
		transform: translate(-50%, -50%);
		font-size: 40px;
		animation: placeholderAnimation 2.5s infinite ease-in-out; /* stylelint-disable-line */
	}
}

@mixin carouselDotsBordered {
	.b-owl_carousel {
		&-nav_dot {
			@include icon(after);

			width: 32px;

			&::after {
				font-size: 20px;
				color: currentColor;
			}

			&.active {
				&::after {
					content: $icon-diamond-bordered;
					font-size: 20px;
				}
			}
		}
	}
}
