/*
#Custom scroll component
*/
.b-scroll {
    &-bar_x_hidden,
    &-bar_y_hidden {
        display: none;
    }

    &-x {
        display: none !important;
    }

    &-bar,
    &-bar_outer {
        position: absolute;
        top: 3px;
        right: 0;
        bottom: 3px;
        width: 16px;
        padding: 0 3px;
        z-index: 999;
    }

    &-bar_size,
    &-bar_track {
        position: absolute;
        top: 0;
        width: 100%;
        height: 103%;
    }

    &-bar_control {
        position: absolute;
        width: 10px;
        min-height: 25px;
        background: $color_second;
    }
}
