.b-lp-asset {
    @include clearfix;

    margin: 0 40px 95px;
    text-align: center;

    &.first-asset {
        margin-top: 30px;
    }

    &.full-width-world {
        margin: 0 0 95px;

        .b-lp-asset-body-section {
            width: 100%;
        }
    }

    &.video {
        padding-bottom: 0;

        @include respond-to(until-laptop) {
            padding-bottom: 0;
        }

        .b-lp-asset-body {
            &-section {
                overflow: hidden;

                .mejs-container {
                    background: transparent;
                }
            }
        }
    }

    p {
        text-align: center;
    }

    &.left,
    .left {
        &,
        p {
            text-align: left;
        }
    }

    &.right,
    .right {
        &,
        p {
            text-align: right;
        }
    }

    &.center,
    .center {
        &,
        p {
            text-align: center;
        }
    }

    a:hover {
        border-bottom: 1px solid transparent;
    }

    &.b-masonary-block {
        margin: 0 40px;
    }

    &-body {
        @include clearfix;

        position: relative;
        width: 100%;

        .clear {
            clear: both;
            height: 0;
        }

        &-world-masonry {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0;

            &-header {
                @include t-title-h2;

                padding: 60px 0 48px;
            }

            &-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1 1 auto;
                width: 1 / 3 * 100%;
                max-width: 1 / 3 * 100%;

                &.b-big-item {
                    margin: 0 0 125px;
                }
            }

            &-img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }

            &-popup {
                &-content,
                &-video,
                &-carousel {
                    position: relative;
                    display: block;
                    max-width: 89%;
                    margin: 0 auto;
                    text-align: center;
                    cursor: pointer;

                    &::after {
                        content: ' ';
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }

                &-content::after {
                    width: 26px;
                    height: 31px;
                    background: url("../images/content-popup-symbol.png") no-repeat;
                }

                &-video::after {
                    width: 30px;
                    height: 35px;
                    background: url("../images/video-popup-symbol.png") no-repeat;
                }

                &-carousel::after {
                    width: 40px;
                    height: 40px;
                    background: url("../images/carousel-popup-symbol.png") no-repeat;
                }
            }

            &-title {
                @include t-title-h3;

                max-width: 89%;
                min-height: 55px;
                margin: 20px calc((100% - 282px) / 2) 0;
            }
        }

        &.full-width {
            .b-lp-asset-body {
                &-title {
                    @include t-title-h9;

                    margin-bottom: 10px;
                }

                &-text {
                    @include t-title-h2;

                    margin-bottom: 0;
                }
            }
        }

        &.image-left,
        &.image-right {
            .b-lp-asset-body-section.v-align-center {
                .b-lp-asset-body-section_title {
                    @include t-title-h9;

                    margin: 0 0 10px;
                }
            }
        }

        &.image-left-world,
        &.image-right-world {
            .b-lp-asset-body-section.v-align-center {
                .b-lp-asset-body-section {
                    &_title {
                        margin: 0 0 21px;
                    }

                    &-text {
                        @include t-title-h4;
                    }
                }
            }
        }

        &.image-left-world {
            .fl {
                width: calc(50% - 30px);
                margin: 0 15px;
            }

            .fr {
                width: 30%;
                margin: 0 10%;

                @include respond-to(custom) {
                    width: 40%;
                    margin: 0 5%;
                }
            }
        }

        &.image-right-world {
            .fl {
                width: 30%;
                margin: 0 10%;

                @include respond-to(custom) {
                    width: 40%;
                    margin: 0 5%;
                }
            }

            .fr {
                width: calc(50% - 30px);
                margin: 0 15px;
            }
        }

        &.image-left {
            .fl {
                width: calc(50% - 30px);
                margin: 0 15px;
            }
        }

        &.two-image {
            .fl {
                width: calc(50% - 56px);
                margin: 0 28px;
            }

            &.hp {
                .b-lp-asset-body-section {
                    &_brand {
                        margin-top: 15px;
                    }

                    &_title {
                        margin: 5px 0;
                    }
                }
            }
        }

        &.image-right {
            .fr {
                width: calc(50% - 26px);
                margin: 0 13px;
            }
        }

        &.slim-image {
            .b-lp-asset-body {
                &-title {
                    @include t-title-h3;

                    margin-bottom: 35px;
                }

                &-section {
                    &.fl {
                        width: calc(50% - 192px);
                        margin: 0 96px;

                        @include respond-to(until-laptop) {
                            width: calc(50% - 30px);
                            margin: 0 15px;
                        }
                    }

                    &.fr {
                        width: 50%;
                    }
                }
            }
        }

        &.three-equal {
            .b-lp-asset-body {
                &-title,
                &-view_all {
                    @include t-title-h3;
                }

                &-title_world {
                    @include t-title-h2;

                    margin-bottom: 5px;
                }

                &-view_all {
                    &:hover {
                        border-bottom: $border_main;
                    }
                }

                &-top_part {
                    margin-bottom: 35px;

                    p {
                        margin-bottom: 0;
                    }
                }

                &-section {
                    width: calc(100% / 3);
                    margin: 0;
                    padding: 0 15px;

                    &_title {
                        margin: 5px 0;
                    }

                    &_brand {
                        margin-top: 15px;
                    }
                }
            }
        }

        &.four-equal {
            .b-lp-asset-body {
                &-section {
                    width: calc(100% / 4);
                    margin: 0;
                    padding-right: 40px;

                    @include respond-to(until-laptop) {
                        padding-right: 10px;
                    }

                    &-text {
                        @include t-title-h8;

                        margin-bottom: 5px;
                    }

                    &_brand {
                        margin-top: 0;
                    }
                }
            }
        }

        &-section {
            width: 50%;

            &_brand {
                display: block;
                margin-top: 25px;

                @include t-title-h9;
            }

            &-text,
            &_tag,
            &_title,
            &_title-long {
                @include t-title-h2;
            }

            &_title {
                margin: 10px 0 5px;
            }

            &_name {
                @include t-title-h5;

                margin: 25px 0 3px;
            }

            &_price {
                @include t-title-h6;
            }

            &_arr {
                @include t-title-h4;
                @include icon(before, arrow-link);
                @include underline_remove;
            }

            &_img {
                max-width: 100%;
                font-size: 0;

                &.full-width {
                    width: 100%;
                }
            }

            &.v-align-center {
                position: absolute;
                height: 100%;
                margin: 0;

                .b-lp-asset-body-section {
                    &-middle {
                        position: absolute;
                        display: table;
                        width: 100%;
                        height: 100%;
                    }

                    &-inner {
                        display: table-cell;
                        vertical-align: middle;
                    }
                }
            }

            &.three-block {
                width: 25%;
                margin: 0;

                &-big {
                    width: calc(50% - 80px);
                    margin: 0 40px;

                    .b-lp-asset-body-section {
                        &_brand {
                            margin: 15px 0 10px;
                        }
                    }
                }
            }

            &.fl {
                left: 0;
                float: left;
            }

            &.fr {
                right: 0;
                float: right;
            }
        }
    }
}

.s-landingpage {
    .l-breadcrumb {
        margin-bottom: 30px;
    }
}

.b-world-wrapper {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0;
    border: none;

    .fancybox-skin {
        padding: 0 !important;

        .fancybox-outer {
            .fancybox-inner {
                width: 100% !important;
                height: 100% !important;
                overflow-y: hidden !important;
            }
        }
    }

    .fancybox-close {
        top: 17px;
        right: 17px;
        width: 25px;
        height: 25px;

        &::before {
            font-size: 17px;
        }
    }
}

.html_fancybox_opened .s-landingpage {
    overflow: hidden;
}

.b-world {
    &-video {
        margin: 80px 130px;
        text-align: center;

        .b-lp-asset-body-section {
            &_title {
                @include t-title-h3;
            }

            &_brand {
                margin-top: 38px;
            }
        }

        video,
        .slot-video {
            height: calc(100vh - 225px) !important;
        }
    }

    &-logo {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 300;

        &-link {
            &:hover {
                border-bottom-color: transparent;
            }
        }
    }

    &-content {
        margin: 20px 0 0;

        .b-lp-asset-body-section {
            width: 100%;

            &-wrap {
                position: relative;
                display: inline-block;
                height: auto;
            }

            &_img {
                max-height: calc(100vh - 155px);
            }

            &_button {
                @include t-title-h10;

                position: absolute;
                top: 0;
                right: 0;
                width: 80px;
                height: 80px;
                padding: 16px 0;
                background: $color_main;
                color: $white;
            }

            &_title {
                @include t-title-h3;

                margin: 30px 0 8px;
            }

            &-text {
                @include t-title-h5;
            }
        }
    }

    &-slider {
        margin: 65px 0 0;
        height: calc(100vh - 85px);

        .b-owl_carousel {
            &-item {
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(100vh - 85px);
            }

            &-nav {
                &_prev,
                &_next {
                    width: 60px;
                    height: 60px;
                    margin-top: -30px;
                    text-align: center;
                    background: $color_main;
                    color: $white;
                    opacity: 0.5;
                    line-height: 60px;

                    &::after {
                        font-size: 27px;
                    }
                }

                &_prev {
                    left: -5px;
                }

                &_next {
                    right: -5px;
                }
            }
        }

        .b-lp-asset-body-section {
            width: auto;
            max-width: 100%;

            &_title {
                @include t-title-h3;

                margin: 13px 0 0;
            }

            &_img {
                display: inline-block;
                width: auto;
                max-height: calc(100vh - 220px);
                padding: 0 10px;
            }
        }
    }
}
