/*
# BLOCK - Fancybox styles
```
<div class="fancybox-wrap">
  <div class="fancybox-skin">
    <div class="fancybox-outer">
      <div class="fancybox-inner">
        content
      </div>
    </div>
  </div>
</div>
<div class="fancybox-overlay"></div>
```
*/

.html_fancybox_opened,
.html_flyout_opened,
.lock-scroll {
    &,
    body {
        position: relative;
        overflow: hidden;
    }
}

//Unlock specific flyouts
.html_flyout_opened {
	&.l-flyout-opened-minicart,
	&.l-flyout-opened-wishlist {
		&,
		body {
			position: initial;
			overflow: initial;
		}
	}
}

.fancybox-wrap {
    padding: 25px;
    background: $white;
    z-index: 200;
    box-sizing: content-box;

    &.fancybox-wrap_position-fix.fancybox-mobile {
        position: absolute !important;

		&.l-newsletter_popup_desktop,
		&.fancybox-edit_address {
			position: fixed !important;
		}
    }

    &.fancybox-type-html {
        .fancybox-inner {
            height: auto !important;
        }
    }

    &:not(.b-product_image_zoomed) .fancybox-inner {
		padding-right: 24px;
		overflow: visible !important;
    }

    &.b-checkout_summary,
    &.fancybox-edit_address {
		top: calc(50% + 10px) !important;
		max-height: calc(100vh - 71px);
		transform: translateY(-50%);
		overflow-y: auto !important;
		overflow-x: hidden !important;
    }
}

.fancybox-inner {
    width: 100%;

    #faq-section {
        max-width: 800px;

        .b-faq_popup-left {
            width: 50%;
            float: left;
            padding: 20px;

            .scroll-pane {
                max-height: 300px;
                padding: 0 20px 0 0;
                overflow-y: auto;
            }
        }

        .b-faq_popup-right {
            width: 50%;
            float: left;
            padding: 40px 20px 20px;

            div {
                max-height: 300px;
                padding: 0 20px 0 0;
                overflow-y: auto;
            }
        }
    }
}

.fancybox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background: rgba($color_third, .8);
    z-index: 199;
}

.fancybox-close {
    @include icon(before, close);

    position: absolute;
    top: 32px;
    right: 40px;
    width: 16px;
    height: 16px;
    padding: 5px;
    font-size: 0;
    line-height: 1;
    text-align: center;
    cursor: pointer;

    &::before {
        font-size: 16px;
    }
}
