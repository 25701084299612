/*
#BLOCK - Reset Password Dialog
*/
.b-reset_password {
    text-align: center;

	&-title {
		@include t-title-h5(uppercase);

		margin: 0 0 9px;
	}

    .f-label {
        display: none;
    }

    .f-field {
        padding-top: 13px;
        margin: 0 0 30px;
    }

    .f-email {
        @include t-title-h9;

        width: auto;
        min-width: 300px;
        height: 40px;
        padding: 12px 10px 10px;
        border: 0;
        border-bottom: 1px solid $color_main;
        background: none;
        color: $color_main;
        text-align: center;

        &::placeholder {
            color: $color_main;
            font-size: 12px;
        }
    }

	&-message {
		@include t-title-h7;

		margin: 0 0 15px;
		text-align: center;
	}

    &-button_box {
        font-size: 0;
        text-align: right;
    }

	&-submit {
		@extend %g-button-primary;

		margin: 0 auto;
	}

    &-close {
        display: none;
    }
}

.b-first_visit_banner {
	display: none;
	padding: 17px 40px;
}

.b-cookies_informer {
    color: $color_main;

    &-title {
        @include t-title-h4;

        margin-bottom: 8px;
    }

    &-info {
        width: 90%;
        margin: 0 auto 17px;
        text-align: center;
    }

	&-close_button {
		@include icon(after, close);

		position: absolute;
		top: 16px;
		right: 13px;
		width: 16px;
		min-width: 0;
		height: 16px;
		font-size: 0;
		cursor: pointer;
		line-height: 1;

		&::after {
			font-size: 16px;
		}
}
}

.header-promo {
    &-container {
        position: relative;
		max-height: 32px;
        padding: 3px 50px 3px 0;
        text-align: center;
        background-color: $bordeaux;
        z-index: 4;

		// fix for auto width mode owl stage track width which creates an empty space before start a new loop iteration
		.owl-stage {
			min-width: 9999px;
		}

		&.b-owl_carousel {
			z-index: 5;
		}
    }

    &-close a,
    &-message {
        @include t-title-h8;

        color: $white;
        border-bottom: 1px solid transparent;

        &:hover {
            border-color: currentColor;
        }
    }

	&-close a {
		@include icon(after, close);
		@include underline_remove;

		position: absolute;
		top: 50%;
		right: 26px;
		width: 16px;
		height: 16px;
		border: none;
		font-size: 0;
		transform: translateY(-50%);
		line-height: 1;

		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 20px;
		}
	}

    &-message {
		@include t-title-h6-h7;

		display: inline-block;
		transform: translateX(30px); //Visually centering promo text
		letter-spacing: 0.14px;
    }
}

/*
#BLOCK - Send to Friend Response Dialog
*/
.b-send_to_friend_response {
    text-align: center;

    &-title {
        @include t-title-h4;

        margin: 0 0 9px;
    }

    p:last-child {
        margin-bottom: 0;
    }
}


/*
#BLOCK - Modal newsletter subscribe
*/
.b-newsletter_popup,
.b-newsletter_modal {
    width: 370px;
    text-align: center;

    &-icon {
        @include icon(after, mail);

        margin-bottom: 11px;
        text-align: center;

        &::after {
            font-size: 19px;
            line-height: 20px;
        }
    }

	&_text {
		@include t-text-3;

		font-weight: normal;
		text-transform: none;
	}

    &-title {
        display: none;
    }

    &-birthday,
    &-privacy {
        display: none;
    }

    .f-field-wrapper {
        float: none;
        margin-bottom: 8px;

        .f-error_message {
			display: block;
            width: 100%;
        }
    }

    .f-field {
        margin: 0;
    }

    .f-textinput,
    .f-email {
        width: 299px;
        height: 20px;
        padding: 0;
        border-width: 0 0 1px;
        text-align: left;

		&::placeholder {
			@include t-text-6;

			text-transform: none;
			color: $color_main;
		}

        &:focus {
            background: none;

            &::placeholder {
                opacity: 0;
            }
        }
    }

    .b-simple_newsletter-button {
        margin: 20px auto;
    }
}

.l-newsletter_popup_desktop .b-newsletter_modal-close_button {
    @include icon(before, close);

    position: absolute;
    top: 16px;
    right: 13px;
    width: 16px;
    min-width: 0;
    height: 16px;
    font-size: 0;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    user-select: none;

    &::before {
        font-size: 16px;
    }
}

.b-newsletter_popup {
	position: relative;
	width: 100%;
	max-width: 675px;
	padding-right: 5px;
	text-align: left;

	&-text {
		&-img {
			position: absolute;
			top: -15px;
			left: -15px;
			max-width: 100%;
			width: 50%;
			height: calc(100% + 34px);
			object-fit: cover;
		}

		&-wrapper {
			min-width: 670px;
			padding-top: 5px;
			padding-left: calc(50% + 8px);
	   	}

	   &-title {
			@include t-title-h2(uppercase);

			margin-bottom: 38px;
			letter-spacing: 0.16px;
		}

		&-description {
			@include t-title-h7;

			margin-bottom: 32px;
			letter-spacing: 0.14px;
			font-weight: 325;
		}
	}

	&-main_info {
		display: flex;
		flex-direction: column-reverse;
		margin-bottom: 8px;
	}

	.b-simple_newsletter-button {
		position: static;
		order: 2;
		width: 100%;
		max-width: none;
		margin: 35px 0 0;

		&_text {
			display: block;
		}

		&::before {
			content: none;
		}
	}

	.f-label {
		float: none;
	}

	&-icon,
	.js-simple_newsletter-button_selectall {
		display: none;
	}

	.f-type-email {
		@include icon(after, arrow-wide-right);

		input {
			@include t-title-h9;

			position: relative;
			padding: 0 25px 10px 0;
			width: 100%;
			margin: 0;
			border-bottom: 0.5px solid $black;
			text-align: left;
			text-transform: none;
			letter-spacing: 0.24px;

			&::placeholder {
				@include t-title-h9;

				letter-spacing: 0.24px;
			}
		}

		&::after {
			position: absolute;
			top: 1px;
   			right: 0;
		}

		.f-error_text {
			margin-top: 0;
		}
	}

	.f-field-checkbox {
		.f-label {
			display: flex;
			width: 100%;
			padding-left: 28px;
			color: $black;

			a {
				border: none;
			}

			&-value {
				@include t-title-h11;

				letter-spacing: 0.2px;

				&::after {
					content: none;
				}
			}
		}
	}

    &-sections {
        &_label {
            @include t-title-h5;

            margin-bottom: 8px;
        }

        &_wrapper {
            width: 415px;
        }

        .f-field {
            float: left;
            width: 50%;
            text-align: center;

            &-wrapper {
                float: none;
                width: auto;
                text-align: center;
            }

            &.f-type-section1 {
                width: 45%;
                margin-left: 5%;
            }

            &.f-type-section2 {
                width: 35%;
                margin-left: 11%;
            }
        }

        .f-label {
            width: 100%;
            padding-left: 32px;
            text-align: left;

            &-value {
                @include t-title-h5;

                text-transform: none;
            }
        }

        & + .f-field-email {
            margin-top: 18px;
        }
    }

    .b-formsections_error {
        @include t-title-h10;

        width: 100%;
        clear: both;
        text-align: center;
        color: $validation-color;

        &:not(:empty) {
            position: relative;
            top: 78px;
        }
    }

    .b-content_asset--nl-popup-pp-disclaimer {
        width: 100%;
        font-size: 10px;

        a {
            @include underline_on_hover_remove;

            border-bottom: 1px solid $color_main;
            font-size: 10px;

            &:hover {
                border-color: transparent;
            }
        }
    }

    &-form {
		display: flex;
    	flex-direction: column;
		min-width: 670px;
		padding-left: calc(50% + 8px);

        &_description {
           padding: 10px;
           font-size: 14px;
        }

        &_types {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            &-item {
                float: none;
                width: auto;

                .f-label {
                    margin-right: 10px;
                    text-align: left;
                }
            }

            .b-formsections_error {
                display: none;

                &:not(:empty) {
                    top: 10px;
                }
            }
        }

		.f-field {
			position: relative;
			float: none;

			&-checkbox .f-label {
				& + .f-error_message {
					bottom: -20px;
				}

				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}
		}

		.f-type-email input {
			margin-bottom: 20px;
		}


		.f-error_message {
			position: absolute;
			bottom: -5px;
		}

		.b-simple_newsletter-button {
			@extend %g-button-primary;
		}
    }
}

.b-newsletter_modal-title {
    margin-bottom: 20px;
}

.l-newsletter_popup {
	&_desktop {
		top: 50% !important; // overwrite inline styles
		min-height: 375px !important;
		padding: 0 !important;
		overflow: hidden !important;
		transform: translateY(-50%);

		~ .fancybox-overlay {
			background: $black-overlay;
		}

		.fancybox-close {
			&::before {
				position: absolute;
				right: -20px;
				top: -12px;
			}
		}
	}
}

.l-newsletter-popup-fancybox-wrap {
    padding: 20px;

    .fancybox-inner {
        overflow: visible !important;
    }

    .b-newsletter_popup-close_button,
    .b-newsletter_modal-close_button {
        display: none;
    }
}

// Newsletter subscription popup
.fancybox-newsletter_response,
.fancybox-newsletter_response-error {
    width: 500px;
    padding: 0;
    border: 1px solid $color_second;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

    &.fancybox-wrap {
        padding: 0;
    }

    .fancybox-close {
        display: none;
    }
} // /.b-newsletter_popup

.b-modal_locale_redirect {
	max-width: 400px;
	text-align: center;

	&-title {
		@include t-text-6;

		margin-bottom: 30px;
	}

	&-confirm_cancel {
		@extend %g-button-primary;
	}

	&-confirm_continue {
		@extend %g-button-primary;
	}

	&-footer {
		font-size: 16px;

		& a {
			@extend %g-button_link;

			cursor: pointer;
		}
	}

	&_countryselect {
		.f-label,
		.b-countryselect-input .f-field-wrapper {
			width: 100%;
			float: none;
		}

		.b-countryselect-button {
			@extend %g-button-primary !optional;

			width: 100%;
			max-width: inherit;
		}
	}

	button {
		flex-wrap: wrap;
		width: 100%;
		max-width: inherit;

		span {
			display: contents;
		}
	}

	.b-countryselect-input {
		&.m-language .f-label {
			display: none;
		}
	}
}
