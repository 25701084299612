/*
#Page - Payment Methods
*/

/*
#Block - Payment Methods
*/
.b-payment_methods {
	&-title {
		@include t-title-h8(uppercase);

		margin-bottom: 25px;
	}

	&-create {
		@extend %g-button-primary;

		width: auto;
		float: left;
	}

	&-description {
		@include t-text-6;

		margin-bottom: 20px;
	}
}

/*
#Layout - Payment List
*/
.l-payment_list {
    margin: 0 0 0 -30px;
    padding: 0;
    list-style: none;
    vertical-align: top;

    .b-mini_credit_card-owner {
        @include t-text-4;

        margin-bottom: 15px;
    }

    .b-payment_item-delete {
        @include t-title-h7(uppercase);
    }
}

/*
#Block - Payment Item
*/
.b-payment_item {
    display: inline-block;
    width: 260px;
    margin: 0 0 30px 30px;
}

/*
#Block - Payment Item - Mini Credit Card
*/
.b-mini_credit_card {
    @include t-title-h9;

    padding: 5px 0;

    &-expires_label {
        text-transform: uppercase;
    }
}

/*
#Popup Add credit card
*/
.b-add_credit_card {
	&-title {
		@include t-title-h8(uppercase);

		margin-bottom: 25px;
	}

    .f-field-wrapper {
        @include myaccount_field_wrapper;
    }

    &-label_required {
        padding: 0 0 15px 32%;
        text-transform: uppercase;
        font-size: 12px;
        color: $color_main;

        &::after {
            @include required_fields;
        }
    }

    &-expires_fields {
        @include clearfix;

        .b-add_credit_card-fields_label_expires {
            width: 32%;
        }

        .b-add_credit_card-fields_month {
            width: 33%;

            .f-field-wrapper {
                width: 100%;
            }
        }

        .b-add_credit_card-fields_year {
            width: 33%;
            float: right;

            .f-field-wrapper {
                width: 100%;
            }
        }
    }

	&-submit {
		@extend %g-button-primary;

		display: inline-block;
		margin: 23px 23px 0 0;
	}

	&-cancel {
		@extend %g-button-primary;

		display: inline-block;
		margin: 23px 20px 0 0;
	}
}

.b-expires_fields_error {
    display: none !important;
    padding: 0 0 15px 32%;
    font-size: 12px;
}
