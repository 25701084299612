/* General styles */
.b-product_you-may-also-like,
.l-search_result-content_no_hits .b-no_hits-banner,
.l-checkout_cart {
    .b-recommendation_title {
		@include t-text-4;

		margin-bottom: 24px;

		&-text {
			@include t-text-4;
		}
    }

    .l-product_tile_wrapper {
        list-style: none;
    }

    .l-product_carousel {
        .b-owl_carousel {
            &-nav_next,
            &-nav_prev {
                top: 106px;

                @include respond-to(all) {
                    top: 97px;
                }
            }
        }

        &-list {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 0;

            > .l-product_carousel-item {
                width: 25%;
            }
        }

        &-wrapper,
        &-static-wrapper {
            display: inline-block;
            width: 100%;
            vertical-align: top;
            text-align: center;

            .b-capture_product_id {
                display: none;
            }

            .b-product_tile {
                display: block;
                float: none;
                width: 100%;
                margin: 0 auto;

				.b-add_to_wishlist {
					top: 10px;
					right: 50%;
					transform: translateX(50%);
				}
            }

            .b-product_more-colors {
                display: none;
            }

            .b-product_tile_wrapper {
                margin: 0 0 18px;
            }

            .b-product_variations--plp {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }

    &-heading {
        padding: 40px 0 20px;
        text-align: center;
        text-transform: uppercase;
    }
}

/* Cart */
.l-checkout_cart {
    .b-recommendation_title {
        margin-top: 50px;
    }

    .l-product_carousel .b-owl_carousel {
        &-nav_next,
        &-nav_prev {
            top: 69px;

            @include respond-to(all) {
                top: 63px;
            }
        }
    }

    //	Predictive recommendation
    .b-products_recommender {
        padding: 0;

        .b-recommendation_title {
            margin-top: 17px;
            text-align: center;
        }

        .b-product_tile {
            width: 100%;
        }
    }
}

/* Empty cart */
.l-checkout_cart .b-empty_cart-recommendations {
    padding-left: 0;
    padding-right: 0;

    .b-recommendation_title {
        margin-top: 33px;
    }

    .loader-indicator {
        top: 79%;
        left: 35%;
    }
}

.l-search_result-content_no_hits .b-no_hits-banner,
.b-product_you-may-also-like,
.b-slot--product-recently-view-pdp {
	padding: 40px 30px;

	.b-owl_carousel {
		&-nav_dots {
			max-width: 100%;
			bottom: -24px;
			overflow: hidden;
		}
	}
}

.b-product_you-may-also-like {
	.b-recommendation_title {
		margin: 0;
	}
}

.b-product_last-visited {
	padding-bottom: 0;
}

.b-product_recom-tabs {
	margin-bottom: 60px;

	.b-recommendation_title-text,
	.b-last_visited-title {
		display: none;
	}

	.b-product {
		&_you-may-also-like,
		&_we-also-recommend {
			padding: 0 0 40px 40px;
		}
	}

	.b-slot--product-recently-view-pdp {
		padding: 0;
	}

	.b-owl_carousel {
		&.b-products_recommender,
		&.b-product_last-visited {
			padding-bottom: 0;

			.owl-stage {
				padding-left: 0 !important;
			}
		}

		&.b-products_recommender {
			> .owl-stage-outer {
				margin-left: 40px;
			}
		}

		&.b-product_last-visited {
			.owl-stage {
				&-outer {
					margin-left: 40px;
				}
			}
		}

		&-item {
			li {
				list-style: none;
			}
		}
	}
}
