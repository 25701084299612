/*
#Helper classes
*/

/*
##Hide element with display:none
*/
.h-hidden {
    display: none !important;
}

.h-hidden_minor {
    display: none;
}

/*
##Show element with display:block
*/
.h-show {
    display: block !important;
}

.table {
    display: table;
}

.table-cell {
    display: table-cell;
}

/*
##Minified with zero height
*/
.h-minimized,
.h-minified {
    max-height: 0 !important;
    overflow: hidden;
}

/*
##Invisible block (use to get height of any element)
*/
.h-invisible_block {
    display: block !important;
    visibility: hidden !important;
}

/*
##Flyout helpers
*/
.h-flyout {
    position: relative;

    &:hover .h-flyout_target {
        position: absolute;
        display: block;
        top: 100%;
    }
}

/*
##Product tile helpers (PDP)
*/
%product_tile-PDP {
    .b-quickview {
        display: none;
    }

    .b-product_tile {
        float: left;
        margin-bottom: 45px;
        margin-left: 10px;
        width: 184px;

        &:hover .b-product_share {
            display: none;
        }

        @include respond-to(horizontal) {
            width: 180px;
            margin: 0 18px 0 0;
        }

        @include respond-to(vertical) {
            width: 170px;
            margin: 0 13px 0 0;
        }

        .b-product-hover_box:hover {
            .b-add_to_cart,
            .b-add_to_wishlist {
                display: none;
            }
        }

        .b-product_image {
            &-box {
                display: table-cell;
                height: 225px;
                text-align: center;
                vertical-align: bottom;

                @include respond-to(vertical) {
                    height: 200px;
                }
            }

            &-wrapper {
                position: relative;
                display: inline-block;
                width: 100%;
            }
        }

        .b-product_name {
            @include product-name(uppercase);

            margin: 15px 0 0;
        }

        .b-product_price {
            @include t-title-h4;

            &-standard--line_through {
                color: $grey4;
                text-decoration: line-through;
            }
        }

        .b-manufacturer_name {
            @include font_main(18px, 25px);
        }
    }
}

/*
##Owl Carousel - homepage/pdp styling
*/
%owl_carousel {
    .b-owl_carousel {
        &-item {
            position: relative;
            float: left;
        }

        &-nav_controls {
            position: absolute;
            display: inline-block;
            bottom: 30px;
            left: 50%;
            width: 100px;
            margin-left: -50px;
            text-align: center;
            z-index: 2;
        }

        &-nav_dot {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 5px;
            background: $color_second;
            border-radius: 5px;
            cursor: pointer;
            font-size: 0;

            &::after {
                font-size: 0;
            }
        }

        &-nav_dot.active {
            background: $color_main;

            &::after {
                border: 0;
            }
        }
    }
}

.loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    z-index: 999;

    &-indicator {
		@include icon(before, heelheight);

		position: absolute;
		top: 50%;
		left: 50%;
		width: 40px;
		height: 40px;
		transform: translate(-50%, -50%);

		&::before {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 40px;
			color: $color_second;
		}
    }
}

.lazyloadxt-indicator {
	@include icon(before, heelheight);

	position: absolute;
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	transform: translate(-50%, -50%);
	z-index: 3;

	&::before {
		font-size: 40px;
	}
}

@include respond-to(all) {
    .tablet-hidden {
        display: none !important;
    }
}

/*Hide content from Content Assets on Desktop & Tablet that is visible on Mobile */
.desktop-hidden {
    display: none !important;
}

.h-click-disable {
	pointer-events: none;
}

/* Makes block with cut corners */
.b-diamond,
%b-diamond {
	@include diamond-box;
}

.m-search_opened {
	overflow: hidden;
}
