/*
#Icons from a icon font
_icons.tpl - is a template for _icons.scss
_icons.scss - is an automaticaly generated SCSS file. It contains:
- placeholder class %icon - it's needed for mixin icon
- function icon-char - it's needed for mixin icon
- mixin icon - you can use this mixin to insert an icon into :before or :after pseudo-elements. this mixin takes two parameters: icon name (file name without a char prefix), and position (before or after)
- and icon classes to use in HTML (content assets, content slots)
*/
%icon {
  font-family: $font_icons;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == arrow-up {
    $char: "\e94a";
  }

  @if $filename == arrow-down {
    $char: "\e936";
  }

  @if $filename == arrow-left {
    $char: "\e93b";
  }

  @if $filename == arrow-right {
    $char: "\e944";
  }

  @if $filename == close {
    $char: "\e922";
  }

  @if $filename == hamburger-menu {
    $char: "\e91f";
  }

  @if $filename == login {
    $char: "\e934";
  }

  @if $filename == carrello {
    $char: "\e932";
  }

  @if $filename == wishlist {
    $char: "\e95b";
  }

  @if $filename == wishlist-not-selected {
    $char: "\e95a";
  }

  @if $filename == search {
    $char: "\e951";
  }

  @if $filename == lows-in-stock {
    $char: "\e919";
  }

  @if $filename == share-email {
    $char: "\e90d";
  }

  @if $filename == back-to-top {
    $char: "\e914";
  }

  @if $filename == back-to-top-arrow {
    $char: "\e914";
  }

  @if $filename == play {
    $char: "\e940";
  }

  @if $filename == slider-not-selected {
    $char: "\e00f";
  }

  @if $filename == slider-selected {
    $char: "\e010";
  }

  @if $filename == email-notification {
    $char: "\e91a";
  }

  @if $filename == mobile-zoom {
    $char: "\e918";
  }

  @if $filename == instagram {
    $char: "\e93a";
  }

  @if $filename == facebook {
    $char: "\e939";
  }

  @if $filename == youtube {
    $char: "\e94f";
  }

  @if $filename == twitter {
    $char: "\e949";
  }

  @if $filename == pinterest {
    $char: "\e93f";
  }

  @if $filename == google {
    $char: "\e95d";
  }

  @if $filename == headphones {
    $char: "\e937";
  }

  @if $filename == minus {
    $char: "\2012";
  }

  @if $filename == pencil {
    $char: "\e01b";
  }

  @if $filename == plus {
    $char: "\e95f";
  }

  @if $filename == mail {
    $char: "\e90d";
  }

  @if $filename == arrow-link {
    $char: "\e909";
  }

  @if $filename == phone {
    $char: "\e90c";
  }

  @if $filename == track-order {
    $char: "\e916";
  }

  @if $filename == share {
    $char: "\e917";
  }

  @if $filename == tick {
    $char: "\e908";
  }

  @if $filename == tooltip {
    $char: "\e91c";
  }

  @if $filename == tiktok {
    $char: "\e948";
  }

  @if $filename == apple {
    $char: "\e95e";
  }

  @if $filename == plus-bold {
    $char: "\e91d";
  }

  @if $filename == calendar {
    $char: "\e920";
  }

  @if $filename == chat {
    $char: "\e921";
  }

  @if $filename == contactus {
    $char: "\e923";
  }

  @if $filename == delivery {
    $char: "\e924";
  }

  @if $filename == diamond {
    $char: "\e925";
  }

  @if $filename == gift {
    $char: "\e92b";
  }

  @if $filename == fullscreen {
    $char: "\e92c";
  }

  @if $filename == curlyarrow {
    $char: "\e92d";
  }

  @if $filename == heelheight {
    $char: "\e92e";
  }

  @if $filename == aim {
    $char: "\e92f";
  }

  @if $filename == mail {
    $char: "\e930";
  }

  @if $filename == payment {
    $char: "\e931";
  }

  @if $filename == productcare {
    $char: "\e933";
  }

  @if $filename == booktime {
    $char: "\e935";
  }

  @if $filename == delivery2 {
    $char: "\e938";
  }

  @if $filename == linkedin {
    $char: "\e93c";
  }

  @if $filename == live-chat {
    $char: "\e93d";
  }

  @if $filename == packaging {
    $char: "\e93e";
  }

  @if $filename == productcarethin {
    $char: "\e941";
  }

  @if $filename == return {
    $char: "\e942";
  }

  @if $filename == storelocator-current {
    $char: "\e943";
  }

  @if $filename == securepayment {
    $char: "\e945";
  }

  @if $filename == storelocator {
    $char: "\e946";
  }

  @if $filename == storelocatordark {
    $char: "\e947";
  }

  @if $filename == wechat{
    $char: "\e94b";
  }

  @if $filename == weibo {
    $char: "\e94c";
  }

  @if $filename == whatsapp {
    $char: "\e94d";
  }

  @if $filename == whatsapphone {
    $char: "\e94e";
  }

  @if $filename == return2 {
    $char: "\e950";
  }

  @if $filename == searchmenu {
    $char: "\e952";
  }

  @if $filename == storemarker {
    $char: "\e954";
  }

  @if $filename == store {
    $char: "\e955";
  }

  @if $filename == minus-long {
    $char: "\e956";
  }

  @if $filename == timeslot {
    $char: "\e957";
  }

  @if $filename == trash {
    $char: "\e958";
  }

  @if $filename == volume {
    $char: "\e959";
  }

  @if $filename == return3 {
    $char: "\e95c";
  }

  @if $filename == arrow-wide-left {
    $char: "\e960";
  }

  @if $filename == arrow-wide-right {
    $char: "\e961";
  }

  @if $filename == diamond-bordered {
    $char: "\e962";
  }

  @return $char;
}


@mixin icon($position: before, $icon: false) {
    @if $position == both {
        $position: 'before, &:after';
    }
    // Either a :before or :after pseudo-element, or both, defaulting to :before
    &:#{$position} {

       	//style for font
		@extend %icon;

        @if $icon {
    		content: icon-char($icon);
        }
    }
}

/*
#Font icon variables
*/
 $icon-arrow-up: '\e94a';
 $icon-arrow-down: '\e936';
 $icon-arrow-left: '\e93b';
 $icon-arrow-right: '\e944';
 $icon-close: '\e922';
 $icon-hamburger-menu: '\e91f';
 $icon-carrello: '\e932';
 $icon-login: '\e934';
 $icon-wishlist: '\e95b';
 $icon-wishlist-not-selected: '\e95a';
 $icon-search: '\e951';
 $icon-low-in-stock: '\e919';
 $icon-hare-email: '\e00c';
 $icon-back-to-top: '\e94a';
 $icon-back-to-top-arrow: '\e94a';
 $icon-play: '\e940';
 $icon-slider-not-selected: '\e00f';
 $icon-slider-selected: '\e010';
 $icon-email-notification: '\e011';
 $icon-mobile-zoom: '\e918';
 $icon-instagram: '\e93a';
 $icon-facebook: '\e939';
 $icon-youtube: '\e94f';
 $icon-twitter: '\e949';
 $icon-pinterest: '\e93f';
 $icon-google: '\e95d';
 $icon-headphones: '\e937';
 $icon-minus: '\2012';
 $icon-pencil: '\e01b';
 $icon-plus: '\e95f';
 $icon-mail: '\e90d';
 $icon-mail-small: '\e91a';
 $icon-arrow-link: '\e909';
 $icon-phone: '\e90c';
 $icon-track-order: '\e916';
 $icon-share: '\e917';
 $icon-tick: '\e908';
 $icon-tooltip: '\e91c';
 $icon-info: '\e926';
 $icon-four-active: '4';
 $icon-four: '4';
 $icon-two-active: '2';
 $icon-two: '2';
 $icon-one: '1';
 $icon-one-active: '1';
 $icon-three: '3';
 $icon-three-active: '3';

 $icon-tiktok: '\e948';
 $icon-apple: '\e95e';
 $icon-plus-bold: '\e91d';
 $icon-calendar: '\e920';
 $icon-chat: '\e921';
 $icon-contactus: '\e923';
 $icon-delivery: '\e924';
 $icon-diamond: '\e925';
 $icon-gift: '\e92b';
 $icon-fullscreen: '\e92c';
 $icon-curlyarrow: '\e92d';
 $icon-heelheight: '\e92e';
 $icon-aim: '\e92f';
 $icon-mail: '\e930';
 $icon-payment: '\e931';
 $icon-productcare: '\e933';
 $icon-booktime: '\e935';
 $icon-delivery2: '\e938';
 $icon-linkedin: '\e93c';
 $icon-live-chat: '\e93d';
 $icon-packaging: '\e93e';
 $icon-productcarethin: '\e941';
 $icon-return: '\e942';
 $icon-storelocator-current: '\e943';
 $icon-securepayment: '\e945';
 $icon-storelocator: '\e946';
 $icon-storelocatordark: '\e947';
 $icon-wechat: '\e94b';
 $icon-weibo: '\e94c';
 $icon-whatsapp: '\e94d';
 $icon-whatsapphone: '\e94e';
 $icon-return2: '\e950';
 $icon-searchmenu: '\e952';
 $icon-storemarker: '\e954';
 $icon-store: '\e955';
 $icon-minus-long: '\e956';
 $icon-timeslot: '\e957';
 $icon-trash: '\e958';
 $icon-volume: '\e959';
 $icon-apple: '\e95e';
 $icon-return3: '\e95c';
 $icon-arrow-wide-left: '\e960';
 $icon-arrow-wide-right: '\e961';
 $icon-diamond-bordered: '\e962';
