/* Social Share Buttons. Used on PDP, Quick View and Shop the Look */
.social-share {
	&-button {
		@include icon(before);
		@include underline_remove;

		display: inline-block;
		padding: 0 0 0 19px;
		font-size: 14px;

		&.facebook {
			&::before {
				content: icon-char(facebook);
			}
		}

		&.twitter {
			&::before {
				content: icon-char(twitter);
			}
		}

		&.pinterest {
			&::before {
				content: icon-char(pinterest);
			}
		}

		&.googleplus {
			&::before {
				content: icon-char(google);
			}
		}
	}
}
