@mixin image-fading-mask($deg: 90deg) {
	-webkit-mask-image: linear-gradient($deg, $black, transparent); /* stylelint-disable-line */
}

$grid-transition-duration: .5s;

.b-module_constructor {
	$module: &;

	&.m-image_scale {
		#{$module}-bg,
		#{$module}-desktop_bg {
			overflow: hidden;

			&-image {
				transform: scale(1.1);
			}
		}
	}

	&.m-filter-sepia,
	.m-filter-sepia {
		&.b-showcase-image,
		.b-showcase-image {
			filter: brightness(2) sepia(1);
		}
	}

	.m-filter_grayscale,
	&.m-filter_grayscale {
		.b-showcase-image {
			filter: grayscale(100%);
		}
	}

	&-item {
		transition: opacity, filter 0.5s linear;

		&.m-image_scale {
			.b-showcase-image,
			#{$module}-item-bg-image,
			#{$module}-item-desktop_bg-image {
				transform: scale(1.1);
			}
		}
	}
}

%m-mask,
.m-mask {
	&-top {
		@include image-fading-mask(0deg);
	}

	&-right {
		@include image-fading-mask(90deg);
	}

	&-bottom {
		@include image-fading-mask(180deg);
	}

	&-left {
		@include image-fading-mask(270deg);
	}

	&-diamond {
		$diamondSize: map-get(map-get($configEffects, "diamond"), "size");

		clip-path: polygon(
			0 calc(#{$diamondSize} * 2),
			calc(#{$diamondSize} * 2) 0,
			calc(100% - #{$diamondSize} * 2) 0,
			100% calc(#{$diamondSize} * 2),
			100% calc(100% - #{$diamondSize} * 2),
			calc(100% - #{$diamondSize} * 2) 100%,
			calc(#{$diamondSize} * 2) 100%,
			0 calc(100% - #{$diamondSize} * 2)
		);
	}

	&_overlay {
		&-dark {
			&::after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: rgba($black, 40%);
			}
		}
	}
}

.b-showcase-image {
	transition: all $grid-transition-duration linear;
}

//Stretching image effect
.b-stretched_container {
	width: 100%;

	&-outer {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		overflow: hidden;
	}

	&-inner {
		display: flex;
	}

	&-limiter {
		height: 55rem;
	}

	.m-ready {
		.b-diamond_frame-corner {
			border-width: 0;
			transition: border-width var(--scaleDuration) ease-out var(--scaleDuration);
		}

		.m-cut-edges {
			padding: 0;
		}
	}
}

.m-cut-edges,
.b-diamond_frame {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	width: 25%;
	transform-origin: 0 100%;
	will-change: transform;

	@include respond-to(nav) {
		width: 50%;
	}

	.m-scale_transition & {
		transform-origin: bottom left;
		transition: transform var(--scaleDuration, 0.3s) ease-out;
	}
}

.b-diamond_frame {
	$def-frame-color: $white;
	$diamondSize: map-get(map-get($configEffects, "diamond"), "size") * 2;
	$border-width: calc(#{$diamondSize} / var(--currentScale, 1));


	z-index: 1;
	transform: scaleX(var(--currentScale, 1)) translateX(-50%);


	&-corner {
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;

		&.m-top-left {
			top: -1px;
			left: -1px;
			border-width: $diamondSize calc(#{$diamondSize} / var(--currentScale, 1)) 0 0;
			border-color: var(--frame-color, $def-frame-color) transparent transparent transparent;
		}

		&.m-top-right {
			top: -1px;
			right: -1px;
			border-width: $diamondSize 0 0 calc(#{$diamondSize} / var(--currentScale, 1));
			border-color: var(--frame-color, $def-frame-color) transparent transparent transparent;
		}

		&.m-bottom-left {
			bottom: -1px;
			left: -1px;
			border-width: 0 calc(#{$diamondSize} / var(--currentScale, 1)) $diamondSize  0;
			border-color: transparent transparent var(--frame-color, $def-frame-color) transparent;
		}

		&.m-bottom-right {
			bottom: -1px;
			right: -1px;
			border-width: 0 0 $diamondSize calc(#{$diamondSize} / var(--currentScale, 1));
			border-color: transparent transparent var(--frame-color, $def-frame-color) transparent;
		}
	}
}

.m-cut-edges {
	display: flex;
	margin: 0 auto;
	padding: 1px;
	transform: scale(var(--currentScale, 1)) translateX(-50%);

	img {
		display: block;
		height: 100%;
		object-fit: cover;
	}
}

//Colored sticky content
.m-sticky_container {
	--default-color-visible-percentage: 100%;

	padding-bottom: 100px;

	@include respond-to(all) {
		padding-bottom: 50px;
	}

	.b-showcase-media {
		overflow: initial;

		&-frame {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: -1;
		}

		&-header {
			position: sticky;
			bottom: 0;
			text-align: initial;

			&-title {
				text-transform: uppercase;
				font-weight: 700;

				&:not(:last-child) {
					margin-bottom: inherit;
				}
			}

			&_layer {
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				padding: 20px;

				&.cloned {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					clip-path: polygon(0% var(--default-color-visible-percentage, 100%), 100% var(--default-color-visible-percentage, 100%), 100% 100%, 0% 100%);
					will-change: clip-path;
					color: $black;
				}
			}

			&-text {
				font-weight: 700;
			}

			&-link {
				align-self: self-start;
				flex-shrink: 0;
				margin-top: 7px;
				margin-left: 20px;
				color: currentColor;
			}
		}
	}

	&.m-centered {
		padding-bottom: 0;

		.b-showcase-media {
			&-header {
				padding: 0;
				text-align: center;

				&-main {
					width: 100%;
				}

				&_layer {
					flex-wrap: wrap;
					justify-content: center;

					&.cloned {
						right: 0;
					}
				}

				&-link {
					margin: 0;
				}
			}
		}
	}
}

.b-showcase-media-inner.b-showcase-parallax {
	position: relative;

	img.b-showcase-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		transform: translateY(var(--parallax-image-offset, 0));
		transition: transform .1s cubic-bezier(0.39, 0.58, 0.57, 1);
		will-change: transform;

		@include respond-to(all) {
			width: auto;
			right: 0;
			left: 50%;
			transform: translate(-50%, var(--parallax-image-offset, 0));
		}
	}
}
