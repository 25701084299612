.b-popup_checkoutregistration {
	width: 700px;

	.b-popup_close-btn {
		top: 15px;
		right: 15px;
	}
}

.b-precheckout {
	display: flex;
	padding: 20px;
	background: $white;

	&.m-account-exists {
		.b-precheckout_login {
			width: 100%;
		}

		.b-precheckout_signup {
			display: none;
		}

		.m-exists-header,
		.b-login_account_label,
		.b-login_account_bordered {
			display: block;
		}

		.m-precheck-header {
			display: none;
		}

		.b-login_account {
			&_bordered {
				@include underline;

				padding-bottom: 20px;
				margin-bottom: 10px;
			}

			&_label {
				float: initial;
				width: 100%;
				text-transform: initial;
			}

			&_header {
				padding: 0;
			}

			&-fields_for_iframe {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
				margin-bottom: 20px;
				gap: 16px;

				.f-field {
					width: calc(50% - 8px);
					margin: 0;
				}
			}
		}

		.b-login_account-form_row {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			.b-login_account-guest_button {
				display: block;
			}

			.b-login_account-forgot_password {
				order: -1;
			}
		}
	}

	&_signup {
		flex-basis: 50%;
	}

	.b-login_account {
		&-form_fildset {
			margin-top: 10px;
		}

		.b-login_account-guest_button,
		&_label,
		&_bordered {
			display: none;
		}

		&-form_row {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}

		&-forgot_password {
			position: relative;
			width: fit-content;
			inset: initial;
		}

		&-guest_button {
			@extend %g-button-secondary;

			margin: 0;
		}

		&-login_button {
			width: 100%;
			margin: 0;
		}

		&_header {
			padding-bottom: 20px;
		}

		.b-rememberme {
			margin-top: 10px;
		}
	}

	.b-account_registration {
		&_title {
			@include t-title-h8(uppercase);

			margin-bottom: 18px;
		}

		&-button_submit {
			float: initial;
		}

		.f-field {
			margin-bottom: 20px;

			&-wrapper {
				float: initial;
				width: 250px;
			}
		}

		&_policy {
			.f-label {
				width: 100%;
			}
		}

		.b-login_account-privacy_box {
			display: block;
		}

		&-general_information {
			margin: 10px 0 0;

			.f-label {
				display: none;
			}
		}
	}

	.b-account-existence {
		&_title {
			@include t-title-h8(uppercase);

			margin-bottom: 18px;
		}

		&_content {
			.f-label {
				display: none;
			}
		}
	}

	.m-exists-header {
		display: none;
	}
}
