.b-storelocator {
	$searchBoxHeight: 152px;

	display: flex;
	height: 100%;

	.b-upsaplocator & {
		flex-wrap: wrap;
	}

    &-map {
        width: 100%;
        height: 100%;
        margin: 0;
	}

    &-search {
		&_wrapper {
			position: relative;
			width: 100%;
			max-width: 480px;

			.b-accordion_store {
				&-header {
					@include t-title-h7;
					@include icon(after, plus);

					position: relative;
					justify-content: unset;
					max-width: none;
					margin: 0;
					padding: 0;
					border: none;
					background-color: transparent;
					color: $black;
					font-weight: 350;

					&::after {
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
					}

					&.ui-state-active {
						@include icon(after, minus-long);
					}

					+ .b-accordion_cntr-item_text {
						padding: 10px 0 5px;
					}
				}

				&-item {
					margin: 10px 0 30px;

					&--hours .b-accordion_cntr-item_text {
						* {
							@include t-title-h9;

							letter-spacing: 0.24px;
						}
					}
				}
			}

			&.m-store-selected {
				.b-storelocator-search_container,
				.b-storelocator-results_item:not(.m-active) {
					display: none;
				}
			}
		}

		&_zip {
			.f-label-value {
				@include t-title-h4(uppercase);

				letter-spacing: 0.16px;
				font-weight: 350;
			}
		}

		&_container {
			height: $searchBoxHeight;
			padding: 26px 40px;
			background-color: $bordeaux;
			color: $white;
		}

        &_action {
            padding-top: 50px;

            .b-storelocator-find_button {
                @extend %g-button-primary;
            }
        }

		.f-field {
			margin: 0;

			&-wrapper {
				position: relative;

				@include icon(after, aim);

				&::after {
					position: absolute;
					top: 3px;
					right: 0;
				}

				input {
					&::placeholder {
						@include t-title-h9;

						letter-spacing: 0.24px;
					}
				}
			}
		}

		.f-select {
			border: 1px solid currentColor;
		}

		.f-textinput {
			height: 30px;
			padding: 0;
			border: none;
			border-bottom: 0.5px solid $white;
		}

		.f-field-wrapper,
		.f-field,
		.f-label {
			float: none;
		}

        #stateSelector,
        #citySelector {
            display: none;
        }

        #countrySelector {
            width: calc((100% + 150px) / 2);
        }

        #storeTypeSelector {
            width: calc((100% - 150px) / 2);
            padding-top: 50px;

            .f-label {
                display: none;
            }
        }

		&_close {
			@include button_reset;
			@include icon(after, close);

			position: absolute;
			display: none;
			top: 12px;
			right: 20px;
			width: 30px;
			height: 30px;
			z-index: 2;

			&:hover,
			&:focus,
			&:active {
				border-color: transparent;
			}

			&::after {
				color: $black;
				font-size: 18px;
			}

			.m-store-selected & {
				display: block;
			}
		}
    }

    &-results {
        &_title,
		.b-scroll-bar_outer {
            display: none;
        }

		&_no-stores {
			@include t-title-h7;

			padding: 20px 40px 5px;
		}

        &_list {
			/* stylelint-disable unit-no-unknown */

			height: calc(var(--full-dvh) - var(--header-height) - #{$searchBoxHeight});
			overflow-y: auto;

			.m-store-selected & {
				height: calc(var(--full-dvh) - var(--header-height));
				overflow-y: scroll;
				overflow-x: hidden;

				&:not(.m-overflowed)::-webkit-scrollbar {
					opacity: 0;
				}

				&:not(.m-overflowed) {
					scrollbar-color: transparent transparent;
				}
			}
        }

        &_item {
			position: relative;
			display: block;
			width: 100%;
			padding: 20px 40px 5px;
			border-bottom: 1px solid $grey2;
        }
    }

    &-store {
		&_title {
			@include t-title-h10(uppercase);

			padding-bottom: 16px;
			color: $gold;
			letter-spacing: 0.24px;
			font-weight: 325;
		}

		&_appointment-link {
			&.g-button {
				max-width: none;

				&:not(:hover):not(:focus):not(:active) {
					color: $white;
				}
			}
		}

        &_pin {
            height: 39px;
            margin: 0 0 14px;
            background: $map-pin 50% 50% no-repeat;
        }

        &_name {
            @include t-title-h8(uppercase);

			padding-bottom: 16px;
        }

        &_info {
            @include t-title-h9;

			letter-spacing: 0.24px;

            span {
                display: block;
            }

			> *:not(.b-storelocator-store_address) {
				padding-bottom: 16px;
			}

			.b-customer_service_box + .b-customer_service_box {
				margin-top: 4px;
			}
        }

		&_link {
			@include t-title-h6-h7;

			letter-spacing: 0.14px;

			.m-store-selected & {
				display: none;
			}
		}

		&_direction-link {
			@include icon(before, curlyarrow);

			position: relative;
			margin-left: 24px;
			text-transform: uppercase;

			&::before {
				position: absolute;
				top: -2px;
				left: -25px;
				font-size: 16px;
				color: $bordeaux;
			}
		}

		&_img {
			max-width: 100%;

			&-wrapper {
				margin-top: 35px;
			}
		}

		&_description {
			margin-bottom: 40px;

			* {
				@include t-title-h7-secondary;

				letter-spacing: 0.16px;
			}

			li {
				list-style: disc;
			}

			ul,
			ol {
				padding-left: 20px;
			}
		}

		&_hours {
			&-wrapper {
				display: flex;
				justify-content: space-between;
				gap: 10px;

				&:not(:last-child) {
					padding-bottom: 10px;
				}
			}
		}
    }

    .b-content_asset--stores-notfound {
        clear: both;
    }
}

.b-stores_slider-wrapper {
    margin: 0 0 20px;

    .b-owl_carousel-nav_controls {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
    }
}

.l-main_storelocator {
    .breadcrumb,
    .l-navigation,
	.b-stores_slider-wrapper,
	.b-scroll-bar {
        display: none;
    }

    .l-primary_content {
        float: none;
		height: calc(var(--full-dvh) - var(--header-height));
        width: 100%;
        padding: 0;
    }
}
