/*
#My Account general styles
*/

/*
#Layout - My Account
*/
.l-account {
    @include clearfix;

    padding: 50px 20% 0;
    width: 100%;

    @include respond-to(custom) {
        padding-left: 40px;
        padding-right: 40px;
    }

    .b-login_wide-item,
    .b-login_dropdown-item {
        margin-bottom: 35px;
        float: none;
        width: auto;
    }

    .b-login_order_track {
        &-title {
            @include t-title-h6;

            margin-bottom: 25px;
        }

        &-button {
            @extend %g-button;

            float: right;
            margin: 20px 0;

            &_box {
                overflow: hidden;
            }
        }

        &-label_required {
            @include t-title-h9;

            padding: 0 0 0 32%;

            &::after {
                content: '*';
                padding-left: 1px;
            }
        }

        &-content .f-field-wrapper {
            float: right;
            width: 68%;
        }
    }

	.b-login .b-login_wide-list_title,
	.b-login_dropdown-list_title {
		@include t-title-h5(uppercase);

		color: $color_main;
	}

	.b-login_dropdown-list_coment {
		@include t-text-6;

		padding-top: 5px;
	}

    .f-field-checkbox {
        .f-label {
            position: relative;
            width: auto;
            z-index: 1;
        }
    }

	.b-order_confirmation-title,
	.b-return_details h2 {
		@include t-title-h8(uppercase);

		margin-bottom: 25px;
	}
}

.l-account_navigation {
    float: left;
    width: 33.5%;

    @include respond-to(custom) {
        width: 25%;
    }
}

.l-account_content {
    float: left;
    width: 66.5%;

    @include respond-to(custom) {
        width: 75%;
    }

    .b-return_confirmation {
        float: left;
        width: 100%;

        div {
            float: left;
            width: 100%;
        }

        &-title {
            @include t-title-h8(uppercase);

            margin-bottom: 25px;
        }

        &-description {
            @include t-title-h4;

            margin-bottom: 20px;
        }

        &-headline {
            @include t-title-h8;

            margin-bottom: 20px;
        }

        &-summary {
            @include t-title-h9;

            margin-bottom: 20px;
        }

        &-summary_header > div {
            width: 25%;
        }

		&-summary_header_column {
			padding-right: 10px;
			padding-bottom: 10px;
			text-transform: uppercase;
		}

        &-summary_body > div {
            width: 25%;
        }

        &-summary_body_column {
            @include t-title-h9;

            padding-right: 10px;
            word-wrap: break-word;

            &:nth-child(3) > div {
                @include t-title-h9;

                text-transform: none;
            }
        }
    }

    .b-subscription_results {
        &-continue_link,
        &-account_link {
            @extend %g-button-primary;

            float: right;
            margin-right: 20px;
        }

        &-message {
            @include t-text-6;

            display: block;
            margin-bottom: 20px;
        }
    }

    .b-order_details {
        margin-bottom: 40px;

        &.sales-tax .sale-tax {
            &-icon,
            &-checkout {
                display: none;
            }
        }
    }

    .b-password_set,
    .b-password_reset {
        &-title {
            @include t-title-h2(uppercase);
        }

        &-form {
            overflow: hidden;
        }

        &-button,
        .f-label {
            width: 100%;
        }
    }

	.b-password_confirm {
		&-title {
			@include t-title-h2(uppercase);

			margin-bottom: 25px;
		}
	}
}

.b-account_login-create {
		& &_title {
			@include t-title-h8(uppercase);
		}

		& &_description {
			@include t-text-6;

			margin-bottom: 20px;
		}
}
/*
#Block - Account Navigation
*/
.b-account_navigation {
	&-unregistered,
	&-asset,
	.b-menu_account-link {
		@include t-title-h8(uppercase);

		display: inline-block;
		margin-bottom: 25px;
	}
}

.b-menu_account-level_2 {
    display: block;

	&-item {
		padding-bottom: 20px;
	}

	&-link {
		@include t-title-h8(uppercase);
		@include animated-underline;

		&.h-selected {
			position: relative;
			border-bottom: 1px solid;

			&::before {
				content: "";
				position: absolute;
				display: block;
				bottom: -3px;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: $color_second;
			}
		}
	}
}

/*
#Block - Account Landing Page
*/
.b-account_overview {
	&-title {
		@include t-title-h8(uppercase);

		margin-bottom: 25px;
	}

	&-welcome {
		@include t-text-6;

		margin-bottom: 15px;
	}

    &-logout-link {
        position: relative;
        padding: 0 3px;

        &::before,
        &::after {
            position: absolute;
            top: 0;
        }

        &::before {
            content: "(";
            left: -3px;
        }

        &::after {
            content: ")";
            right: -3px;
        }
    }

    &-content {
        h2 {
            margin-bottom: 10px;
        }

        h2 a {
            display: inline-block;
            margin: 32px 0 0;
            border-bottom: $border_main;
        }
    }

	&-logout {
		@include t-text-6;

		margin-bottom: 40px;
	}
}

.b-account_store_credit-title {
    @include myaccount_title;
}

.b-account_store_credit-empty_asset p {
    @include description_text;
}
