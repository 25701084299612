.b-popup {
	position: fixed;
	width: 400px;
	height: auto;
	top: 50%;
	left: 50%;
	overflow: visible;
	transform: translate(-50%, -50%);
	z-index: 200;

	&-overlay {
		position: fixed;
		top: 0;
		left: 0;
		min-width: 100%;
		min-height: 100%;
		background: rgba($color_third, .8);
		z-index: 199;
	}

	&_close-btn {
		@include button_reset();
		@include icon(before, close);

		position: absolute;
		top: 15px;
		right: 15px;
		width: 25px;
		height: 25px;
		cursor: pointer;
		font-size: 9px;

		&:hover,
		&:focus {
			background-color: $color_main;
			color: $color_second;
			border: none;
			opacity: .75;
		}
	}

	&-content {
		width: 100%;
		height: 100%;
	}

	&_WorldpayThreeDS {
		left: 0;
		top: 0;
		width: 100%;
		max-width: 100%;
		height: 70vh;
		transform: none;
		background-color: $white;
	}
}
