@import "./form_elements/textinputs";
@import "./form_elements/checkboxes";
@import "./form_elements/radiobuttons";

.g-field_light,
%g-field-light {
	color: $black;

	&:not(.f-radio-wrapper) {
		@include field-autofill($white, $color_main);
	}

	&.f-select-wrapper {
		.f-select option {
			background-color: $white;
		}
	}
}

.f-field {
    position: relative;
    float: left;
    width: 100%;
    margin: 0 0 30px;

    & > .f-field {
        margin-bottom: 0;
    }

    &-wrapper {
        position: relative;
        float: left;
        width: 100%;

        .f-field_description {
            @include font_main(10px, 16px);

            display: block;
        }

        .f-error_message,
        .f-warning_message {
            @include t-title-h11;

            position: relative;
            color: $validation-color;
        }

        .f-warning_message {
            display: none;

            .b-warning-light & {
                display: block;
            }
        }
    }
}

.f-field .f-field.f-state-error {
    margin: 0 0 -15px;
}

.f-error_text {
    display: block;
    margin: 6px 0 3px;
}

.f-label {
    float: left;
    width: 30%;
    margin-right: 2%;
    text-transform: uppercase;
    line-height: 50px;
	text-align: initial;

    &-value {
        @include t-title-h11;

        display: inline-block;
        vertical-align: middle;
    }

    .f-state-required &-value::after {
        @include required_fields;
    }
}

.f-select {
    @include t-title-h9;
	@include field-autofill;

    width: 100%;
    height: 50px;
    padding: 12px 10px;
    outline: none;
    text-overflow: ellipsis;
	background-color: transparent;
    border: $border_main;
	border: 0 none;
    border-radius: 0;
	border-bottom: 1px solid currentColor;
    appearance: none;

    option {
		@include t-title-h9;

        padding: 11px 16px;

		&:hover {
			background: $grey2;
		}
    }

    .f-state-error &,
    &.f-state-error {
		border: 0 none;
        border-bottom: 1px solid $validation_color;
    }

    &-wrapper {
        @include icon(after);

        position: relative;

        &::after {
            content: icon-char(arrow-down);
            position: absolute;
            top: 1px;
            bottom: 1px;
            right: 20px;
            font-size: 12px;
            line-height: 48px;
            text-align: center;
            pointer-events: none;
			color: currentColor;
        }

        &-disabled::after {
            content: '';
        }
    }
}

select::-ms-expand {
    display: none;
}

.textinput,
.f-textinput,
.f-textinput-no_hits,
.f-email,
.f-password {
	@include t-title-h9;
	@include field-autofill;

	width: 100%;
	height: 50px;
	border: 0 none;
	color: currentColor;
	border-bottom: 1px solid;
	background-color: transparent;

    &:focus {
        outline: 0;

		&::placeholder {
			color: transparent;
		}
    }

	&::placeholder {
		color: currentColor;
	}
}

.f-field-checkbox {
    .f-checkbox {
        position: absolute;
        visibility: hidden;
    }
}

.f-textarea {
    @include t-title-h9;

    width: 100%;
    height: 80px;
    padding: 10px;
    border: 1px solid currentColor;
    border-radius: 0;
    resize: vertical;
	background-color: transparent;

    &:focus::placeholder {
        color: transparent;
    }
}

::placeholder {
    color: $color_main;
}

form,
.f-field_textinput,
.f-field_checkbox,
.f-field_email,
.f-field_password {
    position: relative;
}

fieldset,
.f-fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

.f-form_error_message,
.f-form-error_message,
.b-return_authorization-error {
    @include t-title-h7;

    color: $validation-color;
}

.b-return_authorization-error {
    position: relative;
    top: -15px;
}

.f-wrapper-f-type-allowtocollect {
    position: relative;
    float: left;
    width: 75%;
    padding: 20px 0;

    p,
    ul,
    ol {
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }

    .f-field {
        &-checkbox.f-type-allowtocollect {
            position: static;
            float: left;
            width: 20px;
            margin: 0;
        }

        &-message {
            position: relative;
            float: left;
            width: 90%;
            margin: 0 0 10px;
        }

        &-wrapper {
            position: static;

            .f-error_message,
            .f-warning_message {
                position: absolute;
                top: auto;
                bottom: 0;
                left: 20px;
                width: auto;
            }

            .f-label-value::after {
                display: none;
            }
        }
    }
}

.b-customer_service_content {
    .f-wrapper-f-type-allowtocollect {
        width: 100%;
        margin-bottom: 10px;

        .f-error_message,
        .f-warning_message {
            left: 0;
        }

        .f-field-message {
            margin: 0 0 -5px;
        }
    }
}

.grecaptcha-badge {
    display: none;
}

.f-captcha_error-message {
    display: inline-block;
    margin-bottom: 32px;
}
