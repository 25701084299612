@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/*
#Core Owl Carousel CSS File
*/

.b-owl_carousel {
	position: relative;
	display: none;
	width: 100%;
	margin: 0;
	-webkit-tap-highlight-color: transparent;
	z-index: 1;

    /*
    #Owl Carousel - Animate Plugin
    */
    .animated {
        -webkit-animation-duration: 1000ms; /* stylelint-disable-line */
        animation-duration: 1000ms;
        -webkit-animation-fill-mode: both; /* stylelint-disable-line */
        animation-fill-mode: both;
    }

    .owl-animated-in {
        z-index: 0;
    }

    .owl-animated-out {
        z-index: 1;
    }

    .fadeOut {
        -webkit-animation-name: fadeout; /* stylelint-disable-line */
        animation-name: fadeOut;
    }

    /*
    ##Owl Carousel - Auto Height Plugin
    */
    .b-owl_carousel-height {
        transition: height 500ms ease-in-out;
    }

    .owl-stage {
        position: relative;
        -ms-touch-action: pan-y; /* stylelint-disable-line */

        &::after {
            content: ".";
            display: block;
            clear: both;
            height: 0;
            line-height: 0;
            visibility: hidden;
        }
    }

    .owl-stage-outer {
        position: relative;
        counter-reset: numberednav;
        overflow: hidden;
        -webkit-transform: translate3d(0, 0, 0); /* stylelint-disable-line */

		.l-assets_slider_with_thumbs-container & {
			overflow: clip;
		}
    }

    &-nav_prev,
    &-nav_next,
    &-nav_dot {
        cursor: pointer;
        cursor: hand;
        user-select: none;
    }

    &-nav_dots {
		position: absolute;
		display: flex;
		justify-content: center;
		max-width: 440px;
		left: 0;
		right: 0;
		bottom: 20px;
		margin: 0 auto;
		text-align: center;
		font-size: 0;
		line-height: 0;
		white-space: nowrap;

		&::before,
		&::after {
			display: inline-block;
			vertical-align: middle;
		}

		&::before {
			content: attr(data-current-slide);
			margin-right: 10px;
		}

		&::after {
			content: attr(data-total-slide);
			margin-left: 10px;
		}
    }

	&-nav_dot {
		$dot-size: 10px;

		@include icon(after, diamond);

		position: relative;
		display: inline-block;
		width: calc(30px + #{$dot-size});
		height: $dot-size * 2;
		margin: 0;

		&::after {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			transform: translateY(-50%);
			color: $grey4;
			font-size: $dot-size;
		}

		span {
			display: none;
			width: 8px;
			height: 8px;
			margin: 0 $dot-size;
			border-radius: 50%;
			opacity: 0.4;
			transition: all 600ms ease;
		}

		&.active {
			&::after {
				color: $black;
			}

			span {
				background: $white;
				opacity: 1;
			}
		}
	}

    &.owl-loaded {
        display: block;
    }

    &.owl-loading {
        display: block;
        opacity: 0;
    }

    &.owl-hidden {
        opacity: 0;
    }

    &-item {
        position: relative;
        float: left;
        min-height: 1px;
        -webkit-backface-visibility: hidden; /* stylelint-disable-line */
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        user-select: none;
    }

    &-item img {
        display: block;
        width: 100%;
        height: auto;

		&.loaded + .lazyloadxt-indicator {
			display: none;
		}
    }

    &.owl-text-select-on &-item {
        user-select: auto;
    }

    .owl-grab {
        cursor: move;
        cursor: grab;
    }

    &.owl-rtl {
        direction: rtl;
    }

    &.owl-rtl &-item {
        float: right;
    }

    /* No Js */
    .no-js & {
        display: block;
    }

    /* 
    #Owl Carousel - Lazy Load Plugin
    */
    &-item .owl-lazy {
        opacity: 0;
        transition: opacity 400ms ease;
    }

    /* 
    #Owl Carousel - Video Plugin
    */
    .owl-video-wrapper {
        position: relative;
        height: 100%;
        background: #000;
    }

    .owl-video-play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 80px;
        margin-top: -40px;
        margin-left: -40px;
        cursor: pointer;
        z-index: 1;
        -webkit-backface-visibility: hidden; /* stylelint-disable-line */
        transition: scale 100ms ease;
    }

    .owl-video-play-icon:hover {
        transition: scale(1.3, 1.3);
    }

    .owl-video-playing .owl-video-tn,
    .owl-video-playing .owl-video-play-icon {
        display: none;
    }

    .owl-video-tn {
        height: 100%;
        opacity: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        transition: opacity 400ms ease;
    }

    .owl-video-frame {
        position: relative;
        z-index: 1;
    }

    .b-owl_carousel {
		&-nav_prev,
		&-nav_next {
			@include icon(after);

			position: absolute;
			top: 50%;
			width: 32px;
			height: 32px;
			margin-top: -16px;
			transform: translateY(-50%);
			text-align: initial;
			font-size: 0;
			line-height: 32px;

			&.disabled,
			&.disabled {
				display: none !important;
			}

			.b-hp_product_slider & {
				margin-top: -60px;
			}
		}

        &-nav_prev {
			left: -20px;

            &::after {
                content: icon-char(arrow-left);
                font-size: 14px;
            }

			.b-hp_product_slider & {
				left: -35px;
			}

            .b-variation & {
                left: 8px;
            }
        }

        &-nav_next {
            right: -20px;
            text-align: right;

            &::after {
                content: icon-char(arrow-right);
                font-size: 14px;
            }

			.b-hp_product_slider & {
				right: -35px;
			}
        }
    }

	&-nav {
		position: absolute;
		display: flex;
		flex-wrap: wrap;
		bottom: 30px;
		left: 0;
		padding: 0 30px;
		width: 100%;
		z-index: 2;

		.b-wish_list-flyout &,
		.b-mini_cart-flyout_products &,
		.b-hp_product_slider & {
			position: initial;
		}

		.b-swatches_color & {
			position: absolute;
		}
	}

	&-nav_item {
		display: flex;
		flex-direction: column;
		align-self: flex-end;
		flex: 1 1 0;
		margin-right: 30px;
		text-align: left;
		cursor: pointer;

		&:last-child {
			margin-right: 0;
		}

		&-content {
			color: $white;
		}

		&-title {
			@include t-title-h7(uppercase);

			padding-bottom: 18px;
		}

		&-text {
			@include t-text-6;

			padding: 0;
			max-height: 0;
			width: 100%;
			transition: max-height 1.4s cubic-bezier(0, 1, 0, 1), padding 300ms ease-in-out;
			opacity: 0;
			visibility: hidden;
		}

		&.active {
			cursor: default;

			.b-owl_carousel-nav_item-text {
				padding: 10px 0;
				max-height: 500px;
				transition: max-height 1.2s ease-in-out, opacity 500ms cubic-bezier(0.75, 0.05, 0.85, 0.06), padding 300ms ease-in-out;
				visibility: visible;
				opacity: 1;
			}

			.b-owl_carousel-progress-inner {
				height: 2px;
				width: 100%;
				animation: progressBarLoading var(--autoplay-timeout) 1 linear;
				background: $white;
			}
		}

		&.m-complete {
			.b-owl_carousel-progress-inner {
				height: 2px;
				width: 100%;
				background: $white;
			}
		}
	}

	&-progress {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 2px;
		width: 100%;
		background: $progress_bar;
	}
}

.js-owl_carousel_nav {
    cursor: pointer;

    &.m-numeric {
        display: inline-block;
        margin: 9px 25px 9px 0;
        padding: 5px 1px;
        border-bottom: 2px solid transparent;
        font-weight: 350;
        transition: border 1s ease-in;

        &.m-active {
            border-bottom: 2px solid $color_main;
            transition: border 1s ease-in;
        }
    }
}
