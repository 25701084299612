.varying_block {
    position: absolute;

    &-container {
        position: relative;
    }

    &-top {
        top: 0;
    }

    &-bottom {
        bottom: 0;
    }

    &-center {
        left: 50%;
        transform: translateX(-50%);

        &_content {
            text-align: center;
        }
    }

    &-middle {
        top: 50%;
        transform: translateY(-50%);

        &.varying_block-center {
            transform: translate(-50%, -50%);
        }
    }

    &-left {
        left: 0;

        &_content {
            text-align: left;
        }
    }

    &-right {
        right: 0;

        &_content {
            text-align: right;
        }
    }

    &-full {
        width: 100%;
    }

    &-1x2 {
        width: 50%;
    }

    &-1x3 {
        width: 33%;
    }

    &-1x4 {
        width: 25%;
    }

    &-btn_black {
        color: $white;
        background-color: $color_main;
        border: $border_main;

        &:hover {
            color: $color_main;
            background-color: $white;
        }
    }

    &-btn_white {
        color: $color_main;
        background-color: $white;
        border: $border_main;

        &:hover {
            color: $white;
            background-color: $color_main;
        }
    }

    &-white {
        background-color: $white;

        &_transparent {
            padding: 3%;
            background: rgba(255, 255, 255, 0.75);
        }

        &_content {
            color: $white;
        }
    }

    &-black {
        background-color: $color_main;

        &_transparent {
            padding: 3%;
            background: rgba(0, 0, 0, 0.75);
        }

        &_content {
            color: $color_main;
        }
    }
}
