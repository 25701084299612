#first-visit-baner {
    display: none;
}

//#Footer layout and global styles
.l-footer {
    clear: both;

    @extend .clearfix;

    &_banner {
        &-top {
            text-align: center;

            &_icon {
                margin: 0 0 13px;

                @include icon(before);

                &::before {
                    content: icon-char(instagram);
                    font-size: 20px;
                    line-height: 20px;
                }
            }

            &_text {
                @include font_main(12px, 18px, inherit, 0.3em, uppercase);

                margin: 0 0 15px;
            }
        }

        &-images {
            @extend .clearfix;

            img {
                float: left;
                width: 14.2857%;
            }
        }
    }

    &_second-block-wrapper,
	&_newsletter-container-wrap {
        width: 100%;
        text-align: center;

		.l-footer-blocks-wrap.newsletter-block .b-formsections_error {
			display: none;
		}
    }

	&_newsletter-container-wrap {
		padding: 40px 0 54px;
		background-color: $bordeaux;
	}

	&_second-block-wrapper {
		padding: 57px 40px;
		background-color: $grey2;
	}

    &-blocks-wrap {
        &.social-block,
        &.contact-block {
            .l-footer_social-text,
            .contact-us-text {
                @include t-title-h4;
            }
        }

		&.social-block {
			.l-footer_social-heading {
				@include t-title-h6;

				margin-bottom: 24px;
				text-align: left;
				text-transform: uppercase;
			}

            .l-footer_social-icon {
                @include icon(before);

                margin: 0 0 11px;
                text-align: center;

                &::before {
                    content: icon-char(wishlist-not-selected);
                    font-size: 19px;
                    line-height: 20px;
                }

                &.icon-false {
                    @include icon_false;
                }
            }
        }

        &.contact-block {
            .contact-us-icon {
                @include icon(before);

                margin: 0 0 13px;
                text-align: center;

                &::before {
                    content: icon-char(headphones);
                    font-size: 19px;
                    line-height: 20px;
                }

                &.icon-false {
                    @include icon_false;
                }
            }

            .l-footer_navigation-link {
                @include t-title-h5;

                display: inline-block;
                margin-top: 24px;
                padding: 0;
                text-transform: initial;
                border-color: $color_main;

                &:hover {
                    border-color: transparent;
                }
            }
        }

        &.newsletter-block {
			.newsletter-block {
				&_content-wrapper {
					padding-left: 102px;
					text-align: left;
					color: $grey2;

					.b-simple_newsletter {
						margin-top: 32px;
					}
				}

				&-title {
					@include t-text-6(uppercase);
					@include icon(before);

					position: relative;
					margin-bottom: 10px;

					&.appointment-title {
						&::before {
							content: icon-char(storelocator);
						}
					}

					.title_text-part {
						margin-left: 32px;
					}

					&::before {
						content: icon-char(mail);
						position: absolute;
						top: 2px;
						font-size: 20px;
						line-height: 20px;
					}
				}

				&-subtitle {
					@include font_main(14px, 20px, $font_second, $font-weight: 400);
				}

				&-link {
					@include font_main(14px, 16px, $font-weight: 325);

					margin-top: 40px;
					text-align-last: left;

					a {
						padding-bottom: 4px;
						color: $grey2;
					}
				}
			}

            .b-simple_newsletter {
                &-sections {
                    .f-label {
                        display: inline-block;
                        width: 100%;
                        padding-left: 32px;

                        &-value {
                            @include t-title-h5;

                            text-transform: none;
                        }
                    }

                    &_label {
                        @include t-title-h5;

                        margin-bottom: 6px;
                    }

                    &_wrapper {
                        float: left;
                        width: 330px;
                        margin: 0 0 0 -8px;

                        .f-field {
                            &.f-type {
                                &-section1,
                                &-section2 {
                                    float: left;
                                    width: 50%;
                                    text-align: center;
                                }
                            }

                            &.f-type-section2 {
                                width: 30%;
                                margin-left: 20%;
                            }

                            &-wrapper {
                                display: inline-block;
                                float: none;
                                width: auto;
                                text-align: left;
                            }
                        }
                    }

                    & + .f-field-email {
                        width: 265px;
                        margin: 23px 0 10px 28px;
                    }
                }

				&-heading {
					@include t-text-3;
				}

				&-text {
					@include t-text-6;

					margin-bottom: 30px;
				}

				.f-field .f-email {
					@include t-title-h9;

					position: relative;
					padding: 0 25px 10px 0;
					margin: 0;
					max-width: 335px;
					text-align: left;
					text-transform: none;
					border-bottom: $border_grey;
					color: $grey2;
					caret-color: $grey2;

					&::placeholder {
						@include t-title-h9;

						color: $grey2;
						opacity: .4;
					}
				}

				&-form {
					clear: both;

					& .f-error_text {
						padding-left: 0;
						text-align: initial;
						color: $grey2;
					}

					&_input_text {
						.f-label {
							display: none;
						}
					}

					&_types {
						display: flex;
						justify-content: center;
						margin: 0 auto;

						&-item {
							width: auto;
							margin: 20px 10px 10px;

							.f-label {
								padding: 0;

								.f-label-value {
									padding-left: 30px;
									font-size: 1.2rem;
								}
							}
						}

						.b-detailed_newsletter-form_types-error {
							display: none;
						}
					}
				}
			}
		}
	}

    &_newsletter {
        &-icon {
			display: none;

            @include icon(before);

            margin: 0 0 11px;
            text-align: center;

            &::before {
                content: icon-char(mail);
                font-size: 19px;
                line-height: 20px;
            }

            &.icon-false {
                @include icon_false;
            }
        }

        &-small-text {
            @include font_main(8px, 1);

            position: relative;
            top: 20px !important;

            a {
				@include t-text-8;

				padding: 0;
				text-transform: initial;
				color: $color_main;
				border: none;
            }
        }

        .b-simple_newsletter-fieldset {
            position: relative;
			display: flex;
			flex-direction: column;
			max-width: 335px;
			gap: 20px;

            .b-simple_newsletter_action_button {
                @include icon(before);

                position: absolute;
                right: 0;
                margin: 0;
                padding: 0 0 0 15px;
                border: none;
                background: none;

                &::before {
                    content: icon-char(arrow-right);
                    margin-right: -2px;
                    font-size: 8px;
                }

                &:hover {
                    color: $color_main;
                }

                span {
                    display: none;
                }
            }

			.b-newsletter_profile_policy {
				display: flex;
				flex-direction: column;
				gap: 10px;
				color: $grey2;

				.f-field-wrapper {
					float: initial;
					display: flex;
					flex-direction: column;
					gap: 10px;
				}

				.f-label {
					width: 100%;

					a {
						color: currentColor;
					}

					&::before {
						border-color: currentColor;
					}
				}
			}
        }
    }

    .b-formsections_error {
        @include t-title-h10;

        width: 100%;
        margin-top: 15px;
        text-align: center;
        color: $validation-color;
    }

	.b-content_asset--footer-navigation {
		@include respond-to(tablets-landscape) {
			flex-grow: 1;
			margin: 0 20px;
		}
	}

	&_navigation {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		padding: 40px 80px 40px 40px;
		background-color: $white;

		@include respond-to(all) {
			gap: 60px;
		}

		&_countrylang-wrap {
			position: relative;
			min-width: 262px;
			padding-top: 150px;
			margin-left: auto;
		}

        &-list {
            margin: 0 -3px 0 0;
            text-align: justify;
            font-size: 0;
            line-height: 0;

            &::after {
                content: '';
                display: inline-block;
                width: 100%;
            }
        }

        &-item {
            display: inline-block;
        }

        &-link {
            @include t-title-h9;
        }
    }

	&_links {
		display: inline-flex;
		justify-content: space-between;
		width: 100%;
		gap: 80px;

		@include respond-to(all) {
			gap: 60px;
		}

		&-list {
			text-align: left;
			line-height: 25px;
		}

		&-item {
			margin-bottom: 24px;

			&_heading {
				@include t-title-h6;

				text-transform: uppercase;
				color: $black;
			}

			a {
				@include t-title-h6;

				border-bottom: none;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&_logo {
		margin-bottom: 40px;
	}

    &_bottom {
		@include clearfix;

		padding: 30px 40px 28px;
		text-align: left;

		@include respond-to(all) {
			margin: -1px 0 0;
			padding: 0 19px 20px;
		}
    }

    /*
    #Footer copyright links
    */
    &_misc_links {
        float: right;
        width: 35%;
        margin: -4px 0 0;

        &-list {
            margin: 0;
            padding: 1px 0 0;
            text-align: right;
        }

        &-item {
            display: inline;

            &_separator {
                @include t-title-h11;

                margin: 0 6px;
            }

            &:first-child::before {
                display: none;
            }
        }

        &-link {
            @include t-title-h11;

            text-transform: none;
        }
    }

    &_social {
		position: absolute;
		bottom: 0;
		padding: 0;
		text-align: right;

		&-list {
			margin: 0 0 24px;
			text-align: left;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&-item {
			display: inline-block;
			margin-right: 40px;
			vertical-align: middle;
		}

		&-item:nth-child(5) {
			margin-right: 0;
		}

        &-link {
            @include icon(before);
            @include underline_remove;

            margin: 0;
            padding: 0;
            border: none;
            font-size: 16px;
            line-height: 1;

            &.instagram::before {
                content: icon-char(instagram);
            }

            &.facebook::before {
                content: icon-char(facebook);
            }

            &.youtube::before {
                content: icon-char(youtube);
            }

            &.twitter::before {
                content: icon-char(twitter);
            }

            &.pinterest::before {
                content: icon-char(pinterest);
            }

            &.google {
                margin-right: 0;

                &::before {
                    content: icon-char(google);
                }
            }

			&.tiktok::before {
                content: icon-char(tiktok);
            }

			&.wechat::before {
                content: icon-char(wechat);
            }

			&.weibo::before {
                content: icon-char(weibo);
            }

			&.whatsapp::before {
                content: icon-char(whatsapp);
            }
        }
    }

	&_copyright {
		@include t-title-h11;

		color: $grey4;
	}
}
/*
#Footer newsletter form
*/
.b-simple_newsletter {
    &-title {
        @include font_main(24px, 30px, $font_main, 0);

        display: none;
        margin: 0 0 12px;
    }

    p {
        @include t-title-h4;

        margin: 0 0 12px;
        text-align: center;
    }

	&-button {
		@include icon(before);

		position: absolute;
		width: auto;
		height: auto;
		top: 3px;
		right: 0;
		padding: 0;
		margin: 0 auto;
		border: none;
		background-color: transparent;

		span {
			display: none;
		}

		&::before {
			content: icon-char(arrow-link);
			font-size: 16px;
		}

		&:hover,
		&:active,
		&:focus {
			border: none;
			background-color: transparent;
			color: $grey2;
		}

		.l-footer_newsletter & {
			margin: 0 auto;
		}
	}

    .f-field {
        margin: 0;

        .f-email {
            @include t-title-h5;

            width: 100%;
            height: 20px;
            padding: 0;
            background: none;
            border: 0;
            color: $color_main;

            &::placeholder {
                @include t-title-h5;

                color: $color_main;
            }

            &:focus {
                background: none;

                &::placeholder {
                    opacity: 0;
                }
            }
        }

        .f-error_message {
            @include t-title-h7;

            width: 100%;
            color: $validation-color;
        }
    }
}

.b-selector {
	&_item {
		display: flex;
		align-items: center;
		gap: 40px;
		margin-bottom: 24px;
	}

	&_title {
		@include t-title-h6(uppercase);

		width: 110px;
		white-space: nowrap;
	}

	&_value {
		@include t-title-h6-h7;

		padding: 2px 0;
		border-bottom: 1px solid currentColor;
		cursor: pointer;

		&-country {
			position: relative;
			border-bottom: none;

			&::after {
				content: "";
				position: absolute;
				display: inline-block;
				width: 100%;
				height: 1px;
				bottom: -1px;
				left: 0;
				background-color: currentColor;
			}
		}
	}
}

.b-services {
	&-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&-item {
		flex: 0 0 20%;
		padding: 0 7px;
		color: $black;
	}

	&-link {
		display: block;
		text-decoration: none;
		border-bottom: none;
	}

	&-icon {
		@include icon(before);

		margin-bottom: 16px;
		font-size: 40px;

		&.delivery-icon::before {
			content: icon-char(delivery2);
		}

		&.return-icon::before {
			content: icon-char(return3);
		}

		&.whatsapphone-icon::before {
			content: icon-char(whatsapphone);
		}

		&.securepayment-icon::before {
			content: icon-char(securepayment);
		}

		&.packaging-icon::before {
			content: icon-char(packaging);
		}
	}

	&-title {
		@include t-title-h6;

		margin-bottom: 8px;
		letter-spacing: 0;
	}

	&-text {
		@include font_main(14px, 20px, $font_second, 0, none, 400);
	}
}
