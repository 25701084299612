
.b-info_card {
	$card: &;

	&-icon {
		&-text {
			@include t-title-h7(uppercase);
		}
	}
}
