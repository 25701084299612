/*
#Layout - Account Wishlist
*/
.l-wishlist {
    padding: 50px 20% 0;
    width: 100%;

    @include clearfix;

    @include respond-to(custom) {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.l-wishlist_content {
    float: left;
    width: 66.5%;

    @include respond-to(custom) {
        width: 75%;
    }
}

/*
#Block - Empty Wishlist
*/
.b-wishlist-title {
	@include t-title-h8(uppercase);

	margin-bottom: 25px;
}

.b-wishlist_empty {
    &-title {
        @include description_text;

        margin: 0 0 20px;
    }

    &-link_home {
        padding: 7px 0;
        text-decoration: underline;
    }
}

/*
#Block - Wishlist Send To Friend button
*/

.b-wishlist_send {
    @include clearfix;

    &-button {
        @extend %g-button-primary;

        width: auto;
		max-width: none;
        float: right;
    }
}

/*
#Block - Wishlist Items Table
*/
.b-wishlist_table {
    width: 100%;
    margin: 30px 0;
    text-align: left;
    border: none;

	&-item {
		position: relative;
	}

    td {
        padding: 0 15px;
        vertical-align: top;
        border: none;
    }

    &-item-- {
        border-top: $border_main;
    }

    &-controls {
        td {
            padding: 15px 0;
        }
    }

	&-image {
		min-width: 130px;

		& .b-product_image-wrapper {
			padding-top: 20px;
			border-bottom: none;
		}
	}

	& td.b-wishlist_table-attributes {
		padding-top: 15px;
		width: 43%;

		.b-cart_table-body_col_product-attribute {
			@include t-text-6;
		}
	}

	& td.b-wishlist_table-price,
	& td.b-wishlist_table-remove {
		padding-top: 13px;
	}

    &-qty {
        display: none;

        .f-field {
            float: none;
            margin: 0;
        }

        .f-label {
            display: block;
            float: none;
            width: auto;
            margin: 0 0 20px;
            text-transform: none;
        }

        .f-label-value {
            line-height: 18px;
            font-size: 14px;
        }
    }

	&-price {
		width: 100%;
		text-align: left;

		.product-availability-list {
			@extend .h-hidden;

			@include t-title-h10;

			.notavailable {
				color: $validation-color;
				text-align: center;
			}
		}
	}

	.b-product_list {
		&-price {
			display: none;
		}

		&-price_label,
		&-remove_label {
			@include t-text-6;

			margin: 0 10px 20px 0;
		}

		&-remove_button {
			@include t-title-h9(uppercase);

			@extend .g-button_link;

			width: auto;
			color: $color_main;
		}

		&-add_to_cart_button {
			@extend %g-button-primary;

			float: right;
			min-width: 150px;
			padding: 14px 5px;

			&.banned,
			&.banned:hover {
				background: $color_second;
				border: $color_second;
				color: $white;
			}

			&:focus {
				border-color: $color_second;
    			background-color: $color_second;
				color: $white;
			}
		}

		&-sku {
			@include t-text-6;

			margin-top: 20px;
		}

		&-notavailable {
			@include t-text-6;
		}

        &-preorder {
            padding-bottom: 10px;
        }
    }

    .b-product_share {
        padding: 0;

        &-content {
            display: block;
            padding-top: 0;
            border: none;

            .social-share-bar {
                display: none;
            }
        }

        &-text {
            display: none;
        }

        &-send_to_friend {
            @include g-button($max-width: none, $margin: 0, $height: 30px);

            span {
                display: block;
            }

            &::before {
                display: none;
            }
        }

        &-addthis {
            padding: 15px 0 0;

            a {
                display: inline-block;
                margin: 0 7px 0 0;
                vertical-align: top;
            }
        }
    }

	& &-remove {
		width: 22%;
		padding-left: 35px;
		text-align: right;
	}
}

.b-send_to_friend_form-submit {
	@extend %g-button-primary;
}

/*
#Block - Product Recommendations
*/
.l-wishlist .b-product_recommendations {
    margin: 20px 0 0;
    padding: 0;
    list-style: none;
    font-size: 0;

    &-title {
        @include myaccount_title;

        margin: 25px 0 0;
    }

    &-item {
        display: inline-block;
        width: 25%;
        padding: 0 12px 7px 0;
        vertical-align: top;
    }

    .b-product-hover_box {
        margin-bottom: 9px;
    }
}

@media screen and (min-width: 1920px) {
    .b-product_recommendations-item {
        width: 12.5%;
    }
}

/*
#Block - Wishlist Login
*/
.b-account_wishlist_login {
    .b-login_account {
        margin-bottom: 20px;

        &-title {
            display: none;
        }

		&-login_button {
			float: right;
			margin: 0;
			min-width: 250px;

			@include respond-to(vertical) {
				position: static;
			}
		}

		&-forgot_password {
			@include t-title-h7;

			position: relative;
			display: inline-block;
			top: 0;
			left: 0;
			margin: 0 0 0 32%;
			padding: 0;
		}
    }

    &-title {
		@include t-title-h8(uppercase);

		margin-bottom: 20px;
    }

    &-description {
        p,
        ul {
			@include t-text-6;

			margin-bottom: 10px;
		}
    }

    &-create_account_button {
		@extend %g-button-primary;
        float: right;
        margin-top: 20px;
    }

    .f-field {
        @include clearfix;

        clear: both;
        width: 100%;
        margin: 0 0 20px;

        &-wrapper {
            @include myaccount_field_wrapper;
        }
    }

    .f-label {
        display: block;
    }

    .f-field-checkbox .f-label .f-label-value {
        line-height: 1;
    }

    .b-login_account-info {
        @include description_text;

        margin-bottom: 22px;
    }

    .b-login_account-form > .f-form-error_message {
		@include font_main(12px, 14px, $font_main);

		margin-bottom: 26px;
		text-transform: none;
	}
}

/*
#Block - Wishlist for unlogged in users
*/
.b-wishlistblock {
    $this: &;

    padding: 0 9%;
    margin: 25px 0;

    &-title {
        @include icon(after);

        cursor: pointer;

        &::after {
            content: icon-char(arrow-down);
            position: absolute;
            top: 2px;
            right: 15px;
            font-size: 14px;
            pointer-events: none;
        }

        &.active {
            @include icon(after);

            &::after {
                content: icon-char(arrow-up);
            }
        }

        &.empty ~ #{$this}-content #{$this}-content-user_message {
            display: none;
        }
    }

    &-content {
        padding: 20px 0;

        &-added_message {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            width: 100%;
            text-align: center;
        }

        &-user_message {
            > a {
                font-size: 14px;

                &:hover {
                    text-decoration: underline;
                    border-bottom-color: transparent;
                }
            }
        }
    }

    &-content_tiles {
        display: flex;
        flex-flow: row wrap;
        margin-top: 15px;
    }

    &-content_tile {
        position: relative;
        display: inline-block;
        width: calc(100% / 6);
        margin: 15px;

        &:hover .b-wishlistblock-remove {
            display: block;
        }
    }

    &-remove {
        @include icon(after);
        @include underline_remove;

        position: absolute;
        display: none;
        top: 0;
        left: 0;
        z-index: 3;
        font-size: 0;
        cursor: pointer;

        @include respond-to(all) {
            display: block;
        }

        &::after {
            content: icon-char(close);
            color: $color_second;
        }
    }

    &-title,
    .b-content_asset--empty-wishlist-login-block.content-asset {
        @include t-title-h6;

        position: relative;
        text-transform: uppercase;
    }

    .b-content_asset--empty-wishlist-login-block.content-asset {
        cursor: default;
    }
}

.l-checkout_cart-left {
    .b-wishlistblock {
        padding: 0;
        margin: 45px 0 0;

        &-title {
            text-indent: initial;
        }

        &-content_tile {
            width: calc(100% / 5);
            margin: 10px;
        }
    }
}
