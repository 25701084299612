/*
#Page - Address Book
*/

/*
#Block - Address Book
*/
.b-address_book {
	&-title {
		@include t-title-h8(uppercase);

		margin-bottom: 25px;
	}

	&-description {
		@include t-text-6;

		margin-bottom: 20px;
	}

	&-create {
		@extend %g-button-primary;
	}
}

/*
#Block - Address List
*/
.l-address_list {
    @include init-radiobutton;

    margin: 0 0 0 -30px;
    padding: 0;
}

/*
#Block - Address Item
*/
.b-address_item {
    display: inline-block;
    margin: 5px 30px 30px;
    vertical-align: top;
    text-align: left;

    &-default_button {
        float: left;
        margin-right: 5px;
    }

    &-default_label {
        white-space: nowrap;
    }

    &-edit,
    &-delete {
        @include t-title-h7(uppercase);
        @include animated-underline;

        display: inline-block;
        margin: 7px 30px 0 0;
    }
}

/*
#Block - Address Item - Mini-Address
*/
.b-mini_address {
	@include t-title-h9;

	padding: 15px 0;
	line-height: 20px;

	&-title {
		@include t-title-h7;

		margin: 25px 0 15px;
		text-align: left;
	}

	&-country,
	&-phone {
		display: block;
	}
}

/*
#Popup Add Address
*/
.b-address_details {
    &-title {
        @include myaccount_title;
    }

    .f-field-wrapper {
        @include myaccount_field_wrapper;
    }

    &-label_required {
        padding: 0 0 15px 32%;
        text-transform: uppercase;
        font-size: 12px;

        &::after {
            @include required_fields;
        }
    }

	&-submit {
		@extend %g-button-primary;

		display: inline-block;
		margin: 23px 23px 0 0;
	}

	&-delete {
		display: none;
	}

	&-cancel {
		@extend %g-button-primary;

		display: inline-block;
		margin: 23px $padding-md $padding-md 0;
	}

	@include phone_fields;
}
