.b-category_name {
	padding: 40px 0;
	text-align: center;
}

.b-search-header {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 32px 0 40px;
	gap: 16px;

	.b-search-count {
		@include t-title-h4;
	}
}

.l-main_search {
    padding: 0;

    &_wrapper {
		@include clearfix;

		max-width: 1900px;
        margin: 0 auto;
    }
}

.l-primary_content {
	position: relative;
}

.l-secondary_content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 20px;
	height: 400px;
	overflow-y: auto;

	@include respond-to(all) {
		padding-top: 40px;
	}

    &.secondary-with-padding {
        padding-bottom: 100px;
    }
}

.l-search_result-content {
    position: relative;
    clear: both;
    margin: 51px 0 0 60px;
    overflow: hidden;

	&.m-two-columns {
		padding: 0 10%;
	}

    @include respond-to(all) {
        margin-top: 46px;
    }

    .loader {
        position: relative;

        &.refinement-loader {
            position: fixed;
        }
    }
}

.did-you-mean {
    @include t-title-h5;

    position: absolute;
	display: none;
    top: -36px;
    margin: 0 0 0 20px;
    z-index: 1;
}

.b-list_item_page {
    display: flex;
	flex-wrap: wrap;
}

.b-product-hover_box {
    span.b-swatches-link_notifyme {
        display: none;
    }

	.b-productimageslider {
		&-item:not(:first-child) {
			display: none;
		}

		&.owl-loaded {
			.b-productimageslider-item {
				display: block;
			}
		}

		.b-owl_carousel-nav {
			position: relative;
			display: none;
			justify-content: space-between;
			width: 100%;
			padding: 0 10px;
			bottom: unset;

			&_next {
				right: 0;
			}

			&_prev {
				left: 0;
			}

			&_next,
			&_prev {
				position: relative;
				margin: 0;
				top: unset;
				transform: none;

				.arrow-btn {
					display: none;
				}
			}

			&_controls {
				position: absolute;
				width: 100%;
				top: 50%;
				transform: translate(0, -50%);
			}
		}
	}
}

.b-content_asset--product-preorder {
    .pre-order-plp-label {
        @include t-title-h10;

        display: inline-block;

        .b-last_visited-item & {
            display: inline-block;
        }
    }
}

.b-category_top-banner {
	position: relative;
	width: 100%;
	height: 560px;
	max-width: 100%;
	overflow: hidden;

	@include respond-to(after-tablet) {
		height: 480px;
	}

	&_image {
		position: relative;
		display: block;
		min-width: 100%;
		min-height: 100%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.l-search_result {
	&-options {
		@include clearfix;

		position: relative;

		.b-change_view {
			flex-grow: 1;
			align-self: flex-end;
			margin-left: auto;

			&-list {
				display: flex;
    			align-items: center;
				float: right;
				font-size: 17px;
			}

			&-item {
				display: inline-block;
				margin: 0 15px;

				@include respond-to(tablet) {
					margin: 0;
				}
			}

			&-label {
				@include t-text-5;

				margin: 0;
			}

			&-type {
				@include t-text-6;

				width: 0;
				height: 0;
				padding: 0;
				margin: 0;
				border: none;
				color: $color_main;
				opacity: .4;
				cursor: pointer;

				&:hover,
				&:focus {
					opacity: 1;
				}

				&_two {
					&::before {
						content: $icon-two;
					}
				}

				&_three {
					&::before {
						content: $icon-three;
					}
				}

				&_four {
					&::before {
						content: $icon-four;
					}
				}

				&-active {
					@include t-text-5;

					opacity: 1;

					&.b-change_view-type_two {
						&::before {
							content: '2';
						}
					}

					&.b-change_view-type_three {
						&::before {
							content: '3';
						}
					}

					&.b-change_view-type_four {
						&::before {
							content: '4';
						}
					}
				}

				@include respond-to(tablet) {
					&_two {
						padding: 10px 0 10px 7px;
					}

					&_three {
						padding: 10px 0 10px 7px;
					}

					&_four {
						padding: 10px 7px 10px 0;
					}
				}
			}
		}
    }

	&-wrapper {
		text-align: center;

		.l-filter_dropdown {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 30px 40px 0;

			@include respond-to(tablet) {
				padding-bottom: 20px;
			}

			@include respond-to(vertical) {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}

    &-paging-controls {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 55px 50px 20px;
		text-align: center;

        &-loaded {
            @include t-text-6;

			order: 2;
        }

		&-links {
			display: flex;
			justify-content: center;
		}

        &-link {
            @extend %g-button-primary;

			margin-bottom: 40px;
        }

        & + .loader {
            position: absolute;
            top: auto;
            right: auto;
            bottom: -5px;
            width: 100%;
            height: 250px;
            opacity: 1;

			.loader-indicator {
				margin-top: 20px;
			}

            @include respond-to(all) {
                left: -90px;
            }

            .loader-bg {
                height: 100%;
            }
        }

        .next_page_load_progress {
            visibility: hidden;
        }
    }
}

.b-scroll_to_top {
    position: fixed;
    right: 40px;
    bottom: 60px;
    width: 48px;
    height: 48px;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    transition: opacity 0.5s, background-color 0.5s;
    border-radius: 50%;
    font-size: 17px;
    line-height: 48px;
    text-align: center;
    z-index: 2;

    @include icon(before);

    &:hover {
        background-color: $color_second;
        color: $color_main;
    }

    &::before {
        content: icon-char(arrow-up);
    }

    &.h-opaque {
        opacity: 1;
        visibility: visible;
    }
}

.b-infinite_scroll {
    @include loading-icon-indicator;

    width: 30px;
    height: 30px;
    margin: 0 auto;
}

p.fancybox-error {
    @include t-text-6;

    padding: 0 34px;
    margin: 0;
    text-align: center;
}

.b-slot-grid {
	&_header {
		margin: 0 auto;
		padding: 0 5px;
		line-height: 20px;
	}

    &_bottom {
        position: relative;
        width: 100%;
        margin: 0 auto;

        @include respond-to(all) {
            left: auto;
        }

        .plp-block-bottom {
            &-wrapper {
                padding: 0 85px 97px;
                text-align: center;

                @include respond-to(all) {
                    padding: 0 45px 97px;
                }
            }

            &-title {
                @include t-title-h3;

                padding-bottom: 19px;
            }

            &-txt {
                @include t-title-h5;
            }
        }

        .plp-last-view-wrapper {
            width: 480px;
            margin: 0 0 94px 75px;

            .b-product_last-visited {
                margin: 0 auto;
            }

            .b-last_visited-title {
                @include t-title-h6;

                margin: 0 0 14px;
            }

            .b-product_name,
            .b-productile_hover .b-quickview {
                display: none;
            }

            .b-owl_carousel {
                &-nav_prev {
                    left: -38px;
                }

                &-nav_next {
                    right: -38px;
                }

                &.owl-text-select-on {
                    .b-owl_carousel-item {
                        margin: 0 !important;
                        padding: 0 10px;
                        text-align: center;
                    }
                }

                .b-product_tile {
                    width: 100%;
                    margin: 0;
                }

                .b-product_price {
                    margin: 27px 0 0;
                }
            }
        }
    }
}

.banner {
	&-image-container {
		margin-bottom: 20px;

		img {
			display: block;
			width: 100%;
		}
	}

	&-container {
		margin: 0 auto;
		max-width: 860px;
		text-align: center;
	}

	&-title {
		@include t-text-2;

		text-transform: initial;
	}

	&-seotext {
		@include t-text-6;

		margin: 15px auto 34px;
	}

	&-links {
		display: flex;
		justify-content: center;
	}

	&-link {
		@extend %g-button-primary;

		margin-bottom: 0;

		&:not(:last-child) {
			margin-right: 30px;
		}
	}
}

.l-category-banner {
    &_bottom {
        width: 350px;
        margin: 65px auto 0;
    }

    &_bottom,
    &_top {
        text-align: center;
        font-size: 0;

        &_heading {
            @include t-title-h2;

            margin: 0;
            text-align: center;
        }

        &_text {
            @include t-title-h4;
        }

        &-image {
            float: left;
            width: 100%;

            img {
                width: 100%;
            }
        }

        &-description {
            margin: 0 auto;
            width: 80%;
            padding-top: 21px;
            clear: both;
        }
    }

    &_top {
        @include clearfix;

        margin: 0 0 0 80px;

        &_text {
            max-width: 350px;
            margin: 0 auto;
        }
    }
}

.b-seo-text {
	@include font_main(28px, 40px, $font_second);

	max-width: 924px;
	margin: 0 auto;
	padding: 55px 14px 65px;
	text-align: center;

	h1 {
		@include t-title-h1-secondary;
	}

	h2 {
		@include t-title-h2-secondary;
	}

	h3 {
		@include t-title-h3-secondary;
	}

	p {
		font: inherit;
	}
}
