/*
## Module: Constructor

Example:
...
<section class="b-module_constructor b-module_constructor--<module_type>">
	<div class="b-module_constructor-content"> //Optional wrapper (for module padding)
		<div class="b-module_constructor-bg">
			<img class="b-module_constructor-bg-image" src="#" />
		</div>
		<h3 class="b-module_constructor-title">Module Title</h3>
		<p class="b-module_constructor-text">Module text</p>
		<div class="b-module_constructor-items">
			<div class="b-module_constructor-item m-<item_variation>">
				<div class="b-module_constructor-item">
					<div class="b-module_constructor-item-content">
						<div class="b-module_constructor-item-image">
							<img src="#" />
						</div>
						<h4 class="b-module_constructor-item-title">Item Title</h4>
						<h4 class="b-module_constructor-item-text">Item text</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

Module Structure
1) SCSS files (general styles, effects, variations, text_variations etc). Mandatory
2) JS files (moduleConstructor), OneApp level. Can provide extended functionality for effects and variations. Optional
3) Grid System and Utility classes, Bootstrap GridCSS (general) and Flexbox (secondary)  Grid Systems, Utilities and classes
4) Brand level for module customization - using specific brand mixins, styles etc

*/
.b-module_constructor {
	$module: &;
	$max-overlaid-items: 2;

	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	text-align: initial;

	&.b-feedpage-folder {
		&:first-child {
			border-top: 1px solid $grey2;
		}

		&:not(:last-child) {
			border-bottom: 1px solid $grey2;
		}
	}

	&-item-bg,
	&-desktop-item_bg,
	&-bg,
	&-desktop_bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		z-index: -1;

		&-video,
		&-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: all .5s linear;
		}
	}

	&-header {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		width: 100%;
		padding: 40px 40px 20px;

		&-link {
			@extend %g-button_link;
			@include t-title-h6-h7;

			display: inline-block;
			flex-shrink: 0;
		}

		#{$module}-title {
			flex-grow: 1;
			margin: 0;
			padding: 0;

			&:not(:last-child) {
				margin-bottom: 0;
			}
		}

		.s-landingpage & {
			padding: 40px;
		}
	}

	&-content {
		width: 100%;
		padding: 40px;

		.s-landingpage & {
			padding: 40px 40px 80px;
		}
	}

	&-title {
		@include t-title-h1-secondary;

		width: 100%;
		margin: 40px 0 20px;
		padding: 0 40px;
		text-align: center;
	}

	&-text {
		width: 100%;
	}

	&-items {
		width: 100%;

		&.grid {
			grid-template-rows: minmax(var(--item-minheight), max-content);
			gap: 20px;
		}
	}

	&-item {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		grid-row: var(--row-index, auto);

		&.m-overlaid {
			z-index: 1;

			@for $i from 1 to 6 {
				&.g-start-#{$i} + .g-start-#{$i + 1} {
					#{$module}-item-indent {
						padding-left: calc(100vw / #{$grid-columns});
					}
				}
			}
		}

		@for $i from 1 to $max-overlaid-items {
			&.m-overlaid-#{$i} {
				z-index: $i;
			}
		}

		&-content {
			width: 100%;
			padding: 40px;

			& > :last-child {
				margin-bottom: 0;
			}
		}

		&-title {
			@include t-title-h3;

			margin-bottom: 10px;
		}

		&-text {
			@include font_main(18px, 24px);
		}

		&-links {
			display: flex;
		}

		&-video,
		&-image {
			&,
			img {
				display: block;
				width: 100%;
			}
		}

		& &-link {
			@include t-title-h6-h7;

			display: inline-block;
			flex-shrink: 0;
			align-self: self-start;
			margin-top: 40px;
			margin-right: 20px;

			&:last-child {
				margin-right: 0;
			}
		}

		& &-links &-link {
			margin-top: 0;
			margin-bottom: 0;
		}

		&-stickers {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
		}
	}

	&-navigation {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 20px 40px;
  		gap: 64px;

		&-item {
			&-link {
				@include t-text-5;

				border-bottom: none;
			}
		}
	}

	.b-videomgr {
		height: 100%;

		iframe {
			display: block;
			width: 100% !important;
			margin: 0;
		}
	}

	.l-search_result-content & {
		min-height: 0;
	}

	//Widgets carousels
	.b-owl_carousel-item,
	.b-owl_carousel .b-showcase,
	.owl-stage,
	.owl-stage-outer,
	.owl-stage-outer img {
		height: 100%;
	}

	.b-owl_carousel-item {
		img {
			object-fit: cover;
		}
	}

	[data-carousel-type='products'] {
		display: flex;

		.owl-stage {
			display: flex;
			right: 100px;

			.b-product_variations--plp {
				display: none !important; // overwrite js inline styles
			}

			.b-product_tile {
				width: 100%;

				&-body {
					&-main {
						display: flex !important;// overwrite js inline styles
					}
				}
			}
		}

		.b-product_tile {
			width: calc(100% / 3.5);

			&-body {
				&-main {
					display: flex !important;// overwrite js inline styles
				}
			}
		}
	}

	[data-carousel-type='assets'] {
		.b-showcase {
			&-title {
				&:not(:last-child) {
					margin-bottom: 32px;
				}
			}

			&-content {
				padding: 24px 0 0;
			}
		}
	}

	[data-carousel-type='assetsShifted'] {
		padding-right: 0;
		padding-left: 80px;

		.owl-stage {
			right: 100px;
		}
	}

	[data-carousel-type='singleAssets'] {
		@include carouselDotsBordered;

		.b-showcase {
			&-title {
				@include t-title-h6-h7(uppercase);
			}

			&-text {
				@include t-title-h3-secondary;
			}
		}
	}

	//Images without SRC / Lazy Load
	img:not([src]) {
		@include imgWithoutSrc();
	}
}

.b-showcase {
	$showcase: &;

	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	text-align: center;

	.l-search_result-content & {
		z-index: 1;
	}

	.b-feedpage-container & {
		justify-content: initial;
    	width: 100%;
	}

	&.m-inner {
		#{$showcase} {
			&-media {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: -1;
			}
		}
	}

	&-media {
		position: relative;
		flex: 1;
		width: 100%;
		height: 100%;
		overflow: hidden;

		&.m-square {
			aspect-ratio: 1/1; /* stylelint-disable-line */
		}

		&-inner {
			height: 100%;
			overflow: hidden;
		}

		&-banner {
			height: 100%;
		}

		&-header-link {
			@extend %g-button_link;
		}

		picture {
			height: 100%;
		}
	}

	&-video,
	&-image {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: transform .5s linear;
	}

	&-text {
		width: 100%;
	}

	&-title {
		@include t-text-4;

		width: 100%;

		&:not(:last-child) {
			margin-bottom: 24px;
		}
	}

	&-content {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 40px;

		> p:last-child {
			margin-bottom: 0;
		}
	}

	&-links {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
		margin-top: 25px;
	}

	&-link {
		@include t-title-h6-h7;

		flex-shrink: 0;
		align-self: self-start;
		margin-right: 30px;

		&:last-child {
			margin-right: 0;
		}
	}
}
