.feed-landing {
	.b-breadcrumb {
		display: flex;
		justify-content: center;

		&-item {
			a {
				border-bottom: 1px solid transparent;

				&:hover {
					border-color: currentColor;
				}
			}
		}
	}
}

.b-feedpage {
	$feedpage: &;

	$gap: 16;
	$feature-fullimage-ar: map-get(map-get($configVariation, "aspectRatio"), "rect-5");
	$twin-img-ar: map-get(map-get($configVariation, "aspectRatio"), "rect-3");
	$double-img-ar: calc((442 * 2 + #{$gap}) / 442); //From mockup

	&-container {
		.l-search_result-paging {
			margin: 0 auto;

			&-controls-link {
				@extend %g-button-secondary;

				&:not(:last-child) {
					margin-right: 10px;
				}
			}
		}


		#{$feedpage}-row.grid {
			gap: #{$gap}px;

			&.cols-2,
			.cols-2 & {
				--bs-columns: 2;
			}

			&.cols-3,
			.cols-3 & {
				--bs-columns: 3;
			}

			&.cols-4,
			.cols-4 & {
				--bs-columns: 4;
			}
		}
	}

	&-folder {
		&--feature {
			.b-feedpage-item.m-featured {
				.b-showcase-content {
					flex-flow: initial nowrap;
					justify-content: space-between;
					align-items: center;
					padding: 20px 40px;
				}

				.b-feedpage-item-body {
					@extend %title-sm-3;

					width: auto;
					margin: 0;

					&:not(:last-child) {
						margin-bottom: 0;
					}
				}

				.b-feedpage-item-readmore {
					margin-top: 0;
				}

				img {
					clip-path: initial;
				}
			}
		}
	}

	&-item {
		&.preview-double {
			.b-feedpage-folder-preview & {
				grid-column: auto/span 2;

				.b-showcase-media {
					padding-bottom: calc(1 / #{$double-img-ar} * 100%);
				}
			}
		}

		&.tile-double {
			.b-feedpage-folder-tile & {
				grid-column: auto/span 2;

				.b-showcase-media {
					padding-bottom: calc(1 / #{$double-img-ar} * 100%);
				}
			}
		}

		&.m-fullwidth_image {
			grid-column: 1 / -1;

			.b-showcase-media {
				padding-bottom: calc(1 / #{$feature-fullimage-ar} * 100%);

				img {
					object-position: top;
				}
			}
		}

		& &-content {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			height: 100%;
			padding: 24px;

			#{$feedpage}-item-title {
				@include t-title-h4-secondary;

				border: 0;
			}
		}

		& &-image {
			position: relative;
			flex-grow: 0;
			padding-bottom: 100%;

			.b-feedpage-folder--twin & {
				padding-bottom: calc(1 / #{$twin-img-ar} * 100%);
			}

			img {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				object-position: top;
			}
		}

		&-date {
			@include t-title-h6-h7;

			margin-bottom: 16px;
		}

		&-readmore {
			@extend %g-button_link;

			margin-top: auto;
		}
	}
}
