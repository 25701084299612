// responsive breakpoints
$break-small-tablet: 655px;
$break-tablet: 768px;
$break-big-tablet: 960px;
$break-small: 1008px;
$break-large: 1024px;
$break-custom: 1100px;
$break-nav: 1340px;
$break-laptop: 1366px;
$break-until-laptop: 1365px;
$max-width: 2560px;
$nav-max-width: 1440px;


@mixin respond-to($media) {
	/* [0...655] */
  @if $media == sm-tablet {
    @media screen and (max-width: $break-small-tablet - 1) { @content; }
  }

	/* [0...767] */
  @if $media == tablet {
    @media screen and (max-width: $break-tablet - 1) { @content; }
  }

  @if $media == after-tablet {
    @media screen and (max-width: $break-tablet + 1) { @content; }
  }

  /* [0...1007] */
  @if $media == vertical {
    @media screen and (max-width: $break-small - 1) { @content; }
  }

  /* [768...1024] Retina Display */
  @if $media == tablet-retina {
    @media screen and (min-width: $break-tablet - 1) and (max-width: $break-large - 1) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) { @content; }
  }

  /* [1008...1024] */
  @if $media == horizontal {
    @media screen and (min-width: $break-small) and (max-width: $break-large) { @content; }
  }

  /* [0...1024] */
  @if $media == all {
    @media screen and (max-width: $break-large) { @content; }
  }

  /* [0...1340] */
  @if $media == nav {
    @media screen and (max-width: $break-nav - 1) { @content; }
  }

  /* [1340...2560] */
  @if $media == withnav {
    @media screen and (min-width: $break-nav) and (max-width: $max-width) { @content; }
  }

  /* [0...1365] */
  @if $media == until-laptop {
    @media screen and (max-width: $break-until-laptop) { @content; }
  }

  /* [0...1366] */
  @if $media == laptop {
    @media screen and (max-width: $break-laptop) { @content; }
  }

  /* [1366...2560] */
  @if $media == after-laptop {
    @media screen and (min-width: $break-laptop + 1) and (max-width: $max-width) { @content; }
  }

  /* [CUSTOM] */
  @if $media == custom {
    @media screen and (max-width: $break-custom) { @content; }
  }

  @if $media == portrait_tablet {
	@media screen and (max-width: $break-big-tablet), (orientation: portrait) { @content; }
  }

  @if $media == to_portrait_tablet {
	@media screen and (min-width: $break-big-tablet+1) { @content; }
  }

  @if $media == landscape_tablet {
    @media screen and (device-width: $break-large) and (device-height: $break-tablet), (orientation: landscape) { @content; }
  }

  @if $media == desktop {
  @media screen and (min-width: $break-large) and (max-width: $max-width) { @content; }
  }

  @if $media == nav_fullwidth {
	@media screen and (max-width: $nav-max-width) { @content; }
  }
}
