.s-splashpage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    width: 100%;
}

.l-splashpage {
    flex-grow: 5;

    &-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;

        img {
            height: 30px;
        }
    }

    &-footer {
        @include font_main(10px, 16px);

        width: 100%;
        padding: 20px;
        border-top: $border_main;
        text-align: center;
    }
}

.b-splashpage {
    &-container {
        width: 100%;
        max-width: 1020px;
        margin: 0 auto;
        padding: 0 20px;
    }

    &-description {
        text-align: center;

        h1 {
            @include font_main(20px, 28px);

            margin: 25px 0 10px;
            font-weight: 350;
        }

        p {
            @include font_main(14px, 22px);

            margin: 0 0 10px;
        }
    }

    &-region {
        margin-top: 40px;
        text-align: left;
    }

    &-title {
        @include font_main(18px, 26px);

        margin: 0 0 10px;
        font-weight: 350;
    }

    &-list {
        column-count: 3;
        column-gap: 20px;
        list-style: none;
    }

    &-country {
        margin: 0 0 10px;

        &-link {
            @include font_main(14px, 22px);
            @include underline_on_hover_only;

            display: inline-block;

            &:hover {
                color: $color_second;
            }

            &:not(:last-child) {
                &::after {
                    content: '|';
                    margin-left: 4px;
                    color: $color_main;
                }
            }
        }
    }
}
