@include keyframe(slideDownMaxHeight) {
	0% {
		max-height: 0;
	}

	100% {
		max-height: 999px;
	}
}

@include keyframe(slideUpMaxHeight) {
	0% {
		max-height: 999px;
	}

	100% {
		max-height: 0;
	}
}

@include keyframe(progressBarLoading) {
	0% { width: 0; }
	100% { width: 100%; }
}

@include keyframe(slideInRight) {
	from {
		transform: translate3d(100%, 0, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@include keyframe(slideOutRight) {
	0% { transform: translate3d(0, 0, 0); }

	100% {
		transform: translate3d(100%, 0, 0);
	}
}

@include keyframe(placeholderAnimation) {
	0% {
		color: $black;
		opacity: 1;
	}

	50% {
		opacity: 0;
		color: $gold;
	}

	100% {
		opacity: 1;
		color: $black;
	}
}
