@mixin sliding-pills(
	$bg-active: $black,
	$bg: transparent,
	$border-radius: 25px,
	$border-color: $color,
	$button-min-width: 67px,
	$color: $gray,
	$color-active: $white
) {
	.b-sliding-pills {
		margin: 20px 0 15px;

		&_headings {
			--animationOffsetLeft: 0;
			--animationOffsetRight: 0;
			--animationDuration: 0.2s;
			--currentElWidth: auto;

			position: relative;
			display: inline-flex;
			max-width: 600px;
			height: 35px;
			text-align: center;
			border-radius: $border-radius;
			border: 1px solid $border-color;
			background-color: $bg;

			&::before {
				content: '';
				position: absolute;
				width: var(--currentElWidth);
				top: 0;
				left: 0;
				height: var(--parentHeight);
				transform: translateX(var(--animationOffsetLeft));
				background-color: $bg-active;
				border-radius: $border-radius;
				transition: all .2s ease-out;
			}
		}

		&_heading {
			@include button_reset();

			display: flex;
			justify-content: center;
			align-items: center;
			min-width: $button-min-width;
			height: inherit;
			padding: 0 10px;
			background-color: transparent;
			border: none;
			border-radius: $border-radius;
			color: $color;
			text-transform: uppercase;
			transition: all 0.4s linear;
			z-index: 1;

			&.active {
				color: $color-active;
			}

			&:focus {
				outline: none !important;
				color: $color-active;
				background-color: transparent;
			}

			&:hover {
				background-color: transparent;
			}
		}
	}
}

@include sliding-pills(
	$bg-active: $grey1,
	$bg: #232222,
	$border-radius: 25px,
	$border-color: #232222,
	$button-min-width: 0,
	$color: #4d4d4d,
	$color-active: $grey1
);
