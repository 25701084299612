/*
## LAYOUT - Checkout Cart
*/

.l-checkout_cart {
	@include clearfix;

	float: left;
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	padding: 15px 0 8px;

	.sale-tax-account {
		display: none;
	}

	.f-field-radio {
		.f-radio {
			position: absolute;
			visibility: hidden;
		}
	}

	&-left {
		@include span(60%);

		position: relative;
		margin-right: 9%;
		z-index: 0;

		.sale-tax {
			&-icon,
			&-label {
				display: none;
			}
		}

		.b-checkout {
			&_billing_address,
			&_shipping_address,
			&_payment {
				.f-field-checkbox {
					float: right;
					width: 68%;
				}
			}

			&_placeorder-condition {
				margin: 0 0 20px;

				.f-error_message {
					display: inline-block;
					width: 100%;
				}

				.f-label {
					&::before {
						top: 2px;
					}

					&::after {
						top: 7px;
					}
				}
			}
		}

		.f-field-checkbox {
			.f-label {
				width: 100%;
			}
		}

		.b-empty_cart {
			@include t-title-h9;
		}

		.b-checkout_submit-button_container {
			padding-top: 20px;
			border-top: $border_main;
		}

		.b-cart_shipping_method,
		.b-number_of_items,
		.b-checkout_shipping_address,
		.b-checkout_billing_address,
		.b-checkout_payment,
		.b-cart_payment_method,
		.b-order_confirmation,
		.b-cart_coupon_code {
			@include clearfix;

			padding: 50px 0 0;
			border-top: $border_main;

			&-title {
				@include t-title-h3(uppercase);

				margin-bottom: 30px;
			}
		}

		.b-cart_giftemail {
			@include clearfix;

			padding: 47px 0 20px;
			border-top: $border_main;

			.f-field-checkbox {
				margin-bottom: 25px;
			}

			&-message {
				@include clearfix;

				clear: both;

				.f-field {
					margin-bottom: 27px;
				}

				.f-textarea {
					padding: 10px 19px 8px;
				}
			}

			&-message_instruments {
				@include t-title-h5;

				float: right;
				width: 68%;
				margin-top: -27px;
				text-align: right;
			}

			&-signature_instruments {
				display: none;
			}
		}

		.b-checkout_shipping_address .f-type-packstation .f-error_text {
			margin-top: 3px;
		}

		.b-number_of_items,
		.b-checkout_shipping_address,
		.b-order_confirmation {
			border-top: none;
		}

		.f-label + .f-field-wrapper,
		.f-type-address2 {
			float: right;
			width: 68%;
		}

		.f-type-phonecode {
			width: 46%;
			margin-right: 3.3%;

			.f-field-wrapper {
				width: 30%;
			}

			.f-label {
				width: 65%;
				margin-right: 5%;
			}

			.f-select {
				padding: 12px 15px;

				@include respond-to(all) {
					padding: 12px;
				}


				&-wrapper::after {
					right: 17px;

					@include respond-to(all) {
						right: 12px;
					}
				}
			}
		}

		.f-type-phone {
			width: 50.7%;
		}

		@at-root .b-checkout_shipping_address-country_select {
			@include font_main(12px, 43px, inherit, inherit, uppercase);

			float: right;
			width: 68%;
			min-height: 43px;
			padding: 0 12px;
			border: $border_main;

			.b-checkout_summary & {
				width: 100%;
				cursor: default;
			}

			&-change_link {
				float: right;
				margin: 12px 0 0;
				line-height: 13px;
			}
		}

		.b-checkout_billing_address-country_select {
			float: left;
			width: 68%;
			padding: 0 19px;
			border: $border_main;
			line-height: 48px;
			color: $color_second;
			font-size: 18px;
		}

		.b-checkout_payment-toc_block {
			@include clearfix;

			&-tooltip {
				float: right;
				width: 68%;
				margin-bottom: 20px;
			}

			.f-type-concent {
				display: block;
				margin-bottom: 15px;

				.f-label {
					float: none;
				}
			}
		}

		.b-checkout_button,
		.b-checkout_submit-button {
			@extend %g-button-primary;

			&.b-paypal_button_small_container {
				background: transparent;

				&,
				&:hover {
					border: none;
				}
			}
		}

		.b-checkout_button {
			margin-bottom: 0;
		}

		.b-mini_address {
			@include t-title-h9;
		}

		.b-payment_method-gift_certificate {
			@include t-title-h6;
		}
	}

	&-right {
		position: sticky;
		top: 0;
		float: right;
		padding: 0;
		width: 30% !important;

		.b-checkout_button {
			@extend %g-button-primary;

			margin: 0;

			&.b-paypal_button_big_container {
				margin-bottom: -25px;
				background: transparent;
				border-bottom: none;

				&:hover {
					border: 1px solid $color_second;
					border-bottom: none;
				}
			}
		}

		.b-checkout_termsandconditionsconsent {
			@include clearfix;

			margin: 20px 20px 0;

			.f-label {
				float: none;
				width: auto;

				&::before {
					top: 2px;
				}

				&::after {
					top: 7px;
				}
			}

			a {
				@include animated-underline;

				vertical-align: middle;
			}
		}

		.b-checkout_order_summary {
			float: right;
			width: 100%;
		}
	}

	.b-login_account {
		&_popup {
			position: relative;
			top: 0;
			height: auto;
			margin: -110px 0 0;
			background-color: $white;
		}

		&-title {
			display: none;
		}

		&-form {
			&_row {
				display: inline-block;
				width: 68%;
				margin-left: 32%;
				white-space: nowrap;
			}

			.f-field {
				width: 100%;
				margin: 0 0 30px;

				&-checkbox {
					width: 68%;
					max-width: 212px;
					margin-left: 32%;
				}
			}
		}

		&-login_button {
			position: static;
			margin: 0 0 20px;
		}

		&-forgot_password {
			position: relative;
			margin: -49px 0 0;
			z-index: 1;
		}
	}

	.b-login_create_account {
		&-description {
			padding: 10px 0 15px;
		}

		.f-label {
			margin: 0 0 10px;
			line-height: 18px;
		}
	}

	.largebutton {
		@extend %g-button-primary;

		width: 100%;
	}

	.b-product_tile {
		width: 48%;
		margin: 0 1% 40px;
	}

	.b-wishlistblock {
		position: relative;

		.b-login_account_popup {
			top: 37%;
		}
	}

	.b-checkout {
		&_placeorder-condition {
			a {
				@include animated-underline;

				vertical-align: middle;
			}
		}
	}
}

.l-continue_shopping_cart {
	padding: 16px 0 0;
	clear: right;

	.b-back_to_shopping {
		@include t-title-h7(uppercase);
		@include icon(before);

		position: relative;
		margin-left: 18px;
		border: 0;

		&::before {
			content: icon-char(arrow-left);
			position: absolute;
			left: -18px;
		}

		&-message {
			@include animated-underline;

			padding-top: 0;
		}
	}
}

.l-checkout_button_bottom {
	float: right;
	width: 40%;
	margin-top: 20px;

	.l-checkout_button {
		@include clearfix;
	}
}

/*
#BLOCK - hosted payment
*/
.b-hosted_payment_method {
	position: relative;
	overflow: hidden;

	&-list {
		position: relative;
		margin: 0;
		text-align: left;
		overflow: hidden;
	}

	&-item {
		width: auto;
		float: left;
		margin: 0 4px 9px;
	}

	&-img { /* stylelint-disable */
		width: $payment-icon-size;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: optimizeQuality;
		-ms-interpolation-mode: nearest-neighbor; /* stylelint-enable */
	}
}


/*
#BLOCK - Checkout Login
*/
.b-checkout_login {
	&-title {
		margin-bottom: 20px;
	}

	.g-button {
		@extend %g-button-primary;
	}
}

/*
#BLOCK - Cart Table
*/
.b-cart_table {
	$table: &;
	$product_line_controls_size: 16px;

	padding-bottom: 15px;

	&-header {
		position: relative;
		display: table;
		float: right;
		width: 100%;
		font-size: 12px;
		text-transform: uppercase;
		background: $white;
		z-index: 1;

		> span {
			@include t-title-h7(uppercase);

			padding: 12px 0;
			border-bottom: $border_main;

			@include respond-to(all) {
				letter-spacing: 0;
			}
		}

		&.is_stuck {
			#{$table}-cols {
				padding: 20px 0;
				border-bottom: 1px solid $color_second;
			}
		}
	}

	&-line_body:nth-child(2) {
		border-top: none;
	}

	&-line_body {
		padding: 55px 0 30px;
		border-top: $border_main;
	}

	&-rows {
		position: relative;
		display: table;
		width: 100%;
		margin-bottom: 39px;
	}

	&-cols {
		display: table-cell;
		width: 20%;
		text-align: center;
		vertical-align: top;
	}

	&-body_col {
		&_qty,
		&_price,
		&_tax,
		&_total_price,
		&_duti,
		&_tax-duties {
			@include t-title-h7;

			line-height: $product_line_controls_size;
			vertical-align: middle;
		}

		&_total_price {
			text-align: right;

			&-item_total_price {
				&-label {
					display: none;
				}

				&-price_unadjusted {
					text-decoration: line-through;
					color: $grey4;
				}

				&-value_promo_adjustment {
					@include custom_promo_adjustment;

					color: $bordeaux;
				}
			}
		}

		&_tax {
			text-align: right;
		}

		&_product-banzip_details > .f-form-error_message {
			margin-bottom: 20px;
		}
	}

	&-header_col {
		&_product {
			text-align: left;
		}

		&_qty,
		&_product,
		&_price,
		&_tax,
		&_total_price,
		&_duti {
			@include t-title-h9;

			padding-top: 0;
			padding-bottom: 19px;
			white-space: nowrap;
		}

		&_total_price,
		&_tax {
			text-align: right;
		}
	}

	&-body_col_product {
		@include t-title-h7;

		width: 30%;
		padding-bottom: 10px;
		text-align: left;

		&-product_name {
			max-width: 180px;
			word-break: break-word;

			&-link {
				@include t-text-6;
				@include animated-underline;
			}
		}

		&-sku {
			margin: 5px 0 0;
		}

		&-user_actions {
			display: flex;
			align-items: center;
			width: 100%;

			.b-add_to_wishlist_button {
				@include t-title-h7(uppercase);
				@include icon(before, wishlist-not-selected);
				@include animated-underline;

				position: relative;
				display: inline-block;
				min-height: 16px;
				margin: 0;

				&:hover span {
					text-decoration: none;
				}

				span,
				&::before {
					display: inline-block;
				}

				&-added_message {
					display: none !important;
				}

				&--added::before {
					color: $color_main;
				}
			}

			.b-remove_product_button,
			.b-cart_table-body_col_edit_details-edit_poput_link {
				@include t-title-h7(uppercase);
				@include animated-underline;

				display: inline-block;
				min-width: auto;
				margin: 0 0 0 50px;
				padding: 0;
				line-height: 18px;

				&:hover span {
					text-decoration: none;
				}
			}

			.b-remove_product_button {
				@include icon(before, close);

				@extend %g-button_link;

				width: auto;
				height: auto;
				border: 0;
				border-radius: 0;
				color: $color_main;

				&::before {
					font-size: inherit;
				}
			}
		}

		&-quantity_details {
			.b-product_availability_list {
				margin: 0;

				.b-product_availability_list-not_available:not(:empty) {
					margin-bottom: 20px;
				}

				&-not_available {
					@include t-title-h10;

					color: $validation-color;
				}
			}
		}
	}

	&-body_col_edit_details,
	&-body_col_bonus_product {
		width: auto;
		vertical-align: bottom;
	}

	&-body_col_edit_details {
		&-edit_poput_link {
			@include icon(before, pencil);
			@include underline_remove;

			cursor: pointer;

			&::before {
				line-height: 20px;
			}
		}

		&-edit_popup_wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 200px;
			padding: 20px;
			border: 1px solid $color_second;
			background: $white;
			z-index: 5;
		}

		&-label {
			display: none;
		}

		&-edit_product_image {
			margin: 5px 0 20px;
			text-align: center;

			> img {
				max-height: 113px;
			}
		}

		&-edit_product_color,
		&-edit_product_size {
			position: relative;

			.ie & {
				&::before {
					display: none;
				}
			}
		}

		&-edit_product_color {
			margin-bottom: 10px;
		}

		&-edit_product_size {
			margin-bottom: 20px;
		}

		&-close_button {
			@extend .fancybox-close;
		}

		button {
			@extend %g-button-primary;

			width: 100%;
			padding-left: 5px;
			padding-right: 5px;
			margin: 0;
		}
	}

	&-body_col_image {
		display: inline-block;
		width: auto;
		vertical-align: bottom;
		text-align: left;

		&-image {
			display: inline-block;

			.b-product_image {
				width: 100px;
				height: auto;
			}
		}
	}

	&-body_col_qty {
		&-item_quantity {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 9px;
			white-space: nowrap;
			font-size: 0;
			letter-spacing: 0;

			&-plus,
			&-minus {
				display: inline-block;
				width: $product_line_controls_size;
				height: $product_line_controls_size;
				vertical-align: top;
				font-size: 16px;
				cursor: pointer;
				line-height: 1;
				text-align: center;
			}

			&-plus {
				@include icon(after, plus);

				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
			}

			&-minus {
				@include icon(after, minus-long);
			}

			&-qty {
				@include t-title-h9;

				display: inline-block;
				padding: 0;
				border: none;
				cursor: default;
				text-align: center;
				outline: none;
				vertical-align: top;
				line-height: $product_line_controls_size;
			}
		}
	}

	&-product_callout_message {
		@include t-title-h5;

		display: none;
	}

	&-body_col_drop_ship {
		@include t-title-h4;
	}

	.b-product-additional_info {
		@include t-title-h4;

		margin-bottom: 39px;
		font-weight: 700;
	}
}

/* hiding unnecessary elements */
.b-product_availability_list-is_in_stock,
.b-cart_order_total {
	display: none;
}

/*
#BLOCK - Shipping Method
*/
.b-cart_shipping_method {
	@include init-radiobutton;

	padding: 0 0 30px;
	margin: 0;

	.f-field-radio {
		margin-top: 5px;
	}

	&-list {
		@include clearfix;

		&_wrapper {
			margin-top: 20px;

			@include clearfix;
		}
	}

	&-select_block {
		@include clearfix;

		padding: 0 0 30px;

		.f-label-value {
			@include t-title-h9;
		}

		.f-select-wrapper {
			position: relative;

			&::before {
				height: 30px;
				right: 4px;
				top: 4px;
				background-position: 0 12px;
			}
		}
	}

	&-info {
		width: 95%;
		margin-bottom: 20px;

		.b-content_asset {
			display: none;
		}
	}

	&-radio,
	&-label,
	&-value {
		@include t-title-h7;

		display: inline-block;
		vertical-align: middle;
		white-space: normal;
	}

	&-label {
		padding-left: 13px;
		width: 57%;
	}

	&-radio {
		width: 30%;

		.f-label-value,
		.f-label {
			width: auto;
			text-transform: uppercase;
		}
	}

	&-value {
		float: right;
		width: 13%;
		text-align: right;

		.b-cross_shipping-cost {
			margin-right: 5px;
			text-decoration: line-through;
			color: $color_second;
		}
	}

	&-no_shipping {
		margin-bottom: 30px;
	}
}

/*
#BLOCK - Gift Certificate
*/
.b-cart_gift_certificate {
	@include clearfix;

	padding: 47px 0 37px;

	.f-field-checkbox {
		width: 507px;
		float: left;
	}

	.f-field-checkbox .f-label {
		padding-left: 22px;
	}

	&-check_label {
		@include font_main(12px, 21px, inherit, inherit, uppercase);

		padding-left: 10px;
	}

	.b-content_asset--gift-wrap-heading {
		font-size: 12px;
		line-height: 18px;
	}

	&-label,
	.b-content_asset--gift-wrap-image {
		display: none;
	}

	&-info {
		width: 80px;
		float: right;
		text-align: right;
	}

	&-value {
		display: none;
	}

	&-message {
		margin-top: 15px;
		clear: both;

		textarea {
			width: 100%;
			height: 150px;
			resize: none;
		}
	}

	&-message_instruments {
		margin-top: -14px;
		text-align: right;
	}
}

.gift-cert-delivery {
	@include t-title-h5;

	margin: 0;
	padding: 10px 0;
}

/*
#BLOCK - login popup
*/
.b-cart_payment_method,
.b-wishlistblock {
	.l-checkout_cart & .b-login_account_popup {
		position: absolute;
		top: -1px;
		left: 0;
		width: 100%;
		padding: 40px;
		background-color: $white;
		border: $border_main;
		z-index: 40;

		.b-login_account {
			&-login_button {
				display: inline-block;
			}

			&-title {
				font-size: 12px;
			}

			&-required_indicator {
				font-size: 10px;
			}

			&-form_fildset_container {
				@include clearfix;
			}
		}

		.f-field {
			margin: 0 18px 20px 0;

			&-email,
			&-password,
			&-wrapper {
				width: 100%;
			}
		}

		.f-label {
			width: auto;
			margin: 0 0 12px;
			line-height: 16px;
		}

		&-close {
			@include icon(after, close);

			position: absolute;
			top: 16px;
			right: 20px;
			cursor: pointer;
			font-size: 14px;
			line-height: 20px;
		}
	}
}

/*
#BLOCK - Payment Method
*/
.b-cart_payment_method {
	@include init-radiobutton;

	position: relative;

	.b-cart_payment_method-list {
		margin-bottom: 30px;
	}

	.f-field-radio {
		margin-top: 5px;

		.f-label {
			width: auto;
		}
	}

	.b-cart_payment_method-label_text {
		.clarna_logo {
			margin-right: 10px;
		}
	}

	&-info {
		width: 90%;
		padding: 20px 0;
		font-size: 12px;

		&_credit {
			@include clearfix;

			position: relative;
			margin: 0;
			padding-bottom: 40px;
			font-size: 0;

			&-left,
			&-center,
			&-right {
				display: inline-block;
				vertical-align: middle;
			}

			&-left {
				width: 60%;

				.b-cart_payment_method-title {
					margin-bottom: 0;
					padding-bottom: 0;
					text-transform: uppercase;
					font-size: 10px;
					line-height: 16px;
				}
			}

			&-left_block {
				display: inline-block;
				width: 65%;
				vertical-align: middle;
			}

			&-center {
				width: 40%;
				padding: 0 10px;
			}

			&-right {
				width: 35%;
			}

			&_button {
				@include g-button;

				float: right;
				margin-bottom: 0;
			}

			&-link {
				@include t-title-h9;

				vertical-align: middle;
				cursor: pointer;
			}
		}
	}

	.b-login_account {
		&-form_row {
			position: relative;
			width: 100%;
			margin: 0;
			z-index: 1;
		}

		&-forgot_password {
			margin: -39px 0 0 8px;
		}
	}

	div[data-model-basket="storeCreditBalance"] {
		float: left;
		width: 50%;
	}

	&-donthavestorecredit {
		width: 50%;
	}

	&-donthavestorecredit,
	&-yourstorecredit {
		@include t-title-h5;

		float: left;
	}

	&-yourstorebalance {
		@include font_main(18px, 18px, inherit, inherit, uppercase);

		float: right;
		text-align: right;
	}

	.b-payment_method-gift_certificate {
		clear: left;
		margin-top: 20px;
		font: 12px $font_main;
	}

	&-credit_tooltip {
		@include t-title-h11;

		width: 300px;
	}

	&-list_wrapper {
		@include clearfix;

		display: flex;
		flex-wrap: wrap;
		margin-bottom: 15px;
		font-size: 0;

		&:first-child {
			padding-top: 30px;
			border-top: $border_main;
		}
	}

	&-radio,
	&-label,
	&-value {
		@include t-title-h7;

		display: inline-block;
		margin: 0;
		vertical-align: top;

		p {
			@include t-title-h7;
		}
	}

	&-radio {
		width: 30%;
	}

	&-label {
		width: 55%;
		padding: 0 20px;
	}

	&-value {
		width: 15%;
		text-align: right;
		text-transform: uppercase;
	}

	&-information {
		@include t-title-h7;

		margin-bottom: 5px;
		padding-left: 25px;
		color: $beige;
	}
}

/*
#BLOCK - Coupone Code, Gift Card
*/
.b-cart_coupon_code,
.b-cart_gift_certificate_code {
	padding-bottom: 20px;
	border-bottom: $border_main;

	&-title {
		@include t-title-h6;

		position: relative;
		text-transform: uppercase;
		cursor: pointer;

		&.b-checkout_coupon_block-toggle_title {
			&--open,
			&--close {
				&::after {
					position: absolute;
					top: 0;
					right: 0;
					font-size: 20px;
				}
			}

			&--open {
				@include icon(after, minus);

				&::after {
					top: -2px;
				}
			}

			&--close {
				@include icon(after, plus);
			}
		}
	}

	.f-label {
		display: none;
	}

	&-button {
		@extend %g-button-primary;

		max-width: none;
	}

	&-wrapper {
		@include clearfix;
	}

	&-left {
		@include span(70%);

		padding-right: 10px;
	}

	&-right {
		float: right;
		width: 29%;
	}

	&-code {
		.f-field-textinput {
			margin-bottom: 0;
		}

		.f-textinput {
			margin-bottom: 5px;
		}
	}

	&-info_link {
		@include underline_remove;

		text-transform: uppercase;
		font-size: 12px;
	}

	&-applied {
		@include t-title-h5;

		clear: both;
		margin: 5px 0 0;
		padding: 5px 0 0;

		&-list,
		&_list,
		.b-cart_coupons-applied-list {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
			font-size: 0;
		}

		&-name,
		&-price,
		&_name,
		&_price {
			@include t-title-h11;

			display: inline-block;
			vertical-align: middle;
		}

		&-name,
		&_name {
			width: 50%;
		}

		&-price,
		&_price {
			width: 30%;
		}

		&-remove,
		&_remove {
			@extend %g-button-primary;

			float: right;
			height: 30px;
			min-width: 100px;
			width: auto;
			margin: 0;
		}
	}

	.f-form-error_message {
		clear: both;
	}
}

.b-cart_gift_certificate_code {
	padding-top: 45px;

	.f-field .f-field-wrapper {
		width: 100%;
	}
}

.b-cart_coupon_code {
	&-title {
		font-weight: 500;
	}

	&-code {
		.f-field .f-field-wrapper {
			width: 100%;
		}
	}
}

form.b-cart_pre_order-wrapper {
	@include clearfix;

	margin-bottom: 40px;
	padding-bottom: 35px;
	border-bottom: $border_main;
}

.b-cart_pre_order {
	&-title {
		margin: 0 0 16px;

		@include t-title-h6;
	}

	&-description {
		@include t-title-h8;

		.b-content_asset {
			margin-bottom: 24px;
		}
	}

	&-checkbox {
		.f-field {
			@include clearfix;

			&-checkbox {
				margin-bottom: 0;
			}
		}

		.f-error_message {
			float: left;
		}
	}
}

/*
#BLOCK - Checkout Content Formatting
*/
.b-checkout_content_block {
	padding: 34px 0 20px;
	border-top: none;

	& + .b-checkout_content_block {
		padding-top: 10px;
	}

	&-toggle_title {
		@include t-title-h8(uppercase);

		position: relative;
		overflow: hidden;
		cursor: pointer;
		font-weight: 350;

		&::before {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 20px;
		}

		&--open {
			@include icon(before, minus);
		}

		&--close {
			@include icon(before, plus);

			&::before {
				top: -2px;
			}
		}
	}

	&-faq_questions {
		margin: 10px 0;
		padding: 0;
		list-style-type: none;
		list-style-position: inside;

		&-link {
			@include t-text-6;

			border-bottom: none;
		}

		&-li {
			margin: 20px 0 0;
		}
	}

	&-table {
		padding: 17px 0;
		font-size: 0;
		text-align: center;

		&-col {
			display: inline-block;
			width: 50%;
			vertical-align: top;
			font-size: 12px;
		}
	}

	&-title {
		@include t-text-6;

		padding: 0 0 27px;
	}

	&-text {
		&:first-child p {
			@include t-text-6;
		}

		p {
			margin-bottom: 14px;
		}
	}

	&-icon {
		&_block {
			img {
				display: none;
			}

			a {
				@include icon(before);

				display: block;
				border-bottom: none;

				&::before {
					display: block;
					margin: 0 auto 3px;
				}
			}
		}

		&_chat::before {
			content: icon-char(headphones);
			font-size: 38px;
		}

		&_mail::before {
			content: icon-char(mail);
			font-size: 30px;
		}
	}

	.f-label {
		width: auto;
	}

	.b-login {
		&_create_account {
			& > h3 {
				@include t-title-h6;
			}

			&-description {
				@include t-title-h8;
			}
		}
	}
}

.b-upsaplocator {
	clear: both;
	margin-bottom: 20px;

	&_selected-address {
		margin-left: 32%;
	}

	&-change {
		margin: 20px 0 20px 32%;
	}

	&_map {
		width: 100%;

		&.location-loader {
			height: 30px;
		}

		.b-upsaplocator {
			&_map-block {
				display: inline-block;
				width: 74%;
				height: 400px;
				margin: 10px 0 15px;
			}

			&_storelist {
				position: relative;
				display: inline-block;
				width: 25%;
				height: 400px;
				margin: 10px 0 15px;
				padding-right: 16px;
				overflow: hidden;

				&-wrap {
					height: 400px;
					overflow: hidden;
				}

				.b-scroll-bar {
					top: 0;
					width: 16px;

					&_outer {
						top: 0;
						width: 16px;
					}
				}
			}
		}
	}

	&-store {
		padding-bottom: 10px;

		&:hover {
			cursor: pointer;

			.b-upsaplocator {
				&_address {
					&-name {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&_address {
		&-name {
			font-weight: 350;
			text-transform: capitalize;
		}

		&-row {
			display: block;
		}
	}

	.b-storelocator {
		position: relative;

		&-search_zip {
			display: block;
			width: 100%;

			.f-field {
				display: flex;

				.f-field-wrapper {
					width: 40%;
					float: left;
				}
			}

			&_error {
				margin-left: 32%;

				&_text {
					margin: 0;
					color: $validation-color;

					@include t-title-h5;
				}
			}

			.f-label-value {
				@include t-title-h11;
			}
		}

		&-search_action {
			position: absolute;
			display: block;
			right: 0;
			top: 0;
			width: 26%;
			padding-top: 0;
			text-align: center;

			a {
				@extend %g-button-primary !optional;

				margin-bottom: 0;
				line-height: 40px;

				&:hover {
					background-color: $white;
					color: $color_main;
				}
			}
		}
	}
}

/*
## Checkout Cart us
*/
.sales-tax {
	&-error {
		@include t-title-h5;

		color: $validation-color;

		p {
			margin: 0 0 15px;
		}
	}

	.b-cart_table {
		&-header,
		&-body {
			&_col_product {
				width: 24%;
			}

			&_col_price {
				text-indent: 25px;
			}
		}

		&-cols.b-cart_table-header_col_price {
			text-indent: 0;
		}
	}

	.sale-tax {
		&-icon {
			float: left;
			clear: left;
			margin-top: 2px;
			font-size: 0;

			@include icon(before);

			&::before {
				content: icon-char(lows-in-stock);
				display: block;
				font-size: 16px;
				line-height: 18px;
				cursor: pointer;
			}
		}

		&-content {
			position: relative;

			.b-content_asset--checkout-sale-tax-info {
				position: absolute;
				top: 15px;
				left: -397px;
				width: 393px;
				padding: 30px 20px;
				text-align: center;
				border: 1px solid $color_second;
				background: $white;
				line-height: 20px;
				z-index: 99;
			}
		}

		&-label {
			@include font_main(10px, 18px);

			margin: 2px 0 0 8px;
			color: $color_main;
		}
	}

	> form {
		clear: both;
	}

	.b-checkout_addresses {
		&-shipping,
		&-billing {
			padding: 40px 0;
			float: left;
			margin-top: 40px;
			border-top: 1px solid $color_second;

			h3 {
				@include font_main(12px, 1, inherit, inherit, uppercase);

				margin-bottom: 19px;
			}
		}

		&-shipping {
			width: 63%;
		}

		&-billing {
			width: 37%;

			.b-mini_address-country {
				display: inline-block;
			}
		}

		&-edit {
			@include font_main(12px, 1, inherit, inherit, uppercase);
		}
	}
}

.b-preorder_notifyme_block-button {
	float: right;
	padding-left: 17px;
	margin: 20px 0 20px 10px;
}

.b-content_asset--new_checkout_login {
	padding: 25px 0 0;
}

/*
#BLOCK - Checkout button with loader on submit
*/

.l-checkout_cart-left,
.l-checkout_cart-right {
	.b-checkout_button.b-submitted,
	.b-checkout_submit-button.b-submitted {
		position: relative;
		background: none;
		color: $black;
		opacity: .4;
		outline: 0;

		.loader {
			position: absolute;

			&-indicator {
				margin-top: 12px;
			}
		}
	}
}

.sale-tax-info {
	&-icon {
		@include icon(before);

		float: left;
		width: 16px;
		margin: 5px 8px 0 0;
		font-size: 0;
		cursor: pointer;

		&::before {
			content: $icon-tooltip;
			font-size: 14px;
			border: 1px solid;
			border-radius: 50%;
		}
	}

	.b-summary_list-total_summary &-label {
		margin: 2px 0 0;
		padding-right: 0;
		font-size: 12px;
		letter-spacing: 0;
		color: $color_main;

		.l-checkout_cart-left & {
			display: none;
		}

		.l-account_content & {
			width: 100%;
			text-align: left;
		}
	}

	&-text {
		position: relative;

		div {
			position: absolute;
			top: 25px;
			left: -397px;
			width: 393px;
			padding: 20px;
			text-align: center;
			border: $border_main;
			background: $white;
			letter-spacing: 2px;
			line-height: 20px;
			z-index: 99;
			font-size: 12px;
		}
	}

	.l-checkout_cart-left & {
		&-icon {
			float: right;

			&::before {
				font-size: 12px;
				line-height: 1;
			}
		}

		&-text {
			display: inline-block;
		}
	}

	.l-account_content &-icon,
	.b-order_confirmation &-icon {
		display: none;
	}
}

/*
# Show shipping address extra fields
*/
.b-checkout_shipping_address,
.b-checkout_billing_address {
	.f-field[data-show] {
		@include t-title-h9;
		@include icon(after, plus);

		position: relative;
		padding: 7px 20px 7px 0;

		&:hover {
			cursor: pointer;
		}

		&::after {
			position: absolute;
			top: 50%;
			right: 0;
			font-size: 16px;
			transform: translateY(-50%);
		}
	}

	&-phone {
		position: relative;
		float: right;

		&-icon {
			@include icon(before, tooltip);

			float: left;
			margin: 12px 8px;
			font-size: 0;
			cursor: pointer;

			&::before {
				font-size: 16px;
				border: 1px solid;
				border-radius: 50%;
			}
		}

		&-content {
			@include font_main(16px, 18px, inherit, 0.2em);

			position: absolute;
			top: 35px;
			left: -375px;
			width: 393px;
			padding: 20px;
			text-align: center;
			border: $border_main;
			background: $white;
			z-index: 99;
		}

		& + .f-type-phone {
			width: 43.7%;
		}
	}

	&-packstation_find {
		position: relative;
		display: inline-block;
		left: 32%;
		bottom: 12px;
	}

	.f-type-postnummer {
		.f-field_description {
			margin: 6px 0 3px;
			text-transform: uppercase;
		}
	}
}

/*
# Alternative payments label
*/
.b-checkout_button-alter {
	padding: 8px 0;
	text-align: center;
}

// Paypal button
.b-express_paypal-btn {
	& > div {
		margin-bottom: 10px;

		.s-pdp & {
			position: relative;
			z-index: 2;
		}
	}
}

//Fixed overlay after form submit on checkout
.b-submit-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.5);

	&-loader {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		padding: 0 10px;
		transform: translate(-50%, -50%);
		text-align: center;

		&_spinner {
			@include loading-icon-indicator;

			width: 110px;
			height: 110px;
			margin: 0 auto 20px;
		}

		&_text {
			@include t-title-h2;

			line-height: 1.2;
		}
	}
}
