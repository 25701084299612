/* Styles for PDP Quick View */

.b-quickview-wrapper {
    padding-right: 5px;
    max-height: 82vh;

    .l-pdp_primary_content {
        display: flex;
        justify-content: space-between;
		padding: 0;
    }

    .b-product-banning-text {
        width: 100%;
    }

    .b-product_primary_image {
		max-width: 100%;
        padding: 0;

        .b-product_badge {
            right: 0;
        }
    }

    .l-quick_view {
        @include clearfix;

        max-width: 700px;
        max-height: 82vh;
        padding-right: 25px;
        overflow: hidden;

        .b-product_content > .b-content_asset--product-preorder {
            display: none;
        }
    }

    .fancybox-skin {
        padding: 0 !important;
    }

    .b-owl_carousel {
        &-item {
            float: left;
            padding: 0;
        }

        &-image {
            display: block;
            width: 100%;
        }

        &-nav {
            position: initial;

            &_prev,
            &_next {
                margin-top: -27px;
            }

            &_prev {
                left: 15px;
            }

            &_next {
                right: 15px;
            }
        }
    }

    .l-product_images_container {
        position: relative;
        width: 45%;
        max-height: none;
        margin: 0;
        padding: 0;
        overflow: hidden;

        .b-owl_carousel-item {
            height: auto;
        }
    }

    .l-product-details {
		position: initial;
        float: none;
        width: 45%;
        margin: 0;

        .b-quickview-read_more {
			display: inline-block;
			padding-bottom: 5px;
			border-bottom: $border_second;
        }
    }

    .b-product_content {
        margin-bottom: 20px;

        .b-variation {
            margin: 0;
        }

        .b-product_price-standard,
        .b-product_price-standard--line_through,
        .b-product_price-sales,
        .b-product_price-percent {
            @include t-title-h4;
        }

		.b-product_category {
			display: inline-block;
			margin-bottom: 10px;
		}
    }

	.b-product_primary_image .b-product_image {
		display: block;
		margin: 0 auto;
		max-height: none;
		max-width: 440px;
		width: 100%;
		cursor: default;
	}

    .fancybox-inner {
        height: auto !important;
        overflow: visible !important;
    }

    .fancybox-close,
    .b-cart_table-body_col_edit_details-close_button {
        top: 20px;
        right: 20px;
    }

    &_edit {
        .b-owl_carousel-nav_dots {
            bottom: 0;
        }
    }

	.s-checkout & {
		width: 750px !important;

		.fancybox-inner {
			width: 750px !important;
			overflow-y: auto !important;
		}

		.l-quick_view {
			padding-right: 0;
			overflow: visible;
		}

		.b-variation-item .b-variation-title {
			&::before {
				content: none;
			}
		}

		.b-product_content .b-swatches_color {
			flex-wrap: wrap;
			gap: 6px 4px;
		}

		.fancybox-close {
			right: 25px;
		}

		.b-product_content .b-swatches_size-link {
			font-weight: 300;
			letter-spacing: 1px;
		}

		.b-product_content .b-swatches_size-item-selected .b-swatches_size-link {
			border-bottom: none;
			color: $gold;
		}
	}
}
