.b-customer_service {
	display: flex;
	flex-direction: column;
	gap: 4px;

	&_box {
		$padding: 20px;

		padding: $padding;
		background: $grey2;

		&-whatsappsupport,
		&-shippingandreturns,
		&-customercare {
			@include icon;

			$icon-size: 16px;

			position: relative;
			padding-left: calc(#{$padding} + #{$icon-size} + 8px);

			&::before {
				position: absolute;
				left: $padding;
				top: calc(#{$padding} + 2px);
				width: $icon-size;
				height: $icon-size;
				color: $bordeaux;
				font-weight: 700;
			}
		}

		&-whatsappsupport {
			&::before {
				content: icon-char(whatsapp);
			}
		}

		&-shippingandreturns {
			&::before {
				content: icon-char(return2);
			}
		}

		&-customercare {
			&::before {
				content: icon-char(headphones);
			}
		}

		&-exclusive {
			display: none;
		}
	}

	&_button-link {
		@include t-title-h9(uppercase);

		display: inline-block;
		padding: 0;
	}
}

.b-customerservice_upper {
	margin-bottom: 44px;
}

.b-service {
	&_title {
		@include t-title-h2(uppercase);

		margin-bottom: 32px;
	}

	&_text {
		margin-bottom: 32px;
		font: inherit;

		&.b-service_icon-container {
			@include icon;

			$icon-size: 40px;

			display: flex;
			flex-direction: column;
			gap: 16px;

			// fix for Tablet ios, it adds automatically link
			a {
				border: none;
			}

			&::before {
				width: $icon-size;
				height: $icon-size;
				font-size: $icon-size;
			}

			&.m-shipping {
				&::before {
					content: icon-char(return);
				}
			}

			&.m-customerservice {
				&::before {
					content: icon-char(headphones);
				}
			}
		}
	}

	&_link {
		@include t-title-h6-h7;

		display: inline-block;
	}
}

.b-exclusive {
	max-width: 320px;

	&_image {
		width: 114px;
		height: 90px;
		aspect-ratio: 1.26;
	}

	&_content {
		margin: 24px 0;

		&-title {
			@include t-title-h6-h7(uppercase);

			margin-bottom: 12px;
		}

		&-text {
			@include t-title-h7-secondary;
		}
	}

	&_link {
		@include t-title-h9;
	}

	&_flyout-image {
		display: block;
		margin: 0 auto 90px;
		aspect-ratio: 4 / 5;
	}
}
