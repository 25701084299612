/*
#LAYOUT - Footer Checkout
*/
.l-footer_checkout {
	position: relative;
	max-width: 1060px;
	margin: 0 auto;
	padding: 45px 40px;
	clear: both;
	z-index: 2;
}

.b-copyright_footer {
    text-align: center;
}
