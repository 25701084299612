/*
#PAGE: SiteMap
*/

.b-sitemap {
    padding-top: 20px;

	&-container {
		margin: 0 10%;
	}

    &-title {
        @include t-title-h3;

		margin: 40px 0 20px;
    }

    &-list {
        letter-spacing: 0;
        font-size: 0;
        line-height: 0;
    }

    &-item {
        display: inline-block;
        margin-bottom: 30px;
        width: 25%;
        vertical-align: top;
    }

    &-1_level {
		margin-bottom: 12px;

		&_link {
			@include t-title-h2;

			border-bottom: none;

			&:hover {
				border-bottom: 1px solid currentColor;
			}
		}
    }

    &-2_level {
        padding-left: 12px;

        &_element {
			margin: 0;

			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}

		&_link {
			@include t-text-6;

			border-bottom: none;

			&:hover {
				border-bottom: 1px solid currentColor;
			}
		}
    }

	&-3_level {
        padding-left: 12px;

        &_element {
			margin: 0;

			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}

		&_link {
			@include t-navigation-link;

			border-bottom: none;

			&:hover {
				border-bottom: 1px solid currentColor;
			}
		}
    }

	&-4_level {
		padding-left: 12px;

		&_element {
			margin: 0;

			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}

		&_link {
			@include t-navigation-link;

			border-bottom: none;

			&:hover {
				border-bottom: 1px solid currentColor;
			}
		}
	}
}
