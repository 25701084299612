// ============
// @@Widgets
// ============

.b-klarna-widget {
    &.m-pdp {
        margin: 20px 0;
    }
}

.b-content-picture {
	img {
		display: block;
		width: 100%;
		height: auto;
	}
}
