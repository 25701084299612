/*
#BUTTONS
##Button styles

If we need to modify button styles inside a context - create mixin and put it to mixins library
```
<button class="g-button">Button copy</button>
```
*/

@mixin g-button(
	$display: flex,
	$width: 100%,
	$max_width: 250px,
	$height: 44px,
	$padding: 14px 48px,
	$margin: 0 0 20px,
	$font-size: 14px,
	$line-height: 16px,
	$letter-spacing: $ls-second,
	$text-transform: none,
	$bg_color: transparent,
	$bg_color_hover: $color_main,
	$color: $white,
	$color_hover: $color_second,
	$border: none,
	$border_color: currentColor,
	$border_color_hover: currentColor
	) {
	display: $display;
	justify-content: center;
	align-items: center;
	width: $width;
	max-width: $max_width;
	height: $height;
	padding: $padding;
	margin: $margin;
	font-size: $font-size;
	letter-spacing: $letter-spacing;
	line-height: 16px;
    border: $border;
	border-radius: 0;
    background-color: $bg_color;
	color: $color;
	text-decoration: none;
	text-align: center;
	font-weight: 325;
	text-transform: $text-transform;
	cursor: pointer;
	transition: all 0.2s ease-in;

	&:hover,
	&:active,
	&:focus {
		border: $border_main;
		background-color: $white;
		color: $color_main;
		text-decoration: none;
	}

	&[disabled] {
		background: $grey3;
		color: $white;
		border-color: $grey3;
	}

	&::-moz-focus-inner {
		padding: 0;
		border: 0;
	}
}

button,
.g-button,
%g-button {
    @extend %g-button-primary;
}

.g-button-primary,
%g-button-primary {
	@include g-button(
		$color: $white,
		$bg_color: $gold,
		$border_color: $gold,
		$color_hover: $white,
		$bg_color_hover: $color_main,
		$border_color_hover: $color_main
	);
}

.g-button-white,
%g-button-white {
    @include g-button(
		$color: $black,
		$bg_color: transparent,
		$border_color: $black,
		$color_hover: $white,
		$bg_color_hover: $color_second,
		$border_color_hover: $color_second
	);
}

.g-button-dark,
%g-button-dark {
    @include g-button(
		$color: $color_second,
		$bg_color: $color_third,
		$border_color: $color_third,
		$color_hover: $color_third,
		$bg_color_hover: $color_second,
		$border_color_hover: $color_second
	);
}

@mixin g-button-with-icon {
	&::before {
		padding-right: 12px;
	}
}

a.g-button-dark {
	@include animation_underline_reset;
	@extend %g-button-dark;
}

button,
input[type="button"],
input[type="submit"] {
    @extend %g-button-primary;

    &[disabled='disabled'] {
        &:hover,
		&:active,
		&:focus {
			background-color: transparent;
			color: $color_main;
			border-color: $color_main;
		}
    }
}

.g-button_link-white,
%g-button_link-white,
.g-button_link-white--small,
%g-button_link-white--small {
	color: $white;
}

.g-button_link,
%g-button_link,
.g-button_link-white,
%g-button_link-white {
    @include button_reset;
    @include t-title-h7;
	@include underline;

	height: auto;
	border-radius: 0;
	border: 0;
}

.g-button_link-dark,
%g-button_link-dark {
	@extend %g-button_link;

	color: $color_main;
}

.g-button_link--small,
%g-button_link--small,
.g-button_link-white--small,
%g-button_link-white--small {
	@include font_main(12px, 15px, $font_second, $ls-main);
}

.g-button_link-dark--small,
%g-button_link-dark--small {
	@extend %g-button_link-dark;

	@include font_main(12px, 15px, $font_second, $ls-main);
}

.g-close_button {
    position: absolute;
    display: block;
    right: -10px;
    top: 0;
    cursor: pointer;
}

.l-close,
%l-close {
    @include icon(before, close);

    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 13px;
    cursor: pointer;
}

.g-button-reset,
%g-button-reset {
	@include g-button(
	$display: inline,
	$width: auto,
	$max_width: none,
	$height: auto,
	$padding: 0,
	$margin: 0,
	$font-size: 14px,
	$line-height: 16px,
	$letter-spacing: $ls-second,
	$text-transform: none,
	$bg_color: transparent,
	$bg_color_hover: transparent,
	$color: $color_main,
	$color_hover: $color_main,
	$border_color: transparent,
	$border_color_hover: transparent
	);

	border: 0;

	&:hover {
		background-color: $white;
		border-color: $white;
	}
}

.g-button-secondary,
%g-button-secondary {
    @include g-button(
		$width: auto,
		$bg_color: transparent,
		$color: $color_main,
		$border: $border_main,
	);

	&:hover,
	&:active,
	&:focus {
		background-color: $color_second;
		color: $white;
		border: $border_second;
	}
}

.g-button-card,
%g-button-card {
	@include t-title-h6-h7;
    @include g-button(
		$max_width: 100%,
		$bg_color: $gold
	);
}
