/* Homepage */
.l-hp-slot-1,
.l-hp-slot-2,
.l-hp-slot-3,
.l-hp-slot-4,
.l-hp-slot-5,
.l-hp-slot-6,
.l-hp-slot-7 {
    @include clearfix;

    clear: both;
    text-align: center;
    font-family: $font_second;

    [class*='arrow'] {
        @include underline_remove;
    }
}

.l-homepage {
    &-hero_banner {
		@include clearfix;

        position: relative;
        margin: 0 0 60px;

        &-narrow {
            margin-left: 8%;
            margin-right: 8%;
        }

        &-info {
            &_title {
                margin: 0 0 10px;
                font-size: 30px;
            }

            &_subtitle {
                @include font_main(16px, 26px, 0.1em);
            }
        }

        &-info_bottom {
            width: 100%;
            margin: 22px auto 0;
            text-align: center;

            @include respond-to(tablet) {
                width: 80%;
            }
        }

        img {
            width: 100%;
        }
    }

    &-mood_banner {
        position: relative;
        float: left;
        width: 49%;

        &:last-child {
            float: right;
        }

        img {
            width: 100%;
            height: auto;
        }

        &_info {
            padding: 0 15%;

            @include respond-to(tablet) {
                padding-right: 4%;
                padding-left: 4%;
            }
        }
    }

    &-lookbook {
        display: table;
        width: 100%;
        margin: 0 0 60px;

        &_info {
            display: table-cell;
            width: 34%;
            vertical-align: middle;

            &-wrapper {
                padding: 0 15%;
            }
        }

        &_metro {
            display: table-cell;
            width: 33%;

            img {
                float: left;
                width: 50%;
            }
        }

        &_image {
            display: table-cell;
            width: 33%;

            img {
                width: 100%;
                float: left;
            }

            &-wide {
                width: 66%;
            }
        }
    }

    &-single_product {
        display: table;
        width: 100%;
        padding: 0 4%;
        margin: 0 0 60px;

        .b-product_tile {
            display: table-cell;
            width: 50%;
            margin: 0;

            &:hover {
                .b-productile_hover {
                    display: none;
                }
            }

            .b-product {
                &_name,
                &_price {
                    display: none;
                }
            }
        }

        &-info {
            display: table-cell;
            width: 50%;
            padding: 0 12%;
            vertical-align: middle;
        }
    }

    &-title {
        @include t-title-h1;

        margin: 22px 0 10px;

        @include respond-to(tablet-portrait) {
            font-size: 28px;
            line-height: 32px;
        }

        @include respond-to(tablet-landscape) {
            font-size: 26px;
            line-height: 30px;
        }
    }

    &-subtitle {
        @include font_main(16px, 26px);

        @include respond-to(tablet-portrait) {
            line-height: 24px;
        }

        @include respond-to(tablet-landscape) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    &-link {
        @extend %g-button;

        display: inline-block;
        margin: 20px 0 0;
        font-size: 16px;
        color: $white;
        border: $border_main;

        &:hover {
            color: $color_main;
            background-color: $white;
        }
    }

    &-module_banner {
        margin: 40px -2% 0;
        padding: 50px 2% 0;
        border-top: 1px solid $color_second;

        &-wrapper {
            @include clearfix;

            &_left,
            &_middle,
            &_right {
                float: left;
                width: 33%;
                padding: 0 20px;
            }
        }

        &-item {
            margin: 40px 0 0;

            .l-homepage-title {
                font-size: 14px;
            }

            img {
                display: block;
                width: 100%;
            }
        }
    }

    &-mood_carousel {
        padding: 0 2%;
        margin-bottom: 60px;
        width: 100%;

        &-item {
            padding: 0 3%;

            img {
                display: block;
                width: 100%;
            }
        }

        .l-homepage-title {
            font-size: 14px;
        }

        .b-owl_carousel-item {
            margin: 0 -1px 0 0 !important;
        }
    }
}

.varying_block {
    .l-homepage-hero_banner-info,
    .l-homepage {
        &_subtitle,
        &-subtitle {
            font-size: 16px;


            @include respond-to(tablet) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

.full-screen-slider {
    position: relative;
    height: calc(100vh - 153px);
    margin-bottom: 95px;
    overflow: hidden;

    @include respond-to(nav) {
        height: calc(100vh - 66px);
    }


    @include respond-to(portrait_tablet) {
        height: 505px;
    }


    .js-owl_carousel {
        visibility: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 50px;
            height: 50px;
            margin: -25px 0 0 -25px;
            visibility: visible;

            @include loading-icon-indicator;
        }
    }

    .b-owl_carousel {
        height: calc(100vh - 153px);
        color: $color_main;

        &.owl-loaded {
            visibility: visible;

            &::before {
                display: none;
            }
        }

        @include respond-to(nav) {
            height: calc(100vh - 66px);
        }

        @include respond-to(to_portrait_tablet) {
            .owl-stage-outer,
            .owl-stage {
                height: 100% !important;
            }
        }

        @include respond-to(portrait_tablet) {
            height: 505px;
        }

        &-item {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &-nav {
            &_dots {
                position: absolute;
                right: 0;
                bottom: 8px;
                left: 0;
                color: $color_main;

                @include respond-to(until-laptop) {
                    bottom: 8px;
                }
            }

            &_dot {
                @include font_main(13px, 20px, $font_main);

                width: auto;
                height: auto;
                margin-right: 12px;
                margin-left: 12px;

                &.active {
                    border-bottom: 1px solid $color_main;
                }
            }

            &_prev {
                left: 38px;
            }

            &_next {
                right: 38px;
            }
        }
    }

    .assets_slider_with_thumbs-slides_item {
        height: 100%;
    }

    .fw-slider {
        &-img {
            height: calc(100vh - 153px);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;

            @include respond-to(nav) {
                height: calc(100vh - 66px);
            }


            @include respond-to(portrait_tablet) {
                height: 505px;
            }
        }

        &-link {
            position: relative;
            display: block;
            padding: 0;
            z-index: 5;
        }

        &-text {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 0;
            width: 100%;
            height: 100%;

            &-inner {
                position: relative;
                width: 40%;
                margin: 0 auto;
                z-index: 10;
            }

            &_title {
                @include t-title-h1;

                margin-bottom: 23px;
            }

            &_button {
                @include t-title-h8;
            }

            &_label {
                @include t-title-h9;

                display: block;
                margin-bottom: 22px;
            }
        }
    }
}

.b-hp_product_slider {
    position: relative;
    margin: 0 50px;
    padding-bottom: 80px;

    .js-owl_carousel {
        visibility: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30px;
            height: 30px;
            margin: -15px 0 0 -15px;
            visibility: visible;

            @include loading-icon-indicator;
        }
    }

    @include respond-to(until-laptop) {
        padding-top: 97px;
        padding-bottom: 94px;
    }


    &-title {
		@include t-text-4;

		margin-top: 60px;
		margin-bottom: 7px;
    }

    &-link {
        margin-bottom: 31px;

        @include respond-to(until-laptop) {
            margin-bottom: 53px;
        }

        &-arrow {
            @include t-title-h4;
            @include icon(before, arrow-link);

            &:hover {
                border-bottom-color: transparent;
            }
        }
    }

    .b-product {
        &_tile {
            width: 100%;
            margin: 0;

            &:hover {
                .b-productile_hover,
                .b-add_to_wishlist {
                    display: none;
                }
            }
        }
    }

    .b-owl_carousel {
        &.owl-loaded {
            visibility: visible;

            &::before {
                display: none;
            }
        }

		&-nav_dots {
			@extend .h-hidden;
		}
    }
}

.asset-table-cell {
    display: table;
    width: 100%;
    font-size: 0;

    .b-asset_body-section {
        display: table-cell;
        width: 50%;
        height: 100%;
        vertical-align: middle;
    }

    &.three-in-row {
        padding: 0 20px;

        .b-asset_body-section {
            width: 33.33%;
            padding: 0 20px 74px;
            vertical-align: top;

            &_text {
                width: 90%;
            }

            &_img {
                max-width: 100%;
            }

            &_link {
                margin-top: 10px;
            }
        }
    }
}

.asset-half-text-img {
    margin: 0 40px 95px;
    width: calc(100% - 80px);

    .b-asset_body {
        &-section {
            padding: 0 30px;

            .clear {
                height: 0;
                clear: both;
            }

            &.text-block {
                padding: 0 10.45%;

                @include respond-to(until-laptop) {
                    padding: 0 5.2%;
                }
            }

            &_title {
                @include t-title-h9;

                margin: 0 0 10px;

                @include respond-to(until-laptop) {
                    margin-bottom: 9px;
                }
            }

            &_text {
                @include t-title-h2;

                margin: 0;
                text-align: center;
            }

            &_img {
                max-width: 100%;

                @include respond-to(after-laptop) {
                    .l-home-page & {
                        width: 100%;
                        height: auto !important;
                    }
                }
            }

            &_imgs {
                float: left;
                width: calc(100% / 3);

                &.big_img {
                    float: right;
                    width: calc(100% / 3 * 2);
                }

                img {
                    display: block;
                    width: 100%;

                    &.hide {
                        display: none;
                    }
                }
            }

            &_imglink {
                display: none;
                padding: 0;
                border: none;
                vertical-align: top;

                &.show,
                img {
                    display: block;
                }
            }
        }
    }
}

.asset-text-under-img {
    .b-asset_body-section {
        padding-top: 100px;
        padding-bottom: 92px;

        &_title {
            margin-top: 25px;
            margin-bottom: 12px;

            @include respond-to(until-laptop) {
                margin-top: 26px;
            }
        }

        &_text {
            margin: 0 auto;
            width: 315px;
        }

        &_link {
            display: inline-block;
            margin-top: 9px;
            vertical-align: top;
        }

        &_img {
            max-width: 100%;
        }
    }
}

.asset-video-cell {
    padding-bottom: 90px;

    @include respond-to(until-laptop) {
        padding-bottom: 95px;
    }


    .mejs-container {
        margin: 0 auto;
    }

    .slot-video {
        width: 100%;
    }

    .b-asset_body,
    .b-lp-asset-body {
        &-section {
            width: 100%;
            margin: 0 auto;

            video,
            .slot-video {
                width: 100% !important;
            }

            .me-plugin,
            .mejs-poster {
                width: 100% !important;
                height: 100% !important;
                background-size: cover;
                z-index: 3;
            }

            .mejs-mediaelement {
                z-index: 1;
            }

            .mejs-container .mejs-controls {
                z-index: 2;

                &.mejs-offscreen {
                    z-index: 6;
                }
            }

            .mejs-overlay-play {
                height: 100% !important;
                z-index: 5;

                .mejs-overlay-button {
                    width: 28px;
                    height: 36px;
                    margin: -18px 0 0 -14px;
                    background: url("../images/play_button.png") no-repeat;
                }
            }

            @include respond-to(vertical) {
                width: 100%;

                video,
                .slot-video {
                    width: 100% !important;
                    height: 366px !important;
                }
            }


            &_title {
                @include t-title-h2;

                margin: 12px 0 5px;
                text-transform: none;
            }

            &_link-arrow {
                @include t-title-h4;
                @include icon(before, arrow-link);

                &:hover {
                    border-bottom-color: transparent;
                }
            }
        }
    }
}

.b-reftab {
	&-wrapper {
		width: 100%;
		max-width: 1150px;
		margin: auto;

		a,
		a:hover,
		a:visited,
		a:active {
			text-decoration: none;
		}
	}

	&-tabs {
		display: flex;

		&_item {
			width: 100%;
			padding: 0 20px;
			font-size: 14px;

			&-title {
				padding: 20px 0;
				background-color: $color_main;

				&,
				&--link {
					color: $white;
				}
			}

			&-content {
				&--description {
					padding: 15px 0;
					font-weight: 350;
				}

				&--refinements {
					margin: -10px 0;

					&-link {
						display: inline-block;
						margin: 10px;
						height: 50px;
						padding: 0 15px;
						color: $color_main;
						border: 1px solid $color_main;
						text-align: center;
						line-height: 50px;
						transition: 500ms ease-in-out;

						&:hover {
							background-color: $color_main;
							color: $white;
						}

						&.size {
							padding: 0;
							width: 50px;
						}
					}
				}

				&--cta-link {
					@extend %g-button;

					margin: 50px auto 0;
				}
			}
		}
	}
}
