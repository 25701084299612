/*
*	Order Second step
*/

.b-checkout_blacklist {
	margin-top: 5px;

	.b-content_asset--blacklist-customer-notification {
		color: $validation-color;

		a {
			color: $validation-color;

			&:hover {
				border-bottom-color: $validation-color;
			}
		}
	}
}
