/*
## LAYOUT - Checkout Main Section
*/

.l-main_checkout {
    position: relative;
    max-width: 1060px;
    margin: 0 auto;
    padding: 0 40px;

    @include clearfix;

    @include respond-to(all) {
        width: 980px;
    }


    .b-checkout {
        &_shipping_address,
        &_billing_address {
            &-title {
                margin-bottom: 50px;
            }
        }

        &_privacy {
            &,
            h2 {
                @include t-title-h8;
            }
        }
    }

    .sales-tax {
        .b-checkout {
            &_shipping_address,
            &_billing_address {
                &-title {
                    margin-bottom: 25px;
                }
            }
        }
    }

	.b-checkout_button {
		margin-bottom: 20px;
		max-width: none;
	}
}

/*
## BLOCK - Checkout Progress Indicator
*/

.b-checkout_progress_indicator {
    display: flex;
	align-items: center;
    margin: 0;
    padding: 0;
	gap: 55px;
    list-style-type: decimal;
    list-style-position: inside;
    z-index: 3;

    &-step {
        padding-bottom: 2px;
        cursor: pointer;

        @include t-title-h9(uppercase);

        text-transform: uppercase;

        &--active {
            @include t-title-h5(uppercase);

			&::marker {
				@include t-title-h8(uppercase);
			}
        }
    }
}

/*
## BLOCK - Summary List
*/
.b-summary_list {
    position: relative;
    margin-top: 25px;
    padding: 28px 0 0;
    z-index: 101;

    &-title {
        @include t-title-h8(uppercase);

        margin: 0 0 18px;
    }

    &-line {
        @include clearfix;

        padding: 0 0 20px;
    }

	&-label,
	&-value {
		width: 50%;
	}

    &-label {
		@include t-text-6;

        float: left;
        padding-right: 5%;

        &_total {
            float: left;
        }
    }

	&-value {
		@include t-text-6;

		float: right;
		text-align: right;
	}

    &-total_summary {
        border-top: $border_main;

        .b-summary_list-label:nth-child(1) {
            @include t-title-h6;

            width: 55%;
        }

        .b-summary_list-value:nth-child(2) {
            @include t-title-h6;

            width: 45%;
        }
    }
}

/*
## BLOCK - Checkout Payment section
*/
.b-checkout_payment {
	.f-label {
		@include t-title-h11;

		padding-top: 18px;
	}

    &-cvn_block {
        position: relative;
        clear: both;

        &-field {
            .f-textinput {
                width: 45%;
            }

            .f-error_message {
                display: block;
            }
        }

        &-cvn_tip {
            position: absolute;
            left: 66%;
            top: 15px;

			.g-tooltip-link {
				@include t-text-8(uppercase);
			}
        }
    }

    &-wrapper {
        @include clearfix;

        .f-field-select.month,
        .f-field-select.f-type-dynamic_year {
            .f-label,
            .f-label-value {
                display: none;
            }

            .f-field-wrapper {
                float: left;
                margin: 0 20px 0 0;
            }

            &.f-state-valid {
                .f-error_message {
                    display: none;
                }
            }
        }
    }
}

.b-checkout_payment-name_card {
    .f-field {
        margin-bottom: 5px;

        &-wrapper {
            width: 100%;
        }

        &_description {
            @include t-title-h10;

            margin: 6px 0 3px;
        }
    }

    .f-state-error .f-field_description {
        display: none;
    }
}

.b-checkout_payment-exp_date-month,
.b-checkout_payment-exp_date-year {
    float: left;
    width: 25%;
    font-size: 12px;
    vertical-align: top;
}

.b-cardtypes {
    padding-left: 32%;
    margin-bottom: 20px;

    > ul {
        @include clearfix;

        li {
            float: left;

            &.b-cardsize {
                margin-right: 5px;
                background-size: 41px 25px;
            }
        }
    }

    &-visa,
    &-master,
    &-amex,
    &-maestro,
    &-jcb,
    &-discover,
    &-diners,
    &-dinersclub,
    &-visadebit,
    &-visaelectron {
        display: inline-block;
        height: 25px;
        width: 41px;
    }

    &-visa {
        background: $visa;

        &.off {
            background: $visa-off;
        }
    }

    &-master {
        background: $master;

        &.off {
            background: $master-off;
        }
    }

    &-amex {
        background: $amex;

        &.off {
            background: $amex-off;
        }
    }

    &-maestro {
        background: $maestro;

        &.off {
            background: $maestro-off;
        }
    }

    &-jcb {
        background: $jcb;

        &.off {
            background: $jcb-off;
        }
    }

    &-discover {
        background: $discover;

        &.off {
            background: $discover-off;
        }
    }

    &-diners {
        background: $diners;

        &.off {
            background: $diners-off;
        }
    }

    &-dinersclub {
        background: $dinersclub;

        &.off {
            background: $dinersclub-off;
        }
    }

    &-visadebit {
        background: $visadebit;

        &.off {
            background: $visadebit-off;
        }
    }

    &-visaelectron {
        background: $visaelectron;

        &.off {
            background: $visaelectron-off;
        }
    }
}

/*
#BLOCK - Order Confirmation
*/
.b-order_confirmation {
    &-message {
        padding: 0 0 20px;

        p {
            @include t-title-h9;
        }
    }

    &-message_asset {
        @include t-title-h9;
    }

    &-details_wrapper {
        margin: 20px 0 0;
        padding: 30px 0 20px;
        border-top: $border_main;
    }

    &-order_information {
        padding: 0 0 15px;
        border-bottom: $border_main;

        &-row {
            @include t-title-h9;

            padding: 0 0 8px;
        }
    }

    &-shipping_bundle {
        & + & {
            border-top: $border_main;
        }
    }

    &-product_list_wrapper {
        @include t-title-h5;

        margin: 0 0 10px;
    }

    &-product_list {
        padding: 20px 0;
        font-size: 0;
        letter-spacing: 0;

		&-product_image,
		&-product_details {
			@include t-text-6;

			display: inline-block;
			vertical-align: top;
			text-align: left;
		}

		& div.b-order_confirmation-product_list-product_price {
			@include t-text-6;

			display: inline-block;
			vertical-align: top;
			text-transform: none;
		}

        &-product_image {
            padding-right: 10px;
            width: 19%;
        }

        &-product_details {
            @include t-title-h9;

            padding: 0 15px;
            width: 56%;
            word-break: break-all;
        }

        &-product_price {
            @include t-title-h9;

            width: 25%;
            text-align: right;

            &-price {
                margin-top: 18px;
            }
        }
    }

    &-summary_list {
        margin-bottom: 30px;

        .b-summary_list {
            padding: 0;
            z-index: 0;

            &-line {
                padding: 10px 0 13px;
            }

            &-total_summary,
            &-total_due {
                margin-top: 10px;
                padding: 10px 0;
                border-top: $border_main;
            }

			&-label {
				@include t-text-6;
			}
        }

        h2 {
            display: none;
        }
    }

    &-order_addresses {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

		&-shipping,
		&-billing,
		&-payment {
			@include t-title-h8;

			width: 32%;
		}

		&-heading {
			@include t-title-h9(uppercase);

			margin-bottom: 10px;
		}

        &-shipping_info {
            margin: 10px 0;
        }
    }

	&-payment_type {
		@include t-title-h9;

		padding-top: 15px;
	}

    &-shipping_method,
    &-shipping_status,
    &-payment_amount,
    &-estimated_delivery,
    &-shipping_bundle-row {
        @include t-title-h9;

        padding: 8px 0 0;
    }

    &-payment_amount {
        padding-bottom: 15px;
    }

    &-payment_type_name {
        padding-bottom: 10px;
    }

    &-shipping_status-data_value,
    &-data_value {
        text-transform: none;
    }
}

.b-checkout_content_block {
    .f-label {
        display: block;
    }

    .f-field-textinput,
    .f-field-email {
        .f-field-wrapper {
            width: 100%;
        }
    }
}

//Print version
@media print {
    .l-header_checkout .b-primary_logo {
        left: 10%;
    }

    .b-checkout_progress_indicator,
    .l-header_service_menu-checkout,
    .l-checkout_cart-right {
        display: none;
    }

    .l-checkout_cart-left {
        width: 90%;
    }
}

//Cash on delivery block
.b-cash_on_delivery {
    &-phone_block,
    &-code_block {
        @include clearfix;

        width: 68%;
        float: right;

        &-button {
            @extend %g-button-primary;

            float: right;
            width: auto;
            max-width: 28%;
        }
    }

    .f-type-phonecode,
    .f-type-phone,
    .f-type-code {
        float: left;

        .f-field-wrapper {
            width: 100%;
            margin: 0;
        }
    }

    .f-type-phonecode {
        position: static;
        width: 20%;
        margin-bottom: 0;

        .f-label {
            width: 30%;
        }
    }

    .f-type-phone {
        width: 45%;
        margin-bottom: 0;

        .f-label {
            display: none;
        }
    }

    .f-type-code {
        position: static;
        width: 68%;
        margin: 0;

        & + .f-error_message {
            margin: -5px 0 15px;
        }
    }

    .f-label {
        position: absolute;
        left: 0;
    }

    .f-error_message,
    .f-valid_message {
        @include t-title-h7;

        float: left;
    }

    .f-error_message {
        color: $validation-color;
    }

    .f-valid_message {
        clear: both;
        margin: 0 0 10px;
    }
}

.b-content_asset- {
    &-use-as-billing-address-info {
        margin: 0 0 50px;
    }
}

/*
## BLOCK - Bag recap
*/
.b-bag_recap {
    overflow: hidden;

    @include respond-to(all) {
        overflow-y: auto;
    }


    &-title {
        @include t-title-h8(uppercase);

		display: inline-block;

        & + a {
            @include t-title-h7(uppercase);

            float: right;

            &:hover {
                text-decoration: none;
            }
        }

        &-section {
            padding: 10px 0 34px;
        }
    }

    &_wrapper {
        position: relative;

        .b-scroll {
            &-y {
                top: 0;
                right: -8px;
            }

            &-bar {
                &_size {
                    height: 100%;
                    width: 5px;
                    border-right: 1px solid $color_second;
                    opacity: .2;
                }

                &_control {
                    width: 5px;
                    background: $color_main;
                    border: $border_main;
                    opacity: .5;
                }
            }
        }
    }

    &_products {
        padding: 0 0 17px;
        text-align: left;


        .b-mini_cart-product {
            &_name {
                order: -1;
                margin: 0 0 10px;
                text-align: left;
				line-height: 20px;

                &-link {
					@include t-text-6;
					@include animated-underline;

                    padding-top: 0;
                }
            }

            &_attributes,
            &_pricing-qty {
                &,
                .b-cart_table-body_col_product-attribute {
                    display: block;
                    text-align: left;

                    &-label,
                    &-value {
                        display: inline-block;
                    }
                }
            }

            &_pricing {
                text-align: left;
            }
        }
    }

    .l-mini_cart {
        &-image,
        &-product_info {
            width: 50%;
        }

        &-image {
            max-width: 100%;
            padding-right: 10px;
        }

        &-product {
            display: flex;
            margin-top: 0;

            & + .l-mini_cart-product {
                padding-top: 20px;
            }

            &_info {
                margin: 0;
                padding: 0 0 0 10px;
            }
        }
    }

    &.js-scroll .l-mini_cart-product_info {
        padding-right: 5px;
    }

    &_scroll-area {
        position: relative;
        overflow: hidden;
    }
}

.b-sale-tax {
	&-container {
		position: relative;

		.l-checkout_cart-left & {
			display: none;
		}
	}

	&-content {
		.b-content_asset--checkout-sale-tax-info {
			position: absolute;
			width: 393px;
			left: -397px;
			top: 15px;
			padding: 30px 20px;
			text-align: center;
			border: 1px solid $color_main;
			background-color: $white;
			z-index: 99;
		}
	}

	&-icon {
		@include icon(before);

		position: relative;
		display: inline-flex;
		justify-content: center;
		width: 24px;
		height: 24px;
		padding: 0;
		margin: 0 8px 0 0;
		vertical-align: middle;
		border: 1px solid $color_main;
		border-radius: 50%;
		color: $color_main;
		background: none;

		&::before {
			content: 'i';
		}

		&:hover {
			border: 1px solid $color_main;
			background: none;
			color: $color_main;
		}
	}

	&-label {
		@include t-title-h10;

		display: inline-block;
		width: auto;
		float: none;
	}

	&-info-close_button {
		@extend %g-button-reset;
		@extend %l-close;

		position: absolute;
		top: 25px;
		left: -35px;
		width: 16px;
		z-index: 110;
		background: none;
		border: 0;

		&:hover {
			border: 0;
		}
	}
}
