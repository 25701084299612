/*
#Page - Account Registration
*/

/*
#Block - Account Registration
*/
.b-account_registration {
    &-form {
        display: flex;
        flex-direction: column;

        .b-account-social-wrapper {
            order: 2;
        }

        .b-login_account-privacy_box {
            display: block;
            margin-left: 32%;
        }
    }

	&-general_information {
		order: 1;
		margin-bottom: 25px;
	}

	&-email_information {
		order: 3;
	}

	&-title {
		@include t-title-h8(uppercase);
	}

	&-button_submit {
		@extend %g-button-primary;

		float: right;
		width: 100%;
		margin: 20px 0;
	}

    &-newsletter_field {
        margin-bottom: 12px;
    }

    &-sections {
        clear: both;

        &_label {
            @include t-title-h7;

            margin-left: 32%;
        }

        &_wrapper {
            .f-field {
                margin-bottom: 0;

                &.f-field-checkbox:last-child {
                    margin-bottom: 20px;
                }

                .f-error_message {
                    position: static;
                }

                .f-error_text {
                    clear: both;
                }
            }
        }

        .f-label {
            padding-left: 35px;

            &-value {
                @include t-title-h7;

                text-transform: none;
            }
        }
    }

    &_policy {
        .f-field {
            margin-bottom: 15px;
        }

        .b-content_asset {
            @include t-title-h7;

            margin-bottom: 30px;
            margin-left: 32%;
        }
    }

    .f-field {
        @include clearfix;

        &-wrapper {
            @include myaccount_field_wrapper;
        }
    }

    &-label_required {
        @include t-title-h9;

        padding: 0 0 0 32%;
        color: $color_main;

        &::after {
            @include required_fields;
        }
    }

    &-salutation_field {
        .f-field-wrapper {
            width: calc(22.66% - 13.33px);
            float: left;
        }
    }

    &-birthday_fields {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        line-height: 1;
    }

    &-birthday_day_field,
    &-birthday_month_field,
    &-birthday_year_field {
        .f-error_message {
            display: none;
        }
    }

    &-birthday_month_field,
    &-birthday_year_field {
        width: calc(22.66% - 13.33px);

        .f-field-wrapper {
            width: 100%;
        }
    }

    &-birthday_month_field {
        margin-right: 20px;
    }

    .f-type-phonecode,
    &-birthday_day_field {
        width: 55.56%;

        .f-field-wrapper {
            width: calc(40.79% - 13.33px);
            margin-right: 20px;
        }
    }

    &-phone_field {
        width: 44.44%;

        .f-field-wrapper {
            width: 100%;
        }
    }

    &-phone_field,
    &-password_field {
        .f-field_description {
            display: none;
        }
    }

    .b-account_registration-birthday_day,
    .b-account_registration-birthday_month_field,
    .b-account_registration-birthday_year_field,
    .f-type-phonecode,
    .b-account_registration-phone_field {
        clear: none;
    }

    .f-wrapper-f-type-allowtocollect {
        width: calc(100% - 130px);

        .f-field-message {
            clear: none;
        }

        .f-field-wrapper {
            float: left;
        }
    }

    .b-date_fields_error {
        @include t-title-h10;

        display: none;
        margin: -24px 0 8px;
        padding: 0 0 0 32%;
        color: $validation-color;
    }

    .f-field-password.f-state-error {
        .f-field_description {
            display: none;
        }
    }

    .b-content_asset--registration-pp-disclaimer {
        float: right;
        margin-bottom: 20px;
        clear: both;
    }
}
